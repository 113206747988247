const areaData = [
  {
    id: '110101',
    name: '东城区',
    short_name: '东城',
    f_id: '110100',
  },
  {
    id: '110102',
    name: '西城区',
    short_name: '西城',
    f_id: '110100',
  },
  {
    id: '110105',
    name: '朝阳区',
    short_name: '朝阳',
    f_id: '110100',
  },
  {
    id: '110106',
    name: '丰台区',
    short_name: '丰台',
    f_id: '110100',
  },
  {
    id: '110107',
    name: '石景山区',
    short_name: '石景山',
    f_id: '110100',
  },
  {
    id: '110108',
    name: '海淀区',
    short_name: '海淀',
    f_id: '110100',
  },
  {
    id: '110109',
    name: '门头沟区',
    short_name: '门头沟',
    f_id: '110100',
  },
  {
    id: '110111',
    name: '房山区',
    short_name: '房山',
    f_id: '110100',
  },
  {
    id: '110112',
    name: '通州区',
    short_name: '通州',
    f_id: '110100',
  },
  {
    id: '110113',
    name: '顺义区',
    short_name: '顺义',
    f_id: '110100',
  },
  {
    id: '110114',
    name: '昌平区',
    short_name: '昌平',
    f_id: '110100',
  },
  {
    id: '110115',
    name: '大兴区',
    short_name: '大兴',
    f_id: '110100',
  },
  {
    id: '110116',
    name: '怀柔区',
    short_name: '怀柔',
    f_id: '110100',
  },
  {
    id: '110117',
    name: '平谷区',
    short_name: '平谷',
    f_id: '110100',
  },
  {
    id: '110228',
    name: '密云县',
    short_name: '密云',
    f_id: '110100',
  },
  {
    id: '110229',
    name: '延庆县',
    short_name: '延庆',
    f_id: '110100',
  },
  {
    id: '120101',
    name: '和平区',
    short_name: '和平',
    f_id: '120100',
  },
  {
    id: '120102',
    name: '河东区',
    short_name: '河东',
    f_id: '120100',
  },
  {
    id: '120103',
    name: '河西区',
    short_name: '河西',
    f_id: '120100',
  },
  {
    id: '120104',
    name: '南开区',
    short_name: '南开',
    f_id: '120100',
  },
  {
    id: '120105',
    name: '河北区',
    short_name: '河北',
    f_id: '120100',
  },
  {
    id: '120106',
    name: '红桥区',
    short_name: '红桥',
    f_id: '120100',
  },
  {
    id: '120110',
    name: '东丽区',
    short_name: '东丽',
    f_id: '120100',
  },
  {
    id: '120111',
    name: '西青区',
    short_name: '西青',
    f_id: '120100',
  },
  {
    id: '120112',
    name: '津南区',
    short_name: '津南',
    f_id: '120100',
  },
  {
    id: '120113',
    name: '北辰区',
    short_name: '北辰',
    f_id: '120100',
  },
  {
    id: '120114',
    name: '武清区',
    short_name: '武清',
    f_id: '120100',
  },
  {
    id: '120115',
    name: '宝坻区',
    short_name: '宝坻',
    f_id: '120100',
  },
  {
    id: '120116',
    name: '滨海新区',
    short_name: '滨海',
    f_id: '120100',
  },
  {
    id: '120221',
    name: '宁河县',
    short_name: '宁河',
    f_id: '120100',
  },
  {
    id: '120223',
    name: '静海县',
    short_name: '静海',
    f_id: '120100',
  },
  {
    id: '120225',
    name: '蓟县',
    short_name: '蓟县',
    f_id: '120100',
  },
  {
    id: '130102',
    name: '长安区',
    short_name: '长安',
    f_id: '130100',
  },
  {
    id: '130103',
    name: '桥东区',
    short_name: '桥东',
    f_id: '130100',
  },
  {
    id: '130104',
    name: '桥西区',
    short_name: '桥西',
    f_id: '130100',
  },
  {
    id: '130105',
    name: '新华区',
    short_name: '新华',
    f_id: '130100',
  },
  {
    id: '130107',
    name: '井陉矿区',
    short_name: '井陉矿',
    f_id: '130100',
  },
  {
    id: '130108',
    name: '裕华区',
    short_name: '裕华',
    f_id: '130100',
  },
  {
    id: '130121',
    name: '井陉县',
    short_name: '井陉',
    f_id: '130100',
  },
  {
    id: '130123',
    name: '正定县',
    short_name: '正定',
    f_id: '130100',
  },
  {
    id: '130124',
    name: '栾城县',
    short_name: '栾城',
    f_id: '130100',
  },
  {
    id: '130125',
    name: '行唐县',
    short_name: '行唐',
    f_id: '130100',
  },
  {
    id: '130126',
    name: '灵寿县',
    short_name: '灵寿',
    f_id: '130100',
  },
  {
    id: '130127',
    name: '高邑县',
    short_name: '高邑',
    f_id: '130100',
  },
  {
    id: '130128',
    name: '深泽县',
    short_name: '深泽',
    f_id: '130100',
  },
  {
    id: '130129',
    name: '赞皇县',
    short_name: '赞皇',
    f_id: '130100',
  },
  {
    id: '130130',
    name: '无极县',
    short_name: '无极',
    f_id: '130100',
  },
  {
    id: '130131',
    name: '平山县',
    short_name: '平山',
    f_id: '130100',
  },
  {
    id: '130132',
    name: '元氏县',
    short_name: '元氏',
    f_id: '130100',
  },
  {
    id: '130133',
    name: '赵县',
    short_name: '赵县',
    f_id: '130100',
  },
  {
    id: '130181',
    name: '辛集市',
    short_name: '辛集',
    f_id: '130100',
  },
  {
    id: '130182',
    name: '藁城市',
    short_name: '藁城',
    f_id: '130100',
  },
  {
    id: '130183',
    name: '晋州市',
    short_name: '晋州',
    f_id: '130100',
  },
  {
    id: '130184',
    name: '新乐市',
    short_name: '新乐',
    f_id: '130100',
  },
  {
    id: '130185',
    name: '鹿泉市',
    short_name: '鹿泉',
    f_id: '130100',
  },
  {
    id: '130202',
    name: '路南区',
    short_name: '路南',
    f_id: '130200',
  },
  {
    id: '130203',
    name: '路北区',
    short_name: '路北',
    f_id: '130200',
  },
  {
    id: '130204',
    name: '古冶区',
    short_name: '古冶',
    f_id: '130200',
  },
  {
    id: '130205',
    name: '开平区',
    short_name: '开平',
    f_id: '130200',
  },
  {
    id: '130207',
    name: '丰南区',
    short_name: '丰南',
    f_id: '130200',
  },
  {
    id: '130208',
    name: '丰润区',
    short_name: '丰润',
    f_id: '130200',
  },
  {
    id: '130223',
    name: '滦县',
    short_name: '滦县',
    f_id: '130200',
  },
  {
    id: '130224',
    name: '滦南县',
    short_name: '滦南',
    f_id: '130200',
  },
  {
    id: '130225',
    name: '乐亭县',
    short_name: '乐亭',
    f_id: '130200',
  },
  {
    id: '130227',
    name: '迁西县',
    short_name: '迁西',
    f_id: '130200',
  },
  {
    id: '130229',
    name: '玉田县',
    short_name: '玉田',
    f_id: '130200',
  },
  {
    id: '130230',
    name: '曹妃甸区',
    short_name: '曹妃甸',
    f_id: '130200',
  },
  {
    id: '130281',
    name: '遵化市',
    short_name: '遵化',
    f_id: '130200',
  },
  {
    id: '130283',
    name: '迁安市',
    short_name: '迁安',
    f_id: '130200',
  },
  {
    id: '130302',
    name: '海港区',
    short_name: '海港',
    f_id: '130300',
  },
  {
    id: '130303',
    name: '山海关区',
    short_name: '山海关',
    f_id: '130300',
  },
  {
    id: '130304',
    name: '北戴河区',
    short_name: '北戴河',
    f_id: '130300',
  },
  {
    id: '130321',
    name: '青龙满族自治县',
    short_name: '青龙',
    f_id: '130300',
  },
  {
    id: '130322',
    name: '昌黎县',
    short_name: '昌黎',
    f_id: '130300',
  },
  {
    id: '130323',
    name: '抚宁县',
    short_name: '抚宁',
    f_id: '130300',
  },
  {
    id: '130324',
    name: '卢龙县',
    short_name: '卢龙',
    f_id: '130300',
  },
  {
    id: '130402',
    name: '邯山区',
    short_name: '邯山',
    f_id: '130400',
  },
  {
    id: '130403',
    name: '丛台区',
    short_name: '丛台',
    f_id: '130400',
  },
  {
    id: '130404',
    name: '复兴区',
    short_name: '复兴',
    f_id: '130400',
  },
  {
    id: '130406',
    name: '峰峰矿区',
    short_name: '峰峰矿',
    f_id: '130400',
  },
  {
    id: '130421',
    name: '邯郸县',
    short_name: '邯郸',
    f_id: '130400',
  },
  {
    id: '130423',
    name: '临漳县',
    short_name: '临漳',
    f_id: '130400',
  },
  {
    id: '130424',
    name: '成安县',
    short_name: '成安',
    f_id: '130400',
  },
  {
    id: '130425',
    name: '大名县',
    short_name: '大名',
    f_id: '130400',
  },
  {
    id: '130426',
    name: '涉县',
    short_name: '涉县',
    f_id: '130400',
  },
  {
    id: '130427',
    name: '磁县',
    short_name: '磁县',
    f_id: '130400',
  },
  {
    id: '130428',
    name: '肥乡县',
    short_name: '肥乡',
    f_id: '130400',
  },
  {
    id: '130429',
    name: '永年县',
    short_name: '永年',
    f_id: '130400',
  },
  {
    id: '130430',
    name: '邱县',
    short_name: '邱县',
    f_id: '130400',
  },
  {
    id: '130431',
    name: '鸡泽县',
    short_name: '鸡泽',
    f_id: '130400',
  },
  {
    id: '130432',
    name: '广平县',
    short_name: '广平',
    f_id: '130400',
  },
  {
    id: '130433',
    name: '馆陶县',
    short_name: '馆陶',
    f_id: '130400',
  },
  {
    id: '130434',
    name: '魏县',
    short_name: '魏县',
    f_id: '130400',
  },
  {
    id: '130435',
    name: '曲周县',
    short_name: '曲周',
    f_id: '130400',
  },
  {
    id: '130481',
    name: '武安市',
    short_name: '武安',
    f_id: '130400',
  },
  {
    id: '130502',
    name: '桥东区',
    short_name: '桥东',
    f_id: '130500',
  },
  {
    id: '130503',
    name: '桥西区',
    short_name: '桥西',
    f_id: '130500',
  },
  {
    id: '130521',
    name: '邢台县',
    short_name: '邢台',
    f_id: '130500',
  },
  {
    id: '130522',
    name: '临城县',
    short_name: '临城',
    f_id: '130500',
  },
  {
    id: '130523',
    name: '内丘县',
    short_name: '内丘',
    f_id: '130500',
  },
  {
    id: '130524',
    name: '柏乡县',
    short_name: '柏乡',
    f_id: '130500',
  },
  {
    id: '130525',
    name: '隆尧县',
    short_name: '隆尧',
    f_id: '130500',
  },
  {
    id: '130526',
    name: '任县',
    short_name: '任县',
    f_id: '130500',
  },
  {
    id: '130527',
    name: '南和县',
    short_name: '南和',
    f_id: '130500',
  },
  {
    id: '130528',
    name: '宁晋县',
    short_name: '宁晋',
    f_id: '130500',
  },
  {
    id: '130529',
    name: '巨鹿县',
    short_name: '巨鹿',
    f_id: '130500',
  },
  {
    id: '130530',
    name: '新河县',
    short_name: '新河',
    f_id: '130500',
  },
  {
    id: '130531',
    name: '广宗县',
    short_name: '广宗',
    f_id: '130500',
  },
  {
    id: '130532',
    name: '平乡县',
    short_name: '平乡',
    f_id: '130500',
  },
  {
    id: '130533',
    name: '威县',
    short_name: '威县',
    f_id: '130500',
  },
  {
    id: '130534',
    name: '清河县',
    short_name: '清河',
    f_id: '130500',
  },
  {
    id: '130535',
    name: '临西县',
    short_name: '临西',
    f_id: '130500',
  },
  {
    id: '130581',
    name: '南宫市',
    short_name: '南宫',
    f_id: '130500',
  },
  {
    id: '130582',
    name: '沙河市',
    short_name: '沙河',
    f_id: '130500',
  },
  {
    id: '130602',
    name: '新市区',
    short_name: '新市',
    f_id: '130600',
  },
  {
    id: '130603',
    name: '北市区',
    short_name: '北市',
    f_id: '130600',
  },
  {
    id: '130604',
    name: '南市区',
    short_name: '南市',
    f_id: '130600',
  },
  {
    id: '130621',
    name: '满城县',
    short_name: '满城',
    f_id: '130600',
  },
  {
    id: '130622',
    name: '清苑县',
    short_name: '清苑',
    f_id: '130600',
  },
  {
    id: '130623',
    name: '涞水县',
    short_name: '涞水',
    f_id: '130600',
  },
  {
    id: '130624',
    name: '阜平县',
    short_name: '阜平',
    f_id: '130600',
  },
  {
    id: '130625',
    name: '徐水县',
    short_name: '徐水',
    f_id: '130600',
  },
  {
    id: '130626',
    name: '定兴县',
    short_name: '定兴',
    f_id: '130600',
  },
  {
    id: '130627',
    name: '唐县',
    short_name: '唐县',
    f_id: '130600',
  },
  {
    id: '130628',
    name: '高阳县',
    short_name: '高阳',
    f_id: '130600',
  },
  {
    id: '130629',
    name: '容城县',
    short_name: '容城',
    f_id: '130600',
  },
  {
    id: '130630',
    name: '涞源县',
    short_name: '涞源',
    f_id: '130600',
  },
  {
    id: '130631',
    name: '望都县',
    short_name: '望都',
    f_id: '130600',
  },
  {
    id: '130632',
    name: '安新县',
    short_name: '安新',
    f_id: '130600',
  },
  {
    id: '130633',
    name: '易县',
    short_name: '易县',
    f_id: '130600',
  },
  {
    id: '130634',
    name: '曲阳县',
    short_name: '曲阳',
    f_id: '130600',
  },
  {
    id: '130635',
    name: '蠡县',
    short_name: '蠡县',
    f_id: '130600',
  },
  {
    id: '130636',
    name: '顺平县',
    short_name: '顺平',
    f_id: '130600',
  },
  {
    id: '130637',
    name: '博野县',
    short_name: '博野',
    f_id: '130600',
  },
  {
    id: '130638',
    name: '雄县',
    short_name: '雄县',
    f_id: '130600',
  },
  {
    id: '130681',
    name: '涿州市',
    short_name: '涿州',
    f_id: '130600',
  },
  {
    id: '130682',
    name: '定州市',
    short_name: '定州',
    f_id: '130600',
  },
  {
    id: '130683',
    name: '安国市',
    short_name: '安国',
    f_id: '130600',
  },
  {
    id: '130684',
    name: '高碑店市',
    short_name: '高碑店',
    f_id: '130600',
  },
  {
    id: '130702',
    name: '桥东区',
    short_name: '桥东',
    f_id: '130700',
  },
  {
    id: '130703',
    name: '桥西区',
    short_name: '桥西',
    f_id: '130700',
  },
  {
    id: '130705',
    name: '宣化区',
    short_name: '宣化区',
    f_id: '130700',
  },
  {
    id: '130706',
    name: '下花园区',
    short_name: '下花园',
    f_id: '130700',
  },
  {
    id: '130721',
    name: '宣化县',
    short_name: '宣化县',
    f_id: '130700',
  },
  {
    id: '130722',
    name: '张北县',
    short_name: '张北',
    f_id: '130700',
  },
  {
    id: '130723',
    name: '康保县',
    short_name: '康保',
    f_id: '130700',
  },
  {
    id: '130724',
    name: '沽源县',
    short_name: '沽源',
    f_id: '130700',
  },
  {
    id: '130725',
    name: '尚义县',
    short_name: '尚义',
    f_id: '130700',
  },
  {
    id: '130726',
    name: '蔚县',
    short_name: '蔚县',
    f_id: '130700',
  },
  {
    id: '130727',
    name: '阳原县',
    short_name: '阳原',
    f_id: '130700',
  },
  {
    id: '130728',
    name: '怀安县',
    short_name: '怀安',
    f_id: '130700',
  },
  {
    id: '130729',
    name: '万全县',
    short_name: '万全',
    f_id: '130700',
  },
  {
    id: '130730',
    name: '怀来县',
    short_name: '怀来',
    f_id: '130700',
  },
  {
    id: '130731',
    name: '涿鹿县',
    short_name: '涿鹿',
    f_id: '130700',
  },
  {
    id: '130732',
    name: '赤城县',
    short_name: '赤城',
    f_id: '130700',
  },
  {
    id: '130733',
    name: '崇礼县',
    short_name: '崇礼',
    f_id: '130700',
  },
  {
    id: '130802',
    name: '双桥区',
    short_name: '双桥',
    f_id: '130800',
  },
  {
    id: '130803',
    name: '双滦区',
    short_name: '双滦',
    f_id: '130800',
  },
  {
    id: '130804',
    name: '鹰手营子矿区',
    short_name: '鹰手营子矿',
    f_id: '130800',
  },
  {
    id: '130821',
    name: '承德县',
    short_name: '承德',
    f_id: '130800',
  },
  {
    id: '130822',
    name: '兴隆县',
    short_name: '兴隆',
    f_id: '130800',
  },
  {
    id: '130823',
    name: '平泉县',
    short_name: '平泉',
    f_id: '130800',
  },
  {
    id: '130824',
    name: '滦平县',
    short_name: '滦平',
    f_id: '130800',
  },
  {
    id: '130825',
    name: '隆化县',
    short_name: '隆化',
    f_id: '130800',
  },
  {
    id: '130826',
    name: '丰宁满族自治县',
    short_name: '丰宁',
    f_id: '130800',
  },
  {
    id: '130827',
    name: '宽城满族自治县',
    short_name: '宽城',
    f_id: '130800',
  },
  {
    id: '130828',
    name: '围场满族蒙古族自治县',
    short_name: '围场',
    f_id: '130800',
  },
  {
    id: '130902',
    name: '新华区',
    short_name: '新华',
    f_id: '130900',
  },
  {
    id: '130903',
    name: '运河区',
    short_name: '运河',
    f_id: '130900',
  },
  {
    id: '130921',
    name: '沧县',
    short_name: '沧县',
    f_id: '130900',
  },
  {
    id: '130922',
    name: '青县',
    short_name: '青县',
    f_id: '130900',
  },
  {
    id: '130923',
    name: '东光县',
    short_name: '东光',
    f_id: '130900',
  },
  {
    id: '130924',
    name: '海兴县',
    short_name: '海兴',
    f_id: '130900',
  },
  {
    id: '130925',
    name: '盐山县',
    short_name: '盐山',
    f_id: '130900',
  },
  {
    id: '130926',
    name: '肃宁县',
    short_name: '肃宁',
    f_id: '130900',
  },
  {
    id: '130927',
    name: '南皮县',
    short_name: '南皮',
    f_id: '130900',
  },
  {
    id: '130928',
    name: '吴桥县',
    short_name: '吴桥',
    f_id: '130900',
  },
  {
    id: '130929',
    name: '献县',
    short_name: '献县',
    f_id: '130900',
  },
  {
    id: '130930',
    name: '孟村回族自治县',
    short_name: '孟村',
    f_id: '130900',
  },
  {
    id: '130981',
    name: '泊头市',
    short_name: '泊头',
    f_id: '130900',
  },
  {
    id: '130982',
    name: '任丘市',
    short_name: '任丘',
    f_id: '130900',
  },
  {
    id: '130983',
    name: '黄骅市',
    short_name: '黄骅',
    f_id: '130900',
  },
  {
    id: '130984',
    name: '河间市',
    short_name: '河间',
    f_id: '130900',
  },
  {
    id: '131002',
    name: '安次区',
    short_name: '安次',
    f_id: '131000',
  },
  {
    id: '131003',
    name: '广阳区',
    short_name: '广阳',
    f_id: '131000',
  },
  {
    id: '131022',
    name: '固安县',
    short_name: '固安',
    f_id: '131000',
  },
  {
    id: '131023',
    name: '永清县',
    short_name: '永清',
    f_id: '131000',
  },
  {
    id: '131024',
    name: '香河县',
    short_name: '香河',
    f_id: '131000',
  },
  {
    id: '131025',
    name: '大城县',
    short_name: '大城',
    f_id: '131000',
  },
  {
    id: '131026',
    name: '文安县',
    short_name: '文安',
    f_id: '131000',
  },
  {
    id: '131028',
    name: '大厂回族自治县',
    short_name: '大厂',
    f_id: '131000',
  },
  {
    id: '131081',
    name: '霸州市',
    short_name: '霸州',
    f_id: '131000',
  },
  {
    id: '131082',
    name: '三河市',
    short_name: '三河',
    f_id: '131000',
  },
  {
    id: '131102',
    name: '桃城区',
    short_name: '桃城',
    f_id: '131100',
  },
  {
    id: '131121',
    name: '枣强县',
    short_name: '枣强',
    f_id: '131100',
  },
  {
    id: '131122',
    name: '武邑县',
    short_name: '武邑',
    f_id: '131100',
  },
  {
    id: '131123',
    name: '武强县',
    short_name: '武强',
    f_id: '131100',
  },
  {
    id: '131124',
    name: '饶阳县',
    short_name: '饶阳',
    f_id: '131100',
  },
  {
    id: '131125',
    name: '安平县',
    short_name: '安平',
    f_id: '131100',
  },
  {
    id: '131126',
    name: '故城县',
    short_name: '故城',
    f_id: '131100',
  },
  {
    id: '131127',
    name: '景县',
    short_name: '景县',
    f_id: '131100',
  },
  {
    id: '131128',
    name: '阜城县',
    short_name: '阜城',
    f_id: '131100',
  },
  {
    id: '131181',
    name: '冀州市',
    short_name: '冀州',
    f_id: '131100',
  },
  {
    id: '131182',
    name: '深州市',
    short_name: '深州',
    f_id: '131100',
  },
  {
    id: '140105',
    name: '小店区',
    short_name: '小店',
    f_id: '140100',
  },
  {
    id: '140106',
    name: '迎泽区',
    short_name: '迎泽',
    f_id: '140100',
  },
  {
    id: '140107',
    name: '杏花岭区',
    short_name: '杏花岭',
    f_id: '140100',
  },
  {
    id: '140108',
    name: '尖草坪区',
    short_name: '尖草坪',
    f_id: '140100',
  },
  {
    id: '140109',
    name: '万柏林区',
    short_name: '万柏林',
    f_id: '140100',
  },
  {
    id: '140110',
    name: '晋源区',
    short_name: '晋源',
    f_id: '140100',
  },
  {
    id: '140121',
    name: '清徐县',
    short_name: '清徐',
    f_id: '140100',
  },
  {
    id: '140122',
    name: '阳曲县',
    short_name: '阳曲',
    f_id: '140100',
  },
  {
    id: '140123',
    name: '娄烦县',
    short_name: '娄烦',
    f_id: '140100',
  },
  {
    id: '140181',
    name: '古交市',
    short_name: '古交',
    f_id: '140100',
  },
  {
    id: '140202',
    name: '城区',
    short_name: '城区',
    f_id: '140200',
  },
  {
    id: '140203',
    name: '矿区',
    short_name: '矿区',
    f_id: '140200',
  },
  {
    id: '140211',
    name: '南郊区',
    short_name: '南郊',
    f_id: '140200',
  },
  {
    id: '140212',
    name: '新荣区',
    short_name: '新荣',
    f_id: '140200',
  },
  {
    id: '140221',
    name: '阳高县',
    short_name: '阳高',
    f_id: '140200',
  },
  {
    id: '140222',
    name: '天镇县',
    short_name: '天镇',
    f_id: '140200',
  },
  {
    id: '140223',
    name: '广灵县',
    short_name: '广灵',
    f_id: '140200',
  },
  {
    id: '140224',
    name: '灵丘县',
    short_name: '灵丘',
    f_id: '140200',
  },
  {
    id: '140225',
    name: '浑源县',
    short_name: '浑源',
    f_id: '140200',
  },
  {
    id: '140226',
    name: '左云县',
    short_name: '左云',
    f_id: '140200',
  },
  {
    id: '140227',
    name: '大同县',
    short_name: '大同',
    f_id: '140200',
  },
  {
    id: '140302',
    name: '城区',
    short_name: '城区',
    f_id: '140300',
  },
  {
    id: '140303',
    name: '矿区',
    short_name: '矿区',
    f_id: '140300',
  },
  {
    id: '140311',
    name: '郊区',
    short_name: '郊区',
    f_id: '140300',
  },
  {
    id: '140321',
    name: '平定县',
    short_name: '平定',
    f_id: '140300',
  },
  {
    id: '140322',
    name: '盂县',
    short_name: '盂县',
    f_id: '140300',
  },
  {
    id: '140421',
    name: '长治县',
    short_name: '长治',
    f_id: '140400',
  },
  {
    id: '140423',
    name: '襄垣县',
    short_name: '襄垣',
    f_id: '140400',
  },
  {
    id: '140424',
    name: '屯留县',
    short_name: '屯留',
    f_id: '140400',
  },
  {
    id: '140425',
    name: '平顺县',
    short_name: '平顺',
    f_id: '140400',
  },
  {
    id: '140426',
    name: '黎城县',
    short_name: '黎城',
    f_id: '140400',
  },
  {
    id: '140427',
    name: '壶关县',
    short_name: '壶关',
    f_id: '140400',
  },
  {
    id: '140428',
    name: '长子县',
    short_name: '长子',
    f_id: '140400',
  },
  {
    id: '140429',
    name: '武乡县',
    short_name: '武乡',
    f_id: '140400',
  },
  {
    id: '140430',
    name: '沁县',
    short_name: '沁县',
    f_id: '140400',
  },
  {
    id: '140431',
    name: '沁源县',
    short_name: '沁源',
    f_id: '140400',
  },
  {
    id: '140481',
    name: '潞城市',
    short_name: '潞城',
    f_id: '140400',
  },
  {
    id: '140482',
    name: '城区',
    short_name: '城区',
    f_id: '140400',
  },
  {
    id: '140483',
    name: '郊区',
    short_name: '郊区',
    f_id: '140400',
  },
  {
    id: '140502',
    name: '城区',
    short_name: '城区',
    f_id: '140500',
  },
  {
    id: '140521',
    name: '沁水县',
    short_name: '沁水',
    f_id: '140500',
  },
  {
    id: '140522',
    name: '阳城县',
    short_name: '阳城',
    f_id: '140500',
  },
  {
    id: '140524',
    name: '陵川县',
    short_name: '陵川',
    f_id: '140500',
  },
  {
    id: '140525',
    name: '泽州县',
    short_name: '泽州',
    f_id: '140500',
  },
  {
    id: '140581',
    name: '高平市',
    short_name: '高平',
    f_id: '140500',
  },
  {
    id: '140602',
    name: '朔城区',
    short_name: '朔城',
    f_id: '140600',
  },
  {
    id: '140603',
    name: '平鲁区',
    short_name: '平鲁',
    f_id: '140600',
  },
  {
    id: '140621',
    name: '山阴县',
    short_name: '山阴',
    f_id: '140600',
  },
  {
    id: '140622',
    name: '应县',
    short_name: '应县',
    f_id: '140600',
  },
  {
    id: '140623',
    name: '右玉县',
    short_name: '右玉',
    f_id: '140600',
  },
  {
    id: '140624',
    name: '怀仁县',
    short_name: '怀仁',
    f_id: '140600',
  },
  {
    id: '140702',
    name: '榆次区',
    short_name: '榆次',
    f_id: '140700',
  },
  {
    id: '140721',
    name: '榆社县',
    short_name: '榆社',
    f_id: '140700',
  },
  {
    id: '140722',
    name: '左权县',
    short_name: '左权',
    f_id: '140700',
  },
  {
    id: '140723',
    name: '和顺县',
    short_name: '和顺',
    f_id: '140700',
  },
  {
    id: '140724',
    name: '昔阳县',
    short_name: '昔阳',
    f_id: '140700',
  },
  {
    id: '140725',
    name: '寿阳县',
    short_name: '寿阳',
    f_id: '140700',
  },
  {
    id: '140726',
    name: '太谷县',
    short_name: '太谷',
    f_id: '140700',
  },
  {
    id: '140727',
    name: '祁县',
    short_name: '祁县',
    f_id: '140700',
  },
  {
    id: '140728',
    name: '平遥县',
    short_name: '平遥',
    f_id: '140700',
  },
  {
    id: '140729',
    name: '灵石县',
    short_name: '灵石',
    f_id: '140700',
  },
  {
    id: '140781',
    name: '介休市',
    short_name: '介休',
    f_id: '140700',
  },
  {
    id: '140802',
    name: '盐湖区',
    short_name: '盐湖',
    f_id: '140800',
  },
  {
    id: '140821',
    name: '临猗县',
    short_name: '临猗',
    f_id: '140800',
  },
  {
    id: '140822',
    name: '万荣县',
    short_name: '万荣',
    f_id: '140800',
  },
  {
    id: '140823',
    name: '闻喜县',
    short_name: '闻喜',
    f_id: '140800',
  },
  {
    id: '140824',
    name: '稷山县',
    short_name: '稷山',
    f_id: '140800',
  },
  {
    id: '140825',
    name: '新绛县',
    short_name: '新绛',
    f_id: '140800',
  },
  {
    id: '140826',
    name: '绛县',
    short_name: '绛县',
    f_id: '140800',
  },
  {
    id: '140827',
    name: '垣曲县',
    short_name: '垣曲',
    f_id: '140800',
  },
  {
    id: '140828',
    name: '夏县',
    short_name: '夏县',
    f_id: '140800',
  },
  {
    id: '140829',
    name: '平陆县',
    short_name: '平陆',
    f_id: '140800',
  },
  {
    id: '140830',
    name: '芮城县',
    short_name: '芮城',
    f_id: '140800',
  },
  {
    id: '140881',
    name: '永济市',
    short_name: '永济',
    f_id: '140800',
  },
  {
    id: '140882',
    name: '河津市',
    short_name: '河津',
    f_id: '140800',
  },
  {
    id: '140902',
    name: '忻府区',
    short_name: '忻府',
    f_id: '140900',
  },
  {
    id: '140921',
    name: '定襄县',
    short_name: '定襄',
    f_id: '140900',
  },
  {
    id: '140922',
    name: '五台县',
    short_name: '五台',
    f_id: '140900',
  },
  {
    id: '140923',
    name: '代县',
    short_name: '代县',
    f_id: '140900',
  },
  {
    id: '140924',
    name: '繁峙县',
    short_name: '繁峙',
    f_id: '140900',
  },
  {
    id: '140925',
    name: '宁武县',
    short_name: '宁武',
    f_id: '140900',
  },
  {
    id: '140926',
    name: '静乐县',
    short_name: '静乐',
    f_id: '140900',
  },
  {
    id: '140927',
    name: '神池县',
    short_name: '神池',
    f_id: '140900',
  },
  {
    id: '140928',
    name: '五寨县',
    short_name: '五寨',
    f_id: '140900',
  },
  {
    id: '140929',
    name: '岢岚县',
    short_name: '岢岚',
    f_id: '140900',
  },
  {
    id: '140930',
    name: '河曲县',
    short_name: '河曲',
    f_id: '140900',
  },
  {
    id: '140931',
    name: '保德县',
    short_name: '保德',
    f_id: '140900',
  },
  {
    id: '140932',
    name: '偏关县',
    short_name: '偏关',
    f_id: '140900',
  },
  {
    id: '140981',
    name: '原平市',
    short_name: '原平',
    f_id: '140900',
  },
  {
    id: '141002',
    name: '尧都区',
    short_name: '尧都',
    f_id: '141000',
  },
  {
    id: '141021',
    name: '曲沃县',
    short_name: '曲沃',
    f_id: '141000',
  },
  {
    id: '141022',
    name: '翼城县',
    short_name: '翼城',
    f_id: '141000',
  },
  {
    id: '141023',
    name: '襄汾县',
    short_name: '襄汾',
    f_id: '141000',
  },
  {
    id: '141024',
    name: '洪洞县',
    short_name: '洪洞',
    f_id: '141000',
  },
  {
    id: '141025',
    name: '古县',
    short_name: '古县',
    f_id: '141000',
  },
  {
    id: '141026',
    name: '安泽县',
    short_name: '安泽',
    f_id: '141000',
  },
  {
    id: '141027',
    name: '浮山县',
    short_name: '浮山',
    f_id: '141000',
  },
  {
    id: '141028',
    name: '吉县',
    short_name: '吉县',
    f_id: '141000',
  },
  {
    id: '141029',
    name: '乡宁县',
    short_name: '乡宁',
    f_id: '141000',
  },
  {
    id: '141030',
    name: '大宁县',
    short_name: '大宁',
    f_id: '141000',
  },
  {
    id: '141031',
    name: '隰县',
    short_name: '隰县',
    f_id: '141000',
  },
  {
    id: '141032',
    name: '永和县',
    short_name: '永和',
    f_id: '141000',
  },
  {
    id: '141033',
    name: '蒲县',
    short_name: '蒲县',
    f_id: '141000',
  },
  {
    id: '141034',
    name: '汾西县',
    short_name: '汾西',
    f_id: '141000',
  },
  {
    id: '141081',
    name: '侯马市',
    short_name: '侯马',
    f_id: '141000',
  },
  {
    id: '141082',
    name: '霍州市',
    short_name: '霍州',
    f_id: '141000',
  },
  {
    id: '141102',
    name: '离石区',
    short_name: '离石',
    f_id: '141100',
  },
  {
    id: '141121',
    name: '文水县',
    short_name: '文水',
    f_id: '141100',
  },
  {
    id: '141122',
    name: '交城县',
    short_name: '交城',
    f_id: '141100',
  },
  {
    id: '141123',
    name: '兴县',
    short_name: '兴县',
    f_id: '141100',
  },
  {
    id: '141124',
    name: '临县',
    short_name: '临县',
    f_id: '141100',
  },
  {
    id: '141125',
    name: '柳林县',
    short_name: '柳林',
    f_id: '141100',
  },
  {
    id: '141126',
    name: '石楼县',
    short_name: '石楼',
    f_id: '141100',
  },
  {
    id: '141127',
    name: '岚县',
    short_name: '岚县',
    f_id: '141100',
  },
  {
    id: '141128',
    name: '方山县',
    short_name: '方山',
    f_id: '141100',
  },
  {
    id: '141129',
    name: '中阳县',
    short_name: '中阳',
    f_id: '141100',
  },
  {
    id: '141130',
    name: '交口县',
    short_name: '交口',
    f_id: '141100',
  },
  {
    id: '141181',
    name: '孝义市',
    short_name: '孝义',
    f_id: '141100',
  },
  {
    id: '141182',
    name: '汾阳市',
    short_name: '汾阳',
    f_id: '141100',
  },
  {
    id: '150102',
    name: '新城区',
    short_name: '新城',
    f_id: '150100',
  },
  {
    id: '150103',
    name: '回民区',
    short_name: '回民',
    f_id: '150100',
  },
  {
    id: '150104',
    name: '玉泉区',
    short_name: '玉泉',
    f_id: '150100',
  },
  {
    id: '150105',
    name: '赛罕区',
    short_name: '赛罕',
    f_id: '150100',
  },
  {
    id: '150121',
    name: '土默特左旗',
    short_name: '土默特左',
    f_id: '150100',
  },
  {
    id: '150122',
    name: '托克托县',
    short_name: '托克托',
    f_id: '150100',
  },
  {
    id: '150123',
    name: '和林格尔县',
    short_name: '和林格尔',
    f_id: '150100',
  },
  {
    id: '150124',
    name: '清水河县',
    short_name: '清水河',
    f_id: '150100',
  },
  {
    id: '150125',
    name: '武川县',
    short_name: '武川',
    f_id: '150100',
  },
  {
    id: '150202',
    name: '东河区',
    short_name: '东河',
    f_id: '150200',
  },
  {
    id: '150203',
    name: '昆都仑区',
    short_name: '昆都仑',
    f_id: '150200',
  },
  {
    id: '150204',
    name: '青山区',
    short_name: '青山',
    f_id: '150200',
  },
  {
    id: '150205',
    name: '石拐区',
    short_name: '石拐',
    f_id: '150200',
  },
  {
    id: '150206',
    name: '白云鄂博矿区',
    short_name: '白云矿区',
    f_id: '150200',
  },
  {
    id: '150207',
    name: '九原区',
    short_name: '九原',
    f_id: '150200',
  },
  {
    id: '150221',
    name: '土默特右旗',
    short_name: '土默特右',
    f_id: '150200',
  },
  {
    id: '150222',
    name: '固阳县',
    short_name: '固阳',
    f_id: '150200',
  },
  {
    id: '150223',
    name: '达尔罕茂明安联合旗',
    short_name: '达尔罕茂明安联合',
    f_id: '150200',
  },
  {
    id: '150302',
    name: '海勃湾区',
    short_name: '海勃湾',
    f_id: '150300',
  },
  {
    id: '150303',
    name: '海南区',
    short_name: '海南',
    f_id: '150300',
  },
  {
    id: '150304',
    name: '乌达区',
    short_name: '乌达',
    f_id: '150300',
  },
  {
    id: '150402',
    name: '红山区',
    short_name: '红山',
    f_id: '150400',
  },
  {
    id: '150403',
    name: '元宝山区',
    short_name: '元宝山',
    f_id: '150400',
  },
  {
    id: '150404',
    name: '松山区',
    short_name: '松山',
    f_id: '150400',
  },
  {
    id: '150421',
    name: '阿鲁科尔沁旗',
    short_name: '阿鲁科尔沁',
    f_id: '150400',
  },
  {
    id: '150422',
    name: '巴林左旗',
    short_name: '巴林左',
    f_id: '150400',
  },
  {
    id: '150423',
    name: '巴林右旗',
    short_name: '巴林右',
    f_id: '150400',
  },
  {
    id: '150424',
    name: '林西县',
    short_name: '林西',
    f_id: '150400',
  },
  {
    id: '150425',
    name: '克什克腾旗',
    short_name: '克什克腾',
    f_id: '150400',
  },
  {
    id: '150426',
    name: '翁牛特旗',
    short_name: '翁牛特',
    f_id: '150400',
  },
  {
    id: '150428',
    name: '喀喇沁旗',
    short_name: '喀喇沁',
    f_id: '150400',
  },
  {
    id: '150429',
    name: '宁城县',
    short_name: '宁城',
    f_id: '150400',
  },
  {
    id: '150430',
    name: '敖汉旗',
    short_name: '敖汉',
    f_id: '150400',
  },
  {
    id: '150502',
    name: '科尔沁区',
    short_name: '科尔沁',
    f_id: '150500',
  },
  {
    id: '150521',
    name: '科尔沁左翼中旗',
    short_name: '科尔沁左翼中',
    f_id: '150500',
  },
  {
    id: '150522',
    name: '科尔沁左翼后旗',
    short_name: '科尔沁左翼后',
    f_id: '150500',
  },
  {
    id: '150523',
    name: '开鲁县',
    short_name: '开鲁',
    f_id: '150500',
  },
  {
    id: '150524',
    name: '库伦旗',
    short_name: '库伦',
    f_id: '150500',
  },
  {
    id: '150525',
    name: '奈曼旗',
    short_name: '奈曼',
    f_id: '150500',
  },
  {
    id: '150526',
    name: '扎鲁特旗',
    short_name: '扎鲁特',
    f_id: '150500',
  },
  {
    id: '150581',
    name: '霍林郭勒市',
    short_name: '霍林郭勒',
    f_id: '150500',
  },
  {
    id: '150602',
    name: '东胜区',
    short_name: '东胜',
    f_id: '150600',
  },
  {
    id: '150621',
    name: '达拉特旗',
    short_name: '达拉特',
    f_id: '150600',
  },
  {
    id: '150622',
    name: '准格尔旗',
    short_name: '准格尔',
    f_id: '150600',
  },
  {
    id: '150623',
    name: '鄂托克前旗',
    short_name: '鄂托克前',
    f_id: '150600',
  },
  {
    id: '150624',
    name: '鄂托克旗',
    short_name: '鄂托克',
    f_id: '150600',
  },
  {
    id: '150625',
    name: '杭锦旗',
    short_name: '杭锦',
    f_id: '150600',
  },
  {
    id: '150626',
    name: '乌审旗',
    short_name: '乌审',
    f_id: '150600',
  },
  {
    id: '150627',
    name: '伊金霍洛旗',
    short_name: '伊金霍洛',
    f_id: '150600',
  },
  {
    id: '150702',
    name: '海拉尔区',
    short_name: '海拉尔',
    f_id: '150700',
  },
  {
    id: '150703',
    name: '扎赉诺尔区',
    short_name: '扎赉诺尔',
    f_id: '150700',
  },
  {
    id: '150721',
    name: '阿荣旗',
    short_name: '阿荣',
    f_id: '150700',
  },
  {
    id: '150722',
    name: '莫力达瓦达斡尔族自治旗',
    short_name: '莫力达瓦',
    f_id: '150700',
  },
  {
    id: '150723',
    name: '鄂伦春自治旗',
    short_name: '鄂伦春',
    f_id: '150700',
  },
  {
    id: '150724',
    name: '鄂温克族自治旗',
    short_name: '鄂温克',
    f_id: '150700',
  },
  {
    id: '150725',
    name: '陈巴尔虎旗',
    short_name: '陈巴尔虎',
    f_id: '150700',
  },
  {
    id: '150726',
    name: '新巴尔虎左旗',
    short_name: '新巴尔虎左',
    f_id: '150700',
  },
  {
    id: '150727',
    name: '新巴尔虎右旗',
    short_name: '新巴尔虎右',
    f_id: '150700',
  },
  {
    id: '150781',
    name: '满洲里市',
    short_name: '满洲里',
    f_id: '150700',
  },
  {
    id: '150782',
    name: '牙克石市',
    short_name: '牙克石',
    f_id: '150700',
  },
  {
    id: '150783',
    name: '扎兰屯市',
    short_name: '扎兰屯',
    f_id: '150700',
  },
  {
    id: '150784',
    name: '额尔古纳市',
    short_name: '额尔古纳',
    f_id: '150700',
  },
  {
    id: '150785',
    name: '根河市',
    short_name: '根河',
    f_id: '150700',
  },
  {
    id: '150802',
    name: '临河区',
    short_name: '临河',
    f_id: '150800',
  },
  {
    id: '150821',
    name: '五原县',
    short_name: '五原',
    f_id: '150800',
  },
  {
    id: '150822',
    name: '磴口县',
    short_name: '磴口',
    f_id: '150800',
  },
  {
    id: '150823',
    name: '乌拉特前旗',
    short_name: '乌拉特前',
    f_id: '150800',
  },
  {
    id: '150824',
    name: '乌拉特中旗',
    short_name: '乌拉特中',
    f_id: '150800',
  },
  {
    id: '150825',
    name: '乌拉特后旗',
    short_name: '乌拉特后',
    f_id: '150800',
  },
  {
    id: '150826',
    name: '杭锦后旗',
    short_name: '杭锦后',
    f_id: '150800',
  },
  {
    id: '150902',
    name: '集宁区',
    short_name: '集宁',
    f_id: '150900',
  },
  {
    id: '150921',
    name: '卓资县',
    short_name: '卓资',
    f_id: '150900',
  },
  {
    id: '150922',
    name: '化德县',
    short_name: '化德',
    f_id: '150900',
  },
  {
    id: '150923',
    name: '商都县',
    short_name: '商都',
    f_id: '150900',
  },
  {
    id: '150924',
    name: '兴和县',
    short_name: '兴和',
    f_id: '150900',
  },
  {
    id: '150925',
    name: '凉城县',
    short_name: '凉城',
    f_id: '150900',
  },
  {
    id: '150926',
    name: '察哈尔右翼前旗',
    short_name: '察哈尔右翼前',
    f_id: '150900',
  },
  {
    id: '150927',
    name: '察哈尔右翼中旗',
    short_name: '察哈尔右翼中',
    f_id: '150900',
  },
  {
    id: '150928',
    name: '察哈尔右翼后旗',
    short_name: '察哈尔右翼后',
    f_id: '150900',
  },
  {
    id: '150929',
    name: '四子王旗',
    short_name: '四子王',
    f_id: '150900',
  },
  {
    id: '150981',
    name: '丰镇市',
    short_name: '丰镇',
    f_id: '150900',
  },
  {
    id: '152201',
    name: '乌兰浩特市',
    short_name: '乌兰浩特',
    f_id: '152200',
  },
  {
    id: '152202',
    name: '阿尔山市',
    short_name: '阿尔山',
    f_id: '152200',
  },
  {
    id: '152221',
    name: '科尔沁右翼前旗',
    short_name: '科尔沁右翼前',
    f_id: '152200',
  },
  {
    id: '152222',
    name: '科尔沁右翼中旗',
    short_name: '科尔沁右翼中',
    f_id: '152200',
  },
  {
    id: '152223',
    name: '扎赉特旗',
    short_name: '扎赉特',
    f_id: '152200',
  },
  {
    id: '152224',
    name: '突泉县',
    short_name: '突泉',
    f_id: '152200',
  },
  {
    id: '152501',
    name: '二连浩特市',
    short_name: '二连浩特',
    f_id: '152500',
  },
  {
    id: '152502',
    name: '锡林浩特市',
    short_name: '锡林浩特',
    f_id: '152500',
  },
  {
    id: '152522',
    name: '阿巴嘎旗',
    short_name: '阿巴嘎',
    f_id: '152500',
  },
  {
    id: '152523',
    name: '苏尼特左旗',
    short_name: '苏尼特左',
    f_id: '152500',
  },
  {
    id: '152524',
    name: '苏尼特右旗',
    short_name: '苏尼特右',
    f_id: '152500',
  },
  {
    id: '152525',
    name: '东乌珠穆沁旗',
    short_name: '东乌珠穆沁',
    f_id: '152500',
  },
  {
    id: '152526',
    name: '西乌珠穆沁旗',
    short_name: '西乌珠穆沁',
    f_id: '152500',
  },
  {
    id: '152527',
    name: '太仆寺旗',
    short_name: '太仆寺',
    f_id: '152500',
  },
  {
    id: '152528',
    name: '镶黄旗',
    short_name: '镶黄',
    f_id: '152500',
  },
  {
    id: '152529',
    name: '正镶白旗',
    short_name: '正镶白',
    f_id: '152500',
  },
  {
    id: '152530',
    name: '正蓝旗',
    short_name: '正蓝',
    f_id: '152500',
  },
  {
    id: '152531',
    name: '多伦县',
    short_name: '多伦',
    f_id: '152500',
  },
  {
    id: '152921',
    name: '阿拉善左旗',
    short_name: '阿拉善左',
    f_id: '152900',
  },
  {
    id: '152922',
    name: '阿拉善右旗',
    short_name: '阿拉善右',
    f_id: '152900',
  },
  {
    id: '152923',
    name: '额济纳旗',
    short_name: '额济纳',
    f_id: '152900',
  },
  {
    id: '210102',
    name: '和平区',
    short_name: '和平',
    f_id: '210100',
  },
  {
    id: '210103',
    name: '沈河区',
    short_name: '沈河',
    f_id: '210100',
  },
  {
    id: '210104',
    name: '大东区',
    short_name: '大东',
    f_id: '210100',
  },
  {
    id: '210105',
    name: '皇姑区',
    short_name: '皇姑',
    f_id: '210100',
  },
  {
    id: '210106',
    name: '铁西区',
    short_name: '铁西',
    f_id: '210100',
  },
  {
    id: '210111',
    name: '苏家屯区',
    short_name: '苏家屯',
    f_id: '210100',
  },
  {
    id: '210112',
    name: '东陵区',
    short_name: '东陵',
    f_id: '210100',
  },
  {
    id: '210114',
    name: '于洪区',
    short_name: '于洪',
    f_id: '210100',
  },
  {
    id: '210122',
    name: '辽中县',
    short_name: '辽中',
    f_id: '210100',
  },
  {
    id: '210123',
    name: '康平县',
    short_name: '康平',
    f_id: '210100',
  },
  {
    id: '210124',
    name: '法库县',
    short_name: '法库',
    f_id: '210100',
  },
  {
    id: '210181',
    name: '新民市',
    short_name: '新民',
    f_id: '210100',
  },
  {
    id: '210184',
    name: '沈北新区',
    short_name: '沈北',
    f_id: '210100',
  },
  {
    id: '210202',
    name: '中山区',
    short_name: '中山',
    f_id: '210200',
  },
  {
    id: '210203',
    name: '西岗区',
    short_name: '西岗',
    f_id: '210200',
  },
  {
    id: '210204',
    name: '沙河口区',
    short_name: '沙河口',
    f_id: '210200',
  },
  {
    id: '210211',
    name: '甘井子区',
    short_name: '甘井子',
    f_id: '210200',
  },
  {
    id: '210212',
    name: '旅顺口区',
    short_name: '旅顺口',
    f_id: '210200',
  },
  {
    id: '210213',
    name: '金州区',
    short_name: '金州',
    f_id: '210200',
  },
  {
    id: '210224',
    name: '长海县',
    short_name: '长海',
    f_id: '210200',
  },
  {
    id: '210281',
    name: '瓦房店市',
    short_name: '瓦房店',
    f_id: '210200',
  },
  {
    id: '210282',
    name: '普兰店市',
    short_name: '普兰店',
    f_id: '210200',
  },
  {
    id: '210283',
    name: '庄河市',
    short_name: '庄河',
    f_id: '210200',
  },
  {
    id: '210302',
    name: '铁东区',
    short_name: '铁东',
    f_id: '210300',
  },
  {
    id: '210303',
    name: '铁西区',
    short_name: '铁西',
    f_id: '210300',
  },
  {
    id: '210304',
    name: '立山区',
    short_name: '立山',
    f_id: '210300',
  },
  {
    id: '210311',
    name: '千山区',
    short_name: '千山',
    f_id: '210300',
  },
  {
    id: '210321',
    name: '台安县',
    short_name: '台安',
    f_id: '210300',
  },
  {
    id: '210323',
    name: '岫岩满族自治县',
    short_name: '岫岩',
    f_id: '210300',
  },
  {
    id: '210381',
    name: '海城市',
    short_name: '海城',
    f_id: '210300',
  },
  {
    id: '210402',
    name: '新抚区',
    short_name: '新抚',
    f_id: '210400',
  },
  {
    id: '210403',
    name: '东洲区',
    short_name: '东洲',
    f_id: '210400',
  },
  {
    id: '210404',
    name: '望花区',
    short_name: '望花',
    f_id: '210400',
  },
  {
    id: '210411',
    name: '顺城区',
    short_name: '顺城',
    f_id: '210400',
  },
  {
    id: '210421',
    name: '抚顺县',
    short_name: '抚顺',
    f_id: '210400',
  },
  {
    id: '210422',
    name: '新宾满族自治县',
    short_name: '新宾',
    f_id: '210400',
  },
  {
    id: '210423',
    name: '清原满族自治县',
    short_name: '清原',
    f_id: '210400',
  },
  {
    id: '210502',
    name: '平山区',
    short_name: '平山',
    f_id: '210500',
  },
  {
    id: '210503',
    name: '溪湖区',
    short_name: '溪湖',
    f_id: '210500',
  },
  {
    id: '210504',
    name: '明山区',
    short_name: '明山',
    f_id: '210500',
  },
  {
    id: '210505',
    name: '南芬区',
    short_name: '南芬',
    f_id: '210500',
  },
  {
    id: '210521',
    name: '本溪满族自治县',
    short_name: '本溪',
    f_id: '210500',
  },
  {
    id: '210522',
    name: '桓仁满族自治县',
    short_name: '桓仁',
    f_id: '210500',
  },
  {
    id: '210602',
    name: '元宝区',
    short_name: '元宝',
    f_id: '210600',
  },
  {
    id: '210603',
    name: '振兴区',
    short_name: '振兴',
    f_id: '210600',
  },
  {
    id: '210604',
    name: '振安区',
    short_name: '振安',
    f_id: '210600',
  },
  {
    id: '210624',
    name: '宽甸满族自治县',
    short_name: '宽甸',
    f_id: '210600',
  },
  {
    id: '210681',
    name: '东港市',
    short_name: '东港',
    f_id: '210600',
  },
  {
    id: '210682',
    name: '凤城市',
    short_name: '凤城',
    f_id: '210600',
  },
  {
    id: '210702',
    name: '古塔区',
    short_name: '古塔',
    f_id: '210700',
  },
  {
    id: '210703',
    name: '凌河区',
    short_name: '凌河',
    f_id: '210700',
  },
  {
    id: '210711',
    name: '太和区',
    short_name: '太和',
    f_id: '210700',
  },
  {
    id: '210726',
    name: '黑山县',
    short_name: '黑山',
    f_id: '210700',
  },
  {
    id: '210727',
    name: '义县',
    short_name: '义县',
    f_id: '210700',
  },
  {
    id: '210781',
    name: '凌海市',
    short_name: '凌海',
    f_id: '210700',
  },
  {
    id: '210782',
    name: '北镇市',
    short_name: '北镇',
    f_id: '210700',
  },
  {
    id: '210802',
    name: '站前区',
    short_name: '站前',
    f_id: '210800',
  },
  {
    id: '210803',
    name: '西市区',
    short_name: '西市',
    f_id: '210800',
  },
  {
    id: '210804',
    name: '鲅鱼圈区',
    short_name: '鲅鱼圈',
    f_id: '210800',
  },
  {
    id: '210811',
    name: '老边区',
    short_name: '老边',
    f_id: '210800',
  },
  {
    id: '210881',
    name: '盖州市',
    short_name: '盖州',
    f_id: '210800',
  },
  {
    id: '210882',
    name: '大石桥市',
    short_name: '大石桥',
    f_id: '210800',
  },
  {
    id: '210902',
    name: '海州区',
    short_name: '海州',
    f_id: '210900',
  },
  {
    id: '210903',
    name: '新邱区',
    short_name: '新邱',
    f_id: '210900',
  },
  {
    id: '210904',
    name: '太平区',
    short_name: '太平',
    f_id: '210900',
  },
  {
    id: '210905',
    name: '清河门区',
    short_name: '清河门',
    f_id: '210900',
  },
  {
    id: '210911',
    name: '细河区',
    short_name: '细河',
    f_id: '210900',
  },
  {
    id: '210921',
    name: '阜新蒙古族自治县',
    short_name: '阜新',
    f_id: '210900',
  },
  {
    id: '210922',
    name: '彰武县',
    short_name: '彰武',
    f_id: '210900',
  },
  {
    id: '211002',
    name: '白塔区',
    short_name: '白塔',
    f_id: '211000',
  },
  {
    id: '211003',
    name: '文圣区',
    short_name: '文圣',
    f_id: '211000',
  },
  {
    id: '211004',
    name: '宏伟区',
    short_name: '宏伟',
    f_id: '211000',
  },
  {
    id: '211005',
    name: '弓长岭区',
    short_name: '弓长岭',
    f_id: '211000',
  },
  {
    id: '211011',
    name: '太子河区',
    short_name: '太子河',
    f_id: '211000',
  },
  {
    id: '211021',
    name: '辽阳县',
    short_name: '辽阳',
    f_id: '211000',
  },
  {
    id: '211081',
    name: '灯塔市',
    short_name: '灯塔',
    f_id: '211000',
  },
  {
    id: '211102',
    name: '双台子区',
    short_name: '双台子',
    f_id: '211100',
  },
  {
    id: '211103',
    name: '兴隆台区',
    short_name: '兴隆台',
    f_id: '211100',
  },
  {
    id: '211121',
    name: '大洼县',
    short_name: '大洼',
    f_id: '211100',
  },
  {
    id: '211122',
    name: '盘山县',
    short_name: '盘山',
    f_id: '211100',
  },
  {
    id: '211202',
    name: '银州区',
    short_name: '银州',
    f_id: '211200',
  },
  {
    id: '211204',
    name: '清河区',
    short_name: '清河',
    f_id: '211200',
  },
  {
    id: '211221',
    name: '铁岭县',
    short_name: '铁岭',
    f_id: '211200',
  },
  {
    id: '211223',
    name: '西丰县',
    short_name: '西丰',
    f_id: '211200',
  },
  {
    id: '211224',
    name: '昌图县',
    short_name: '昌图',
    f_id: '211200',
  },
  {
    id: '211281',
    name: '调兵山市',
    short_name: '调兵山',
    f_id: '211200',
  },
  {
    id: '211282',
    name: '开原市',
    short_name: '开原',
    f_id: '211200',
  },
  {
    id: '211302',
    name: '双塔区',
    short_name: '双塔',
    f_id: '211300',
  },
  {
    id: '211303',
    name: '龙城区',
    short_name: '龙城',
    f_id: '211300',
  },
  {
    id: '211321',
    name: '朝阳县',
    short_name: '朝阳',
    f_id: '211300',
  },
  {
    id: '211322',
    name: '建平县',
    short_name: '建平',
    f_id: '211300',
  },
  {
    id: '211324',
    name: '喀喇沁左翼蒙古族自治县',
    short_name: '喀左',
    f_id: '211300',
  },
  {
    id: '211381',
    name: '北票市',
    short_name: '北票',
    f_id: '211300',
  },
  {
    id: '211382',
    name: '凌源市',
    short_name: '凌源',
    f_id: '211300',
  },
  {
    id: '211402',
    name: '连山区',
    short_name: '连山',
    f_id: '211400',
  },
  {
    id: '211403',
    name: '龙港区',
    short_name: '龙港',
    f_id: '211400',
  },
  {
    id: '211404',
    name: '南票区',
    short_name: '南票',
    f_id: '211400',
  },
  {
    id: '211421',
    name: '绥中县',
    short_name: '绥中',
    f_id: '211400',
  },
  {
    id: '211422',
    name: '建昌县',
    short_name: '建昌',
    f_id: '211400',
  },
  {
    id: '211481',
    name: '兴城市',
    short_name: '兴城',
    f_id: '211400',
  },
  {
    id: '220102',
    name: '南关区',
    short_name: '南关',
    f_id: '220100',
  },
  {
    id: '220103',
    name: '宽城区',
    short_name: '宽城',
    f_id: '220100',
  },
  {
    id: '220104',
    name: '朝阳区',
    short_name: '朝阳',
    f_id: '220100',
  },
  {
    id: '220105',
    name: '二道区',
    short_name: '二道',
    f_id: '220100',
  },
  {
    id: '220106',
    name: '绿园区',
    short_name: '绿园',
    f_id: '220100',
  },
  {
    id: '220112',
    name: '双阳区',
    short_name: '双阳',
    f_id: '220100',
  },
  {
    id: '220122',
    name: '农安县',
    short_name: '农安',
    f_id: '220100',
  },
  {
    id: '220181',
    name: '九台市',
    short_name: '九台',
    f_id: '220100',
  },
  {
    id: '220182',
    name: '榆树市',
    short_name: '榆树',
    f_id: '220100',
  },
  {
    id: '220183',
    name: '德惠市',
    short_name: '德惠',
    f_id: '220100',
  },
  {
    id: '220202',
    name: '昌邑区',
    short_name: '昌邑',
    f_id: '220200',
  },
  {
    id: '220203',
    name: '龙潭区',
    short_name: '龙潭',
    f_id: '220200',
  },
  {
    id: '220204',
    name: '船营区',
    short_name: '船营',
    f_id: '220200',
  },
  {
    id: '220211',
    name: '丰满区',
    short_name: '丰满',
    f_id: '220200',
  },
  {
    id: '220221',
    name: '永吉县',
    short_name: '永吉',
    f_id: '220200',
  },
  {
    id: '220281',
    name: '蛟河市',
    short_name: '蛟河',
    f_id: '220200',
  },
  {
    id: '220282',
    name: '桦甸市',
    short_name: '桦甸',
    f_id: '220200',
  },
  {
    id: '220283',
    name: '舒兰市',
    short_name: '舒兰',
    f_id: '220200',
  },
  {
    id: '220284',
    name: '磐石市',
    short_name: '磐石',
    f_id: '220200',
  },
  {
    id: '220302',
    name: '铁西区',
    short_name: '铁西',
    f_id: '220300',
  },
  {
    id: '220303',
    name: '铁东区',
    short_name: '铁东',
    f_id: '220300',
  },
  {
    id: '220322',
    name: '梨树县',
    short_name: '梨树',
    f_id: '220300',
  },
  {
    id: '220323',
    name: '伊通满族自治县',
    short_name: '伊通',
    f_id: '220300',
  },
  {
    id: '220381',
    name: '公主岭市',
    short_name: '公主岭',
    f_id: '220300',
  },
  {
    id: '220382',
    name: '双辽市',
    short_name: '双辽',
    f_id: '220300',
  },
  {
    id: '220402',
    name: '龙山区',
    short_name: '龙山',
    f_id: '220400',
  },
  {
    id: '220403',
    name: '西安区',
    short_name: '西安',
    f_id: '220400',
  },
  {
    id: '220421',
    name: '东丰县',
    short_name: '东丰',
    f_id: '220400',
  },
  {
    id: '220422',
    name: '东辽县',
    short_name: '东辽',
    f_id: '220400',
  },
  {
    id: '220502',
    name: '东昌区',
    short_name: '东昌',
    f_id: '220500',
  },
  {
    id: '220503',
    name: '二道江区',
    short_name: '二道江',
    f_id: '220500',
  },
  {
    id: '220521',
    name: '通化县',
    short_name: '通化',
    f_id: '220500',
  },
  {
    id: '220523',
    name: '辉南县',
    short_name: '辉南',
    f_id: '220500',
  },
  {
    id: '220524',
    name: '柳河县',
    short_name: '柳河',
    f_id: '220500',
  },
  {
    id: '220581',
    name: '梅河口市',
    short_name: '梅河口',
    f_id: '220500',
  },
  {
    id: '220582',
    name: '集安市',
    short_name: '集安',
    f_id: '220500',
  },
  {
    id: '220602',
    name: '浑江区',
    short_name: '浑江',
    f_id: '220600',
  },
  {
    id: '220621',
    name: '抚松县',
    short_name: '抚松',
    f_id: '220600',
  },
  {
    id: '220622',
    name: '靖宇县',
    short_name: '靖宇',
    f_id: '220600',
  },
  {
    id: '220623',
    name: '长白朝鲜族自治县',
    short_name: '长白',
    f_id: '220600',
  },
  {
    id: '220625',
    name: '江源区',
    short_name: '江源',
    f_id: '220600',
  },
  {
    id: '220681',
    name: '临江市',
    short_name: '临江',
    f_id: '220600',
  },
  {
    id: '220702',
    name: '宁江区',
    short_name: '宁江',
    f_id: '220700',
  },
  {
    id: '220721',
    name: '前郭尔罗斯蒙古族自治县',
    short_name: '前郭',
    f_id: '220700',
  },
  {
    id: '220722',
    name: '长岭县',
    short_name: '长岭',
    f_id: '220700',
  },
  {
    id: '220723',
    name: '乾安县',
    short_name: '乾安',
    f_id: '220700',
  },
  {
    id: '220724',
    name: '扶余市',
    short_name: '扶余',
    f_id: '220700',
  },
  {
    id: '220802',
    name: '洮北区',
    short_name: '洮北',
    f_id: '220800',
  },
  {
    id: '220821',
    name: '镇赉县',
    short_name: '镇赉',
    f_id: '220800',
  },
  {
    id: '220822',
    name: '通榆县',
    short_name: '通榆',
    f_id: '220800',
  },
  {
    id: '220881',
    name: '洮南市',
    short_name: '洮南',
    f_id: '220800',
  },
  {
    id: '220882',
    name: '大安市',
    short_name: '大安',
    f_id: '220800',
  },
  {
    id: '222401',
    name: '延吉市',
    short_name: '延吉',
    f_id: '222400',
  },
  {
    id: '222402',
    name: '图们市',
    short_name: '图们',
    f_id: '222400',
  },
  {
    id: '222403',
    name: '敦化市',
    short_name: '敦化',
    f_id: '222400',
  },
  {
    id: '222404',
    name: '珲春市',
    short_name: '珲春',
    f_id: '222400',
  },
  {
    id: '222405',
    name: '龙井市',
    short_name: '龙井',
    f_id: '222400',
  },
  {
    id: '222406',
    name: '和龙市',
    short_name: '和龙',
    f_id: '222400',
  },
  {
    id: '222424',
    name: '汪清县',
    short_name: '汪清',
    f_id: '222400',
  },
  {
    id: '222426',
    name: '安图县',
    short_name: '安图',
    f_id: '222400',
  },
  {
    id: '230102',
    name: '道里区',
    short_name: '道里',
    f_id: '230100',
  },
  {
    id: '230103',
    name: '南岗区',
    short_name: '南岗',
    f_id: '230100',
  },
  {
    id: '230104',
    name: '道外区',
    short_name: '道外',
    f_id: '230100',
  },
  {
    id: '230106',
    name: '香坊区',
    short_name: '香坊',
    f_id: '230100',
  },
  {
    id: '230108',
    name: '平房区',
    short_name: '平房',
    f_id: '230100',
  },
  {
    id: '230109',
    name: '松北区',
    short_name: '松北',
    f_id: '230100',
  },
  {
    id: '230111',
    name: '呼兰区',
    short_name: '呼兰',
    f_id: '230100',
  },
  {
    id: '230123',
    name: '依兰县',
    short_name: '依兰',
    f_id: '230100',
  },
  {
    id: '230124',
    name: '方正县',
    short_name: '方正',
    f_id: '230100',
  },
  {
    id: '230125',
    name: '宾县',
    short_name: '宾县',
    f_id: '230100',
  },
  {
    id: '230126',
    name: '巴彦县',
    short_name: '巴彦',
    f_id: '230100',
  },
  {
    id: '230127',
    name: '木兰县',
    short_name: '木兰',
    f_id: '230100',
  },
  {
    id: '230128',
    name: '通河县',
    short_name: '通河',
    f_id: '230100',
  },
  {
    id: '230129',
    name: '延寿县',
    short_name: '延寿',
    f_id: '230100',
  },
  {
    id: '230181',
    name: '阿城区',
    short_name: '阿城',
    f_id: '230100',
  },
  {
    id: '230182',
    name: '双城市',
    short_name: '双城',
    f_id: '230100',
  },
  {
    id: '230183',
    name: '尚志市',
    short_name: '尚志',
    f_id: '230100',
  },
  {
    id: '230184',
    name: '五常市',
    short_name: '五常',
    f_id: '230100',
  },
  {
    id: '230202',
    name: '龙沙区',
    short_name: '龙沙',
    f_id: '230200',
  },
  {
    id: '230203',
    name: '建华区',
    short_name: '建华',
    f_id: '230200',
  },
  {
    id: '230204',
    name: '铁锋区',
    short_name: '铁锋',
    f_id: '230200',
  },
  {
    id: '230205',
    name: '昂昂溪区',
    short_name: '昂昂溪',
    f_id: '230200',
  },
  {
    id: '230206',
    name: '富拉尔基区',
    short_name: '富拉尔基',
    f_id: '230200',
  },
  {
    id: '230207',
    name: '碾子山区',
    short_name: '碾子山',
    f_id: '230200',
  },
  {
    id: '230208',
    name: '梅里斯达斡尔族区',
    short_name: '梅里斯达斡尔族',
    f_id: '230200',
  },
  {
    id: '230221',
    name: '龙江县',
    short_name: '龙江',
    f_id: '230200',
  },
  {
    id: '230223',
    name: '依安县',
    short_name: '依安',
    f_id: '230200',
  },
  {
    id: '230224',
    name: '泰来县',
    short_name: '泰来',
    f_id: '230200',
  },
  {
    id: '230225',
    name: '甘南县',
    short_name: '甘南',
    f_id: '230200',
  },
  {
    id: '230227',
    name: '富裕县',
    short_name: '富裕',
    f_id: '230200',
  },
  {
    id: '230229',
    name: '克山县',
    short_name: '克山',
    f_id: '230200',
  },
  {
    id: '230230',
    name: '克东县',
    short_name: '克东',
    f_id: '230200',
  },
  {
    id: '230231',
    name: '拜泉县',
    short_name: '拜泉',
    f_id: '230200',
  },
  {
    id: '230281',
    name: '讷河市',
    short_name: '讷河',
    f_id: '230200',
  },
  {
    id: '230302',
    name: '鸡冠区',
    short_name: '鸡冠',
    f_id: '230300',
  },
  {
    id: '230303',
    name: '恒山区',
    short_name: '恒山',
    f_id: '230300',
  },
  {
    id: '230304',
    name: '滴道区',
    short_name: '滴道',
    f_id: '230300',
  },
  {
    id: '230305',
    name: '梨树区',
    short_name: '梨树',
    f_id: '230300',
  },
  {
    id: '230306',
    name: '城子河区',
    short_name: '城子河',
    f_id: '230300',
  },
  {
    id: '230307',
    name: '麻山区',
    short_name: '麻山',
    f_id: '230300',
  },
  {
    id: '230321',
    name: '鸡东县',
    short_name: '鸡东',
    f_id: '230300',
  },
  {
    id: '230381',
    name: '虎林市',
    short_name: '虎林',
    f_id: '230300',
  },
  {
    id: '230382',
    name: '密山市',
    short_name: '密山',
    f_id: '230300',
  },
  {
    id: '230402',
    name: '向阳区',
    short_name: '向阳',
    f_id: '230400',
  },
  {
    id: '230403',
    name: '工农区',
    short_name: '工农',
    f_id: '230400',
  },
  {
    id: '230404',
    name: '南山区',
    short_name: '南山',
    f_id: '230400',
  },
  {
    id: '230405',
    name: '兴安区',
    short_name: '兴安',
    f_id: '230400',
  },
  {
    id: '230406',
    name: '东山区',
    short_name: '东山',
    f_id: '230400',
  },
  {
    id: '230407',
    name: '兴山区',
    short_name: '兴山',
    f_id: '230400',
  },
  {
    id: '230421',
    name: '萝北县',
    short_name: '萝北',
    f_id: '230400',
  },
  {
    id: '230422',
    name: '绥滨县',
    short_name: '绥滨',
    f_id: '230400',
  },
  {
    id: '230502',
    name: '尖山区',
    short_name: '尖山',
    f_id: '230500',
  },
  {
    id: '230503',
    name: '岭东区',
    short_name: '岭东',
    f_id: '230500',
  },
  {
    id: '230505',
    name: '四方台区',
    short_name: '四方台',
    f_id: '230500',
  },
  {
    id: '230506',
    name: '宝山区',
    short_name: '宝山',
    f_id: '230500',
  },
  {
    id: '230521',
    name: '集贤县',
    short_name: '集贤',
    f_id: '230500',
  },
  {
    id: '230522',
    name: '友谊县',
    short_name: '友谊',
    f_id: '230500',
  },
  {
    id: '230523',
    name: '宝清县',
    short_name: '宝清',
    f_id: '230500',
  },
  {
    id: '230524',
    name: '饶河县',
    short_name: '饶河',
    f_id: '230500',
  },
  {
    id: '230602',
    name: '萨尔图区',
    short_name: '萨尔图',
    f_id: '230600',
  },
  {
    id: '230603',
    name: '龙凤区',
    short_name: '龙凤',
    f_id: '230600',
  },
  {
    id: '230604',
    name: '让胡路区',
    short_name: '让胡路',
    f_id: '230600',
  },
  {
    id: '230605',
    name: '红岗区',
    short_name: '红岗',
    f_id: '230600',
  },
  {
    id: '230606',
    name: '大同区',
    short_name: '大同',
    f_id: '230600',
  },
  {
    id: '230621',
    name: '肇州县',
    short_name: '肇州',
    f_id: '230600',
  },
  {
    id: '230622',
    name: '肇源县',
    short_name: '肇源',
    f_id: '230600',
  },
  {
    id: '230623',
    name: '林甸县',
    short_name: '林甸',
    f_id: '230600',
  },
  {
    id: '230624',
    name: '杜尔伯特蒙古族自治县',
    short_name: '杜尔伯特',
    f_id: '230600',
  },
  {
    id: '230702',
    name: '伊春区',
    short_name: '伊春',
    f_id: '230700',
  },
  {
    id: '230703',
    name: '南岔区',
    short_name: '南岔',
    f_id: '230700',
  },
  {
    id: '230704',
    name: '友好区',
    short_name: '友好',
    f_id: '230700',
  },
  {
    id: '230705',
    name: '西林区',
    short_name: '西林',
    f_id: '230700',
  },
  {
    id: '230706',
    name: '翠峦区',
    short_name: '翠峦',
    f_id: '230700',
  },
  {
    id: '230707',
    name: '新青区',
    short_name: '新青',
    f_id: '230700',
  },
  {
    id: '230708',
    name: '美溪区',
    short_name: '美溪',
    f_id: '230700',
  },
  {
    id: '230709',
    name: '金山屯区',
    short_name: '金山屯',
    f_id: '230700',
  },
  {
    id: '230710',
    name: '五营区',
    short_name: '五营',
    f_id: '230700',
  },
  {
    id: '230711',
    name: '乌马河区',
    short_name: '乌马河',
    f_id: '230700',
  },
  {
    id: '230712',
    name: '汤旺河区',
    short_name: '汤旺河',
    f_id: '230700',
  },
  {
    id: '230713',
    name: '带岭区',
    short_name: '带岭',
    f_id: '230700',
  },
  {
    id: '230714',
    name: '乌伊岭区',
    short_name: '乌伊岭',
    f_id: '230700',
  },
  {
    id: '230715',
    name: '红星区',
    short_name: '红星',
    f_id: '230700',
  },
  {
    id: '230716',
    name: '上甘岭区',
    short_name: '上甘岭',
    f_id: '230700',
  },
  {
    id: '230722',
    name: '嘉荫县',
    short_name: '嘉荫',
    f_id: '230700',
  },
  {
    id: '230781',
    name: '铁力市',
    short_name: '铁力',
    f_id: '230700',
  },
  {
    id: '230803',
    name: '向阳区',
    short_name: '向阳',
    f_id: '230800',
  },
  {
    id: '230804',
    name: '前进区',
    short_name: '前进',
    f_id: '230800',
  },
  {
    id: '230805',
    name: '东风区',
    short_name: '东风',
    f_id: '230800',
  },
  {
    id: '230811',
    name: '郊区',
    short_name: '郊区',
    f_id: '230800',
  },
  {
    id: '230822',
    name: '桦南县',
    short_name: '桦南',
    f_id: '230800',
  },
  {
    id: '230826',
    name: '桦川县',
    short_name: '桦川',
    f_id: '230800',
  },
  {
    id: '230828',
    name: '汤原县',
    short_name: '汤原',
    f_id: '230800',
  },
  {
    id: '230833',
    name: '抚远县',
    short_name: '抚远',
    f_id: '230800',
  },
  {
    id: '230881',
    name: '同江市',
    short_name: '同江',
    f_id: '230800',
  },
  {
    id: '230882',
    name: '富锦市',
    short_name: '富锦',
    f_id: '230800',
  },
  {
    id: '230902',
    name: '新兴区',
    short_name: '新兴',
    f_id: '230900',
  },
  {
    id: '230903',
    name: '桃山区',
    short_name: '桃山',
    f_id: '230900',
  },
  {
    id: '230904',
    name: '茄子河区',
    short_name: '茄子河',
    f_id: '230900',
  },
  {
    id: '230921',
    name: '勃利县',
    short_name: '勃利',
    f_id: '230900',
  },
  {
    id: '231002',
    name: '东安区',
    short_name: '东安',
    f_id: '231000',
  },
  {
    id: '231003',
    name: '阳明区',
    short_name: '阳明',
    f_id: '231000',
  },
  {
    id: '231004',
    name: '爱民区',
    short_name: '爱民',
    f_id: '231000',
  },
  {
    id: '231005',
    name: '西安区',
    short_name: '西安',
    f_id: '231000',
  },
  {
    id: '231024',
    name: '东宁县',
    short_name: '东宁',
    f_id: '231000',
  },
  {
    id: '231025',
    name: '林口县',
    short_name: '林口',
    f_id: '231000',
  },
  {
    id: '231081',
    name: '绥芬河市',
    short_name: '绥芬河',
    f_id: '231000',
  },
  {
    id: '231083',
    name: '海林市',
    short_name: '海林',
    f_id: '231000',
  },
  {
    id: '231084',
    name: '宁安市',
    short_name: '宁安',
    f_id: '231000',
  },
  {
    id: '231085',
    name: '穆棱市',
    short_name: '穆棱',
    f_id: '231000',
  },
  {
    id: '231102',
    name: '爱辉区',
    short_name: '爱辉',
    f_id: '231100',
  },
  {
    id: '231121',
    name: '嫩江县',
    short_name: '嫩江',
    f_id: '231100',
  },
  {
    id: '231123',
    name: '逊克县',
    short_name: '逊克',
    f_id: '231100',
  },
  {
    id: '231124',
    name: '孙吴县',
    short_name: '孙吴',
    f_id: '231100',
  },
  {
    id: '231181',
    name: '北安市',
    short_name: '北安',
    f_id: '231100',
  },
  {
    id: '231182',
    name: '五大连池市',
    short_name: '五大连池',
    f_id: '231100',
  },
  {
    id: '231202',
    name: '北林区',
    short_name: '北林',
    f_id: '231200',
  },
  {
    id: '231221',
    name: '望奎县',
    short_name: '望奎',
    f_id: '231200',
  },
  {
    id: '231222',
    name: '兰西县',
    short_name: '兰西',
    f_id: '231200',
  },
  {
    id: '231223',
    name: '青冈县',
    short_name: '青冈',
    f_id: '231200',
  },
  {
    id: '231224',
    name: '庆安县',
    short_name: '庆安',
    f_id: '231200',
  },
  {
    id: '231225',
    name: '明水县',
    short_name: '明水',
    f_id: '231200',
  },
  {
    id: '231226',
    name: '绥棱县',
    short_name: '绥棱',
    f_id: '231200',
  },
  {
    id: '231281',
    name: '安达市',
    short_name: '安达',
    f_id: '231200',
  },
  {
    id: '231282',
    name: '肇东市',
    short_name: '肇东',
    f_id: '231200',
  },
  {
    id: '231283',
    name: '海伦市',
    short_name: '海伦',
    f_id: '231200',
  },
  {
    id: '232702',
    name: '松岭区',
    short_name: '松岭',
    f_id: '232700',
  },
  {
    id: '232703',
    name: '新林区',
    short_name: '新林',
    f_id: '232700',
  },
  {
    id: '232704',
    name: '呼中区',
    short_name: '呼中',
    f_id: '232700',
  },
  {
    id: '232721',
    name: '呼玛县',
    short_name: '呼玛',
    f_id: '232700',
  },
  {
    id: '232722',
    name: '塔河县',
    short_name: '塔河',
    f_id: '232700',
  },
  {
    id: '232723',
    name: '漠河县',
    short_name: '漠河',
    f_id: '232700',
  },
  {
    id: '232724',
    name: '加格达奇区',
    short_name: '加格达奇',
    f_id: '232700',
  },
  {
    id: '310101',
    name: '黄浦区',
    short_name: '黄浦',
    f_id: '310100',
  },
  {
    id: '310104',
    name: '徐汇区',
    short_name: '徐汇',
    f_id: '310100',
  },
  {
    id: '310105',
    name: '长宁区',
    short_name: '长宁',
    f_id: '310100',
  },
  {
    id: '310106',
    name: '静安区',
    short_name: '静安',
    f_id: '310100',
  },
  {
    id: '310107',
    name: '普陀区',
    short_name: '普陀',
    f_id: '310100',
  },
  {
    id: '310108',
    name: '闸北区',
    short_name: '闸北',
    f_id: '310100',
  },
  {
    id: '310109',
    name: '虹口区',
    short_name: '虹口',
    f_id: '310100',
  },
  {
    id: '310110',
    name: '杨浦区',
    short_name: '杨浦',
    f_id: '310100',
  },
  {
    id: '310112',
    name: '闵行区',
    short_name: '闵行',
    f_id: '310100',
  },
  {
    id: '310113',
    name: '宝山区',
    short_name: '宝山',
    f_id: '310100',
  },
  {
    id: '310114',
    name: '嘉定区',
    short_name: '嘉定',
    f_id: '310100',
  },
  {
    id: '310115',
    name: '浦东新区',
    short_name: '浦东',
    f_id: '310100',
  },
  {
    id: '310116',
    name: '金山区',
    short_name: '金山',
    f_id: '310100',
  },
  {
    id: '310117',
    name: '松江区',
    short_name: '松江',
    f_id: '310100',
  },
  {
    id: '310118',
    name: '青浦区',
    short_name: '青浦',
    f_id: '310100',
  },
  {
    id: '310120',
    name: '奉贤区',
    short_name: '奉贤',
    f_id: '310100',
  },
  {
    id: '310230',
    name: '崇明县',
    short_name: '崇明',
    f_id: '310100',
  },
  {
    id: '320102',
    name: '玄武区',
    short_name: '玄武',
    f_id: '320100',
  },
  {
    id: '320104',
    name: '秦淮区',
    short_name: '秦淮',
    f_id: '320100',
  },
  {
    id: '320105',
    name: '建邺区',
    short_name: '建邺',
    f_id: '320100',
  },
  {
    id: '320106',
    name: '鼓楼区',
    short_name: '鼓楼',
    f_id: '320100',
  },
  {
    id: '320111',
    name: '浦口区',
    short_name: '浦口',
    f_id: '320100',
  },
  {
    id: '320113',
    name: '栖霞区',
    short_name: '栖霞',
    f_id: '320100',
  },
  {
    id: '320114',
    name: '雨花台区',
    short_name: '雨花台',
    f_id: '320100',
  },
  {
    id: '320115',
    name: '江宁区',
    short_name: '江宁',
    f_id: '320100',
  },
  {
    id: '320116',
    name: '六合区',
    short_name: '六合',
    f_id: '320100',
  },
  {
    id: '320124',
    name: '溧水区',
    short_name: '溧水',
    f_id: '320100',
  },
  {
    id: '320125',
    name: '高淳区',
    short_name: '高淳',
    f_id: '320100',
  },
  {
    id: '320202',
    name: '崇安区',
    short_name: '崇安',
    f_id: '320200',
  },
  {
    id: '320203',
    name: '南长区',
    short_name: '南长',
    f_id: '320200',
  },
  {
    id: '320204',
    name: '北塘区',
    short_name: '北塘',
    f_id: '320200',
  },
  {
    id: '320205',
    name: '锡山区',
    short_name: '锡山',
    f_id: '320200',
  },
  {
    id: '320206',
    name: '惠山区',
    short_name: '惠山',
    f_id: '320200',
  },
  {
    id: '320211',
    name: '滨湖区',
    short_name: '滨湖',
    f_id: '320200',
  },
  {
    id: '320281',
    name: '江阴市',
    short_name: '江阴',
    f_id: '320200',
  },
  {
    id: '320282',
    name: '宜兴市',
    short_name: '宜兴',
    f_id: '320200',
  },
  {
    id: '320302',
    name: '鼓楼区',
    short_name: '鼓楼',
    f_id: '320300',
  },
  {
    id: '320303',
    name: '云龙区',
    short_name: '云龙',
    f_id: '320300',
  },
  {
    id: '320305',
    name: '贾汪区',
    short_name: '贾汪',
    f_id: '320300',
  },
  {
    id: '320311',
    name: '泉山区',
    short_name: '泉山',
    f_id: '320300',
  },
  {
    id: '320321',
    name: '丰县',
    short_name: '丰县',
    f_id: '320300',
  },
  {
    id: '320322',
    name: '沛县',
    short_name: '沛县',
    f_id: '320300',
  },
  {
    id: '320323',
    name: '铜山区',
    short_name: '铜山',
    f_id: '320300',
  },
  {
    id: '320324',
    name: '睢宁县',
    short_name: '睢宁',
    f_id: '320300',
  },
  {
    id: '320381',
    name: '新沂市',
    short_name: '新沂',
    f_id: '320300',
  },
  {
    id: '320382',
    name: '邳州市',
    short_name: '邳州',
    f_id: '320300',
  },
  {
    id: '320402',
    name: '天宁区',
    short_name: '天宁',
    f_id: '320400',
  },
  {
    id: '320404',
    name: '钟楼区',
    short_name: '钟楼',
    f_id: '320400',
  },
  {
    id: '320405',
    name: '戚墅堰区',
    short_name: '戚墅堰',
    f_id: '320400',
  },
  {
    id: '320411',
    name: '新北区',
    short_name: '新北',
    f_id: '320400',
  },
  {
    id: '320412',
    name: '武进区',
    short_name: '武进',
    f_id: '320400',
  },
  {
    id: '320481',
    name: '溧阳市',
    short_name: '溧阳',
    f_id: '320400',
  },
  {
    id: '320482',
    name: '金坛市',
    short_name: '金坛',
    f_id: '320400',
  },
  {
    id: '320505',
    name: '虎丘区',
    short_name: '虎丘',
    f_id: '320500',
  },
  {
    id: '320506',
    name: '吴中区',
    short_name: '吴中',
    f_id: '320500',
  },
  {
    id: '320507',
    name: '相城区',
    short_name: '相城',
    f_id: '320500',
  },
  {
    id: '320508',
    name: '姑苏区',
    short_name: '姑苏',
    f_id: '320500',
  },
  {
    id: '320581',
    name: '常熟市',
    short_name: '常熟',
    f_id: '320500',
  },
  {
    id: '320582',
    name: '张家港市',
    short_name: '张家港',
    f_id: '320500',
  },
  {
    id: '320583',
    name: '昆山市',
    short_name: '昆山',
    f_id: '320500',
  },
  {
    id: '320584',
    name: '吴江区',
    short_name: '吴江',
    f_id: '320500',
  },
  {
    id: '320585',
    name: '太仓市',
    short_name: '太仓',
    f_id: '320500',
  },
  {
    id: '320602',
    name: '崇川区',
    short_name: '崇川',
    f_id: '320600',
  },
  {
    id: '320611',
    name: '港闸区',
    short_name: '港闸',
    f_id: '320600',
  },
  {
    id: '320612',
    name: '通州区',
    short_name: '通州',
    f_id: '320600',
  },
  {
    id: '320621',
    name: '海安县',
    short_name: '海安',
    f_id: '320600',
  },
  {
    id: '320623',
    name: '如东县',
    short_name: '如东',
    f_id: '320600',
  },
  {
    id: '320681',
    name: '启东市',
    short_name: '启东',
    f_id: '320600',
  },
  {
    id: '320682',
    name: '如皋市',
    short_name: '如皋',
    f_id: '320600',
  },
  {
    id: '320684',
    name: '海门市',
    short_name: '海门',
    f_id: '320600',
  },
  {
    id: '320703',
    name: '连云区',
    short_name: '连云',
    f_id: '320700',
  },
  {
    id: '320705',
    name: '新浦区',
    short_name: '新浦',
    f_id: '320700',
  },
  {
    id: '320706',
    name: '海州区',
    short_name: '海州',
    f_id: '320700',
  },
  {
    id: '320721',
    name: '赣榆县',
    short_name: '赣榆',
    f_id: '320700',
  },
  {
    id: '320722',
    name: '东海县',
    short_name: '东海',
    f_id: '320700',
  },
  {
    id: '320723',
    name: '灌云县',
    short_name: '灌云',
    f_id: '320700',
  },
  {
    id: '320724',
    name: '灌南县',
    short_name: '灌南',
    f_id: '320700',
  },
  {
    id: '320802',
    name: '清河区',
    short_name: '清河',
    f_id: '320800',
  },
  {
    id: '320803',
    name: '淮安区',
    short_name: '淮安',
    f_id: '320800',
  },
  {
    id: '320804',
    name: '淮阴区',
    short_name: '淮阴',
    f_id: '320800',
  },
  {
    id: '320811',
    name: '清浦区',
    short_name: '清浦',
    f_id: '320800',
  },
  {
    id: '320826',
    name: '涟水县',
    short_name: '涟水',
    f_id: '320800',
  },
  {
    id: '320829',
    name: '洪泽县',
    short_name: '洪泽',
    f_id: '320800',
  },
  {
    id: '320830',
    name: '盱眙县',
    short_name: '盱眙',
    f_id: '320800',
  },
  {
    id: '320831',
    name: '金湖县',
    short_name: '金湖',
    f_id: '320800',
  },
  {
    id: '320902',
    name: '亭湖区',
    short_name: '亭湖',
    f_id: '320900',
  },
  {
    id: '320903',
    name: '盐都区',
    short_name: '盐都',
    f_id: '320900',
  },
  {
    id: '320921',
    name: '响水县',
    short_name: '响水',
    f_id: '320900',
  },
  {
    id: '320922',
    name: '滨海县',
    short_name: '滨海',
    f_id: '320900',
  },
  {
    id: '320923',
    name: '阜宁县',
    short_name: '阜宁',
    f_id: '320900',
  },
  {
    id: '320924',
    name: '射阳县',
    short_name: '射阳',
    f_id: '320900',
  },
  {
    id: '320925',
    name: '建湖县',
    short_name: '建湖',
    f_id: '320900',
  },
  {
    id: '320981',
    name: '东台市',
    short_name: '东台',
    f_id: '320900',
  },
  {
    id: '320982',
    name: '大丰市',
    short_name: '大丰',
    f_id: '320900',
  },
  {
    id: '321002',
    name: '广陵区',
    short_name: '广陵',
    f_id: '321000',
  },
  {
    id: '321003',
    name: '邗江区',
    short_name: '邗江',
    f_id: '321000',
  },
  {
    id: '321023',
    name: '宝应县',
    short_name: '宝应',
    f_id: '321000',
  },
  {
    id: '321081',
    name: '仪征市',
    short_name: '仪征',
    f_id: '321000',
  },
  {
    id: '321084',
    name: '高邮市',
    short_name: '高邮',
    f_id: '321000',
  },
  {
    id: '321088',
    name: '江都区',
    short_name: '江都',
    f_id: '321000',
  },
  {
    id: '321102',
    name: '京口区',
    short_name: '京口',
    f_id: '321100',
  },
  {
    id: '321111',
    name: '润州区',
    short_name: '润州',
    f_id: '321100',
  },
  {
    id: '321112',
    name: '丹徒区',
    short_name: '丹徒',
    f_id: '321100',
  },
  {
    id: '321181',
    name: '丹阳市',
    short_name: '丹阳',
    f_id: '321100',
  },
  {
    id: '321182',
    name: '扬中市',
    short_name: '扬中',
    f_id: '321100',
  },
  {
    id: '321183',
    name: '句容市',
    short_name: '句容',
    f_id: '321100',
  },
  {
    id: '321202',
    name: '海陵区',
    short_name: '海陵',
    f_id: '321200',
  },
  {
    id: '321203',
    name: '高港区',
    short_name: '高港',
    f_id: '321200',
  },
  {
    id: '321281',
    name: '兴化市',
    short_name: '兴化',
    f_id: '321200',
  },
  {
    id: '321282',
    name: '靖江市',
    short_name: '靖江',
    f_id: '321200',
  },
  {
    id: '321283',
    name: '泰兴市',
    short_name: '泰兴',
    f_id: '321200',
  },
  {
    id: '321284',
    name: '姜堰区',
    short_name: '姜堰',
    f_id: '321200',
  },
  {
    id: '321302',
    name: '宿城区',
    short_name: '宿城',
    f_id: '321300',
  },
  {
    id: '321311',
    name: '宿豫区',
    short_name: '宿豫',
    f_id: '321300',
  },
  {
    id: '321322',
    name: '沭阳县',
    short_name: '沭阳',
    f_id: '321300',
  },
  {
    id: '321323',
    name: '泗阳县',
    short_name: '泗阳',
    f_id: '321300',
  },
  {
    id: '321324',
    name: '泗洪县',
    short_name: '泗洪',
    f_id: '321300',
  },
  {
    id: '330102',
    name: '上城区',
    short_name: '上城',
    f_id: '330100',
  },
  {
    id: '330103',
    name: '下城区',
    short_name: '下城',
    f_id: '330100',
  },
  {
    id: '330104',
    name: '江干区',
    short_name: '江干',
    f_id: '330100',
  },
  {
    id: '330105',
    name: '拱墅区',
    short_name: '拱墅',
    f_id: '330100',
  },
  {
    id: '330106',
    name: '西湖区',
    short_name: '西湖',
    f_id: '330100',
  },
  {
    id: '330108',
    name: '滨江区',
    short_name: '滨江',
    f_id: '330100',
  },
  {
    id: '330109',
    name: '萧山区',
    short_name: '萧山',
    f_id: '330100',
  },
  {
    id: '330110',
    name: '余杭区',
    short_name: '余杭',
    f_id: '330100',
  },
  {
    id: '330122',
    name: '桐庐县',
    short_name: '桐庐',
    f_id: '330100',
  },
  {
    id: '330127',
    name: '淳安县',
    short_name: '淳安',
    f_id: '330100',
  },
  {
    id: '330182',
    name: '建德市',
    short_name: '建德',
    f_id: '330100',
  },
  {
    id: '330183',
    name: '富阳市',
    short_name: '富阳',
    f_id: '330100',
  },
  {
    id: '330185',
    name: '临安市',
    short_name: '临安',
    f_id: '330100',
  },
  {
    id: '330203',
    name: '海曙区',
    short_name: '海曙',
    f_id: '330200',
  },
  {
    id: '330204',
    name: '江东区',
    short_name: '江东',
    f_id: '330200',
  },
  {
    id: '330205',
    name: '江北区',
    short_name: '江北',
    f_id: '330200',
  },
  {
    id: '330206',
    name: '北仑区',
    short_name: '北仑',
    f_id: '330200',
  },
  {
    id: '330211',
    name: '镇海区',
    short_name: '镇海',
    f_id: '330200',
  },
  {
    id: '330212',
    name: '鄞州区',
    short_name: '鄞州',
    f_id: '330200',
  },
  {
    id: '330225',
    name: '象山县',
    short_name: '象山',
    f_id: '330200',
  },
  {
    id: '330226',
    name: '宁海县',
    short_name: '宁海',
    f_id: '330200',
  },
  {
    id: '330281',
    name: '余姚市',
    short_name: '余姚',
    f_id: '330200',
  },
  {
    id: '330282',
    name: '慈溪市',
    short_name: '慈溪',
    f_id: '330200',
  },
  {
    id: '330283',
    name: '奉化市',
    short_name: '奉化',
    f_id: '330200',
  },
  {
    id: '330302',
    name: '鹿城区',
    short_name: '鹿城',
    f_id: '330300',
  },
  {
    id: '330303',
    name: '龙湾区',
    short_name: '龙湾',
    f_id: '330300',
  },
  {
    id: '330304',
    name: '瓯海区',
    short_name: '瓯海',
    f_id: '330300',
  },
  {
    id: '330322',
    name: '洞头县',
    short_name: '洞头',
    f_id: '330300',
  },
  {
    id: '330324',
    name: '永嘉县',
    short_name: '永嘉',
    f_id: '330300',
  },
  {
    id: '330326',
    name: '平阳县',
    short_name: '平阳',
    f_id: '330300',
  },
  {
    id: '330327',
    name: '苍南县',
    short_name: '苍南',
    f_id: '330300',
  },
  {
    id: '330328',
    name: '文成县',
    short_name: '文成',
    f_id: '330300',
  },
  {
    id: '330329',
    name: '泰顺县',
    short_name: '泰顺',
    f_id: '330300',
  },
  {
    id: '330381',
    name: '瑞安市',
    short_name: '瑞安',
    f_id: '330300',
  },
  {
    id: '330382',
    name: '乐清市',
    short_name: '乐清',
    f_id: '330300',
  },
  {
    id: '330402',
    name: '南湖区',
    short_name: '南湖',
    f_id: '330400',
  },
  {
    id: '330411',
    name: '秀洲区',
    short_name: '秀洲',
    f_id: '330400',
  },
  {
    id: '330421',
    name: '嘉善县',
    short_name: '嘉善',
    f_id: '330400',
  },
  {
    id: '330424',
    name: '海盐县',
    short_name: '海盐',
    f_id: '330400',
  },
  {
    id: '330481',
    name: '海宁市',
    short_name: '海宁',
    f_id: '330400',
  },
  {
    id: '330482',
    name: '平湖市',
    short_name: '平湖',
    f_id: '330400',
  },
  {
    id: '330483',
    name: '桐乡市',
    short_name: '桐乡',
    f_id: '330400',
  },
  {
    id: '330502',
    name: '吴兴区',
    short_name: '吴兴',
    f_id: '330500',
  },
  {
    id: '330503',
    name: '南浔区',
    short_name: '南浔',
    f_id: '330500',
  },
  {
    id: '330521',
    name: '德清县',
    short_name: '德清',
    f_id: '330500',
  },
  {
    id: '330522',
    name: '长兴县',
    short_name: '长兴',
    f_id: '330500',
  },
  {
    id: '330523',
    name: '安吉县',
    short_name: '安吉',
    f_id: '330500',
  },
  {
    id: '330602',
    name: '越城区',
    short_name: '越城',
    f_id: '330600',
  },
  {
    id: '330621',
    name: '绍兴县',
    short_name: '绍兴',
    f_id: '330600',
  },
  {
    id: '330624',
    name: '新昌县',
    short_name: '新昌',
    f_id: '330600',
  },
  {
    id: '330681',
    name: '诸暨市',
    short_name: '诸暨',
    f_id: '330600',
  },
  {
    id: '330682',
    name: '上虞市',
    short_name: '上虞',
    f_id: '330600',
  },
  {
    id: '330683',
    name: '嵊州市',
    short_name: '嵊州',
    f_id: '330600',
  },
  {
    id: '330702',
    name: '婺城区',
    short_name: '婺城',
    f_id: '330700',
  },
  {
    id: '330703',
    name: '金东区',
    short_name: '金东',
    f_id: '330700',
  },
  {
    id: '330723',
    name: '武义县',
    short_name: '武义',
    f_id: '330700',
  },
  {
    id: '330726',
    name: '浦江县',
    short_name: '浦江',
    f_id: '330700',
  },
  {
    id: '330727',
    name: '磐安县',
    short_name: '磐安',
    f_id: '330700',
  },
  {
    id: '330781',
    name: '兰溪市',
    short_name: '兰溪',
    f_id: '330700',
  },
  {
    id: '330782',
    name: '义乌市',
    short_name: '义乌',
    f_id: '330700',
  },
  {
    id: '330783',
    name: '东阳市',
    short_name: '东阳',
    f_id: '330700',
  },
  {
    id: '330784',
    name: '永康市',
    short_name: '永康',
    f_id: '330700',
  },
  {
    id: '330802',
    name: '柯城区',
    short_name: '柯城',
    f_id: '330800',
  },
  {
    id: '330803',
    name: '衢江区',
    short_name: '衢江',
    f_id: '330800',
  },
  {
    id: '330822',
    name: '常山县',
    short_name: '常山',
    f_id: '330800',
  },
  {
    id: '330824',
    name: '开化县',
    short_name: '开化',
    f_id: '330800',
  },
  {
    id: '330825',
    name: '龙游县',
    short_name: '龙游',
    f_id: '330800',
  },
  {
    id: '330881',
    name: '江山市',
    short_name: '江山',
    f_id: '330800',
  },
  {
    id: '330902',
    name: '定海区',
    short_name: '定海',
    f_id: '330900',
  },
  {
    id: '330903',
    name: '普陀区',
    short_name: '普陀',
    f_id: '330900',
  },
  {
    id: '330921',
    name: '岱山县',
    short_name: '岱山',
    f_id: '330900',
  },
  {
    id: '330922',
    name: '嵊泗县',
    short_name: '嵊泗',
    f_id: '330900',
  },
  {
    id: '331002',
    name: '椒江区',
    short_name: '椒江',
    f_id: '331000',
  },
  {
    id: '331003',
    name: '黄岩区',
    short_name: '黄岩',
    f_id: '331000',
  },
  {
    id: '331004',
    name: '路桥区',
    short_name: '路桥',
    f_id: '331000',
  },
  {
    id: '331021',
    name: '玉环县',
    short_name: '玉环',
    f_id: '331000',
  },
  {
    id: '331022',
    name: '三门县',
    short_name: '三门',
    f_id: '331000',
  },
  {
    id: '331023',
    name: '天台县',
    short_name: '天台',
    f_id: '331000',
  },
  {
    id: '331024',
    name: '仙居县',
    short_name: '仙居',
    f_id: '331000',
  },
  {
    id: '331081',
    name: '温岭市',
    short_name: '温岭',
    f_id: '331000',
  },
  {
    id: '331082',
    name: '临海市',
    short_name: '临海',
    f_id: '331000',
  },
  {
    id: '331102',
    name: '莲都区',
    short_name: '莲都',
    f_id: '331100',
  },
  {
    id: '331121',
    name: '青田县',
    short_name: '青田',
    f_id: '331100',
  },
  {
    id: '331122',
    name: '缙云县',
    short_name: '缙云',
    f_id: '331100',
  },
  {
    id: '331123',
    name: '遂昌县',
    short_name: '遂昌',
    f_id: '331100',
  },
  {
    id: '331124',
    name: '松阳县',
    short_name: '松阳',
    f_id: '331100',
  },
  {
    id: '331125',
    name: '云和县',
    short_name: '云和',
    f_id: '331100',
  },
  {
    id: '331126',
    name: '庆元县',
    short_name: '庆元',
    f_id: '331100',
  },
  {
    id: '331127',
    name: '景宁畲族自治县',
    short_name: '景宁',
    f_id: '331100',
  },
  {
    id: '331181',
    name: '龙泉市',
    short_name: '龙泉',
    f_id: '331100',
  },
  {
    id: '340102',
    name: '瑶海区',
    short_name: '瑶海',
    f_id: '340100',
  },
  {
    id: '340103',
    name: '庐阳区',
    short_name: '庐阳',
    f_id: '340100',
  },
  {
    id: '340104',
    name: '蜀山区',
    short_name: '蜀山',
    f_id: '340100',
  },
  {
    id: '340111',
    name: '包河区',
    short_name: '包河',
    f_id: '340100',
  },
  {
    id: '340121',
    name: '长丰县',
    short_name: '长丰',
    f_id: '340100',
  },
  {
    id: '340122',
    name: '肥东县',
    short_name: '肥东',
    f_id: '340100',
  },
  {
    id: '340123',
    name: '肥西县',
    short_name: '肥西',
    f_id: '340100',
  },
  {
    id: '340202',
    name: '镜湖区',
    short_name: '镜湖',
    f_id: '340200',
  },
  {
    id: '340203',
    name: '弋江区',
    short_name: '弋江',
    f_id: '340200',
  },
  {
    id: '340207',
    name: '鸠江区',
    short_name: '鸠江',
    f_id: '340200',
  },
  {
    id: '340208',
    name: '三山区',
    short_name: '三山',
    f_id: '340200',
  },
  {
    id: '340221',
    name: '芜湖县',
    short_name: '芜湖',
    f_id: '340200',
  },
  {
    id: '340222',
    name: '繁昌县',
    short_name: '繁昌',
    f_id: '340200',
  },
  {
    id: '340223',
    name: '南陵县',
    short_name: '南陵',
    f_id: '340200',
  },
  {
    id: '340302',
    name: '龙子湖区',
    short_name: '龙子湖',
    f_id: '340300',
  },
  {
    id: '340303',
    name: '蚌山区',
    short_name: '蚌山',
    f_id: '340300',
  },
  {
    id: '340304',
    name: '禹会区',
    short_name: '禹会',
    f_id: '340300',
  },
  {
    id: '340311',
    name: '淮上区',
    short_name: '淮上',
    f_id: '340300',
  },
  {
    id: '340321',
    name: '怀远县',
    short_name: '怀远',
    f_id: '340300',
  },
  {
    id: '340322',
    name: '五河县',
    short_name: '五河',
    f_id: '340300',
  },
  {
    id: '340323',
    name: '固镇县',
    short_name: '固镇',
    f_id: '340300',
  },
  {
    id: '340402',
    name: '大通区',
    short_name: '大通',
    f_id: '340400',
  },
  {
    id: '340403',
    name: '田家庵区',
    short_name: '田家庵',
    f_id: '340400',
  },
  {
    id: '340404',
    name: '谢家集区',
    short_name: '谢家集',
    f_id: '340400',
  },
  {
    id: '340405',
    name: '八公山区',
    short_name: '八公山',
    f_id: '340400',
  },
  {
    id: '340406',
    name: '潘集区',
    short_name: '潘集',
    f_id: '340400',
  },
  {
    id: '340421',
    name: '凤台县',
    short_name: '凤台',
    f_id: '340400',
  },
  {
    id: '340503',
    name: '花山区',
    short_name: '花山',
    f_id: '340500',
  },
  {
    id: '340504',
    name: '雨山区',
    short_name: '雨山',
    f_id: '340500',
  },
  {
    id: '340506',
    name: '博望区',
    short_name: '博望',
    f_id: '340500',
  },
  {
    id: '340521',
    name: '当涂县',
    short_name: '当涂',
    f_id: '340500',
  },
  {
    id: '340602',
    name: '杜集区',
    short_name: '杜集',
    f_id: '340600',
  },
  {
    id: '340603',
    name: '相山区',
    short_name: '相山',
    f_id: '340600',
  },
  {
    id: '340604',
    name: '烈山区',
    short_name: '烈山',
    f_id: '340600',
  },
  {
    id: '340621',
    name: '濉溪县',
    short_name: '濉溪',
    f_id: '340600',
  },
  {
    id: '340702',
    name: '铜官山区',
    short_name: '铜官山',
    f_id: '340700',
  },
  {
    id: '340703',
    name: '狮子山区',
    short_name: '狮子山',
    f_id: '340700',
  },
  {
    id: '340711',
    name: '郊区',
    short_name: '郊区',
    f_id: '340700',
  },
  {
    id: '340721',
    name: '铜陵县',
    short_name: '铜陵',
    f_id: '340700',
  },
  {
    id: '340802',
    name: '迎江区',
    short_name: '迎江',
    f_id: '340800',
  },
  {
    id: '340803',
    name: '大观区',
    short_name: '大观',
    f_id: '340800',
  },
  {
    id: '340811',
    name: '宜秀区',
    short_name: '宜秀',
    f_id: '340800',
  },
  {
    id: '340822',
    name: '怀宁县',
    short_name: '怀宁',
    f_id: '340800',
  },
  {
    id: '340823',
    name: '枞阳县',
    short_name: '枞阳',
    f_id: '340800',
  },
  {
    id: '340824',
    name: '潜山县',
    short_name: '潜山',
    f_id: '340800',
  },
  {
    id: '340825',
    name: '太湖县',
    short_name: '太湖',
    f_id: '340800',
  },
  {
    id: '340826',
    name: '宿松县',
    short_name: '宿松',
    f_id: '340800',
  },
  {
    id: '340827',
    name: '望江县',
    short_name: '望江',
    f_id: '340800',
  },
  {
    id: '340828',
    name: '岳西县',
    short_name: '岳西',
    f_id: '340800',
  },
  {
    id: '340881',
    name: '桐城市',
    short_name: '桐城',
    f_id: '340800',
  },
  {
    id: '341002',
    name: '屯溪区',
    short_name: '屯溪',
    f_id: '341000',
  },
  {
    id: '341003',
    name: '黄山区',
    short_name: '黄山',
    f_id: '341000',
  },
  {
    id: '341004',
    name: '徽州区',
    short_name: '徽州',
    f_id: '341000',
  },
  {
    id: '341021',
    name: '歙县',
    short_name: '歙县',
    f_id: '341000',
  },
  {
    id: '341022',
    name: '休宁县',
    short_name: '休宁',
    f_id: '341000',
  },
  {
    id: '341023',
    name: '黟县',
    short_name: '黟县',
    f_id: '341000',
  },
  {
    id: '341024',
    name: '祁门县',
    short_name: '祁门',
    f_id: '341000',
  },
  {
    id: '341102',
    name: '琅琊区',
    short_name: '琅琊',
    f_id: '341100',
  },
  {
    id: '341103',
    name: '南谯区',
    short_name: '南谯',
    f_id: '341100',
  },
  {
    id: '341122',
    name: '来安县',
    short_name: '来安',
    f_id: '341100',
  },
  {
    id: '341124',
    name: '全椒县',
    short_name: '全椒',
    f_id: '341100',
  },
  {
    id: '341125',
    name: '定远县',
    short_name: '定远',
    f_id: '341100',
  },
  {
    id: '341126',
    name: '凤阳县',
    short_name: '凤阳',
    f_id: '341100',
  },
  {
    id: '341181',
    name: '天长市',
    short_name: '天长',
    f_id: '341100',
  },
  {
    id: '341182',
    name: '明光市',
    short_name: '明光',
    f_id: '341100',
  },
  {
    id: '341202',
    name: '颍州区',
    short_name: '颍州',
    f_id: '341200',
  },
  {
    id: '341203',
    name: '颍东区',
    short_name: '颍东',
    f_id: '341200',
  },
  {
    id: '341204',
    name: '颍泉区',
    short_name: '颍泉',
    f_id: '341200',
  },
  {
    id: '341221',
    name: '临泉县',
    short_name: '临泉',
    f_id: '341200',
  },
  {
    id: '341222',
    name: '太和县',
    short_name: '太和',
    f_id: '341200',
  },
  {
    id: '341225',
    name: '阜南县',
    short_name: '阜南',
    f_id: '341200',
  },
  {
    id: '341226',
    name: '颍上县',
    short_name: '颍上',
    f_id: '341200',
  },
  {
    id: '341282',
    name: '界首市',
    short_name: '界首',
    f_id: '341200',
  },
  {
    id: '341302',
    name: '埇桥区',
    short_name: '埇桥',
    f_id: '341300',
  },
  {
    id: '341321',
    name: '砀山县',
    short_name: '砀山',
    f_id: '341300',
  },
  {
    id: '341322',
    name: '萧县',
    short_name: '萧县',
    f_id: '341300',
  },
  {
    id: '341323',
    name: '灵璧县',
    short_name: '灵璧',
    f_id: '341300',
  },
  {
    id: '341324',
    name: '泗县',
    short_name: '泗县',
    f_id: '341300',
  },
  {
    id: '341400',
    name: '巢湖市',
    short_name: '巢湖',
    f_id: '340100',
  },
  {
    id: '341421',
    name: '庐江县',
    short_name: '庐江',
    f_id: '340100',
  },
  {
    id: '341422',
    name: '无为县',
    short_name: '无为',
    f_id: '340200',
  },
  {
    id: '341423',
    name: '含山县',
    short_name: '含山',
    f_id: '340500',
  },
  {
    id: '341424',
    name: '和县',
    short_name: '和县',
    f_id: '340500',
  },
  {
    id: '341502',
    name: '金安区',
    short_name: '金安',
    f_id: '341500',
  },
  {
    id: '341503',
    name: '裕安区',
    short_name: '裕安',
    f_id: '341500',
  },
  {
    id: '341521',
    name: '寿县',
    short_name: '寿县',
    f_id: '341500',
  },
  {
    id: '341522',
    name: '霍邱县',
    short_name: '霍邱',
    f_id: '341500',
  },
  {
    id: '341523',
    name: '舒城县',
    short_name: '舒城',
    f_id: '341500',
  },
  {
    id: '341524',
    name: '金寨县',
    short_name: '金寨',
    f_id: '341500',
  },
  {
    id: '341525',
    name: '霍山县',
    short_name: '霍山',
    f_id: '341500',
  },
  {
    id: '341602',
    name: '谯城区',
    short_name: '谯城',
    f_id: '341600',
  },
  {
    id: '341621',
    name: '涡阳县',
    short_name: '涡阳',
    f_id: '341600',
  },
  {
    id: '341622',
    name: '蒙城县',
    short_name: '蒙城',
    f_id: '341600',
  },
  {
    id: '341623',
    name: '利辛县',
    short_name: '利辛',
    f_id: '341600',
  },
  {
    id: '341702',
    name: '贵池区',
    short_name: '贵池',
    f_id: '341700',
  },
  {
    id: '341721',
    name: '东至县',
    short_name: '东至',
    f_id: '341700',
  },
  {
    id: '341722',
    name: '石台县',
    short_name: '石台',
    f_id: '341700',
  },
  {
    id: '341723',
    name: '青阳县',
    short_name: '青阳',
    f_id: '341700',
  },
  {
    id: '341802',
    name: '宣州区',
    short_name: '宣州',
    f_id: '341800',
  },
  {
    id: '341821',
    name: '郎溪县',
    short_name: '郎溪',
    f_id: '341800',
  },
  {
    id: '341822',
    name: '广德县',
    short_name: '广德',
    f_id: '341800',
  },
  {
    id: '341823',
    name: '泾县',
    short_name: '泾县',
    f_id: '341800',
  },
  {
    id: '341824',
    name: '绩溪县',
    short_name: '绩溪',
    f_id: '341800',
  },
  {
    id: '341825',
    name: '旌德县',
    short_name: '旌德',
    f_id: '341800',
  },
  {
    id: '341881',
    name: '宁国市',
    short_name: '宁国',
    f_id: '341800',
  },
  {
    id: '350102',
    name: '鼓楼区',
    short_name: '鼓楼',
    f_id: '350100',
  },
  {
    id: '350103',
    name: '台江区',
    short_name: '台江',
    f_id: '350100',
  },
  {
    id: '350104',
    name: '仓山区',
    short_name: '仓山',
    f_id: '350100',
  },
  {
    id: '350105',
    name: '马尾区',
    short_name: '马尾',
    f_id: '350100',
  },
  {
    id: '350111',
    name: '晋安区',
    short_name: '晋安',
    f_id: '350100',
  },
  {
    id: '350121',
    name: '闽侯县',
    short_name: '闽侯',
    f_id: '350100',
  },
  {
    id: '350122',
    name: '连江县',
    short_name: '连江',
    f_id: '350100',
  },
  {
    id: '350123',
    name: '罗源县',
    short_name: '罗源',
    f_id: '350100',
  },
  {
    id: '350124',
    name: '闽清县',
    short_name: '闽清',
    f_id: '350100',
  },
  {
    id: '350125',
    name: '永泰县',
    short_name: '永泰',
    f_id: '350100',
  },
  {
    id: '350128',
    name: '平潭县',
    short_name: '平潭',
    f_id: '350100',
  },
  {
    id: '350181',
    name: '福清市',
    short_name: '福清',
    f_id: '350100',
  },
  {
    id: '350182',
    name: '长乐市',
    short_name: '长乐',
    f_id: '350100',
  },
  {
    id: '350203',
    name: '思明区',
    short_name: '思明',
    f_id: '350200',
  },
  {
    id: '350205',
    name: '海沧区',
    short_name: '海沧',
    f_id: '350200',
  },
  {
    id: '350206',
    name: '湖里区',
    short_name: '湖里',
    f_id: '350200',
  },
  {
    id: '350211',
    name: '集美区',
    short_name: '集美',
    f_id: '350200',
  },
  {
    id: '350212',
    name: '同安区',
    short_name: '同安',
    f_id: '350200',
  },
  {
    id: '350213',
    name: '翔安区',
    short_name: '翔安',
    f_id: '350200',
  },
  {
    id: '350302',
    name: '城厢区',
    short_name: '城厢',
    f_id: '350300',
  },
  {
    id: '350303',
    name: '涵江区',
    short_name: '涵江',
    f_id: '350300',
  },
  {
    id: '350304',
    name: '荔城区',
    short_name: '荔城',
    f_id: '350300',
  },
  {
    id: '350305',
    name: '秀屿区',
    short_name: '秀屿',
    f_id: '350300',
  },
  {
    id: '350322',
    name: '仙游县',
    short_name: '仙游',
    f_id: '350300',
  },
  {
    id: '350402',
    name: '梅列区',
    short_name: '梅列',
    f_id: '350400',
  },
  {
    id: '350403',
    name: '三元区',
    short_name: '三元',
    f_id: '350400',
  },
  {
    id: '350421',
    name: '明溪县',
    short_name: '明溪',
    f_id: '350400',
  },
  {
    id: '350423',
    name: '清流县',
    short_name: '清流',
    f_id: '350400',
  },
  {
    id: '350424',
    name: '宁化县',
    short_name: '宁化',
    f_id: '350400',
  },
  {
    id: '350425',
    name: '大田县',
    short_name: '大田',
    f_id: '350400',
  },
  {
    id: '350426',
    name: '尤溪县',
    short_name: '尤溪',
    f_id: '350400',
  },
  {
    id: '350427',
    name: '沙县',
    short_name: '沙县',
    f_id: '350400',
  },
  {
    id: '350428',
    name: '将乐县',
    short_name: '将乐',
    f_id: '350400',
  },
  {
    id: '350429',
    name: '泰宁县',
    short_name: '泰宁',
    f_id: '350400',
  },
  {
    id: '350430',
    name: '建宁县',
    short_name: '建宁',
    f_id: '350400',
  },
  {
    id: '350481',
    name: '永安市',
    short_name: '永安',
    f_id: '350400',
  },
  {
    id: '350502',
    name: '鲤城区',
    short_name: '鲤城',
    f_id: '350500',
  },
  {
    id: '350503',
    name: '丰泽区',
    short_name: '丰泽',
    f_id: '350500',
  },
  {
    id: '350504',
    name: '洛江区',
    short_name: '洛江',
    f_id: '350500',
  },
  {
    id: '350505',
    name: '泉港区',
    short_name: '泉港',
    f_id: '350500',
  },
  {
    id: '350521',
    name: '惠安县',
    short_name: '惠安',
    f_id: '350500',
  },
  {
    id: '350524',
    name: '安溪县',
    short_name: '安溪',
    f_id: '350500',
  },
  {
    id: '350525',
    name: '永春县',
    short_name: '永春',
    f_id: '350500',
  },
  {
    id: '350526',
    name: '德化县',
    short_name: '德化',
    f_id: '350500',
  },
  {
    id: '350527',
    name: '金门县',
    short_name: '金门',
    f_id: '350500',
  },
  {
    id: '350581',
    name: '石狮市',
    short_name: '石狮',
    f_id: '350500',
  },
  {
    id: '350582',
    name: '晋江市',
    short_name: '晋江',
    f_id: '350500',
  },
  {
    id: '350583',
    name: '南安市',
    short_name: '南安',
    f_id: '350500',
  },
  {
    id: '350602',
    name: '芗城区',
    short_name: '芗城',
    f_id: '350600',
  },
  {
    id: '350603',
    name: '龙文区',
    short_name: '龙文',
    f_id: '350600',
  },
  {
    id: '350622',
    name: '云霄县',
    short_name: '云霄',
    f_id: '350600',
  },
  {
    id: '350623',
    name: '漳浦县',
    short_name: '漳浦',
    f_id: '350600',
  },
  {
    id: '350624',
    name: '诏安县',
    short_name: '诏安',
    f_id: '350600',
  },
  {
    id: '350625',
    name: '长泰县',
    short_name: '长泰',
    f_id: '350600',
  },
  {
    id: '350626',
    name: '东山县',
    short_name: '东山',
    f_id: '350600',
  },
  {
    id: '350627',
    name: '南靖县',
    short_name: '南靖',
    f_id: '350600',
  },
  {
    id: '350628',
    name: '平和县',
    short_name: '平和',
    f_id: '350600',
  },
  {
    id: '350629',
    name: '华安县',
    short_name: '华安',
    f_id: '350600',
  },
  {
    id: '350681',
    name: '龙海市',
    short_name: '龙海',
    f_id: '350600',
  },
  {
    id: '350702',
    name: '延平区',
    short_name: '延平',
    f_id: '350700',
  },
  {
    id: '350721',
    name: '顺昌县',
    short_name: '顺昌',
    f_id: '350700',
  },
  {
    id: '350722',
    name: '浦城县',
    short_name: '浦城',
    f_id: '350700',
  },
  {
    id: '350723',
    name: '光泽县',
    short_name: '光泽',
    f_id: '350700',
  },
  {
    id: '350724',
    name: '松溪县',
    short_name: '松溪',
    f_id: '350700',
  },
  {
    id: '350725',
    name: '政和县',
    short_name: '政和',
    f_id: '350700',
  },
  {
    id: '350781',
    name: '邵武市',
    short_name: '邵武',
    f_id: '350700',
  },
  {
    id: '350782',
    name: '武夷山市',
    short_name: '武夷山',
    f_id: '350700',
  },
  {
    id: '350783',
    name: '建瓯市',
    short_name: '建瓯',
    f_id: '350700',
  },
  {
    id: '350784',
    name: '建阳市',
    short_name: '建阳',
    f_id: '350700',
  },
  {
    id: '350802',
    name: '新罗区',
    short_name: '新罗',
    f_id: '350800',
  },
  {
    id: '350821',
    name: '长汀县',
    short_name: '长汀',
    f_id: '350800',
  },
  {
    id: '350822',
    name: '永定县',
    short_name: '永定',
    f_id: '350800',
  },
  {
    id: '350823',
    name: '上杭县',
    short_name: '上杭',
    f_id: '350800',
  },
  {
    id: '350824',
    name: '武平县',
    short_name: '武平',
    f_id: '350800',
  },
  {
    id: '350825',
    name: '连城县',
    short_name: '连城',
    f_id: '350800',
  },
  {
    id: '350881',
    name: '漳平市',
    short_name: '漳平',
    f_id: '350800',
  },
  {
    id: '350902',
    name: '蕉城区',
    short_name: '蕉城',
    f_id: '350900',
  },
  {
    id: '350921',
    name: '霞浦县',
    short_name: '霞浦',
    f_id: '350900',
  },
  {
    id: '350922',
    name: '古田县',
    short_name: '古田',
    f_id: '350900',
  },
  {
    id: '350923',
    name: '屏南县',
    short_name: '屏南',
    f_id: '350900',
  },
  {
    id: '350924',
    name: '寿宁县',
    short_name: '寿宁',
    f_id: '350900',
  },
  {
    id: '350925',
    name: '周宁县',
    short_name: '周宁',
    f_id: '350900',
  },
  {
    id: '350926',
    name: '柘荣县',
    short_name: '柘荣',
    f_id: '350900',
  },
  {
    id: '350981',
    name: '福安市',
    short_name: '福安',
    f_id: '350900',
  },
  {
    id: '350982',
    name: '福鼎市',
    short_name: '福鼎',
    f_id: '350900',
  },
  {
    id: '360102',
    name: '东湖区',
    short_name: '东湖',
    f_id: '360100',
  },
  {
    id: '360103',
    name: '西湖区',
    short_name: '西湖',
    f_id: '360100',
  },
  {
    id: '360104',
    name: '青云谱区',
    short_name: '青云谱',
    f_id: '360100',
  },
  {
    id: '360105',
    name: '湾里区',
    short_name: '湾里',
    f_id: '360100',
  },
  {
    id: '360111',
    name: '青山湖区',
    short_name: '青山湖',
    f_id: '360100',
  },
  {
    id: '360121',
    name: '南昌县',
    short_name: '南昌',
    f_id: '360100',
  },
  {
    id: '360122',
    name: '新建县',
    short_name: '新建',
    f_id: '360100',
  },
  {
    id: '360123',
    name: '安义县',
    short_name: '安义',
    f_id: '360100',
  },
  {
    id: '360124',
    name: '进贤县',
    short_name: '进贤',
    f_id: '360100',
  },
  {
    id: '360202',
    name: '昌江区',
    short_name: '昌江',
    f_id: '360200',
  },
  {
    id: '360203',
    name: '珠山区',
    short_name: '珠山',
    f_id: '360200',
  },
  {
    id: '360222',
    name: '浮梁县',
    short_name: '浮梁',
    f_id: '360200',
  },
  {
    id: '360281',
    name: '乐平市',
    short_name: '乐平',
    f_id: '360200',
  },
  {
    id: '360302',
    name: '安源区',
    short_name: '安源',
    f_id: '360300',
  },
  {
    id: '360313',
    name: '湘东区',
    short_name: '湘东',
    f_id: '360300',
  },
  {
    id: '360321',
    name: '莲花县',
    short_name: '莲花',
    f_id: '360300',
  },
  {
    id: '360322',
    name: '上栗县',
    short_name: '上栗',
    f_id: '360300',
  },
  {
    id: '360323',
    name: '芦溪县',
    short_name: '芦溪',
    f_id: '360300',
  },
  {
    id: '360402',
    name: '庐山区',
    short_name: '庐山',
    f_id: '360400',
  },
  {
    id: '360403',
    name: '浔阳区',
    short_name: '浔阳',
    f_id: '360400',
  },
  {
    id: '360421',
    name: '九江县',
    short_name: '九江',
    f_id: '360400',
  },
  {
    id: '360423',
    name: '武宁县',
    short_name: '武宁',
    f_id: '360400',
  },
  {
    id: '360424',
    name: '修水县',
    short_name: '修水',
    f_id: '360400',
  },
  {
    id: '360425',
    name: '永修县',
    short_name: '永修',
    f_id: '360400',
  },
  {
    id: '360426',
    name: '德安县',
    short_name: '德安',
    f_id: '360400',
  },
  {
    id: '360427',
    name: '星子县',
    short_name: '星子',
    f_id: '360400',
  },
  {
    id: '360428',
    name: '都昌县',
    short_name: '都昌',
    f_id: '360400',
  },
  {
    id: '360429',
    name: '湖口县',
    short_name: '湖口',
    f_id: '360400',
  },
  {
    id: '360430',
    name: '彭泽县',
    short_name: '彭泽',
    f_id: '360400',
  },
  {
    id: '360481',
    name: '瑞昌市',
    short_name: '瑞昌',
    f_id: '360400',
  },
  {
    id: '360483',
    name: '共青城市',
    short_name: '共青城',
    f_id: '360400',
  },
  {
    id: '360502',
    name: '渝水区',
    short_name: '渝水',
    f_id: '360500',
  },
  {
    id: '360521',
    name: '分宜县',
    short_name: '分宜',
    f_id: '360500',
  },
  {
    id: '360602',
    name: '月湖区',
    short_name: '月湖',
    f_id: '360600',
  },
  {
    id: '360622',
    name: '余江县',
    short_name: '余江',
    f_id: '360600',
  },
  {
    id: '360681',
    name: '贵溪市',
    short_name: '贵溪',
    f_id: '360600',
  },
  {
    id: '360702',
    name: '章贡区',
    short_name: '章贡',
    f_id: '360700',
  },
  {
    id: '360721',
    name: '赣县',
    short_name: '赣县',
    f_id: '360700',
  },
  {
    id: '360722',
    name: '信丰县',
    short_name: '信丰',
    f_id: '360700',
  },
  {
    id: '360723',
    name: '大余县',
    short_name: '大余',
    f_id: '360700',
  },
  {
    id: '360724',
    name: '上犹县',
    short_name: '上犹',
    f_id: '360700',
  },
  {
    id: '360725',
    name: '崇义县',
    short_name: '崇义',
    f_id: '360700',
  },
  {
    id: '360726',
    name: '安远县',
    short_name: '安远',
    f_id: '360700',
  },
  {
    id: '360727',
    name: '龙南县',
    short_name: '龙南',
    f_id: '360700',
  },
  {
    id: '360728',
    name: '定南县',
    short_name: '定南',
    f_id: '360700',
  },
  {
    id: '360729',
    name: '全南县',
    short_name: '全南',
    f_id: '360700',
  },
  {
    id: '360730',
    name: '宁都县',
    short_name: '宁都',
    f_id: '360700',
  },
  {
    id: '360731',
    name: '于都县',
    short_name: '于都',
    f_id: '360700',
  },
  {
    id: '360732',
    name: '兴国县',
    short_name: '兴国',
    f_id: '360700',
  },
  {
    id: '360733',
    name: '会昌县',
    short_name: '会昌',
    f_id: '360700',
  },
  {
    id: '360734',
    name: '寻乌县',
    short_name: '寻乌',
    f_id: '360700',
  },
  {
    id: '360735',
    name: '石城县',
    short_name: '石城',
    f_id: '360700',
  },
  {
    id: '360781',
    name: '瑞金市',
    short_name: '瑞金',
    f_id: '360700',
  },
  {
    id: '360782',
    name: '南康市',
    short_name: '南康',
    f_id: '360700',
  },
  {
    id: '360802',
    name: '吉州区',
    short_name: '吉州',
    f_id: '360800',
  },
  {
    id: '360803',
    name: '青原区',
    short_name: '青原',
    f_id: '360800',
  },
  {
    id: '360821',
    name: '吉安县',
    short_name: '吉安',
    f_id: '360800',
  },
  {
    id: '360822',
    name: '吉水县',
    short_name: '吉水',
    f_id: '360800',
  },
  {
    id: '360823',
    name: '峡江县',
    short_name: '峡江',
    f_id: '360800',
  },
  {
    id: '360824',
    name: '新干县',
    short_name: '新干',
    f_id: '360800',
  },
  {
    id: '360825',
    name: '永丰县',
    short_name: '永丰',
    f_id: '360800',
  },
  {
    id: '360826',
    name: '泰和县',
    short_name: '泰和',
    f_id: '360800',
  },
  {
    id: '360827',
    name: '遂川县',
    short_name: '遂川',
    f_id: '360800',
  },
  {
    id: '360828',
    name: '万安县',
    short_name: '万安',
    f_id: '360800',
  },
  {
    id: '360829',
    name: '安福县',
    short_name: '安福',
    f_id: '360800',
  },
  {
    id: '360830',
    name: '永新县',
    short_name: '永新',
    f_id: '360800',
  },
  {
    id: '360881',
    name: '井冈山市',
    short_name: '井冈山',
    f_id: '360800',
  },
  {
    id: '360902',
    name: '袁州区',
    short_name: '袁州',
    f_id: '360900',
  },
  {
    id: '360921',
    name: '奉新县',
    short_name: '奉新',
    f_id: '360900',
  },
  {
    id: '360922',
    name: '万载县',
    short_name: '万载',
    f_id: '360900',
  },
  {
    id: '360923',
    name: '上高县',
    short_name: '上高',
    f_id: '360900',
  },
  {
    id: '360924',
    name: '宜丰县',
    short_name: '宜丰',
    f_id: '360900',
  },
  {
    id: '360925',
    name: '靖安县',
    short_name: '靖安',
    f_id: '360900',
  },
  {
    id: '360926',
    name: '铜鼓县',
    short_name: '铜鼓',
    f_id: '360900',
  },
  {
    id: '360981',
    name: '丰城市',
    short_name: '丰城',
    f_id: '360900',
  },
  {
    id: '360982',
    name: '樟树市',
    short_name: '樟树',
    f_id: '360900',
  },
  {
    id: '360983',
    name: '高安市',
    short_name: '高安',
    f_id: '360900',
  },
  {
    id: '361002',
    name: '临川区',
    short_name: '临川',
    f_id: '361000',
  },
  {
    id: '361021',
    name: '南城县',
    short_name: '南城',
    f_id: '361000',
  },
  {
    id: '361022',
    name: '黎川县',
    short_name: '黎川',
    f_id: '361000',
  },
  {
    id: '361023',
    name: '南丰县',
    short_name: '南丰',
    f_id: '361000',
  },
  {
    id: '361024',
    name: '崇仁县',
    short_name: '崇仁',
    f_id: '361000',
  },
  {
    id: '361025',
    name: '乐安县',
    short_name: '乐安',
    f_id: '361000',
  },
  {
    id: '361026',
    name: '宜黄县',
    short_name: '宜黄',
    f_id: '361000',
  },
  {
    id: '361027',
    name: '金溪县',
    short_name: '金溪',
    f_id: '361000',
  },
  {
    id: '361028',
    name: '资溪县',
    short_name: '资溪',
    f_id: '361000',
  },
  {
    id: '361029',
    name: '东乡县',
    short_name: '东乡',
    f_id: '361000',
  },
  {
    id: '361030',
    name: '广昌县',
    short_name: '广昌',
    f_id: '361000',
  },
  {
    id: '361102',
    name: '信州区',
    short_name: '信州',
    f_id: '361100',
  },
  {
    id: '361121',
    name: '上饶县',
    short_name: '上饶',
    f_id: '361100',
  },
  {
    id: '361122',
    name: '广丰县',
    short_name: '广丰',
    f_id: '361100',
  },
  {
    id: '361123',
    name: '玉山县',
    short_name: '玉山',
    f_id: '361100',
  },
  {
    id: '361124',
    name: '铅山县',
    short_name: '铅山',
    f_id: '361100',
  },
  {
    id: '361125',
    name: '横峰县',
    short_name: '横峰',
    f_id: '361100',
  },
  {
    id: '361126',
    name: '弋阳县',
    short_name: '弋阳',
    f_id: '361100',
  },
  {
    id: '361127',
    name: '余干县',
    short_name: '余干',
    f_id: '361100',
  },
  {
    id: '361128',
    name: '鄱阳县',
    short_name: '鄱阳',
    f_id: '361100',
  },
  {
    id: '361129',
    name: '万年县',
    short_name: '万年',
    f_id: '361100',
  },
  {
    id: '361130',
    name: '婺源县',
    short_name: '婺源',
    f_id: '361100',
  },
  {
    id: '361181',
    name: '德兴市',
    short_name: '德兴',
    f_id: '361100',
  },
  {
    id: '370102',
    name: '历下区',
    short_name: '历下',
    f_id: '370100',
  },
  {
    id: '370103',
    name: '市中区',
    short_name: '市中',
    f_id: '370100',
  },
  {
    id: '370104',
    name: '槐荫区',
    short_name: '槐荫',
    f_id: '370100',
  },
  {
    id: '370105',
    name: '天桥区',
    short_name: '天桥',
    f_id: '370100',
  },
  {
    id: '370112',
    name: '历城区',
    short_name: '历城',
    f_id: '370100',
  },
  {
    id: '370113',
    name: '长清区',
    short_name: '长清',
    f_id: '370100',
  },
  {
    id: '370124',
    name: '平阴县',
    short_name: '平阴',
    f_id: '370100',
  },
  {
    id: '370125',
    name: '济阳县',
    short_name: '济阳',
    f_id: '370100',
  },
  {
    id: '370126',
    name: '商河县',
    short_name: '商河',
    f_id: '370100',
  },
  {
    id: '370181',
    name: '章丘市',
    short_name: '章丘',
    f_id: '370100',
  },
  {
    id: '370202',
    name: '市南区',
    short_name: '市南',
    f_id: '370200',
  },
  {
    id: '370203',
    name: '市北区',
    short_name: '市北',
    f_id: '370200',
  },
  {
    id: '370211',
    name: '黄岛区',
    short_name: '黄岛',
    f_id: '370200',
  },
  {
    id: '370212',
    name: '崂山区',
    short_name: '崂山',
    f_id: '370200',
  },
  {
    id: '370213',
    name: '李沧区',
    short_name: '李沧',
    f_id: '370200',
  },
  {
    id: '370214',
    name: '城阳区',
    short_name: '城阳',
    f_id: '370200',
  },
  {
    id: '370281',
    name: '胶州市',
    short_name: '胶州',
    f_id: '370200',
  },
  {
    id: '370282',
    name: '即墨市',
    short_name: '即墨',
    f_id: '370200',
  },
  {
    id: '370283',
    name: '平度市',
    short_name: '平度',
    f_id: '370200',
  },
  {
    id: '370285',
    name: '莱西市',
    short_name: '莱西',
    f_id: '370200',
  },
  {
    id: '370302',
    name: '淄川区',
    short_name: '淄川',
    f_id: '370300',
  },
  {
    id: '370303',
    name: '张店区',
    short_name: '张店',
    f_id: '370300',
  },
  {
    id: '370304',
    name: '博山区',
    short_name: '博山',
    f_id: '370300',
  },
  {
    id: '370305',
    name: '临淄区',
    short_name: '临淄',
    f_id: '370300',
  },
  {
    id: '370306',
    name: '周村区',
    short_name: '周村',
    f_id: '370300',
  },
  {
    id: '370321',
    name: '桓台县',
    short_name: '桓台',
    f_id: '370300',
  },
  {
    id: '370322',
    name: '高青县',
    short_name: '高青',
    f_id: '370300',
  },
  {
    id: '370323',
    name: '沂源县',
    short_name: '沂源',
    f_id: '370300',
  },
  {
    id: '370402',
    name: '市中区',
    short_name: '市中',
    f_id: '370400',
  },
  {
    id: '370403',
    name: '薛城区',
    short_name: '薛城',
    f_id: '370400',
  },
  {
    id: '370404',
    name: '峄城区',
    short_name: '峄城',
    f_id: '370400',
  },
  {
    id: '370405',
    name: '台儿庄区',
    short_name: '台儿庄',
    f_id: '370400',
  },
  {
    id: '370406',
    name: '山亭区',
    short_name: '山亭',
    f_id: '370400',
  },
  {
    id: '370481',
    name: '滕州市',
    short_name: '滕州',
    f_id: '370400',
  },
  {
    id: '370502',
    name: '东营区',
    short_name: '东营',
    f_id: '370500',
  },
  {
    id: '370503',
    name: '河口区',
    short_name: '河口',
    f_id: '370500',
  },
  {
    id: '370521',
    name: '垦利县',
    short_name: '垦利',
    f_id: '370500',
  },
  {
    id: '370522',
    name: '利津县',
    short_name: '利津',
    f_id: '370500',
  },
  {
    id: '370523',
    name: '广饶县',
    short_name: '广饶',
    f_id: '370500',
  },
  {
    id: '370602',
    name: '芝罘区',
    short_name: '芝罘',
    f_id: '370600',
  },
  {
    id: '370611',
    name: '福山区',
    short_name: '福山',
    f_id: '370600',
  },
  {
    id: '370612',
    name: '牟平区',
    short_name: '牟平',
    f_id: '370600',
  },
  {
    id: '370613',
    name: '莱山区',
    short_name: '莱山',
    f_id: '370600',
  },
  {
    id: '370634',
    name: '长岛县',
    short_name: '长岛',
    f_id: '370600',
  },
  {
    id: '370681',
    name: '龙口市',
    short_name: '龙口',
    f_id: '370600',
  },
  {
    id: '370682',
    name: '莱阳市',
    short_name: '莱阳',
    f_id: '370600',
  },
  {
    id: '370683',
    name: '莱州市',
    short_name: '莱州',
    f_id: '370600',
  },
  {
    id: '370684',
    name: '蓬莱市',
    short_name: '蓬莱',
    f_id: '370600',
  },
  {
    id: '370685',
    name: '招远市',
    short_name: '招远',
    f_id: '370600',
  },
  {
    id: '370686',
    name: '栖霞市',
    short_name: '栖霞',
    f_id: '370600',
  },
  {
    id: '370687',
    name: '海阳市',
    short_name: '海阳',
    f_id: '370600',
  },
  {
    id: '370702',
    name: '潍城区',
    short_name: '潍城',
    f_id: '370700',
  },
  {
    id: '370703',
    name: '寒亭区',
    short_name: '寒亭',
    f_id: '370700',
  },
  {
    id: '370704',
    name: '坊子区',
    short_name: '坊子',
    f_id: '370700',
  },
  {
    id: '370705',
    name: '奎文区',
    short_name: '奎文',
    f_id: '370700',
  },
  {
    id: '370724',
    name: '临朐县',
    short_name: '临朐',
    f_id: '370700',
  },
  {
    id: '370725',
    name: '昌乐县',
    short_name: '昌乐',
    f_id: '370700',
  },
  {
    id: '370781',
    name: '青州市',
    short_name: '青州',
    f_id: '370700',
  },
  {
    id: '370782',
    name: '诸城市',
    short_name: '诸城',
    f_id: '370700',
  },
  {
    id: '370783',
    name: '寿光市',
    short_name: '寿光',
    f_id: '370700',
  },
  {
    id: '370784',
    name: '安丘市',
    short_name: '安丘',
    f_id: '370700',
  },
  {
    id: '370785',
    name: '高密市',
    short_name: '高密',
    f_id: '370700',
  },
  {
    id: '370786',
    name: '昌邑市',
    short_name: '昌邑',
    f_id: '370700',
  },
  {
    id: '370802',
    name: '市中区',
    short_name: '市中',
    f_id: '370800',
  },
  {
    id: '370811',
    name: '任城区',
    short_name: '任城',
    f_id: '370800',
  },
  {
    id: '370826',
    name: '微山县',
    short_name: '微山',
    f_id: '370800',
  },
  {
    id: '370827',
    name: '鱼台县',
    short_name: '鱼台',
    f_id: '370800',
  },
  {
    id: '370828',
    name: '金乡县',
    short_name: '金乡',
    f_id: '370800',
  },
  {
    id: '370829',
    name: '嘉祥县',
    short_name: '嘉祥',
    f_id: '370800',
  },
  {
    id: '370830',
    name: '汶上县',
    short_name: '汶上',
    f_id: '370800',
  },
  {
    id: '370831',
    name: '泗水县',
    short_name: '泗水',
    f_id: '370800',
  },
  {
    id: '370832',
    name: '梁山县',
    short_name: '梁山',
    f_id: '370800',
  },
  {
    id: '370881',
    name: '曲阜市',
    short_name: '曲阜',
    f_id: '370800',
  },
  {
    id: '370882',
    name: '兖州市',
    short_name: '兖州',
    f_id: '370800',
  },
  {
    id: '370883',
    name: '邹城市',
    short_name: '邹城',
    f_id: '370800',
  },
  {
    id: '370902',
    name: '泰山区',
    short_name: '泰山',
    f_id: '370900',
  },
  {
    id: '370903',
    name: '岱岳区',
    short_name: '岱岳',
    f_id: '370900',
  },
  {
    id: '370921',
    name: '宁阳县',
    short_name: '宁阳',
    f_id: '370900',
  },
  {
    id: '370923',
    name: '东平县',
    short_name: '东平',
    f_id: '370900',
  },
  {
    id: '370982',
    name: '新泰市',
    short_name: '新泰',
    f_id: '370900',
  },
  {
    id: '370983',
    name: '肥城市',
    short_name: '肥城',
    f_id: '370900',
  },
  {
    id: '371002',
    name: '环翠区',
    short_name: '环翠',
    f_id: '371000',
  },
  {
    id: '371081',
    name: '文登市',
    short_name: '文登',
    f_id: '371000',
  },
  {
    id: '371082',
    name: '荣成市',
    short_name: '荣成',
    f_id: '371000',
  },
  {
    id: '371083',
    name: '乳山市',
    short_name: '乳山',
    f_id: '371000',
  },
  {
    id: '371102',
    name: '东港区',
    short_name: '东港',
    f_id: '371100',
  },
  {
    id: '371103',
    name: '岚山区',
    short_name: '岚山',
    f_id: '371100',
  },
  {
    id: '371121',
    name: '五莲县',
    short_name: '五莲',
    f_id: '371100',
  },
  {
    id: '371122',
    name: '莒县',
    short_name: '莒县',
    f_id: '371100',
  },
  {
    id: '371202',
    name: '莱城区',
    short_name: '莱城',
    f_id: '371200',
  },
  {
    id: '371203',
    name: '钢城区',
    short_name: '钢城',
    f_id: '371200',
  },
  {
    id: '371302',
    name: '兰山区',
    short_name: '兰山',
    f_id: '371300',
  },
  {
    id: '371311',
    name: '罗庄区',
    short_name: '罗庄',
    f_id: '371300',
  },
  {
    id: '371312',
    name: '河东区',
    short_name: '河东',
    f_id: '371300',
  },
  {
    id: '371321',
    name: '沂南县',
    short_name: '沂南',
    f_id: '371300',
  },
  {
    id: '371322',
    name: '郯城县',
    short_name: '郯城',
    f_id: '371300',
  },
  {
    id: '371323',
    name: '沂水县',
    short_name: '沂水',
    f_id: '371300',
  },
  {
    id: '371324',
    name: '苍山县',
    short_name: '苍山',
    f_id: '371300',
  },
  {
    id: '371325',
    name: '费县',
    short_name: '费县',
    f_id: '371300',
  },
  {
    id: '371326',
    name: '平邑县',
    short_name: '平邑',
    f_id: '371300',
  },
  {
    id: '371327',
    name: '莒南县',
    short_name: '莒南',
    f_id: '371300',
  },
  {
    id: '371328',
    name: '蒙阴县',
    short_name: '蒙阴',
    f_id: '371300',
  },
  {
    id: '371329',
    name: '临沭县',
    short_name: '临沭',
    f_id: '371300',
  },
  {
    id: '371402',
    name: '德城区',
    short_name: '德城',
    f_id: '371400',
  },
  {
    id: '371421',
    name: '陵县',
    short_name: '陵县',
    f_id: '371400',
  },
  {
    id: '371422',
    name: '宁津县',
    short_name: '宁津',
    f_id: '371400',
  },
  {
    id: '371423',
    name: '庆云县',
    short_name: '庆云',
    f_id: '371400',
  },
  {
    id: '371424',
    name: '临邑县',
    short_name: '临邑',
    f_id: '371400',
  },
  {
    id: '371425',
    name: '齐河县',
    short_name: '齐河',
    f_id: '371400',
  },
  {
    id: '371426',
    name: '平原县',
    short_name: '平原',
    f_id: '371400',
  },
  {
    id: '371427',
    name: '夏津县',
    short_name: '夏津',
    f_id: '371400',
  },
  {
    id: '371428',
    name: '武城县',
    short_name: '武城',
    f_id: '371400',
  },
  {
    id: '371481',
    name: '乐陵市',
    short_name: '乐陵',
    f_id: '371400',
  },
  {
    id: '371482',
    name: '禹城市',
    short_name: '禹城',
    f_id: '371400',
  },
  {
    id: '371502',
    name: '东昌府区',
    short_name: '东昌府',
    f_id: '371500',
  },
  {
    id: '371521',
    name: '阳谷县',
    short_name: '阳谷',
    f_id: '371500',
  },
  {
    id: '371522',
    name: '莘县',
    short_name: '莘县',
    f_id: '371500',
  },
  {
    id: '371523',
    name: '茌平县',
    short_name: '茌平',
    f_id: '371500',
  },
  {
    id: '371524',
    name: '东阿县',
    short_name: '东阿',
    f_id: '371500',
  },
  {
    id: '371525',
    name: '冠县',
    short_name: '冠县',
    f_id: '371500',
  },
  {
    id: '371526',
    name: '高唐县',
    short_name: '高唐',
    f_id: '371500',
  },
  {
    id: '371581',
    name: '临清市',
    short_name: '临清',
    f_id: '371500',
  },
  {
    id: '371602',
    name: '滨城区',
    short_name: '滨城',
    f_id: '371600',
  },
  {
    id: '371621',
    name: '惠民县',
    short_name: '惠民',
    f_id: '371600',
  },
  {
    id: '371622',
    name: '阳信县',
    short_name: '阳信',
    f_id: '371600',
  },
  {
    id: '371623',
    name: '无棣县',
    short_name: '无棣',
    f_id: '371600',
  },
  {
    id: '371624',
    name: '沾化县',
    short_name: '沾化',
    f_id: '371600',
  },
  {
    id: '371625',
    name: '博兴县',
    short_name: '博兴',
    f_id: '371600',
  },
  {
    id: '371626',
    name: '邹平县',
    short_name: '邹平',
    f_id: '371600',
  },
  {
    id: '371702',
    name: '牡丹区',
    short_name: '牡丹',
    f_id: '371700',
  },
  {
    id: '371721',
    name: '曹县',
    short_name: '曹县',
    f_id: '371700',
  },
  {
    id: '371722',
    name: '单县',
    short_name: '单县',
    f_id: '371700',
  },
  {
    id: '371723',
    name: '成武县',
    short_name: '成武',
    f_id: '371700',
  },
  {
    id: '371724',
    name: '巨野县',
    short_name: '巨野',
    f_id: '371700',
  },
  {
    id: '371725',
    name: '郓城县',
    short_name: '郓城',
    f_id: '371700',
  },
  {
    id: '371726',
    name: '鄄城县',
    short_name: '鄄城',
    f_id: '371700',
  },
  {
    id: '371727',
    name: '定陶县',
    short_name: '定陶',
    f_id: '371700',
  },
  {
    id: '371728',
    name: '东明县',
    short_name: '东明',
    f_id: '371700',
  },
  {
    id: '410102',
    name: '中原区',
    short_name: '中原',
    f_id: '410100',
  },
  {
    id: '410103',
    name: '二七区',
    short_name: '二七',
    f_id: '410100',
  },
  {
    id: '410104',
    name: '管城回族区',
    short_name: '管城回族',
    f_id: '410100',
  },
  {
    id: '410105',
    name: '金水区',
    short_name: '金水',
    f_id: '410100',
  },
  {
    id: '410106',
    name: '上街区',
    short_name: '上街',
    f_id: '410100',
  },
  {
    id: '410108',
    name: '惠济区',
    short_name: '惠济',
    f_id: '410100',
  },
  {
    id: '410122',
    name: '中牟县',
    short_name: '中牟',
    f_id: '410100',
  },
  {
    id: '410181',
    name: '巩义市',
    short_name: '巩义',
    f_id: '410100',
  },
  {
    id: '410182',
    name: '荥阳市',
    short_name: '荥阳',
    f_id: '410100',
  },
  {
    id: '410183',
    name: '新密市',
    short_name: '新密',
    f_id: '410100',
  },
  {
    id: '410184',
    name: '新郑市',
    short_name: '新郑',
    f_id: '410100',
  },
  {
    id: '410185',
    name: '登封市',
    short_name: '登封',
    f_id: '410100',
  },
  {
    id: '410202',
    name: '龙亭区',
    short_name: '龙亭',
    f_id: '410200',
  },
  {
    id: '410203',
    name: '顺河回族区',
    short_name: '顺河回族',
    f_id: '410200',
  },
  {
    id: '410204',
    name: '鼓楼区',
    short_name: '鼓楼',
    f_id: '410200',
  },
  {
    id: '410205',
    name: '禹王台区',
    short_name: '禹王台',
    f_id: '410200',
  },
  {
    id: '410211',
    name: '金明区',
    short_name: '金明',
    f_id: '410200',
  },
  {
    id: '410221',
    name: '杞县',
    short_name: '杞县',
    f_id: '410200',
  },
  {
    id: '410222',
    name: '通许县',
    short_name: '通许',
    f_id: '410200',
  },
  {
    id: '410223',
    name: '尉氏县',
    short_name: '尉氏',
    f_id: '410200',
  },
  {
    id: '410224',
    name: '开封县',
    short_name: '开封',
    f_id: '410200',
  },
  {
    id: '410225',
    name: '兰考县',
    short_name: '兰考',
    f_id: '410200',
  },
  {
    id: '410302',
    name: '老城区',
    short_name: '老城',
    f_id: '410300',
  },
  {
    id: '410303',
    name: '西工区',
    short_name: '西工',
    f_id: '410300',
  },
  {
    id: '410304',
    name: '瀍河回族区',
    short_name: '瀍河回族',
    f_id: '410300',
  },
  {
    id: '410305',
    name: '涧西区',
    short_name: '涧西',
    f_id: '410300',
  },
  {
    id: '410306',
    name: '吉利区',
    short_name: '吉利',
    f_id: '410300',
  },
  {
    id: '410307',
    name: '洛龙区',
    short_name: '洛龙',
    f_id: '410300',
  },
  {
    id: '410322',
    name: '孟津县',
    short_name: '孟津',
    f_id: '410300',
  },
  {
    id: '410323',
    name: '新安县',
    short_name: '新安',
    f_id: '410300',
  },
  {
    id: '410324',
    name: '栾川县',
    short_name: '栾川',
    f_id: '410300',
  },
  {
    id: '410325',
    name: '嵩县',
    short_name: '嵩县',
    f_id: '410300',
  },
  {
    id: '410326',
    name: '汝阳县',
    short_name: '汝阳',
    f_id: '410300',
  },
  {
    id: '410327',
    name: '宜阳县',
    short_name: '宜阳',
    f_id: '410300',
  },
  {
    id: '410328',
    name: '洛宁县',
    short_name: '洛宁',
    f_id: '410300',
  },
  {
    id: '410329',
    name: '伊川县',
    short_name: '伊川',
    f_id: '410300',
  },
  {
    id: '410381',
    name: '偃师市',
    short_name: '偃师',
    f_id: '410300',
  },
  {
    id: '410402',
    name: '新华区',
    short_name: '新华',
    f_id: '410400',
  },
  {
    id: '410403',
    name: '卫东区',
    short_name: '卫东',
    f_id: '410400',
  },
  {
    id: '410404',
    name: '石龙区',
    short_name: '石龙',
    f_id: '410400',
  },
  {
    id: '410411',
    name: '湛河区',
    short_name: '湛河',
    f_id: '410400',
  },
  {
    id: '410421',
    name: '宝丰县',
    short_name: '宝丰',
    f_id: '410400',
  },
  {
    id: '410422',
    name: '叶县',
    short_name: '叶县',
    f_id: '410400',
  },
  {
    id: '410423',
    name: '鲁山县',
    short_name: '鲁山',
    f_id: '410400',
  },
  {
    id: '410425',
    name: '郏县',
    short_name: '郏县',
    f_id: '410400',
  },
  {
    id: '410481',
    name: '舞钢市',
    short_name: '舞钢',
    f_id: '410400',
  },
  {
    id: '410482',
    name: '汝州市',
    short_name: '汝州',
    f_id: '410400',
  },
  {
    id: '410502',
    name: '文峰区',
    short_name: '文峰',
    f_id: '410500',
  },
  {
    id: '410503',
    name: '北关区',
    short_name: '北关',
    f_id: '410500',
  },
  {
    id: '410505',
    name: '殷都区',
    short_name: '殷都',
    f_id: '410500',
  },
  {
    id: '410506',
    name: '龙安区',
    short_name: '龙安',
    f_id: '410500',
  },
  {
    id: '410522',
    name: '安阳县',
    short_name: '安阳',
    f_id: '410500',
  },
  {
    id: '410523',
    name: '汤阴县',
    short_name: '汤阴',
    f_id: '410500',
  },
  {
    id: '410526',
    name: '滑县',
    short_name: '滑县',
    f_id: '410500',
  },
  {
    id: '410527',
    name: '内黄县',
    short_name: '内黄',
    f_id: '410500',
  },
  {
    id: '410581',
    name: '林州市',
    short_name: '林州',
    f_id: '410500',
  },
  {
    id: '410602',
    name: '鹤山区',
    short_name: '鹤山',
    f_id: '410600',
  },
  {
    id: '410603',
    name: '山城区',
    short_name: '山城',
    f_id: '410600',
  },
  {
    id: '410611',
    name: '淇滨区',
    short_name: '淇滨',
    f_id: '410600',
  },
  {
    id: '410621',
    name: '浚县',
    short_name: '浚县',
    f_id: '410600',
  },
  {
    id: '410622',
    name: '淇县',
    short_name: '淇县',
    f_id: '410600',
  },
  {
    id: '410702',
    name: '红旗区',
    short_name: '红旗',
    f_id: '410700',
  },
  {
    id: '410703',
    name: '卫滨区',
    short_name: '卫滨',
    f_id: '410700',
  },
  {
    id: '410704',
    name: '凤泉区',
    short_name: '凤泉',
    f_id: '410700',
  },
  {
    id: '410711',
    name: '牧野区',
    short_name: '牧野',
    f_id: '410700',
  },
  {
    id: '410721',
    name: '新乡县',
    short_name: '新乡',
    f_id: '410700',
  },
  {
    id: '410724',
    name: '获嘉县',
    short_name: '获嘉',
    f_id: '410700',
  },
  {
    id: '410725',
    name: '原阳县',
    short_name: '原阳',
    f_id: '410700',
  },
  {
    id: '410726',
    name: '延津县',
    short_name: '延津',
    f_id: '410700',
  },
  {
    id: '410727',
    name: '封丘县',
    short_name: '封丘',
    f_id: '410700',
  },
  {
    id: '410728',
    name: '长垣县',
    short_name: '长垣',
    f_id: '410700',
  },
  {
    id: '410781',
    name: '卫辉市',
    short_name: '卫辉',
    f_id: '410700',
  },
  {
    id: '410782',
    name: '辉县市',
    short_name: '辉县',
    f_id: '410700',
  },
  {
    id: '410802',
    name: '解放区',
    short_name: '解放',
    f_id: '410800',
  },
  {
    id: '410803',
    name: '中站区',
    short_name: '中站',
    f_id: '410800',
  },
  {
    id: '410804',
    name: '马村区',
    short_name: '马村',
    f_id: '410800',
  },
  {
    id: '410811',
    name: '山阳区',
    short_name: '山阳',
    f_id: '410800',
  },
  {
    id: '410821',
    name: '修武县',
    short_name: '修武',
    f_id: '410800',
  },
  {
    id: '410822',
    name: '博爱县',
    short_name: '博爱',
    f_id: '410800',
  },
  {
    id: '410823',
    name: '武陟县',
    short_name: '武陟',
    f_id: '410800',
  },
  {
    id: '410825',
    name: '温县',
    short_name: '温县',
    f_id: '410800',
  },
  {
    id: '410882',
    name: '沁阳市',
    short_name: '沁阳',
    f_id: '410800',
  },
  {
    id: '410883',
    name: '孟州市',
    short_name: '孟州',
    f_id: '410800',
  },
  {
    id: '410902',
    name: '华龙区',
    short_name: '华龙',
    f_id: '410900',
  },
  {
    id: '410922',
    name: '清丰县',
    short_name: '清丰',
    f_id: '410900',
  },
  {
    id: '410923',
    name: '南乐县',
    short_name: '南乐',
    f_id: '410900',
  },
  {
    id: '410926',
    name: '范县',
    short_name: '范县',
    f_id: '410900',
  },
  {
    id: '410927',
    name: '台前县',
    short_name: '台前',
    f_id: '410900',
  },
  {
    id: '410928',
    name: '濮阳县',
    short_name: '濮阳',
    f_id: '410900',
  },
  {
    id: '411002',
    name: '魏都区',
    short_name: '魏都',
    f_id: '411000',
  },
  {
    id: '411023',
    name: '许昌县',
    short_name: '许昌',
    f_id: '411000',
  },
  {
    id: '411024',
    name: '鄢陵县',
    short_name: '鄢陵',
    f_id: '411000',
  },
  {
    id: '411025',
    name: '襄城县',
    short_name: '襄城',
    f_id: '411000',
  },
  {
    id: '411081',
    name: '禹州市',
    short_name: '禹州',
    f_id: '411000',
  },
  {
    id: '411082',
    name: '长葛市',
    short_name: '长葛',
    f_id: '411000',
  },
  {
    id: '411102',
    name: '源汇区',
    short_name: '源汇',
    f_id: '411100',
  },
  {
    id: '411103',
    name: '郾城区',
    short_name: '郾城',
    f_id: '411100',
  },
  {
    id: '411104',
    name: '召陵区',
    short_name: '召陵',
    f_id: '411100',
  },
  {
    id: '411121',
    name: '舞阳县',
    short_name: '舞阳',
    f_id: '411100',
  },
  {
    id: '411122',
    name: '临颍县',
    short_name: '临颍',
    f_id: '411100',
  },
  {
    id: '411202',
    name: '湖滨区',
    short_name: '湖滨',
    f_id: '411200',
  },
  {
    id: '411221',
    name: '渑池县',
    short_name: '渑池',
    f_id: '411200',
  },
  {
    id: '411222',
    name: '陕县',
    short_name: '陕县',
    f_id: '411200',
  },
  {
    id: '411224',
    name: '卢氏县',
    short_name: '卢氏',
    f_id: '411200',
  },
  {
    id: '411281',
    name: '义马市',
    short_name: '义马',
    f_id: '411200',
  },
  {
    id: '411282',
    name: '灵宝市',
    short_name: '灵宝',
    f_id: '411200',
  },
  {
    id: '411302',
    name: '宛城区',
    short_name: '宛城',
    f_id: '411300',
  },
  {
    id: '411303',
    name: '卧龙区',
    short_name: '卧龙',
    f_id: '411300',
  },
  {
    id: '411321',
    name: '南召县',
    short_name: '南召',
    f_id: '411300',
  },
  {
    id: '411322',
    name: '方城县',
    short_name: '方城',
    f_id: '411300',
  },
  {
    id: '411323',
    name: '西峡县',
    short_name: '西峡',
    f_id: '411300',
  },
  {
    id: '411324',
    name: '镇平县',
    short_name: '镇平',
    f_id: '411300',
  },
  {
    id: '411325',
    name: '内乡县',
    short_name: '内乡',
    f_id: '411300',
  },
  {
    id: '411326',
    name: '淅川县',
    short_name: '淅川',
    f_id: '411300',
  },
  {
    id: '411327',
    name: '社旗县',
    short_name: '社旗县',
    f_id: '411300',
  },
  {
    id: '411328',
    name: '唐河县',
    short_name: '唐河',
    f_id: '411300',
  },
  {
    id: '411329',
    name: '新野县',
    short_name: '新野',
    f_id: '411300',
  },
  {
    id: '411330',
    name: '桐柏县',
    short_name: '桐柏',
    f_id: '411300',
  },
  {
    id: '411381',
    name: '邓州市',
    short_name: '邓州',
    f_id: '411300',
  },
  {
    id: '411402',
    name: '梁园区',
    short_name: '梁园',
    f_id: '411400',
  },
  {
    id: '411403',
    name: '睢阳区',
    short_name: '睢阳',
    f_id: '411400',
  },
  {
    id: '411421',
    name: '民权县',
    short_name: '民权',
    f_id: '411400',
  },
  {
    id: '411422',
    name: '睢县',
    short_name: '睢县',
    f_id: '411400',
  },
  {
    id: '411423',
    name: '宁陵县',
    short_name: '宁陵',
    f_id: '411400',
  },
  {
    id: '411424',
    name: '柘城县',
    short_name: '柘城',
    f_id: '411400',
  },
  {
    id: '411425',
    name: '虞城县',
    short_name: '虞城',
    f_id: '411400',
  },
  {
    id: '411426',
    name: '夏邑县',
    short_name: '夏邑',
    f_id: '411400',
  },
  {
    id: '411481',
    name: '永城市',
    short_name: '永城',
    f_id: '411400',
  },
  {
    id: '411502',
    name: '浉河区',
    short_name: '浉河',
    f_id: '411500',
  },
  {
    id: '411503',
    name: '平桥区',
    short_name: '平桥',
    f_id: '411500',
  },
  {
    id: '411521',
    name: '罗山县',
    short_name: '罗山',
    f_id: '411500',
  },
  {
    id: '411522',
    name: '光山县',
    short_name: '光山',
    f_id: '411500',
  },
  {
    id: '411523',
    name: '新县',
    short_name: '新县',
    f_id: '411500',
  },
  {
    id: '411524',
    name: '商城县',
    short_name: '商城',
    f_id: '411500',
  },
  {
    id: '411525',
    name: '固始县',
    short_name: '固始',
    f_id: '411500',
  },
  {
    id: '411526',
    name: '潢川县',
    short_name: '潢川',
    f_id: '411500',
  },
  {
    id: '411527',
    name: '淮滨县',
    short_name: '淮滨',
    f_id: '411500',
  },
  {
    id: '411528',
    name: '息县',
    short_name: '息县',
    f_id: '411500',
  },
  {
    id: '411602',
    name: '川汇区',
    short_name: '川汇',
    f_id: '411600',
  },
  {
    id: '411621',
    name: '扶沟县',
    short_name: '扶沟',
    f_id: '411600',
  },
  {
    id: '411622',
    name: '西华县',
    short_name: '西华',
    f_id: '411600',
  },
  {
    id: '411623',
    name: '商水县',
    short_name: '商水',
    f_id: '411600',
  },
  {
    id: '411624',
    name: '沈丘县',
    short_name: '沈丘',
    f_id: '411600',
  },
  {
    id: '411625',
    name: '郸城县',
    short_name: '郸城',
    f_id: '411600',
  },
  {
    id: '411626',
    name: '淮阳县',
    short_name: '淮阳',
    f_id: '411600',
  },
  {
    id: '411627',
    name: '太康县',
    short_name: '太康',
    f_id: '411600',
  },
  {
    id: '411628',
    name: '鹿邑县',
    short_name: '鹿邑',
    f_id: '411600',
  },
  {
    id: '411681',
    name: '项城市',
    short_name: '项城',
    f_id: '411600',
  },
  {
    id: '411702',
    name: '驿城区',
    short_name: '驿城',
    f_id: '411700',
  },
  {
    id: '411721',
    name: '西平县',
    short_name: '西平',
    f_id: '411700',
  },
  {
    id: '411722',
    name: '上蔡县',
    short_name: '上蔡',
    f_id: '411700',
  },
  {
    id: '411723',
    name: '平舆县',
    short_name: '平舆',
    f_id: '411700',
  },
  {
    id: '411724',
    name: '正阳县',
    short_name: '正阳',
    f_id: '411700',
  },
  {
    id: '411725',
    name: '确山县',
    short_name: '确山',
    f_id: '411700',
  },
  {
    id: '411726',
    name: '泌阳县',
    short_name: '泌阳',
    f_id: '411700',
  },
  {
    id: '411727',
    name: '汝南县',
    short_name: '汝南',
    f_id: '411700',
  },
  {
    id: '411728',
    name: '遂平县',
    short_name: '遂平',
    f_id: '411700',
  },
  {
    id: '411729',
    name: '新蔡县',
    short_name: '新蔡',
    f_id: '411700',
  },
  {
    id: '420102',
    name: '江岸区',
    short_name: '江岸',
    f_id: '420100',
  },
  {
    id: '420103',
    name: '江汉区',
    short_name: '江汉',
    f_id: '420100',
  },
  {
    id: '420104',
    name: '硚口区',
    short_name: '硚口',
    f_id: '420100',
  },
  {
    id: '420105',
    name: '汉阳区',
    short_name: '汉阳',
    f_id: '420100',
  },
  {
    id: '420106',
    name: '武昌区',
    short_name: '武昌',
    f_id: '420100',
  },
  {
    id: '420107',
    name: '青山区',
    short_name: '青山',
    f_id: '420100',
  },
  {
    id: '420111',
    name: '洪山区',
    short_name: '洪山',
    f_id: '420100',
  },
  {
    id: '420112',
    name: '东西湖区',
    short_name: '东西湖',
    f_id: '420100',
  },
  {
    id: '420113',
    name: '汉南区',
    short_name: '汉南',
    f_id: '420100',
  },
  {
    id: '420114',
    name: '蔡甸区',
    short_name: '蔡甸',
    f_id: '420100',
  },
  {
    id: '420115',
    name: '江夏区',
    short_name: '江夏',
    f_id: '420100',
  },
  {
    id: '420116',
    name: '黄陂区',
    short_name: '黄陂',
    f_id: '420100',
  },
  {
    id: '420117',
    name: '新洲区',
    short_name: '新洲',
    f_id: '420100',
  },
  {
    id: '420202',
    name: '黄石港区',
    short_name: '黄石港',
    f_id: '420200',
  },
  {
    id: '420203',
    name: '西塞山区',
    short_name: '西塞山',
    f_id: '420200',
  },
  {
    id: '420204',
    name: '下陆区',
    short_name: '下陆',
    f_id: '420200',
  },
  {
    id: '420205',
    name: '铁山区',
    short_name: '铁山',
    f_id: '420200',
  },
  {
    id: '420222',
    name: '阳新县',
    short_name: '阳新',
    f_id: '420200',
  },
  {
    id: '420281',
    name: '大冶市',
    short_name: '大冶',
    f_id: '420200',
  },
  {
    id: '420302',
    name: '茅箭区',
    short_name: '茅箭',
    f_id: '420300',
  },
  {
    id: '420303',
    name: '张湾区',
    short_name: '张湾',
    f_id: '420300',
  },
  {
    id: '420321',
    name: '郧县',
    short_name: '郧县',
    f_id: '420300',
  },
  {
    id: '420322',
    name: '郧西县',
    short_name: '郧西',
    f_id: '420300',
  },
  {
    id: '420323',
    name: '竹山县',
    short_name: '竹山',
    f_id: '420300',
  },
  {
    id: '420324',
    name: '竹溪县',
    short_name: '竹溪',
    f_id: '420300',
  },
  {
    id: '420325',
    name: '房县',
    short_name: '房县',
    f_id: '420300',
  },
  {
    id: '420381',
    name: '丹江口市',
    short_name: '丹江口',
    f_id: '420300',
  },
  {
    id: '420502',
    name: '西陵区',
    short_name: '西陵',
    f_id: '420500',
  },
  {
    id: '420503',
    name: '伍家岗区',
    short_name: '伍家岗',
    f_id: '420500',
  },
  {
    id: '420504',
    name: '点军区',
    short_name: '点军',
    f_id: '420500',
  },
  {
    id: '420505',
    name: '猇亭区',
    short_name: '猇亭',
    f_id: '420500',
  },
  {
    id: '420506',
    name: '夷陵区',
    short_name: '夷陵',
    f_id: '420500',
  },
  {
    id: '420525',
    name: '远安县',
    short_name: '远安',
    f_id: '420500',
  },
  {
    id: '420526',
    name: '兴山县',
    short_name: '兴山',
    f_id: '420500',
  },
  {
    id: '420527',
    name: '秭归县',
    short_name: '秭归',
    f_id: '420500',
  },
  {
    id: '420528',
    name: '长阳土家族自治县',
    short_name: '长阳',
    f_id: '420500',
  },
  {
    id: '420529',
    name: '五峰土家族自治县',
    short_name: '五峰',
    f_id: '420500',
  },
  {
    id: '420581',
    name: '宜都市',
    short_name: '宜都',
    f_id: '420500',
  },
  {
    id: '420582',
    name: '当阳市',
    short_name: '当阳',
    f_id: '420500',
  },
  {
    id: '420583',
    name: '枝江市',
    short_name: '枝江',
    f_id: '420500',
  },
  {
    id: '420602',
    name: '襄城区',
    short_name: '襄城',
    f_id: '420600',
  },
  {
    id: '420606',
    name: '樊城区',
    short_name: '樊城',
    f_id: '420600',
  },
  {
    id: '420607',
    name: '襄州区',
    short_name: '襄州',
    f_id: '420600',
  },
  {
    id: '420624',
    name: '南漳县',
    short_name: '南漳',
    f_id: '420600',
  },
  {
    id: '420625',
    name: '谷城县',
    short_name: '谷城',
    f_id: '420600',
  },
  {
    id: '420626',
    name: '保康县',
    short_name: '保康',
    f_id: '420600',
  },
  {
    id: '420682',
    name: '老河口市',
    short_name: '老河口',
    f_id: '420600',
  },
  {
    id: '420683',
    name: '枣阳市',
    short_name: '枣阳',
    f_id: '420600',
  },
  {
    id: '420684',
    name: '宜城市',
    short_name: '宜城',
    f_id: '420600',
  },
  {
    id: '420702',
    name: '梁子湖区',
    short_name: '梁子湖',
    f_id: '420700',
  },
  {
    id: '420703',
    name: '华容区',
    short_name: '华容',
    f_id: '420700',
  },
  {
    id: '420704',
    name: '鄂城区',
    short_name: '鄂城',
    f_id: '420700',
  },
  {
    id: '420802',
    name: '东宝区',
    short_name: '东宝',
    f_id: '420800',
  },
  {
    id: '420804',
    name: '掇刀区',
    short_name: '掇刀',
    f_id: '420800',
  },
  {
    id: '420821',
    name: '京山县',
    short_name: '京山',
    f_id: '420800',
  },
  {
    id: '420822',
    name: '沙洋县',
    short_name: '沙洋',
    f_id: '420800',
  },
  {
    id: '420881',
    name: '钟祥市',
    short_name: '钟祥',
    f_id: '420800',
  },
  {
    id: '420902',
    name: '孝南区',
    short_name: '孝南',
    f_id: '420900',
  },
  {
    id: '420921',
    name: '孝昌县',
    short_name: '孝昌',
    f_id: '420900',
  },
  {
    id: '420922',
    name: '大悟县',
    short_name: '大悟',
    f_id: '420900',
  },
  {
    id: '420923',
    name: '云梦县',
    short_name: '云梦',
    f_id: '420900',
  },
  {
    id: '420981',
    name: '应城市',
    short_name: '应城',
    f_id: '420900',
  },
  {
    id: '420982',
    name: '安陆市',
    short_name: '安陆',
    f_id: '420900',
  },
  {
    id: '420984',
    name: '汉川市',
    short_name: '汉川',
    f_id: '420900',
  },
  {
    id: '421002',
    name: '沙市区',
    short_name: '沙市',
    f_id: '421000',
  },
  {
    id: '421003',
    name: '荆州区',
    short_name: '荆州',
    f_id: '421000',
  },
  {
    id: '421022',
    name: '公安县',
    short_name: '公安',
    f_id: '421000',
  },
  {
    id: '421023',
    name: '监利县',
    short_name: '监利',
    f_id: '421000',
  },
  {
    id: '421024',
    name: '江陵县',
    short_name: '江陵',
    f_id: '421000',
  },
  {
    id: '421081',
    name: '石首市',
    short_name: '石首',
    f_id: '421000',
  },
  {
    id: '421083',
    name: '洪湖市',
    short_name: '洪湖',
    f_id: '421000',
  },
  {
    id: '421087',
    name: '松滋市',
    short_name: '松滋',
    f_id: '421000',
  },
  {
    id: '421102',
    name: '黄州区',
    short_name: '黄州',
    f_id: '421100',
  },
  {
    id: '421121',
    name: '团风县',
    short_name: '团风',
    f_id: '421100',
  },
  {
    id: '421122',
    name: '红安县',
    short_name: '红安',
    f_id: '421100',
  },
  {
    id: '421123',
    name: '罗田县',
    short_name: '罗田',
    f_id: '421100',
  },
  {
    id: '421124',
    name: '英山县',
    short_name: '英山',
    f_id: '421100',
  },
  {
    id: '421125',
    name: '浠水县',
    short_name: '浠水',
    f_id: '421100',
  },
  {
    id: '421126',
    name: '蕲春县',
    short_name: '蕲春',
    f_id: '421100',
  },
  {
    id: '421127',
    name: '黄梅县',
    short_name: '黄梅',
    f_id: '421100',
  },
  {
    id: '421181',
    name: '麻城市',
    short_name: '麻城',
    f_id: '421100',
  },
  {
    id: '421182',
    name: '武穴市',
    short_name: '武穴',
    f_id: '421100',
  },
  {
    id: '421202',
    name: '咸安区',
    short_name: '咸安',
    f_id: '421200',
  },
  {
    id: '421221',
    name: '嘉鱼县',
    short_name: '嘉鱼',
    f_id: '421200',
  },
  {
    id: '421222',
    name: '通城县',
    short_name: '通城',
    f_id: '421200',
  },
  {
    id: '421223',
    name: '崇阳县',
    short_name: '崇阳',
    f_id: '421200',
  },
  {
    id: '421224',
    name: '通山县',
    short_name: '通山',
    f_id: '421200',
  },
  {
    id: '421281',
    name: '赤壁市',
    short_name: '赤壁',
    f_id: '421200',
  },
  {
    id: '421302',
    name: '曾都区',
    short_name: '曾都',
    f_id: '421300',
  },
  {
    id: '421321',
    name: '随县',
    short_name: '随县',
    f_id: '421300',
  },
  {
    id: '421381',
    name: '广水市',
    short_name: '广水',
    f_id: '421300',
  },
  {
    id: '422801',
    name: '恩施市',
    short_name: '恩施',
    f_id: '422800',
  },
  {
    id: '422802',
    name: '利川市',
    short_name: '利川',
    f_id: '422800',
  },
  {
    id: '422822',
    name: '建始县',
    short_name: '建始',
    f_id: '422800',
  },
  {
    id: '422823',
    name: '巴东县',
    short_name: '巴东',
    f_id: '422800',
  },
  {
    id: '422825',
    name: '宣恩县',
    short_name: '宣恩',
    f_id: '422800',
  },
  {
    id: '422826',
    name: '咸丰县',
    short_name: '咸丰',
    f_id: '422800',
  },
  {
    id: '422827',
    name: '来凤县',
    short_name: '来凤',
    f_id: '422800',
  },
  {
    id: '422828',
    name: '鹤峰县',
    short_name: '鹤峰',
    f_id: '422800',
  },
  {
    id: '430102',
    name: '芙蓉区',
    short_name: '芙蓉',
    f_id: '430100',
  },
  {
    id: '430103',
    name: '天心区',
    short_name: '天心',
    f_id: '430100',
  },
  {
    id: '430104',
    name: '岳麓区',
    short_name: '岳麓',
    f_id: '430100',
  },
  {
    id: '430105',
    name: '开福区',
    short_name: '开福',
    f_id: '430100',
  },
  {
    id: '430111',
    name: '雨花区',
    short_name: '雨花',
    f_id: '430100',
  },
  {
    id: '430121',
    name: '长沙县',
    short_name: '长沙',
    f_id: '430100',
  },
  {
    id: '430122',
    name: '望城区',
    short_name: '望城',
    f_id: '430100',
  },
  {
    id: '430124',
    name: '宁乡县',
    short_name: '宁乡',
    f_id: '430100',
  },
  {
    id: '430181',
    name: '浏阳市',
    short_name: '浏阳',
    f_id: '430100',
  },
  {
    id: '430202',
    name: '荷塘区',
    short_name: '荷塘',
    f_id: '430200',
  },
  {
    id: '430203',
    name: '芦淞区',
    short_name: '芦淞',
    f_id: '430200',
  },
  {
    id: '430204',
    name: '石峰区',
    short_name: '石峰',
    f_id: '430200',
  },
  {
    id: '430211',
    name: '天元区',
    short_name: '天元',
    f_id: '430200',
  },
  {
    id: '430221',
    name: '株洲县',
    short_name: '株洲',
    f_id: '430200',
  },
  {
    id: '430223',
    name: '攸县',
    short_name: '攸县',
    f_id: '430200',
  },
  {
    id: '430224',
    name: '茶陵县',
    short_name: '茶陵',
    f_id: '430200',
  },
  {
    id: '430225',
    name: '炎陵县',
    short_name: '炎陵',
    f_id: '430200',
  },
  {
    id: '430281',
    name: '醴陵市',
    short_name: '醴陵',
    f_id: '430200',
  },
  {
    id: '430302',
    name: '雨湖区',
    short_name: '雨湖',
    f_id: '430300',
  },
  {
    id: '430304',
    name: '岳塘区',
    short_name: '岳塘',
    f_id: '430300',
  },
  {
    id: '430321',
    name: '湘潭县',
    short_name: '湘潭',
    f_id: '430300',
  },
  {
    id: '430381',
    name: '湘乡市',
    short_name: '湘乡',
    f_id: '430300',
  },
  {
    id: '430382',
    name: '韶山市',
    short_name: '韶山',
    f_id: '430300',
  },
  {
    id: '430405',
    name: '珠晖区',
    short_name: '珠晖',
    f_id: '430400',
  },
  {
    id: '430406',
    name: '雁峰区',
    short_name: '雁峰',
    f_id: '430400',
  },
  {
    id: '430407',
    name: '石鼓区',
    short_name: '石鼓',
    f_id: '430400',
  },
  {
    id: '430408',
    name: '蒸湘区',
    short_name: '蒸湘',
    f_id: '430400',
  },
  {
    id: '430412',
    name: '南岳区',
    short_name: '南岳',
    f_id: '430400',
  },
  {
    id: '430421',
    name: '衡阳县',
    short_name: '衡阳',
    f_id: '430400',
  },
  {
    id: '430422',
    name: '衡南县',
    short_name: '衡南',
    f_id: '430400',
  },
  {
    id: '430423',
    name: '衡山县',
    short_name: '衡山',
    f_id: '430400',
  },
  {
    id: '430424',
    name: '衡东县',
    short_name: '衡东',
    f_id: '430400',
  },
  {
    id: '430426',
    name: '祁东县',
    short_name: '祁东',
    f_id: '430400',
  },
  {
    id: '430481',
    name: '耒阳市',
    short_name: '耒阳',
    f_id: '430400',
  },
  {
    id: '430482',
    name: '常宁市',
    short_name: '常宁',
    f_id: '430400',
  },
  {
    id: '430502',
    name: '双清区',
    short_name: '双清',
    f_id: '430500',
  },
  {
    id: '430503',
    name: '大祥区',
    short_name: '大祥',
    f_id: '430500',
  },
  {
    id: '430511',
    name: '北塔区',
    short_name: '北塔',
    f_id: '430500',
  },
  {
    id: '430521',
    name: '邵东县',
    short_name: '邵东',
    f_id: '430500',
  },
  {
    id: '430522',
    name: '新邵县',
    short_name: '新邵',
    f_id: '430500',
  },
  {
    id: '430523',
    name: '邵阳县',
    short_name: '邵阳',
    f_id: '430500',
  },
  {
    id: '430524',
    name: '隆回县',
    short_name: '隆回',
    f_id: '430500',
  },
  {
    id: '430525',
    name: '洞口县',
    short_name: '洞口',
    f_id: '430500',
  },
  {
    id: '430527',
    name: '绥宁县',
    short_name: '绥宁',
    f_id: '430500',
  },
  {
    id: '430528',
    name: '新宁县',
    short_name: '新宁',
    f_id: '430500',
  },
  {
    id: '430529',
    name: '城步苗族自治县',
    short_name: '城步',
    f_id: '430500',
  },
  {
    id: '430581',
    name: '武冈市',
    short_name: '武冈',
    f_id: '430500',
  },
  {
    id: '430602',
    name: '岳阳楼区',
    short_name: '岳阳楼',
    f_id: '430600',
  },
  {
    id: '430603',
    name: '云溪区',
    short_name: '云溪',
    f_id: '430600',
  },
  {
    id: '430611',
    name: '君山区',
    short_name: '君山',
    f_id: '430600',
  },
  {
    id: '430621',
    name: '岳阳县',
    short_name: '岳阳',
    f_id: '430600',
  },
  {
    id: '430623',
    name: '华容县',
    short_name: '华容',
    f_id: '430600',
  },
  {
    id: '430624',
    name: '湘阴县',
    short_name: '湘阴',
    f_id: '430600',
  },
  {
    id: '430626',
    name: '平江县',
    short_name: '平江',
    f_id: '430600',
  },
  {
    id: '430681',
    name: '汨罗市',
    short_name: '汨罗',
    f_id: '430600',
  },
  {
    id: '430682',
    name: '临湘市',
    short_name: '临湘',
    f_id: '430600',
  },
  {
    id: '430702',
    name: '武陵区',
    short_name: '武陵',
    f_id: '430700',
  },
  {
    id: '430703',
    name: '鼎城区',
    short_name: '鼎城',
    f_id: '430700',
  },
  {
    id: '430721',
    name: '安乡县',
    short_name: '安乡',
    f_id: '430700',
  },
  {
    id: '430722',
    name: '汉寿县',
    short_name: '汉寿',
    f_id: '430700',
  },
  {
    id: '430723',
    name: '澧县',
    short_name: '澧县',
    f_id: '430700',
  },
  {
    id: '430724',
    name: '临澧县',
    short_name: '临澧',
    f_id: '430700',
  },
  {
    id: '430725',
    name: '桃源县',
    short_name: '桃源',
    f_id: '430700',
  },
  {
    id: '430726',
    name: '石门县',
    short_name: '石门',
    f_id: '430700',
  },
  {
    id: '430781',
    name: '津市市',
    short_name: '津市',
    f_id: '430700',
  },
  {
    id: '430802',
    name: '永定区',
    short_name: '永定',
    f_id: '430800',
  },
  {
    id: '430811',
    name: '武陵源区',
    short_name: '武陵源',
    f_id: '430800',
  },
  {
    id: '430821',
    name: '慈利县',
    short_name: '慈利',
    f_id: '430800',
  },
  {
    id: '430822',
    name: '桑植县',
    short_name: '桑植',
    f_id: '430800',
  },
  {
    id: '430902',
    name: '资阳区',
    short_name: '资阳',
    f_id: '430900',
  },
  {
    id: '430903',
    name: '赫山区',
    short_name: '赫山',
    f_id: '430900',
  },
  {
    id: '430921',
    name: '南县',
    short_name: '南县',
    f_id: '430900',
  },
  {
    id: '430922',
    name: '桃江县',
    short_name: '桃江',
    f_id: '430900',
  },
  {
    id: '430923',
    name: '安化县',
    short_name: '安化',
    f_id: '430900',
  },
  {
    id: '430981',
    name: '沅江市',
    short_name: '沅江',
    f_id: '430900',
  },
  {
    id: '431002',
    name: '北湖区',
    short_name: '北湖',
    f_id: '431000',
  },
  {
    id: '431003',
    name: '苏仙区',
    short_name: '苏仙',
    f_id: '431000',
  },
  {
    id: '431021',
    name: '桂阳县',
    short_name: '桂阳',
    f_id: '431000',
  },
  {
    id: '431022',
    name: '宜章县',
    short_name: '宜章',
    f_id: '431000',
  },
  {
    id: '431023',
    name: '永兴县',
    short_name: '永兴',
    f_id: '431000',
  },
  {
    id: '431024',
    name: '嘉禾县',
    short_name: '嘉禾',
    f_id: '431000',
  },
  {
    id: '431025',
    name: '临武县',
    short_name: '临武',
    f_id: '431000',
  },
  {
    id: '431026',
    name: '汝城县',
    short_name: '汝城',
    f_id: '431000',
  },
  {
    id: '431027',
    name: '桂东县',
    short_name: '桂东',
    f_id: '431000',
  },
  {
    id: '431028',
    name: '安仁县',
    short_name: '安仁',
    f_id: '431000',
  },
  {
    id: '431081',
    name: '资兴市',
    short_name: '资兴',
    f_id: '431000',
  },
  {
    id: '431102',
    name: '零陵区',
    short_name: '零陵',
    f_id: '431100',
  },
  {
    id: '431103',
    name: '冷水滩区',
    short_name: '冷水滩',
    f_id: '431100',
  },
  {
    id: '431121',
    name: '祁阳县',
    short_name: '祁阳',
    f_id: '431100',
  },
  {
    id: '431122',
    name: '东安县',
    short_name: '东安',
    f_id: '431100',
  },
  {
    id: '431123',
    name: '双牌县',
    short_name: '双牌',
    f_id: '431100',
  },
  {
    id: '431124',
    name: '道县',
    short_name: '道县',
    f_id: '431100',
  },
  {
    id: '431125',
    name: '江永县',
    short_name: '江永',
    f_id: '431100',
  },
  {
    id: '431126',
    name: '宁远县',
    short_name: '宁远',
    f_id: '431100',
  },
  {
    id: '431127',
    name: '蓝山县',
    short_name: '蓝山',
    f_id: '431100',
  },
  {
    id: '431128',
    name: '新田县',
    short_name: '新田',
    f_id: '431100',
  },
  {
    id: '431129',
    name: '江华瑶族自治县',
    short_name: '江华',
    f_id: '431100',
  },
  {
    id: '431202',
    name: '鹤城区',
    short_name: '鹤城',
    f_id: '431200',
  },
  {
    id: '431221',
    name: '中方县',
    short_name: '中方',
    f_id: '431200',
  },
  {
    id: '431222',
    name: '沅陵县',
    short_name: '沅陵',
    f_id: '431200',
  },
  {
    id: '431223',
    name: '辰溪县',
    short_name: '辰溪',
    f_id: '431200',
  },
  {
    id: '431224',
    name: '溆浦县',
    short_name: '溆浦',
    f_id: '431200',
  },
  {
    id: '431225',
    name: '会同县',
    short_name: '会同',
    f_id: '431200',
  },
  {
    id: '431226',
    name: '麻阳苗族自治县',
    short_name: '麻阳',
    f_id: '431200',
  },
  {
    id: '431227',
    name: '新晃侗族自治县',
    short_name: '新晃',
    f_id: '431200',
  },
  {
    id: '431228',
    name: '芷江侗族自治县',
    short_name: '芷江',
    f_id: '431200',
  },
  {
    id: '431229',
    name: '靖州苗族侗族自治县',
    short_name: '靖州',
    f_id: '431200',
  },
  {
    id: '431230',
    name: '通道侗族自治县',
    short_name: '通道',
    f_id: '431200',
  },
  {
    id: '431281',
    name: '洪江市',
    short_name: '洪江',
    f_id: '431200',
  },
  {
    id: '431302',
    name: '娄星区',
    short_name: '娄星',
    f_id: '431300',
  },
  {
    id: '431321',
    name: '双峰县',
    short_name: '双峰',
    f_id: '431300',
  },
  {
    id: '431322',
    name: '新化县',
    short_name: '新化',
    f_id: '431300',
  },
  {
    id: '431381',
    name: '冷水江市',
    short_name: '冷水江',
    f_id: '431300',
  },
  {
    id: '431382',
    name: '涟源市',
    short_name: '涟源',
    f_id: '431300',
  },
  {
    id: '433101',
    name: '吉首市',
    short_name: '吉首',
    f_id: '433100',
  },
  {
    id: '433122',
    name: '泸溪县',
    short_name: '泸溪',
    f_id: '433100',
  },
  {
    id: '433123',
    name: '凤凰县',
    short_name: '凤凰',
    f_id: '433100',
  },
  {
    id: '433124',
    name: '花垣县',
    short_name: '花垣',
    f_id: '433100',
  },
  {
    id: '433125',
    name: '保靖县',
    short_name: '保靖',
    f_id: '433100',
  },
  {
    id: '433126',
    name: '古丈县',
    short_name: '古丈',
    f_id: '433100',
  },
  {
    id: '433127',
    name: '永顺县',
    short_name: '永顺',
    f_id: '433100',
  },
  {
    id: '433130',
    name: '龙山县',
    short_name: '龙山',
    f_id: '433100',
  },
  {
    id: '440103',
    name: '荔湾区',
    short_name: '荔湾',
    f_id: '440100',
  },
  {
    id: '440104',
    name: '越秀区',
    short_name: '越秀',
    f_id: '440100',
  },
  {
    id: '440105',
    name: '海珠区',
    short_name: '海珠',
    f_id: '440100',
  },
  {
    id: '440106',
    name: '天河区',
    short_name: '天河',
    f_id: '440100',
  },
  {
    id: '440111',
    name: '白云区',
    short_name: '白云',
    f_id: '440100',
  },
  {
    id: '440112',
    name: '黄埔区',
    short_name: '黄埔',
    f_id: '440100',
  },
  {
    id: '440113',
    name: '番禺区',
    short_name: '番禺',
    f_id: '440100',
  },
  {
    id: '440114',
    name: '花都区',
    short_name: '花都',
    f_id: '440100',
  },
  {
    id: '440115',
    name: '南沙区',
    short_name: '南沙',
    f_id: '440100',
  },
  {
    id: '440116',
    name: '萝岗区',
    short_name: '萝岗',
    f_id: '440100',
  },
  {
    id: '440183',
    name: '增城市',
    short_name: '增城',
    f_id: '440100',
  },
  {
    id: '440184',
    name: '从化市',
    short_name: '从化',
    f_id: '440100',
  },
  {
    id: '440203',
    name: '武江区',
    short_name: '武江',
    f_id: '440200',
  },
  {
    id: '440204',
    name: '浈江区',
    short_name: '浈江',
    f_id: '440200',
  },
  {
    id: '440205',
    name: '曲江区',
    short_name: '曲江',
    f_id: '440200',
  },
  {
    id: '440222',
    name: '始兴县',
    short_name: '始兴',
    f_id: '440200',
  },
  {
    id: '440224',
    name: '仁化县',
    short_name: '仁化',
    f_id: '440200',
  },
  {
    id: '440229',
    name: '翁源县',
    short_name: '翁源',
    f_id: '440200',
  },
  {
    id: '440232',
    name: '乳源瑶族自治县',
    short_name: '乳源',
    f_id: '440200',
  },
  {
    id: '440233',
    name: '新丰县',
    short_name: '新丰',
    f_id: '440200',
  },
  {
    id: '440281',
    name: '乐昌市',
    short_name: '乐昌',
    f_id: '440200',
  },
  {
    id: '440282',
    name: '南雄市',
    short_name: '南雄',
    f_id: '440200',
  },
  {
    id: '440303',
    name: '罗湖区',
    short_name: '罗湖',
    f_id: '440300',
  },
  {
    id: '440304',
    name: '福田区',
    short_name: '福田',
    f_id: '440300',
  },
  {
    id: '440305',
    name: '南山区',
    short_name: '南山',
    f_id: '440300',
  },
  {
    id: '440306',
    name: '宝安区',
    short_name: '宝安',
    f_id: '440300',
  },
  {
    id: '440307',
    name: '龙岗区',
    short_name: '龙岗',
    f_id: '440300',
  },
  {
    id: '440308',
    name: '盐田区',
    short_name: '盐田',
    f_id: '440300',
  },
  {
    id: '440320',
    name: '光明新区',
    short_name: '光明',
    f_id: '440300',
  },
  {
    id: '440321',
    name: '坪山新区',
    short_name: '坪山',
    f_id: '440300',
  },
  {
    id: '440322',
    name: '大鹏新区',
    short_name: '大鹏',
    f_id: '440300',
  },
  {
    id: '440323',
    name: '龙华新区',
    short_name: '龙华',
    f_id: '440300',
  },
  {
    id: '440402',
    name: '香洲区',
    short_name: '香洲',
    f_id: '440400',
  },
  {
    id: '440403',
    name: '斗门区',
    short_name: '斗门',
    f_id: '440400',
  },
  {
    id: '440404',
    name: '金湾区',
    short_name: '金湾',
    f_id: '440400',
  },
  {
    id: '440507',
    name: '龙湖区',
    short_name: '龙湖',
    f_id: '440500',
  },
  {
    id: '440511',
    name: '金平区',
    short_name: '金平',
    f_id: '440500',
  },
  {
    id: '440512',
    name: '濠江区',
    short_name: '濠江',
    f_id: '440500',
  },
  {
    id: '440513',
    name: '潮阳区',
    short_name: '潮阳',
    f_id: '440500',
  },
  {
    id: '440514',
    name: '潮南区',
    short_name: '潮南',
    f_id: '440500',
  },
  {
    id: '440515',
    name: '澄海区',
    short_name: '澄海',
    f_id: '440500',
  },
  {
    id: '440523',
    name: '南澳县',
    short_name: '南澳',
    f_id: '440500',
  },
  {
    id: '440604',
    name: '禅城区',
    short_name: '禅城',
    f_id: '440600',
  },
  {
    id: '440605',
    name: '南海区',
    short_name: '南海',
    f_id: '440600',
  },
  {
    id: '440606',
    name: '顺德区',
    short_name: '顺德',
    f_id: '440600',
  },
  {
    id: '440607',
    name: '三水区',
    short_name: '三水',
    f_id: '440600',
  },
  {
    id: '440608',
    name: '高明区',
    short_name: '高明',
    f_id: '440600',
  },
  {
    id: '440703',
    name: '蓬江区',
    short_name: '蓬江',
    f_id: '440700',
  },
  {
    id: '440704',
    name: '江海区',
    short_name: '江海',
    f_id: '440700',
  },
  {
    id: '440705',
    name: '新会区',
    short_name: '新会',
    f_id: '440700',
  },
  {
    id: '440781',
    name: '台山市',
    short_name: '台山',
    f_id: '440700',
  },
  {
    id: '440783',
    name: '开平市',
    short_name: '开平',
    f_id: '440700',
  },
  {
    id: '440784',
    name: '鹤山市',
    short_name: '鹤山',
    f_id: '440700',
  },
  {
    id: '440785',
    name: '恩平市',
    short_name: '恩平',
    f_id: '440700',
  },
  {
    id: '440802',
    name: '赤坎区',
    short_name: '赤坎',
    f_id: '440800',
  },
  {
    id: '440803',
    name: '霞山区',
    short_name: '霞山',
    f_id: '440800',
  },
  {
    id: '440804',
    name: '坡头区',
    short_name: '坡头',
    f_id: '440800',
  },
  {
    id: '440811',
    name: '麻章区',
    short_name: '麻章',
    f_id: '440800',
  },
  {
    id: '440823',
    name: '遂溪县',
    short_name: '遂溪',
    f_id: '440800',
  },
  {
    id: '440825',
    name: '徐闻县',
    short_name: '徐闻',
    f_id: '440800',
  },
  {
    id: '440881',
    name: '廉江市',
    short_name: '廉江',
    f_id: '440800',
  },
  {
    id: '440882',
    name: '雷州市',
    short_name: '雷州',
    f_id: '440800',
  },
  {
    id: '440883',
    name: '吴川市',
    short_name: '吴川',
    f_id: '440800',
  },
  {
    id: '440902',
    name: '茂南区',
    short_name: '茂南',
    f_id: '440900',
  },
  {
    id: '440903',
    name: '茂港区',
    short_name: '茂港',
    f_id: '440900',
  },
  {
    id: '440923',
    name: '电白县',
    short_name: '电白',
    f_id: '440900',
  },
  {
    id: '440981',
    name: '高州市',
    short_name: '高州',
    f_id: '440900',
  },
  {
    id: '440982',
    name: '化州市',
    short_name: '化州',
    f_id: '440900',
  },
  {
    id: '440983',
    name: '信宜市',
    short_name: '信宜',
    f_id: '440900',
  },
  {
    id: '441202',
    name: '端州区',
    short_name: '端州',
    f_id: '441200',
  },
  {
    id: '441203',
    name: '鼎湖区',
    short_name: '鼎湖',
    f_id: '441200',
  },
  {
    id: '441223',
    name: '广宁县',
    short_name: '广宁',
    f_id: '441200',
  },
  {
    id: '441224',
    name: '怀集县',
    short_name: '怀集',
    f_id: '441200',
  },
  {
    id: '441225',
    name: '封开县',
    short_name: '封开',
    f_id: '441200',
  },
  {
    id: '441226',
    name: '德庆县',
    short_name: '德庆',
    f_id: '441200',
  },
  {
    id: '441283',
    name: '高要市',
    short_name: '高要',
    f_id: '441200',
  },
  {
    id: '441284',
    name: '四会市',
    short_name: '四会',
    f_id: '441200',
  },
  {
    id: '441302',
    name: '惠城区',
    short_name: '惠城',
    f_id: '441300',
  },
  {
    id: '441303',
    name: '惠阳区',
    short_name: '惠阳',
    f_id: '441300',
  },
  {
    id: '441322',
    name: '博罗县',
    short_name: '博罗',
    f_id: '441300',
  },
  {
    id: '441323',
    name: '惠东县',
    short_name: '惠东',
    f_id: '441300',
  },
  {
    id: '441324',
    name: '龙门县',
    short_name: '龙门',
    f_id: '441300',
  },
  {
    id: '441402',
    name: '梅江区',
    short_name: '梅江',
    f_id: '441400',
  },
  {
    id: '441421',
    name: '梅县',
    short_name: '梅县',
    f_id: '441400',
  },
  {
    id: '441422',
    name: '大埔县',
    short_name: '大埔',
    f_id: '441400',
  },
  {
    id: '441423',
    name: '丰顺县',
    short_name: '丰顺',
    f_id: '441400',
  },
  {
    id: '441424',
    name: '五华县',
    short_name: '五华',
    f_id: '441400',
  },
  {
    id: '441426',
    name: '平远县',
    short_name: '平远',
    f_id: '441400',
  },
  {
    id: '441427',
    name: '蕉岭县',
    short_name: '蕉岭',
    f_id: '441400',
  },
  {
    id: '441481',
    name: '兴宁市',
    short_name: '兴宁',
    f_id: '441400',
  },
  {
    id: '441502',
    name: '城区',
    short_name: '城区',
    f_id: '441500',
  },
  {
    id: '441521',
    name: '海丰县',
    short_name: '海丰',
    f_id: '441500',
  },
  {
    id: '441523',
    name: '陆河县',
    short_name: '陆河',
    f_id: '441500',
  },
  {
    id: '441581',
    name: '陆丰市',
    short_name: '陆丰',
    f_id: '441500',
  },
  {
    id: '441602',
    name: '源城区',
    short_name: '源城',
    f_id: '441600',
  },
  {
    id: '441621',
    name: '紫金县',
    short_name: '紫金',
    f_id: '441600',
  },
  {
    id: '441622',
    name: '龙川县',
    short_name: '龙川',
    f_id: '441600',
  },
  {
    id: '441623',
    name: '连平县',
    short_name: '连平',
    f_id: '441600',
  },
  {
    id: '441624',
    name: '和平县',
    short_name: '和平',
    f_id: '441600',
  },
  {
    id: '441625',
    name: '东源县',
    short_name: '东源',
    f_id: '441600',
  },
  {
    id: '441702',
    name: '江城区',
    short_name: '江城',
    f_id: '441700',
  },
  {
    id: '441721',
    name: '阳西县',
    short_name: '阳西',
    f_id: '441700',
  },
  {
    id: '441723',
    name: '阳东县',
    short_name: '阳东',
    f_id: '441700',
  },
  {
    id: '441781',
    name: '阳春市',
    short_name: '阳春',
    f_id: '441700',
  },
  {
    id: '441802',
    name: '清城区',
    short_name: '清城',
    f_id: '441800',
  },
  {
    id: '441821',
    name: '佛冈县',
    short_name: '佛冈',
    f_id: '441800',
  },
  {
    id: '441823',
    name: '阳山县',
    short_name: '阳山',
    f_id: '441800',
  },
  {
    id: '441825',
    name: '连山壮族瑶族自治县',
    short_name: '连山',
    f_id: '441800',
  },
  {
    id: '441826',
    name: '连南瑶族自治县',
    short_name: '连南',
    f_id: '441800',
  },
  {
    id: '441827',
    name: '清新区',
    short_name: '清新',
    f_id: '441800',
  },
  {
    id: '441881',
    name: '英德市',
    short_name: '英德',
    f_id: '441800',
  },
  {
    id: '441882',
    name: '连州市',
    short_name: '连州',
    f_id: '441800',
  },
  {
    id: '445102',
    name: '湘桥区',
    short_name: '湘桥',
    f_id: '445100',
  },
  {
    id: '445121',
    name: '潮安区',
    short_name: '潮安',
    f_id: '445100',
  },
  {
    id: '445122',
    name: '饶平县',
    short_name: '饶平',
    f_id: '445100',
  },
  {
    id: '445202',
    name: '榕城区',
    short_name: '榕城',
    f_id: '445200',
  },
  {
    id: '445221',
    name: '揭东区',
    short_name: '揭东',
    f_id: '445200',
  },
  {
    id: '445222',
    name: '揭西县',
    short_name: '揭西',
    f_id: '445200',
  },
  {
    id: '445224',
    name: '惠来县',
    short_name: '惠来',
    f_id: '445200',
  },
  {
    id: '445281',
    name: '普宁市',
    short_name: '普宁',
    f_id: '445200',
  },
  {
    id: '445302',
    name: '云城区',
    short_name: '云城',
    f_id: '445300',
  },
  {
    id: '445321',
    name: '新兴县',
    short_name: '新兴',
    f_id: '445300',
  },
  {
    id: '445322',
    name: '郁南县',
    short_name: '郁南',
    f_id: '445300',
  },
  {
    id: '445323',
    name: '云安县',
    short_name: '云安',
    f_id: '445300',
  },
  {
    id: '445381',
    name: '罗定市',
    short_name: '罗定',
    f_id: '445300',
  },
  {
    id: '450102',
    name: '兴宁区',
    short_name: '兴宁',
    f_id: '450100',
  },
  {
    id: '450103',
    name: '青秀区',
    short_name: '青秀',
    f_id: '450100',
  },
  {
    id: '450105',
    name: '江南区',
    short_name: '江南',
    f_id: '450100',
  },
  {
    id: '450107',
    name: '西乡塘区',
    short_name: '西乡塘',
    f_id: '450100',
  },
  {
    id: '450108',
    name: '良庆区',
    short_name: '良庆',
    f_id: '450100',
  },
  {
    id: '450109',
    name: '邕宁区',
    short_name: '邕宁',
    f_id: '450100',
  },
  {
    id: '450122',
    name: '武鸣县',
    short_name: '武鸣',
    f_id: '450100',
  },
  {
    id: '450123',
    name: '隆安县',
    short_name: '隆安',
    f_id: '450100',
  },
  {
    id: '450124',
    name: '马山县',
    short_name: '马山',
    f_id: '450100',
  },
  {
    id: '450125',
    name: '上林县',
    short_name: '上林',
    f_id: '450100',
  },
  {
    id: '450126',
    name: '宾阳县',
    short_name: '宾阳',
    f_id: '450100',
  },
  {
    id: '450127',
    name: '横县',
    short_name: '横县',
    f_id: '450100',
  },
  {
    id: '450202',
    name: '城中区',
    short_name: '城中',
    f_id: '450200',
  },
  {
    id: '450203',
    name: '鱼峰区',
    short_name: '鱼峰',
    f_id: '450200',
  },
  {
    id: '450204',
    name: '柳南区',
    short_name: '柳南',
    f_id: '450200',
  },
  {
    id: '450205',
    name: '柳北区',
    short_name: '柳北',
    f_id: '450200',
  },
  {
    id: '450221',
    name: '柳江县',
    short_name: '柳江',
    f_id: '450200',
  },
  {
    id: '450222',
    name: '柳城县',
    short_name: '柳城',
    f_id: '450200',
  },
  {
    id: '450223',
    name: '鹿寨县',
    short_name: '鹿寨',
    f_id: '450200',
  },
  {
    id: '450224',
    name: '融安县',
    short_name: '融安',
    f_id: '450200',
  },
  {
    id: '450225',
    name: '融水苗族自治县',
    short_name: '融水',
    f_id: '450200',
  },
  {
    id: '450226',
    name: '三江侗族自治县',
    short_name: '三江',
    f_id: '450200',
  },
  {
    id: '450302',
    name: '秀峰区',
    short_name: '秀峰',
    f_id: '450300',
  },
  {
    id: '450303',
    name: '叠彩区',
    short_name: '叠彩',
    f_id: '450300',
  },
  {
    id: '450304',
    name: '象山区',
    short_name: '象山',
    f_id: '450300',
  },
  {
    id: '450305',
    name: '七星区',
    short_name: '七星',
    f_id: '450300',
  },
  {
    id: '450311',
    name: '雁山区',
    short_name: '雁山',
    f_id: '450300',
  },
  {
    id: '450321',
    name: '阳朔县',
    short_name: '阳朔',
    f_id: '450300',
  },
  {
    id: '450322',
    name: '临桂区',
    short_name: '临桂',
    f_id: '450300',
  },
  {
    id: '450323',
    name: '灵川县',
    short_name: '灵川',
    f_id: '450300',
  },
  {
    id: '450324',
    name: '全州县',
    short_name: '全州',
    f_id: '450300',
  },
  {
    id: '450325',
    name: '兴安县',
    short_name: '兴安',
    f_id: '450300',
  },
  {
    id: '450326',
    name: '永福县',
    short_name: '永福',
    f_id: '450300',
  },
  {
    id: '450327',
    name: '灌阳县',
    short_name: '灌阳',
    f_id: '450300',
  },
  {
    id: '450328',
    name: '龙胜各族自治县',
    short_name: '龙胜',
    f_id: '450300',
  },
  {
    id: '450329',
    name: '资源县',
    short_name: '资源',
    f_id: '450300',
  },
  {
    id: '450330',
    name: '平乐县',
    short_name: '平乐',
    f_id: '450300',
  },
  {
    id: '450331',
    name: '荔浦县',
    short_name: '荔浦',
    f_id: '450300',
  },
  {
    id: '450332',
    name: '恭城瑶族自治县',
    short_name: '恭城',
    f_id: '450300',
  },
  {
    id: '450403',
    name: '万秀区',
    short_name: '万秀',
    f_id: '450400',
  },
  {
    id: '450405',
    name: '长洲区',
    short_name: '长洲',
    f_id: '450400',
  },
  {
    id: '450406',
    name: '龙圩区',
    short_name: '龙圩',
    f_id: '450400',
  },
  {
    id: '450421',
    name: '苍梧县',
    short_name: '苍梧',
    f_id: '450400',
  },
  {
    id: '450422',
    name: '藤县',
    short_name: '藤县',
    f_id: '450400',
  },
  {
    id: '450423',
    name: '蒙山县',
    short_name: '蒙山',
    f_id: '450400',
  },
  {
    id: '450481',
    name: '岑溪市',
    short_name: '岑溪',
    f_id: '450400',
  },
  {
    id: '450502',
    name: '海城区',
    short_name: '海城',
    f_id: '450500',
  },
  {
    id: '450503',
    name: '银海区',
    short_name: '银海',
    f_id: '450500',
  },
  {
    id: '450512',
    name: '铁山港区',
    short_name: '铁山港',
    f_id: '450500',
  },
  {
    id: '450521',
    name: '合浦县',
    short_name: '合浦',
    f_id: '450500',
  },
  {
    id: '450602',
    name: '港口区',
    short_name: '港口',
    f_id: '450600',
  },
  {
    id: '450603',
    name: '防城区',
    short_name: '防城',
    f_id: '450600',
  },
  {
    id: '450621',
    name: '上思县',
    short_name: '上思',
    f_id: '450600',
  },
  {
    id: '450681',
    name: '东兴市',
    short_name: '东兴',
    f_id: '450600',
  },
  {
    id: '450702',
    name: '钦南区',
    short_name: '钦南',
    f_id: '450700',
  },
  {
    id: '450703',
    name: '钦北区',
    short_name: '钦北',
    f_id: '450700',
  },
  {
    id: '450721',
    name: '灵山县',
    short_name: '灵山',
    f_id: '450700',
  },
  {
    id: '450722',
    name: '浦北县',
    short_name: '浦北',
    f_id: '450700',
  },
  {
    id: '450802',
    name: '港北区',
    short_name: '港北',
    f_id: '450800',
  },
  {
    id: '450803',
    name: '港南区',
    short_name: '港南',
    f_id: '450800',
  },
  {
    id: '450804',
    name: '覃塘区',
    short_name: '覃塘',
    f_id: '450800',
  },
  {
    id: '450821',
    name: '平南县',
    short_name: '平南',
    f_id: '450800',
  },
  {
    id: '450881',
    name: '桂平市',
    short_name: '桂平',
    f_id: '450800',
  },
  {
    id: '450902',
    name: '玉州区',
    short_name: '玉州',
    f_id: '450900',
  },
  {
    id: '450903',
    name: '福绵区',
    short_name: '福绵',
    f_id: '450900',
  },
  {
    id: '450921',
    name: '容县',
    short_name: '容县',
    f_id: '450900',
  },
  {
    id: '450922',
    name: '陆川县',
    short_name: '陆川',
    f_id: '450900',
  },
  {
    id: '450923',
    name: '博白县',
    short_name: '博白',
    f_id: '450900',
  },
  {
    id: '450924',
    name: '兴业县',
    short_name: '兴业',
    f_id: '450900',
  },
  {
    id: '450981',
    name: '北流市',
    short_name: '北流',
    f_id: '450900',
  },
  {
    id: '451002',
    name: '右江区',
    short_name: '右江',
    f_id: '451000',
  },
  {
    id: '451021',
    name: '田阳县',
    short_name: '田阳',
    f_id: '451000',
  },
  {
    id: '451022',
    name: '田东县',
    short_name: '田东',
    f_id: '451000',
  },
  {
    id: '451023',
    name: '平果县',
    short_name: '平果',
    f_id: '451000',
  },
  {
    id: '451024',
    name: '德保县',
    short_name: '德保',
    f_id: '451000',
  },
  {
    id: '451025',
    name: '靖西县',
    short_name: '靖西',
    f_id: '451000',
  },
  {
    id: '451026',
    name: '那坡县',
    short_name: '那坡',
    f_id: '451000',
  },
  {
    id: '451027',
    name: '凌云县',
    short_name: '凌云',
    f_id: '451000',
  },
  {
    id: '451028',
    name: '乐业县',
    short_name: '乐业',
    f_id: '451000',
  },
  {
    id: '451029',
    name: '田林县',
    short_name: '田林',
    f_id: '451000',
  },
  {
    id: '451030',
    name: '西林县',
    short_name: '西林',
    f_id: '451000',
  },
  {
    id: '451031',
    name: '隆林各族自治县',
    short_name: '隆林',
    f_id: '451000',
  },
  {
    id: '451102',
    name: '八步区',
    short_name: '八步',
    f_id: '451100',
  },
  {
    id: '451119',
    name: '平桂管理区',
    short_name: '平桂',
    f_id: '451100',
  },
  {
    id: '451121',
    name: '昭平县',
    short_name: '昭平',
    f_id: '451100',
  },
  {
    id: '451122',
    name: '钟山县',
    short_name: '钟山',
    f_id: '451100',
  },
  {
    id: '451123',
    name: '富川瑶族自治县',
    short_name: '富川',
    f_id: '451100',
  },
  {
    id: '451202',
    name: '金城江区',
    short_name: '金城江',
    f_id: '451200',
  },
  {
    id: '451221',
    name: '南丹县',
    short_name: '南丹',
    f_id: '451200',
  },
  {
    id: '451222',
    name: '天峨县',
    short_name: '天峨',
    f_id: '451200',
  },
  {
    id: '451223',
    name: '凤山县',
    short_name: '凤山',
    f_id: '451200',
  },
  {
    id: '451224',
    name: '东兰县',
    short_name: '东兰',
    f_id: '451200',
  },
  {
    id: '451225',
    name: '罗城仫佬族自治县',
    short_name: '罗城',
    f_id: '451200',
  },
  {
    id: '451226',
    name: '环江毛南族自治县',
    short_name: '环江',
    f_id: '451200',
  },
  {
    id: '451227',
    name: '巴马瑶族自治县',
    short_name: '巴马',
    f_id: '451200',
  },
  {
    id: '451228',
    name: '都安瑶族自治县',
    short_name: '都安',
    f_id: '451200',
  },
  {
    id: '451229',
    name: '大化瑶族自治县',
    short_name: '大化',
    f_id: '451200',
  },
  {
    id: '451281',
    name: '宜州市',
    short_name: '宜州',
    f_id: '451200',
  },
  {
    id: '451302',
    name: '兴宾区',
    short_name: '兴宾',
    f_id: '451300',
  },
  {
    id: '451321',
    name: '忻城县',
    short_name: '忻城',
    f_id: '451300',
  },
  {
    id: '451322',
    name: '象州县',
    short_name: '象州',
    f_id: '451300',
  },
  {
    id: '451323',
    name: '武宣县',
    short_name: '武宣',
    f_id: '451300',
  },
  {
    id: '451324',
    name: '金秀瑶族自治县',
    short_name: '金秀',
    f_id: '451300',
  },
  {
    id: '451381',
    name: '合山市',
    short_name: '合山',
    f_id: '451300',
  },
  {
    id: '451402',
    name: '江州区',
    short_name: '江州',
    f_id: '451400',
  },
  {
    id: '451421',
    name: '扶绥县',
    short_name: '扶绥',
    f_id: '451400',
  },
  {
    id: '451422',
    name: '宁明县',
    short_name: '宁明',
    f_id: '451400',
  },
  {
    id: '451423',
    name: '龙州县',
    short_name: '龙州',
    f_id: '451400',
  },
  {
    id: '451424',
    name: '大新县',
    short_name: '大新',
    f_id: '451400',
  },
  {
    id: '451425',
    name: '天等县',
    short_name: '天等',
    f_id: '451400',
  },
  {
    id: '451481',
    name: '凭祥市',
    short_name: '凭祥',
    f_id: '451400',
  },
  {
    id: '460105',
    name: '秀英区',
    short_name: '秀英',
    f_id: '460100',
  },
  {
    id: '460106',
    name: '龙华区',
    short_name: '龙华',
    f_id: '460100',
  },
  {
    id: '460107',
    name: '琼山区',
    short_name: '琼山',
    f_id: '460100',
  },
  {
    id: '460108',
    name: '美兰区',
    short_name: '美兰',
    f_id: '460100',
  },
  {
    id: '460321',
    name: '西沙群岛',
    short_name: '西沙',
    f_id: '460300',
  },
  {
    id: '460322',
    name: '南沙群岛',
    short_name: '南沙',
    f_id: '460300',
  },
  {
    id: '460323',
    name: '中沙群岛的岛礁及其海域',
    short_name: '中沙',
    f_id: '460300',
  },
  {
    id: '500101',
    name: '万州区',
    short_name: '万州',
    f_id: '500100',
  },
  {
    id: '500102',
    name: '涪陵区',
    short_name: '涪陵',
    f_id: '500100',
  },
  {
    id: '500103',
    name: '渝中区',
    short_name: '渝中',
    f_id: '500100',
  },
  {
    id: '500104',
    name: '大渡口区',
    short_name: '大渡口',
    f_id: '500100',
  },
  {
    id: '500105',
    name: '江北区',
    short_name: '江北',
    f_id: '500100',
  },
  {
    id: '500106',
    name: '沙坪坝区',
    short_name: '沙坪坝',
    f_id: '500100',
  },
  {
    id: '500107',
    name: '九龙坡区',
    short_name: '九龙坡',
    f_id: '500100',
  },
  {
    id: '500108',
    name: '南岸区',
    short_name: '南岸',
    f_id: '500100',
  },
  {
    id: '500109',
    name: '北碚区',
    short_name: '北碚',
    f_id: '500100',
  },
  {
    id: '500112',
    name: '渝北区',
    short_name: '渝北',
    f_id: '500100',
  },
  {
    id: '500113',
    name: '巴南区',
    short_name: '巴南',
    f_id: '500100',
  },
  {
    id: '500114',
    name: '黔江区',
    short_name: '黔江',
    f_id: '500100',
  },
  {
    id: '500115',
    name: '长寿区',
    short_name: '长寿',
    f_id: '500100',
  },
  {
    id: '500222',
    name: '綦江区',
    short_name: '綦江',
    f_id: '500100',
  },
  {
    id: '500223',
    name: '潼南县',
    short_name: '潼南',
    f_id: '500100',
  },
  {
    id: '500224',
    name: '铜梁县',
    short_name: '铜梁',
    f_id: '500100',
  },
  {
    id: '500225',
    name: '大足区',
    short_name: '大足',
    f_id: '500100',
  },
  {
    id: '500226',
    name: '荣昌县',
    short_name: '荣昌',
    f_id: '500100',
  },
  {
    id: '500227',
    name: '璧山县',
    short_name: '璧山',
    f_id: '500100',
  },
  {
    id: '500228',
    name: '梁平县',
    short_name: '梁平',
    f_id: '500100',
  },
  {
    id: '500229',
    name: '城口县',
    short_name: '城口',
    f_id: '500100',
  },
  {
    id: '500230',
    name: '丰都县',
    short_name: '丰都',
    f_id: '500100',
  },
  {
    id: '500231',
    name: '垫江县',
    short_name: '垫江',
    f_id: '500100',
  },
  {
    id: '500232',
    name: '武隆县',
    short_name: '武隆',
    f_id: '500100',
  },
  {
    id: '500233',
    name: '忠县',
    short_name: '忠县',
    f_id: '500100',
  },
  {
    id: '500234',
    name: '开县',
    short_name: '开县',
    f_id: '500100',
  },
  {
    id: '500235',
    name: '云阳县',
    short_name: '云阳',
    f_id: '500100',
  },
  {
    id: '500236',
    name: '奉节县',
    short_name: '奉节',
    f_id: '500100',
  },
  {
    id: '500237',
    name: '巫山县',
    short_name: '巫山',
    f_id: '500100',
  },
  {
    id: '500238',
    name: '巫溪县',
    short_name: '巫溪',
    f_id: '500100',
  },
  {
    id: '500240',
    name: '石柱土家族自治县',
    short_name: '石柱',
    f_id: '500100',
  },
  {
    id: '500241',
    name: '秀山土家族苗族自治县',
    short_name: '秀山',
    f_id: '500100',
  },
  {
    id: '500242',
    name: '酉阳土家族苗族自治县',
    short_name: '酉阳',
    f_id: '500100',
  },
  {
    id: '500243',
    name: '彭水苗族土家族自治县',
    short_name: '彭水',
    f_id: '500100',
  },
  {
    id: '500381',
    name: '江津区',
    short_name: '江津',
    f_id: '500100',
  },
  {
    id: '500382',
    name: '合川区',
    short_name: '合川',
    f_id: '500100',
  },
  {
    id: '500383',
    name: '永川区',
    short_name: '永川',
    f_id: '500100',
  },
  {
    id: '500384',
    name: '南川区',
    short_name: '南川',
    f_id: '500100',
  },
  {
    id: '510104',
    name: '锦江区',
    short_name: '锦江',
    f_id: '510100',
  },
  {
    id: '510105',
    name: '青羊区',
    short_name: '青羊',
    f_id: '510100',
  },
  {
    id: '510106',
    name: '金牛区',
    short_name: '金牛',
    f_id: '510100',
  },
  {
    id: '510107',
    name: '武侯区',
    short_name: '武侯',
    f_id: '510100',
  },
  {
    id: '510108',
    name: '成华区',
    short_name: '成华',
    f_id: '510100',
  },
  {
    id: '510112',
    name: '龙泉驿区',
    short_name: '龙泉驿',
    f_id: '510100',
  },
  {
    id: '510113',
    name: '青白江区',
    short_name: '青白江',
    f_id: '510100',
  },
  {
    id: '510114',
    name: '新都区',
    short_name: '新都',
    f_id: '510100',
  },
  {
    id: '510115',
    name: '温江区',
    short_name: '温江',
    f_id: '510100',
  },
  {
    id: '510121',
    name: '金堂县',
    short_name: '金堂',
    f_id: '510100',
  },
  {
    id: '510122',
    name: '双流县',
    short_name: '双流',
    f_id: '510100',
  },
  {
    id: '510124',
    name: '郫县',
    short_name: '郫县',
    f_id: '510100',
  },
  {
    id: '510129',
    name: '大邑县',
    short_name: '大邑',
    f_id: '510100',
  },
  {
    id: '510131',
    name: '蒲江县',
    short_name: '蒲江',
    f_id: '510100',
  },
  {
    id: '510132',
    name: '新津县',
    short_name: '新津',
    f_id: '510100',
  },
  {
    id: '510181',
    name: '都江堰市',
    short_name: '都江堰',
    f_id: '510100',
  },
  {
    id: '510182',
    name: '彭州市',
    short_name: '彭州',
    f_id: '510100',
  },
  {
    id: '510183',
    name: '邛崃市',
    short_name: '邛崃',
    f_id: '510100',
  },
  {
    id: '510184',
    name: '崇州市',
    short_name: '崇州',
    f_id: '510100',
  },
  {
    id: '510302',
    name: '自流井区',
    short_name: '自流井',
    f_id: '510300',
  },
  {
    id: '510303',
    name: '贡井区',
    short_name: '贡井',
    f_id: '510300',
  },
  {
    id: '510304',
    name: '大安区',
    short_name: '大安',
    f_id: '510300',
  },
  {
    id: '510311',
    name: '沿滩区',
    short_name: '沿滩',
    f_id: '510300',
  },
  {
    id: '510321',
    name: '荣县',
    short_name: '荣县',
    f_id: '510300',
  },
  {
    id: '510322',
    name: '富顺县',
    short_name: '富顺',
    f_id: '510300',
  },
  {
    id: '510402',
    name: '东区',
    short_name: '东区',
    f_id: '510400',
  },
  {
    id: '510403',
    name: '西区',
    short_name: '西区',
    f_id: '510400',
  },
  {
    id: '510411',
    name: '仁和区',
    short_name: '仁和',
    f_id: '510400',
  },
  {
    id: '510421',
    name: '米易县',
    short_name: '米易',
    f_id: '510400',
  },
  {
    id: '510422',
    name: '盐边县',
    short_name: '盐边',
    f_id: '510400',
  },
  {
    id: '510502',
    name: '江阳区',
    short_name: '江阳',
    f_id: '510500',
  },
  {
    id: '510503',
    name: '纳溪区',
    short_name: '纳溪',
    f_id: '510500',
  },
  {
    id: '510504',
    name: '龙马潭区',
    short_name: '龙马潭',
    f_id: '510500',
  },
  {
    id: '510521',
    name: '泸县',
    short_name: '泸县',
    f_id: '510500',
  },
  {
    id: '510522',
    name: '合江县',
    short_name: '合江',
    f_id: '510500',
  },
  {
    id: '510524',
    name: '叙永县',
    short_name: '叙永',
    f_id: '510500',
  },
  {
    id: '510525',
    name: '古蔺县',
    short_name: '古蔺',
    f_id: '510500',
  },
  {
    id: '510603',
    name: '旌阳区',
    short_name: '旌阳',
    f_id: '510600',
  },
  {
    id: '510623',
    name: '中江县',
    short_name: '中江',
    f_id: '510600',
  },
  {
    id: '510626',
    name: '罗江县',
    short_name: '罗江',
    f_id: '510600',
  },
  {
    id: '510681',
    name: '广汉市',
    short_name: '广汉',
    f_id: '510600',
  },
  {
    id: '510682',
    name: '什邡市',
    short_name: '什邡',
    f_id: '510600',
  },
  {
    id: '510683',
    name: '绵竹市',
    short_name: '绵竹',
    f_id: '510600',
  },
  {
    id: '510703',
    name: '涪城区',
    short_name: '涪城',
    f_id: '510700',
  },
  {
    id: '510704',
    name: '游仙区',
    short_name: '游仙',
    f_id: '510700',
  },
  {
    id: '510722',
    name: '三台县',
    short_name: '三台',
    f_id: '510700',
  },
  {
    id: '510723',
    name: '盐亭县',
    short_name: '盐亭',
    f_id: '510700',
  },
  {
    id: '510724',
    name: '安县',
    short_name: '安县',
    f_id: '510700',
  },
  {
    id: '510725',
    name: '梓潼县',
    short_name: '梓潼',
    f_id: '510700',
  },
  {
    id: '510726',
    name: '北川羌族自治县',
    short_name: '北川',
    f_id: '510700',
  },
  {
    id: '510727',
    name: '平武县',
    short_name: '平武',
    f_id: '510700',
  },
  {
    id: '510781',
    name: '江油市',
    short_name: '江油',
    f_id: '510700',
  },
  {
    id: '510802',
    name: '利州区',
    short_name: '利州',
    f_id: '510800',
  },
  {
    id: '510811',
    name: '昭化区',
    short_name: '昭化',
    f_id: '510800',
  },
  {
    id: '510812',
    name: '朝天区',
    short_name: '朝天',
    f_id: '510800',
  },
  {
    id: '510821',
    name: '旺苍县',
    short_name: '旺苍',
    f_id: '510800',
  },
  {
    id: '510822',
    name: '青川县',
    short_name: '青川',
    f_id: '510800',
  },
  {
    id: '510823',
    name: '剑阁县',
    short_name: '剑阁',
    f_id: '510800',
  },
  {
    id: '510824',
    name: '苍溪县',
    short_name: '苍溪',
    f_id: '510800',
  },
  {
    id: '510903',
    name: '船山区',
    short_name: '船山',
    f_id: '510900',
  },
  {
    id: '510904',
    name: '安居区',
    short_name: '安居',
    f_id: '510900',
  },
  {
    id: '510921',
    name: '蓬溪县',
    short_name: '蓬溪',
    f_id: '510900',
  },
  {
    id: '510922',
    name: '射洪县',
    short_name: '射洪',
    f_id: '510900',
  },
  {
    id: '510923',
    name: '大英县',
    short_name: '大英',
    f_id: '510900',
  },
  {
    id: '511002',
    name: '市中区',
    short_name: '市中',
    f_id: '511000',
  },
  {
    id: '511011',
    name: '东兴区',
    short_name: '东兴',
    f_id: '511000',
  },
  {
    id: '511024',
    name: '威远县',
    short_name: '威远',
    f_id: '511000',
  },
  {
    id: '511025',
    name: '资中县',
    short_name: '资中',
    f_id: '511000',
  },
  {
    id: '511028',
    name: '隆昌县',
    short_name: '隆昌',
    f_id: '511000',
  },
  {
    id: '511102',
    name: '市中区',
    short_name: '市中',
    f_id: '511100',
  },
  {
    id: '511111',
    name: '沙湾区',
    short_name: '沙湾',
    f_id: '511100',
  },
  {
    id: '511112',
    name: '五通桥区',
    short_name: '五通桥',
    f_id: '511100',
  },
  {
    id: '511113',
    name: '金口河区',
    short_name: '金口河',
    f_id: '511100',
  },
  {
    id: '511123',
    name: '犍为县',
    short_name: '犍为',
    f_id: '511100',
  },
  {
    id: '511124',
    name: '井研县',
    short_name: '井研',
    f_id: '511100',
  },
  {
    id: '511126',
    name: '夹江县',
    short_name: '夹江',
    f_id: '511100',
  },
  {
    id: '511129',
    name: '沐川县',
    short_name: '沐川',
    f_id: '511100',
  },
  {
    id: '511132',
    name: '峨边彝族自治县',
    short_name: '峨边',
    f_id: '511100',
  },
  {
    id: '511133',
    name: '马边彝族自治县',
    short_name: '马边',
    f_id: '511100',
  },
  {
    id: '511181',
    name: '峨眉山市',
    short_name: '峨眉山',
    f_id: '511100',
  },
  {
    id: '511302',
    name: '顺庆区',
    short_name: '顺庆',
    f_id: '511300',
  },
  {
    id: '511303',
    name: '高坪区',
    short_name: '高坪',
    f_id: '511300',
  },
  {
    id: '511304',
    name: '嘉陵区',
    short_name: '嘉陵',
    f_id: '511300',
  },
  {
    id: '511321',
    name: '南部县',
    short_name: '南部',
    f_id: '511300',
  },
  {
    id: '511322',
    name: '营山县',
    short_name: '营山',
    f_id: '511300',
  },
  {
    id: '511323',
    name: '蓬安县',
    short_name: '蓬安',
    f_id: '511300',
  },
  {
    id: '511324',
    name: '仪陇县',
    short_name: '仪陇',
    f_id: '511300',
  },
  {
    id: '511325',
    name: '西充县',
    short_name: '西充',
    f_id: '511300',
  },
  {
    id: '511381',
    name: '阆中市',
    short_name: '阆中',
    f_id: '511300',
  },
  {
    id: '511402',
    name: '东坡区',
    short_name: '东坡',
    f_id: '511400',
  },
  {
    id: '511421',
    name: '仁寿县',
    short_name: '仁寿',
    f_id: '511400',
  },
  {
    id: '511422',
    name: '彭山县',
    short_name: '彭山',
    f_id: '511400',
  },
  {
    id: '511423',
    name: '洪雅县',
    short_name: '洪雅',
    f_id: '511400',
  },
  {
    id: '511424',
    name: '丹棱县',
    short_name: '丹棱',
    f_id: '511400',
  },
  {
    id: '511425',
    name: '青神县',
    short_name: '青神',
    f_id: '511400',
  },
  {
    id: '511502',
    name: '翠屏区',
    short_name: '翠屏',
    f_id: '511500',
  },
  {
    id: '511521',
    name: '宜宾县',
    short_name: '宜宾',
    f_id: '511500',
  },
  {
    id: '511522',
    name: '南溪区',
    short_name: '南溪',
    f_id: '511500',
  },
  {
    id: '511523',
    name: '江安县',
    short_name: '江安',
    f_id: '511500',
  },
  {
    id: '511524',
    name: '长宁县',
    short_name: '长宁',
    f_id: '511500',
  },
  {
    id: '511525',
    name: '高县',
    short_name: '高县',
    f_id: '511500',
  },
  {
    id: '511526',
    name: '珙县',
    short_name: '珙县',
    f_id: '511500',
  },
  {
    id: '511527',
    name: '筠连县',
    short_name: '筠连',
    f_id: '511500',
  },
  {
    id: '511528',
    name: '兴文县',
    short_name: '兴文',
    f_id: '511500',
  },
  {
    id: '511529',
    name: '屏山县',
    short_name: '屏山',
    f_id: '511500',
  },
  {
    id: '511602',
    name: '广安区',
    short_name: '广安',
    f_id: '511600',
  },
  {
    id: '511603',
    name: '前锋区',
    short_name: '前锋',
    f_id: '511600',
  },
  {
    id: '511621',
    name: '岳池县',
    short_name: '岳池',
    f_id: '511600',
  },
  {
    id: '511622',
    name: '武胜县',
    short_name: '武胜',
    f_id: '511600',
  },
  {
    id: '511623',
    name: '邻水县',
    short_name: '邻水',
    f_id: '511600',
  },
  {
    id: '511681',
    name: '华蓥市',
    short_name: '华蓥',
    f_id: '511600',
  },
  {
    id: '511702',
    name: '通川区',
    short_name: '通川',
    f_id: '511700',
  },
  {
    id: '511721',
    name: '达川区',
    short_name: '达川',
    f_id: '511700',
  },
  {
    id: '511722',
    name: '宣汉县',
    short_name: '宣汉',
    f_id: '511700',
  },
  {
    id: '511723',
    name: '开江县',
    short_name: '开江',
    f_id: '511700',
  },
  {
    id: '511724',
    name: '大竹县',
    short_name: '大竹',
    f_id: '511700',
  },
  {
    id: '511725',
    name: '渠县',
    short_name: '渠县',
    f_id: '511700',
  },
  {
    id: '511781',
    name: '万源市',
    short_name: '万源',
    f_id: '511700',
  },
  {
    id: '511802',
    name: '雨城区',
    short_name: '雨城',
    f_id: '511800',
  },
  {
    id: '511821',
    name: '名山区',
    short_name: '名山',
    f_id: '511800',
  },
  {
    id: '511822',
    name: '荥经县',
    short_name: '荥经',
    f_id: '511800',
  },
  {
    id: '511823',
    name: '汉源县',
    short_name: '汉源',
    f_id: '511800',
  },
  {
    id: '511824',
    name: '石棉县',
    short_name: '石棉',
    f_id: '511800',
  },
  {
    id: '511825',
    name: '天全县',
    short_name: '天全',
    f_id: '511800',
  },
  {
    id: '511826',
    name: '芦山县',
    short_name: '芦山',
    f_id: '511800',
  },
  {
    id: '511827',
    name: '宝兴县',
    short_name: '宝兴',
    f_id: '511800',
  },
  {
    id: '511902',
    name: '巴州区',
    short_name: '巴州',
    f_id: '511900',
  },
  {
    id: '511903',
    name: '恩阳区',
    short_name: '恩阳',
    f_id: '511900',
  },
  {
    id: '511921',
    name: '通江县',
    short_name: '通江',
    f_id: '511900',
  },
  {
    id: '511922',
    name: '南江县',
    short_name: '南江',
    f_id: '511900',
  },
  {
    id: '511923',
    name: '平昌县',
    short_name: '平昌',
    f_id: '511900',
  },
  {
    id: '512002',
    name: '雁江区',
    short_name: '雁江',
    f_id: '512000',
  },
  {
    id: '512021',
    name: '安岳县',
    short_name: '安岳',
    f_id: '512000',
  },
  {
    id: '512022',
    name: '乐至县',
    short_name: '乐至',
    f_id: '512000',
  },
  {
    id: '512081',
    name: '简阳市',
    short_name: '简阳',
    f_id: '512000',
  },
  {
    id: '513221',
    name: '汶川县',
    short_name: '汶川',
    f_id: '513200',
  },
  {
    id: '513222',
    name: '理县',
    short_name: '理县',
    f_id: '513200',
  },
  {
    id: '513223',
    name: '茂县',
    short_name: '茂县',
    f_id: '513200',
  },
  {
    id: '513224',
    name: '松潘县',
    short_name: '松潘',
    f_id: '513200',
  },
  {
    id: '513225',
    name: '九寨沟县',
    short_name: '九寨沟',
    f_id: '513200',
  },
  {
    id: '513226',
    name: '金川县',
    short_name: '金川',
    f_id: '513200',
  },
  {
    id: '513227',
    name: '小金县',
    short_name: '小金',
    f_id: '513200',
  },
  {
    id: '513228',
    name: '黑水县',
    short_name: '黑水',
    f_id: '513200',
  },
  {
    id: '513229',
    name: '马尔康县',
    short_name: '马尔康',
    f_id: '513200',
  },
  {
    id: '513230',
    name: '壤塘县',
    short_name: '壤塘',
    f_id: '513200',
  },
  {
    id: '513231',
    name: '阿坝县',
    short_name: '阿坝',
    f_id: '513200',
  },
  {
    id: '513232',
    name: '若尔盖县',
    short_name: '若尔盖',
    f_id: '513200',
  },
  {
    id: '513233',
    name: '红原县',
    short_name: '红原',
    f_id: '513200',
  },
  {
    id: '513321',
    name: '康定县',
    short_name: '康定',
    f_id: '513300',
  },
  {
    id: '513322',
    name: '泸定县',
    short_name: '泸定',
    f_id: '513300',
  },
  {
    id: '513323',
    name: '丹巴县',
    short_name: '丹巴',
    f_id: '513300',
  },
  {
    id: '513324',
    name: '九龙县',
    short_name: '九龙',
    f_id: '513300',
  },
  {
    id: '513325',
    name: '雅江县',
    short_name: '雅江',
    f_id: '513300',
  },
  {
    id: '513326',
    name: '道孚县',
    short_name: '道孚',
    f_id: '513300',
  },
  {
    id: '513327',
    name: '炉霍县',
    short_name: '炉霍',
    f_id: '513300',
  },
  {
    id: '513328',
    name: '甘孜县',
    short_name: '甘孜',
    f_id: '513300',
  },
  {
    id: '513329',
    name: '新龙县',
    short_name: '新龙',
    f_id: '513300',
  },
  {
    id: '513330',
    name: '德格县',
    short_name: '德格',
    f_id: '513300',
  },
  {
    id: '513331',
    name: '白玉县',
    short_name: '白玉',
    f_id: '513300',
  },
  {
    id: '513332',
    name: '石渠县',
    short_name: '石渠',
    f_id: '513300',
  },
  {
    id: '513333',
    name: '色达县',
    short_name: '色达',
    f_id: '513300',
  },
  {
    id: '513334',
    name: '理塘县',
    short_name: '理塘',
    f_id: '513300',
  },
  {
    id: '513335',
    name: '巴塘县',
    short_name: '巴塘',
    f_id: '513300',
  },
  {
    id: '513336',
    name: '乡城县',
    short_name: '乡城',
    f_id: '513300',
  },
  {
    id: '513337',
    name: '稻城县',
    short_name: '稻城',
    f_id: '513300',
  },
  {
    id: '513338',
    name: '得荣县',
    short_name: '得荣',
    f_id: '513300',
  },
  {
    id: '513401',
    name: '西昌市',
    short_name: '西昌',
    f_id: '513400',
  },
  {
    id: '513422',
    name: '木里藏族自治县',
    short_name: '木里',
    f_id: '513400',
  },
  {
    id: '513423',
    name: '盐源县',
    short_name: '盐源',
    f_id: '513400',
  },
  {
    id: '513424',
    name: '德昌县',
    short_name: '德昌',
    f_id: '513400',
  },
  {
    id: '513425',
    name: '会理县',
    short_name: '会理',
    f_id: '513400',
  },
  {
    id: '513426',
    name: '会东县',
    short_name: '会东',
    f_id: '513400',
  },
  {
    id: '513427',
    name: '宁南县',
    short_name: '宁南',
    f_id: '513400',
  },
  {
    id: '513428',
    name: '普格县',
    short_name: '普格',
    f_id: '513400',
  },
  {
    id: '513429',
    name: '布拖县',
    short_name: '布拖',
    f_id: '513400',
  },
  {
    id: '513430',
    name: '金阳县',
    short_name: '金阳',
    f_id: '513400',
  },
  {
    id: '513431',
    name: '昭觉县',
    short_name: '昭觉',
    f_id: '513400',
  },
  {
    id: '513432',
    name: '喜德县',
    short_name: '喜德',
    f_id: '513400',
  },
  {
    id: '513433',
    name: '冕宁县',
    short_name: '冕宁',
    f_id: '513400',
  },
  {
    id: '513434',
    name: '越西县',
    short_name: '越西',
    f_id: '513400',
  },
  {
    id: '513435',
    name: '甘洛县',
    short_name: '甘洛',
    f_id: '513400',
  },
  {
    id: '513436',
    name: '美姑县',
    short_name: '美姑',
    f_id: '513400',
  },
  {
    id: '513437',
    name: '雷波县',
    short_name: '雷波',
    f_id: '513400',
  },
  {
    id: '520102',
    name: '南明区',
    short_name: '南明',
    f_id: '520100',
  },
  {
    id: '520103',
    name: '云岩区',
    short_name: '云岩',
    f_id: '520100',
  },
  {
    id: '520111',
    name: '花溪区',
    short_name: '花溪',
    f_id: '520100',
  },
  {
    id: '520112',
    name: '乌当区',
    short_name: '乌当',
    f_id: '520100',
  },
  {
    id: '520113',
    name: '白云区',
    short_name: '白云',
    f_id: '520100',
  },
  {
    id: '520121',
    name: '开阳县',
    short_name: '开阳',
    f_id: '520100',
  },
  {
    id: '520122',
    name: '息烽县',
    short_name: '息烽',
    f_id: '520100',
  },
  {
    id: '520123',
    name: '修文县',
    short_name: '修文',
    f_id: '520100',
  },
  {
    id: '520151',
    name: '观山湖区',
    short_name: '观山湖',
    f_id: '520100',
  },
  {
    id: '520181',
    name: '清镇市',
    short_name: '清镇',
    f_id: '520100',
  },
  {
    id: '520201',
    name: '钟山区',
    short_name: '钟山',
    f_id: '520200',
  },
  {
    id: '520203',
    name: '六枝特区',
    short_name: '六枝特',
    f_id: '520200',
  },
  {
    id: '520221',
    name: '水城县',
    short_name: '水城',
    f_id: '520200',
  },
  {
    id: '520222',
    name: '盘县',
    short_name: '盘县',
    f_id: '520200',
  },
  {
    id: '520302',
    name: '红花岗区',
    short_name: '红花岗',
    f_id: '520300',
  },
  {
    id: '520303',
    name: '汇川区',
    short_name: '汇川',
    f_id: '520300',
  },
  {
    id: '520321',
    name: '遵义县',
    short_name: '遵义',
    f_id: '520300',
  },
  {
    id: '520322',
    name: '桐梓县',
    short_name: '桐梓',
    f_id: '520300',
  },
  {
    id: '520323',
    name: '绥阳县',
    short_name: '绥阳',
    f_id: '520300',
  },
  {
    id: '520324',
    name: '正安县',
    short_name: '正安',
    f_id: '520300',
  },
  {
    id: '520325',
    name: '道真仡佬族苗族自治县',
    short_name: '道真',
    f_id: '520300',
  },
  {
    id: '520326',
    name: '务川仡佬族苗族自治县',
    short_name: '务川',
    f_id: '520300',
  },
  {
    id: '520327',
    name: '凤冈县',
    short_name: '凤冈',
    f_id: '520300',
  },
  {
    id: '520328',
    name: '湄潭县',
    short_name: '湄潭',
    f_id: '520300',
  },
  {
    id: '520329',
    name: '余庆县',
    short_name: '余庆',
    f_id: '520300',
  },
  {
    id: '520330',
    name: '习水县',
    short_name: '习水',
    f_id: '520300',
  },
  {
    id: '520381',
    name: '赤水市',
    short_name: '赤水',
    f_id: '520300',
  },
  {
    id: '520382',
    name: '仁怀市',
    short_name: '仁怀',
    f_id: '520300',
  },
  {
    id: '520402',
    name: '西秀区',
    short_name: '西秀',
    f_id: '520400',
  },
  {
    id: '520421',
    name: '平坝县',
    short_name: '平坝',
    f_id: '520400',
  },
  {
    id: '520422',
    name: '普定县',
    short_name: '普定',
    f_id: '520400',
  },
  {
    id: '520423',
    name: '镇宁布依族苗族自治县',
    short_name: '镇宁',
    f_id: '520400',
  },
  {
    id: '520424',
    name: '关岭布依族苗族自治县',
    short_name: '关岭',
    f_id: '520400',
  },
  {
    id: '520425',
    name: '紫云苗族布依族自治县',
    short_name: '紫云',
    f_id: '520400',
  },
  {
    id: '522201',
    name: '碧江区',
    short_name: '碧江',
    f_id: '522200',
  },
  {
    id: '522222',
    name: '江口县',
    short_name: '江口',
    f_id: '522200',
  },
  {
    id: '522223',
    name: '玉屏侗族自治县',
    short_name: '玉屏',
    f_id: '522200',
  },
  {
    id: '522224',
    name: '石阡县',
    short_name: '石阡',
    f_id: '522200',
  },
  {
    id: '522225',
    name: '思南县',
    short_name: '思南',
    f_id: '522200',
  },
  {
    id: '522226',
    name: '印江土家族苗族自治县',
    short_name: '印江',
    f_id: '522200',
  },
  {
    id: '522227',
    name: '德江县',
    short_name: '德江',
    f_id: '522200',
  },
  {
    id: '522228',
    name: '沿河土家族自治县',
    short_name: '沿河',
    f_id: '522200',
  },
  {
    id: '522229',
    name: '松桃苗族自治县',
    short_name: '松桃',
    f_id: '522200',
  },
  {
    id: '522230',
    name: '万山区',
    short_name: '万山',
    f_id: '522200',
  },
  {
    id: '522301',
    name: '兴义市',
    short_name: '兴义',
    f_id: '522300',
  },
  {
    id: '522322',
    name: '兴仁县',
    short_name: '兴仁',
    f_id: '522300',
  },
  {
    id: '522323',
    name: '普安县',
    short_name: '普安',
    f_id: '522300',
  },
  {
    id: '522324',
    name: '晴隆县',
    short_name: '晴隆',
    f_id: '522300',
  },
  {
    id: '522325',
    name: '贞丰县',
    short_name: '贞丰',
    f_id: '522300',
  },
  {
    id: '522326',
    name: '望谟县',
    short_name: '望谟',
    f_id: '522300',
  },
  {
    id: '522327',
    name: '册亨县',
    short_name: '册亨',
    f_id: '522300',
  },
  {
    id: '522328',
    name: '安龙县',
    short_name: '安龙',
    f_id: '522300',
  },
  {
    id: '522401',
    name: '七星关区',
    short_name: '七星关',
    f_id: '522400',
  },
  {
    id: '522422',
    name: '大方县',
    short_name: '大方',
    f_id: '522400',
  },
  {
    id: '522423',
    name: '黔西县',
    short_name: '黔西',
    f_id: '522400',
  },
  {
    id: '522424',
    name: '金沙县',
    short_name: '金沙',
    f_id: '522400',
  },
  {
    id: '522425',
    name: '织金县',
    short_name: '织金',
    f_id: '522400',
  },
  {
    id: '522426',
    name: '纳雍县',
    short_name: '纳雍',
    f_id: '522400',
  },
  {
    id: '522427',
    name: '威宁彝族回族苗族自治县',
    short_name: '威宁',
    f_id: '522400',
  },
  {
    id: '522428',
    name: '赫章县',
    short_name: '赫章',
    f_id: '522400',
  },
  {
    id: '522601',
    name: '凯里市',
    short_name: '凯里',
    f_id: '522600',
  },
  {
    id: '522622',
    name: '黄平县',
    short_name: '黄平',
    f_id: '522600',
  },
  {
    id: '522623',
    name: '施秉县',
    short_name: '施秉',
    f_id: '522600',
  },
  {
    id: '522624',
    name: '三穗县',
    short_name: '三穗',
    f_id: '522600',
  },
  {
    id: '522625',
    name: '镇远县',
    short_name: '镇远',
    f_id: '522600',
  },
  {
    id: '522626',
    name: '岑巩县',
    short_name: '岑巩',
    f_id: '522600',
  },
  {
    id: '522627',
    name: '天柱县',
    short_name: '天柱',
    f_id: '522600',
  },
  {
    id: '522628',
    name: '锦屏县',
    short_name: '锦屏',
    f_id: '522600',
  },
  {
    id: '522629',
    name: '剑河县',
    short_name: '剑河',
    f_id: '522600',
  },
  {
    id: '522630',
    name: '台江县',
    short_name: '台江',
    f_id: '522600',
  },
  {
    id: '522631',
    name: '黎平县',
    short_name: '黎平',
    f_id: '522600',
  },
  {
    id: '522632',
    name: '榕江县',
    short_name: '榕江',
    f_id: '522600',
  },
  {
    id: '522633',
    name: '从江县',
    short_name: '从江',
    f_id: '522600',
  },
  {
    id: '522634',
    name: '雷山县',
    short_name: '雷山',
    f_id: '522600',
  },
  {
    id: '522635',
    name: '麻江县',
    short_name: '麻江',
    f_id: '522600',
  },
  {
    id: '522636',
    name: '丹寨县',
    short_name: '丹寨',
    f_id: '522600',
  },
  {
    id: '522701',
    name: '都匀市',
    short_name: '都匀',
    f_id: '522700',
  },
  {
    id: '522702',
    name: '福泉市',
    short_name: '福泉',
    f_id: '522700',
  },
  {
    id: '522722',
    name: '荔波县',
    short_name: '荔波',
    f_id: '522700',
  },
  {
    id: '522723',
    name: '贵定县',
    short_name: '贵定',
    f_id: '522700',
  },
  {
    id: '522725',
    name: '瓮安县',
    short_name: '瓮安',
    f_id: '522700',
  },
  {
    id: '522726',
    name: '独山县',
    short_name: '独山',
    f_id: '522700',
  },
  {
    id: '522727',
    name: '平塘县',
    short_name: '平塘',
    f_id: '522700',
  },
  {
    id: '522728',
    name: '罗甸县',
    short_name: '罗甸',
    f_id: '522700',
  },
  {
    id: '522729',
    name: '长顺县',
    short_name: '长顺',
    f_id: '522700',
  },
  {
    id: '522730',
    name: '龙里县',
    short_name: '龙里',
    f_id: '522700',
  },
  {
    id: '522731',
    name: '惠水县',
    short_name: '惠水',
    f_id: '522700',
  },
  {
    id: '522732',
    name: '三都水族自治县',
    short_name: '三都',
    f_id: '522700',
  },
  {
    id: '530102',
    name: '五华区',
    short_name: '五华',
    f_id: '530100',
  },
  {
    id: '530103',
    name: '盘龙区',
    short_name: '盘龙',
    f_id: '530100',
  },
  {
    id: '530111',
    name: '官渡区',
    short_name: '官渡',
    f_id: '530100',
  },
  {
    id: '530112',
    name: '西山区',
    short_name: '西山',
    f_id: '530100',
  },
  {
    id: '530113',
    name: '东川区',
    short_name: '东川',
    f_id: '530100',
  },
  {
    id: '530121',
    name: '呈贡区',
    short_name: '呈贡',
    f_id: '530100',
  },
  {
    id: '530122',
    name: '晋宁县',
    short_name: '晋宁',
    f_id: '530100',
  },
  {
    id: '530124',
    name: '富民县',
    short_name: '富民',
    f_id: '530100',
  },
  {
    id: '530125',
    name: '宜良县',
    short_name: '宜良',
    f_id: '530100',
  },
  {
    id: '530126',
    name: '石林彝族自治县',
    short_name: '石林',
    f_id: '530100',
  },
  {
    id: '530127',
    name: '嵩明县',
    short_name: '嵩明',
    f_id: '530100',
  },
  {
    id: '530128',
    name: '禄劝彝族苗族自治县',
    short_name: '禄劝',
    f_id: '530100',
  },
  {
    id: '530129',
    name: '寻甸回族彝族自治县',
    short_name: '寻甸',
    f_id: '530100',
  },
  {
    id: '530181',
    name: '安宁市',
    short_name: '安宁',
    f_id: '530100',
  },
  {
    id: '530302',
    name: '麒麟区',
    short_name: '麒麟',
    f_id: '530300',
  },
  {
    id: '530321',
    name: '马龙县',
    short_name: '马龙',
    f_id: '530300',
  },
  {
    id: '530322',
    name: '陆良县',
    short_name: '陆良',
    f_id: '530300',
  },
  {
    id: '530323',
    name: '师宗县',
    short_name: '师宗',
    f_id: '530300',
  },
  {
    id: '530324',
    name: '罗平县',
    short_name: '罗平',
    f_id: '530300',
  },
  {
    id: '530325',
    name: '富源县',
    short_name: '富源',
    f_id: '530300',
  },
  {
    id: '530326',
    name: '会泽县',
    short_name: '会泽',
    f_id: '530300',
  },
  {
    id: '530328',
    name: '沾益县',
    short_name: '沾益',
    f_id: '530300',
  },
  {
    id: '530381',
    name: '宣威市',
    short_name: '宣威',
    f_id: '530300',
  },
  {
    id: '530402',
    name: '红塔区',
    short_name: '红塔',
    f_id: '530400',
  },
  {
    id: '530421',
    name: '江川县',
    short_name: '江川',
    f_id: '530400',
  },
  {
    id: '530422',
    name: '澄江县',
    short_name: '澄江',
    f_id: '530400',
  },
  {
    id: '530423',
    name: '通海县',
    short_name: '通海',
    f_id: '530400',
  },
  {
    id: '530424',
    name: '华宁县',
    short_name: '华宁',
    f_id: '530400',
  },
  {
    id: '530425',
    name: '易门县',
    short_name: '易门',
    f_id: '530400',
  },
  {
    id: '530426',
    name: '峨山彝族自治县',
    short_name: '峨山',
    f_id: '530400',
  },
  {
    id: '530427',
    name: '新平彝族傣族自治县',
    short_name: '新平',
    f_id: '530400',
  },
  {
    id: '530428',
    name: '元江哈尼族彝族傣族自治县',
    short_name: '元江',
    f_id: '530400',
  },
  {
    id: '530502',
    name: '隆阳区',
    short_name: '隆阳',
    f_id: '530500',
  },
  {
    id: '530521',
    name: '施甸县',
    short_name: '施甸',
    f_id: '530500',
  },
  {
    id: '530522',
    name: '腾冲县',
    short_name: '腾冲',
    f_id: '530500',
  },
  {
    id: '530523',
    name: '龙陵县',
    short_name: '龙陵',
    f_id: '530500',
  },
  {
    id: '530524',
    name: '昌宁县',
    short_name: '昌宁',
    f_id: '530500',
  },
  {
    id: '530602',
    name: '昭阳区',
    short_name: '昭阳',
    f_id: '530600',
  },
  {
    id: '530621',
    name: '鲁甸县',
    short_name: '鲁甸',
    f_id: '530600',
  },
  {
    id: '530622',
    name: '巧家县',
    short_name: '巧家',
    f_id: '530600',
  },
  {
    id: '530623',
    name: '盐津县',
    short_name: '盐津',
    f_id: '530600',
  },
  {
    id: '530624',
    name: '大关县',
    short_name: '大关',
    f_id: '530600',
  },
  {
    id: '530625',
    name: '永善县',
    short_name: '永善',
    f_id: '530600',
  },
  {
    id: '530626',
    name: '绥江县',
    short_name: '绥江',
    f_id: '530600',
  },
  {
    id: '530627',
    name: '镇雄县',
    short_name: '镇雄',
    f_id: '530600',
  },
  {
    id: '530628',
    name: '彝良县',
    short_name: '彝良',
    f_id: '530600',
  },
  {
    id: '530629',
    name: '威信县',
    short_name: '威信',
    f_id: '530600',
  },
  {
    id: '530630',
    name: '水富县',
    short_name: '水富',
    f_id: '530600',
  },
  {
    id: '530702',
    name: '古城区',
    short_name: '古城',
    f_id: '530700',
  },
  {
    id: '530721',
    name: '玉龙纳西族自治县',
    short_name: '玉龙',
    f_id: '530700',
  },
  {
    id: '530722',
    name: '永胜县',
    short_name: '永胜',
    f_id: '530700',
  },
  {
    id: '530723',
    name: '华坪县',
    short_name: '华坪',
    f_id: '530700',
  },
  {
    id: '530724',
    name: '宁蒗彝族自治县',
    short_name: '宁蒗',
    f_id: '530700',
  },
  {
    id: '530802',
    name: '思茅区',
    short_name: '思茅',
    f_id: '530800',
  },
  {
    id: '530821',
    name: '宁洱哈尼族彝族自治县',
    short_name: '宁洱',
    f_id: '530800',
  },
  {
    id: '530822',
    name: '墨江哈尼族自治县',
    short_name: '墨江',
    f_id: '530800',
  },
  {
    id: '530823',
    name: '景东彝族自治县',
    short_name: '景东',
    f_id: '530800',
  },
  {
    id: '530824',
    name: '景谷傣族彝族自治县',
    short_name: '景谷',
    f_id: '530800',
  },
  {
    id: '530825',
    name: '镇沅彝族哈尼族拉祜族自治县',
    short_name: '镇沅',
    f_id: '530800',
  },
  {
    id: '530826',
    name: '江城哈尼族彝族自治县',
    short_name: '江城',
    f_id: '530800',
  },
  {
    id: '530827',
    name: '孟连傣族拉祜族佤族自治县',
    short_name: '孟连',
    f_id: '530800',
  },
  {
    id: '530828',
    name: '澜沧拉祜族自治县',
    short_name: '澜沧',
    f_id: '530800',
  },
  {
    id: '530829',
    name: '西盟佤族自治县',
    short_name: '西盟',
    f_id: '530800',
  },
  {
    id: '530902',
    name: '临翔区',
    short_name: '临翔',
    f_id: '530900',
  },
  {
    id: '530921',
    name: '凤庆县',
    short_name: '凤庆',
    f_id: '530900',
  },
  {
    id: '530922',
    name: '云县',
    short_name: '云县',
    f_id: '530900',
  },
  {
    id: '530923',
    name: '永德县',
    short_name: '永德',
    f_id: '530900',
  },
  {
    id: '530924',
    name: '镇康县',
    short_name: '镇康',
    f_id: '530900',
  },
  {
    id: '530925',
    name: '双江拉祜族佤族布朗族傣族自治县',
    short_name: '双江',
    f_id: '530900',
  },
  {
    id: '530926',
    name: '耿马傣族佤族自治县',
    short_name: '耿马',
    f_id: '530900',
  },
  {
    id: '530927',
    name: '沧源佤族自治县',
    short_name: '沧源',
    f_id: '530900',
  },
  {
    id: '532301',
    name: '楚雄市',
    short_name: '楚雄',
    f_id: '532300',
  },
  {
    id: '532322',
    name: '双柏县',
    short_name: '双柏',
    f_id: '532300',
  },
  {
    id: '532323',
    name: '牟定县',
    short_name: '牟定',
    f_id: '532300',
  },
  {
    id: '532324',
    name: '南华县',
    short_name: '南华',
    f_id: '532300',
  },
  {
    id: '532325',
    name: '姚安县',
    short_name: '姚安',
    f_id: '532300',
  },
  {
    id: '532326',
    name: '大姚县',
    short_name: '大姚',
    f_id: '532300',
  },
  {
    id: '532327',
    name: '永仁县',
    short_name: '永仁',
    f_id: '532300',
  },
  {
    id: '532328',
    name: '元谋县',
    short_name: '元谋',
    f_id: '532300',
  },
  {
    id: '532329',
    name: '武定县',
    short_name: '武定',
    f_id: '532300',
  },
  {
    id: '532331',
    name: '禄丰县',
    short_name: '禄丰',
    f_id: '532300',
  },
  {
    id: '532501',
    name: '个旧市',
    short_name: '个旧',
    f_id: '532500',
  },
  {
    id: '532502',
    name: '开远市',
    short_name: '开远',
    f_id: '532500',
  },
  {
    id: '532522',
    name: '蒙自市',
    short_name: '蒙自',
    f_id: '532500',
  },
  {
    id: '532523',
    name: '屏边苗族自治县',
    short_name: '屏边',
    f_id: '532500',
  },
  {
    id: '532524',
    name: '建水县',
    short_name: '建水',
    f_id: '532500',
  },
  {
    id: '532525',
    name: '石屏县',
    short_name: '石屏',
    f_id: '532500',
  },
  {
    id: '532526',
    name: '弥勒市',
    short_name: '弥勒',
    f_id: '532500',
  },
  {
    id: '532527',
    name: '泸西县',
    short_name: '泸西',
    f_id: '532500',
  },
  {
    id: '532528',
    name: '元阳县',
    short_name: '元阳',
    f_id: '532500',
  },
  {
    id: '532529',
    name: '红河县',
    short_name: '红河',
    f_id: '532500',
  },
  {
    id: '532530',
    name: '金平苗族瑶族傣族自治县',
    short_name: '金平',
    f_id: '532500',
  },
  {
    id: '532531',
    name: '绿春县',
    short_name: '绿春',
    f_id: '532500',
  },
  {
    id: '532532',
    name: '河口瑶族自治县',
    short_name: '河口',
    f_id: '532500',
  },
  {
    id: '532621',
    name: '文山市',
    short_name: '文山',
    f_id: '532600',
  },
  {
    id: '532622',
    name: '砚山县',
    short_name: '砚山',
    f_id: '532600',
  },
  {
    id: '532623',
    name: '西畴县',
    short_name: '西畴',
    f_id: '532600',
  },
  {
    id: '532624',
    name: '麻栗坡县',
    short_name: '麻栗坡',
    f_id: '532600',
  },
  {
    id: '532625',
    name: '马关县',
    short_name: '马关',
    f_id: '532600',
  },
  {
    id: '532626',
    name: '丘北县',
    short_name: '丘北',
    f_id: '532600',
  },
  {
    id: '532627',
    name: '广南县',
    short_name: '广南',
    f_id: '532600',
  },
  {
    id: '532628',
    name: '富宁县',
    short_name: '富宁',
    f_id: '532600',
  },
  {
    id: '532801',
    name: '景洪市',
    short_name: '景洪',
    f_id: '532800',
  },
  {
    id: '532822',
    name: '勐海县',
    short_name: '勐海',
    f_id: '532800',
  },
  {
    id: '532823',
    name: '勐腊县',
    short_name: '勐腊',
    f_id: '532800',
  },
  {
    id: '532901',
    name: '大理市',
    short_name: '大理',
    f_id: '532900',
  },
  {
    id: '532922',
    name: '漾濞彝族自治县',
    short_name: '漾濞',
    f_id: '532900',
  },
  {
    id: '532923',
    name: '祥云县',
    short_name: '祥云',
    f_id: '532900',
  },
  {
    id: '532924',
    name: '宾川县',
    short_name: '宾川',
    f_id: '532900',
  },
  {
    id: '532925',
    name: '弥渡县',
    short_name: '弥渡',
    f_id: '532900',
  },
  {
    id: '532926',
    name: '南涧彝族自治县',
    short_name: '南涧',
    f_id: '532900',
  },
  {
    id: '532927',
    name: '巍山彝族回族自治县',
    short_name: '巍山',
    f_id: '532900',
  },
  {
    id: '532928',
    name: '永平县',
    short_name: '永平',
    f_id: '532900',
  },
  {
    id: '532929',
    name: '云龙县',
    short_name: '云龙',
    f_id: '532900',
  },
  {
    id: '532930',
    name: '洱源县',
    short_name: '洱源',
    f_id: '532900',
  },
  {
    id: '532931',
    name: '剑川县',
    short_name: '剑川',
    f_id: '532900',
  },
  {
    id: '532932',
    name: '鹤庆县',
    short_name: '鹤庆',
    f_id: '532900',
  },
  {
    id: '533102',
    name: '瑞丽市',
    short_name: '瑞丽',
    f_id: '533100',
  },
  {
    id: '533103',
    name: '芒市',
    short_name: '芒市',
    f_id: '533100',
  },
  {
    id: '533122',
    name: '梁河县',
    short_name: '梁河',
    f_id: '533100',
  },
  {
    id: '533123',
    name: '盈江县',
    short_name: '盈江',
    f_id: '533100',
  },
  {
    id: '533124',
    name: '陇川县',
    short_name: '陇川',
    f_id: '533100',
  },
  {
    id: '533321',
    name: '泸水县',
    short_name: '泸水',
    f_id: '533300',
  },
  {
    id: '533323',
    name: '福贡县',
    short_name: '福贡',
    f_id: '533300',
  },
  {
    id: '533324',
    name: '贡山独龙族怒族自治县',
    short_name: '贡山',
    f_id: '533300',
  },
  {
    id: '533325',
    name: '兰坪白族普米族自治县',
    short_name: '兰坪',
    f_id: '533300',
  },
  {
    id: '533421',
    name: '香格里拉县',
    short_name: '香格里拉',
    f_id: '533400',
  },
  {
    id: '533422',
    name: '德钦县',
    short_name: '德钦',
    f_id: '533400',
  },
  {
    id: '533423',
    name: '维西傈僳族自治县',
    short_name: '维西',
    f_id: '533400',
  },
  {
    id: '540102',
    name: '城关区',
    short_name: '城关',
    f_id: '540100',
  },
  {
    id: '540121',
    name: '林周县',
    short_name: '林周',
    f_id: '540100',
  },
  {
    id: '540122',
    name: '当雄县',
    short_name: '当雄',
    f_id: '540100',
  },
  {
    id: '540123',
    name: '尼木县',
    short_name: '尼木',
    f_id: '540100',
  },
  {
    id: '540124',
    name: '曲水县',
    short_name: '曲水',
    f_id: '540100',
  },
  {
    id: '540125',
    name: '堆龙德庆县',
    short_name: '堆龙德庆',
    f_id: '540100',
  },
  {
    id: '540126',
    name: '达孜县',
    short_name: '达孜',
    f_id: '540100',
  },
  {
    id: '540127',
    name: '墨竹工卡县',
    short_name: '墨竹工卡',
    f_id: '540100',
  },
  {
    id: '542121',
    name: '昌都县',
    short_name: '昌都',
    f_id: '542100',
  },
  {
    id: '542122',
    name: '江达县',
    short_name: '江达',
    f_id: '542100',
  },
  {
    id: '542123',
    name: '贡觉县',
    short_name: '贡觉',
    f_id: '542100',
  },
  {
    id: '542124',
    name: '类乌齐县',
    short_name: '类乌齐',
    f_id: '542100',
  },
  {
    id: '542125',
    name: '丁青县',
    short_name: '丁青',
    f_id: '542100',
  },
  {
    id: '542126',
    name: '察雅县',
    short_name: '察雅',
    f_id: '542100',
  },
  {
    id: '542127',
    name: '八宿县',
    short_name: '八宿',
    f_id: '542100',
  },
  {
    id: '542128',
    name: '左贡县',
    short_name: '左贡',
    f_id: '542100',
  },
  {
    id: '542129',
    name: '芒康县',
    short_name: '芒康',
    f_id: '542100',
  },
  {
    id: '542132',
    name: '洛隆县',
    short_name: '洛隆',
    f_id: '542100',
  },
  {
    id: '542133',
    name: '边坝县',
    short_name: '边坝',
    f_id: '542100',
  },
  {
    id: '542221',
    name: '乃东县',
    short_name: '乃东',
    f_id: '542200',
  },
  {
    id: '542222',
    name: '扎囊县',
    short_name: '扎囊',
    f_id: '542200',
  },
  {
    id: '542223',
    name: '贡嘎县',
    short_name: '贡嘎',
    f_id: '542200',
  },
  {
    id: '542224',
    name: '桑日县',
    short_name: '桑日',
    f_id: '542200',
  },
  {
    id: '542225',
    name: '琼结县',
    short_name: '琼结',
    f_id: '542200',
  },
  {
    id: '542226',
    name: '曲松县',
    short_name: '曲松',
    f_id: '542200',
  },
  {
    id: '542227',
    name: '措美县',
    short_name: '措美',
    f_id: '542200',
  },
  {
    id: '542228',
    name: '洛扎县',
    short_name: '洛扎',
    f_id: '542200',
  },
  {
    id: '542229',
    name: '加查县',
    short_name: '加查',
    f_id: '542200',
  },
  {
    id: '542231',
    name: '隆子县',
    short_name: '隆子',
    f_id: '542200',
  },
  {
    id: '542232',
    name: '错那县',
    short_name: '错那',
    f_id: '542200',
  },
  {
    id: '542233',
    name: '浪卡子县',
    short_name: '浪卡子',
    f_id: '542200',
  },
  {
    id: '542301',
    name: '日喀则市',
    short_name: '日喀则',
    f_id: '542300',
  },
  {
    id: '542322',
    name: '南木林县',
    short_name: '南木林',
    f_id: '542300',
  },
  {
    id: '542323',
    name: '江孜县',
    short_name: '江孜',
    f_id: '542300',
  },
  {
    id: '542324',
    name: '定日县',
    short_name: '定日',
    f_id: '542300',
  },
  {
    id: '542325',
    name: '萨迦县',
    short_name: '萨迦',
    f_id: '542300',
  },
  {
    id: '542326',
    name: '拉孜县',
    short_name: '拉孜',
    f_id: '542300',
  },
  {
    id: '542327',
    name: '昂仁县',
    short_name: '昂仁',
    f_id: '542300',
  },
  {
    id: '542328',
    name: '谢通门县',
    short_name: '谢通门',
    f_id: '542300',
  },
  {
    id: '542329',
    name: '白朗县',
    short_name: '白朗',
    f_id: '542300',
  },
  {
    id: '542330',
    name: '仁布县',
    short_name: '仁布',
    f_id: '542300',
  },
  {
    id: '542331',
    name: '康马县',
    short_name: '康马',
    f_id: '542300',
  },
  {
    id: '542332',
    name: '定结县',
    short_name: '定结',
    f_id: '542300',
  },
  {
    id: '542333',
    name: '仲巴县',
    short_name: '仲巴',
    f_id: '542300',
  },
  {
    id: '542334',
    name: '亚东县',
    short_name: '亚东',
    f_id: '542300',
  },
  {
    id: '542335',
    name: '吉隆县',
    short_name: '吉隆',
    f_id: '542300',
  },
  {
    id: '542336',
    name: '聂拉木县',
    short_name: '聂拉木',
    f_id: '542300',
  },
  {
    id: '542337',
    name: '萨嘎县',
    short_name: '萨嘎',
    f_id: '542300',
  },
  {
    id: '542338',
    name: '岗巴县',
    short_name: '岗巴',
    f_id: '542300',
  },
  {
    id: '542421',
    name: '那曲县',
    short_name: '那曲',
    f_id: '542400',
  },
  {
    id: '542422',
    name: '嘉黎县',
    short_name: '嘉黎',
    f_id: '542400',
  },
  {
    id: '542423',
    name: '比如县',
    short_name: '比如',
    f_id: '542400',
  },
  {
    id: '542424',
    name: '聂荣县',
    short_name: '聂荣',
    f_id: '542400',
  },
  {
    id: '542425',
    name: '安多县',
    short_name: '安多',
    f_id: '542400',
  },
  {
    id: '542426',
    name: '申扎县',
    short_name: '申扎',
    f_id: '542400',
  },
  {
    id: '542427',
    name: '索县',
    short_name: '索县',
    f_id: '542400',
  },
  {
    id: '542428',
    name: '班戈县',
    short_name: '班戈',
    f_id: '542400',
  },
  {
    id: '542429',
    name: '巴青县',
    short_name: '巴青',
    f_id: '542400',
  },
  {
    id: '542430',
    name: '尼玛县',
    short_name: '尼玛',
    f_id: '542400',
  },
  {
    id: '542432',
    name: '双湖县',
    short_name: '双湖',
    f_id: '542400',
  },
  {
    id: '542521',
    name: '普兰县',
    short_name: '普兰',
    f_id: '542500',
  },
  {
    id: '542522',
    name: '札达县',
    short_name: '札达',
    f_id: '542500',
  },
  {
    id: '542523',
    name: '噶尔县',
    short_name: '噶尔',
    f_id: '542500',
  },
  {
    id: '542524',
    name: '日土县',
    short_name: '日土',
    f_id: '542500',
  },
  {
    id: '542525',
    name: '革吉县',
    short_name: '革吉',
    f_id: '542500',
  },
  {
    id: '542526',
    name: '改则县',
    short_name: '改则',
    f_id: '542500',
  },
  {
    id: '542527',
    name: '措勤县',
    short_name: '措勤',
    f_id: '542500',
  },
  {
    id: '542621',
    name: '林芝县',
    short_name: '林芝',
    f_id: '542600',
  },
  {
    id: '542622',
    name: '工布江达县',
    short_name: '工布江达',
    f_id: '542600',
  },
  {
    id: '542623',
    name: '米林县',
    short_name: '米林',
    f_id: '542600',
  },
  {
    id: '542624',
    name: '墨脱县',
    short_name: '墨脱',
    f_id: '542600',
  },
  {
    id: '542625',
    name: '波密县',
    short_name: '波密',
    f_id: '542600',
  },
  {
    id: '542626',
    name: '察隅县',
    short_name: '察隅',
    f_id: '542600',
  },
  {
    id: '542627',
    name: '朗县',
    short_name: '朗县',
    f_id: '542600',
  },
  {
    id: '610102',
    name: '新城区',
    short_name: '新城',
    f_id: '610100',
  },
  {
    id: '610103',
    name: '碑林区',
    short_name: '碑林',
    f_id: '610100',
  },
  {
    id: '610104',
    name: '莲湖区',
    short_name: '莲湖',
    f_id: '610100',
  },
  {
    id: '610111',
    name: '灞桥区',
    short_name: '灞桥',
    f_id: '610100',
  },
  {
    id: '610112',
    name: '未央区',
    short_name: '未央',
    f_id: '610100',
  },
  {
    id: '610113',
    name: '雁塔区',
    short_name: '雁塔',
    f_id: '610100',
  },
  {
    id: '610114',
    name: '阎良区',
    short_name: '阎良',
    f_id: '610100',
  },
  {
    id: '610115',
    name: '临潼区',
    short_name: '临潼',
    f_id: '610100',
  },
  {
    id: '610116',
    name: '长安区',
    short_name: '长安',
    f_id: '610100',
  },
  {
    id: '610122',
    name: '蓝田县',
    short_name: '蓝田',
    f_id: '610100',
  },
  {
    id: '610124',
    name: '周至县',
    short_name: '周至',
    f_id: '610100',
  },
  {
    id: '610125',
    name: '户县',
    short_name: '户县',
    f_id: '610100',
  },
  {
    id: '610126',
    name: '高陵县',
    short_name: '高陵',
    f_id: '610100',
  },
  {
    id: '610202',
    name: '王益区',
    short_name: '王益',
    f_id: '610200',
  },
  {
    id: '610203',
    name: '印台区',
    short_name: '印台',
    f_id: '610200',
  },
  {
    id: '610204',
    name: '耀州区',
    short_name: '耀州',
    f_id: '610200',
  },
  {
    id: '610222',
    name: '宜君县',
    short_name: '宜君',
    f_id: '610200',
  },
  {
    id: '610302',
    name: '渭滨区',
    short_name: '渭滨',
    f_id: '610300',
  },
  {
    id: '610303',
    name: '金台区',
    short_name: '金台',
    f_id: '610300',
  },
  {
    id: '610304',
    name: '陈仓区',
    short_name: '陈仓',
    f_id: '610300',
  },
  {
    id: '610322',
    name: '凤翔县',
    short_name: '凤翔',
    f_id: '610300',
  },
  {
    id: '610323',
    name: '岐山县',
    short_name: '岐山',
    f_id: '610300',
  },
  {
    id: '610324',
    name: '扶风县',
    short_name: '扶风',
    f_id: '610300',
  },
  {
    id: '610326',
    name: '眉县',
    short_name: '眉县',
    f_id: '610300',
  },
  {
    id: '610327',
    name: '陇县',
    short_name: '陇县',
    f_id: '610300',
  },
  {
    id: '610328',
    name: '千阳县',
    short_name: '千阳',
    f_id: '610300',
  },
  {
    id: '610329',
    name: '麟游县',
    short_name: '麟游',
    f_id: '610300',
  },
  {
    id: '610330',
    name: '凤县',
    short_name: '凤县',
    f_id: '610300',
  },
  {
    id: '610331',
    name: '太白县',
    short_name: '太白',
    f_id: '610300',
  },
  {
    id: '610402',
    name: '秦都区',
    short_name: '秦都',
    f_id: '610400',
  },
  {
    id: '610403',
    name: '杨陵区',
    short_name: '杨陵',
    f_id: '610400',
  },
  {
    id: '610404',
    name: '渭城区',
    short_name: '渭城',
    f_id: '610400',
  },
  {
    id: '610422',
    name: '三原县',
    short_name: '三原',
    f_id: '610400',
  },
  {
    id: '610423',
    name: '泾阳县',
    short_name: '泾阳',
    f_id: '610400',
  },
  {
    id: '610424',
    name: '乾县',
    short_name: '乾县',
    f_id: '610400',
  },
  {
    id: '610425',
    name: '礼泉县',
    short_name: '礼泉',
    f_id: '610400',
  },
  {
    id: '610426',
    name: '永寿县',
    short_name: '永寿',
    f_id: '610400',
  },
  {
    id: '610427',
    name: '彬县',
    short_name: '彬县',
    f_id: '610400',
  },
  {
    id: '610428',
    name: '长武县',
    short_name: '长武',
    f_id: '610400',
  },
  {
    id: '610429',
    name: '旬邑县',
    short_name: '旬邑',
    f_id: '610400',
  },
  {
    id: '610430',
    name: '淳化县',
    short_name: '淳化',
    f_id: '610400',
  },
  {
    id: '610431',
    name: '武功县',
    short_name: '武功',
    f_id: '610400',
  },
  {
    id: '610481',
    name: '兴平市',
    short_name: '兴平',
    f_id: '610400',
  },
  {
    id: '610502',
    name: '临渭区',
    short_name: '临渭',
    f_id: '610500',
  },
  {
    id: '610521',
    name: '华县',
    short_name: '华县',
    f_id: '610500',
  },
  {
    id: '610522',
    name: '潼关县',
    short_name: '潼关',
    f_id: '610500',
  },
  {
    id: '610523',
    name: '大荔县',
    short_name: '大荔',
    f_id: '610500',
  },
  {
    id: '610524',
    name: '合阳县',
    short_name: '合阳',
    f_id: '610500',
  },
  {
    id: '610525',
    name: '澄城县',
    short_name: '澄城',
    f_id: '610500',
  },
  {
    id: '610526',
    name: '蒲城县',
    short_name: '蒲城',
    f_id: '610500',
  },
  {
    id: '610527',
    name: '白水县',
    short_name: '白水',
    f_id: '610500',
  },
  {
    id: '610528',
    name: '富平县',
    short_name: '富平',
    f_id: '610500',
  },
  {
    id: '610581',
    name: '韩城市',
    short_name: '韩城',
    f_id: '610500',
  },
  {
    id: '610582',
    name: '华阴市',
    short_name: '华阴',
    f_id: '610500',
  },
  {
    id: '610602',
    name: '宝塔区',
    short_name: '宝塔',
    f_id: '610600',
  },
  {
    id: '610621',
    name: '延长县',
    short_name: '延长',
    f_id: '610600',
  },
  {
    id: '610622',
    name: '延川县',
    short_name: '延川',
    f_id: '610600',
  },
  {
    id: '610623',
    name: '子长县',
    short_name: '子长',
    f_id: '610600',
  },
  {
    id: '610624',
    name: '安塞县',
    short_name: '安塞',
    f_id: '610600',
  },
  {
    id: '610625',
    name: '志丹县',
    short_name: '志丹',
    f_id: '610600',
  },
  {
    id: '610626',
    name: '吴起县',
    short_name: '吴起',
    f_id: '610600',
  },
  {
    id: '610627',
    name: '甘泉县',
    short_name: '甘泉',
    f_id: '610600',
  },
  {
    id: '610628',
    name: '富县',
    short_name: '富县',
    f_id: '610600',
  },
  {
    id: '610629',
    name: '洛川县',
    short_name: '洛川',
    f_id: '610600',
  },
  {
    id: '610630',
    name: '宜川县',
    short_name: '宜川',
    f_id: '610600',
  },
  {
    id: '610631',
    name: '黄龙县',
    short_name: '黄龙',
    f_id: '610600',
  },
  {
    id: '610632',
    name: '黄陵县',
    short_name: '黄陵',
    f_id: '610600',
  },
  {
    id: '610702',
    name: '汉台区',
    short_name: '汉台',
    f_id: '610700',
  },
  {
    id: '610721',
    name: '南郑县',
    short_name: '南郑',
    f_id: '610700',
  },
  {
    id: '610722',
    name: '城固县',
    short_name: '城固',
    f_id: '610700',
  },
  {
    id: '610723',
    name: '洋县',
    short_name: '洋县',
    f_id: '610700',
  },
  {
    id: '610724',
    name: '西乡县',
    short_name: '西乡',
    f_id: '610700',
  },
  {
    id: '610725',
    name: '勉县',
    short_name: '勉县',
    f_id: '610700',
  },
  {
    id: '610726',
    name: '宁强县',
    short_name: '宁强',
    f_id: '610700',
  },
  {
    id: '610727',
    name: '略阳县',
    short_name: '略阳',
    f_id: '610700',
  },
  {
    id: '610728',
    name: '镇巴县',
    short_name: '镇巴',
    f_id: '610700',
  },
  {
    id: '610729',
    name: '留坝县',
    short_name: '留坝',
    f_id: '610700',
  },
  {
    id: '610730',
    name: '佛坪县',
    short_name: '佛坪',
    f_id: '610700',
  },
  {
    id: '610802',
    name: '榆阳区',
    short_name: '榆阳',
    f_id: '610800',
  },
  {
    id: '610821',
    name: '神木县',
    short_name: '神木',
    f_id: '610800',
  },
  {
    id: '610822',
    name: '府谷县',
    short_name: '府谷',
    f_id: '610800',
  },
  {
    id: '610823',
    name: '横山县',
    short_name: '横山',
    f_id: '610800',
  },
  {
    id: '610824',
    name: '靖边县',
    short_name: '靖边',
    f_id: '610800',
  },
  {
    id: '610825',
    name: '定边县',
    short_name: '定边',
    f_id: '610800',
  },
  {
    id: '610826',
    name: '绥德县',
    short_name: '绥德',
    f_id: '610800',
  },
  {
    id: '610827',
    name: '米脂县',
    short_name: '米脂',
    f_id: '610800',
  },
  {
    id: '610828',
    name: '佳县',
    short_name: '佳县',
    f_id: '610800',
  },
  {
    id: '610829',
    name: '吴堡县',
    short_name: '吴堡',
    f_id: '610800',
  },
  {
    id: '610830',
    name: '清涧县',
    short_name: '清涧',
    f_id: '610800',
  },
  {
    id: '610831',
    name: '子洲县',
    short_name: '子洲',
    f_id: '610800',
  },
  {
    id: '610902',
    name: '汉滨区',
    short_name: '汉滨',
    f_id: '610900',
  },
  {
    id: '610921',
    name: '汉阴县',
    short_name: '汉阴',
    f_id: '610900',
  },
  {
    id: '610922',
    name: '石泉县',
    short_name: '石泉',
    f_id: '610900',
  },
  {
    id: '610923',
    name: '宁陕县',
    short_name: '宁陕',
    f_id: '610900',
  },
  {
    id: '610924',
    name: '紫阳县',
    short_name: '紫阳',
    f_id: '610900',
  },
  {
    id: '610925',
    name: '岚皋县',
    short_name: '岚皋',
    f_id: '610900',
  },
  {
    id: '610926',
    name: '平利县',
    short_name: '平利',
    f_id: '610900',
  },
  {
    id: '610927',
    name: '镇坪县',
    short_name: '镇坪',
    f_id: '610900',
  },
  {
    id: '610928',
    name: '旬阳县',
    short_name: '旬阳',
    f_id: '610900',
  },
  {
    id: '610929',
    name: '白河县',
    short_name: '白河',
    f_id: '610900',
  },
  {
    id: '611002',
    name: '商州区',
    short_name: '商州',
    f_id: '611000',
  },
  {
    id: '611021',
    name: '洛南县',
    short_name: '洛南',
    f_id: '611000',
  },
  {
    id: '611022',
    name: '丹凤县',
    short_name: '丹凤',
    f_id: '611000',
  },
  {
    id: '611023',
    name: '商南县',
    short_name: '商南',
    f_id: '611000',
  },
  {
    id: '611024',
    name: '山阳县',
    short_name: '山阳',
    f_id: '611000',
  },
  {
    id: '611025',
    name: '镇安县',
    short_name: '镇安',
    f_id: '611000',
  },
  {
    id: '611026',
    name: '柞水县',
    short_name: '柞水',
    f_id: '611000',
  },
  {
    id: '620102',
    name: '城关区',
    short_name: '城关',
    f_id: '620100',
  },
  {
    id: '620103',
    name: '七里河区',
    short_name: '七里河',
    f_id: '620100',
  },
  {
    id: '620104',
    name: '西固区',
    short_name: '西固',
    f_id: '620100',
  },
  {
    id: '620105',
    name: '安宁区',
    short_name: '安宁',
    f_id: '620100',
  },
  {
    id: '620111',
    name: '红古区',
    short_name: '红古',
    f_id: '620100',
  },
  {
    id: '620121',
    name: '永登县',
    short_name: '永登',
    f_id: '620100',
  },
  {
    id: '620122',
    name: '皋兰县',
    short_name: '皋兰',
    f_id: '620100',
  },
  {
    id: '620123',
    name: '榆中县',
    short_name: '榆中',
    f_id: '620100',
  },
  {
    id: '620302',
    name: '金川区',
    short_name: '金川',
    f_id: '620300',
  },
  {
    id: '620321',
    name: '永昌县',
    short_name: '永昌',
    f_id: '620300',
  },
  {
    id: '620402',
    name: '白银区',
    short_name: '白银',
    f_id: '620400',
  },
  {
    id: '620403',
    name: '平川区',
    short_name: '平川',
    f_id: '620400',
  },
  {
    id: '620421',
    name: '靖远县',
    short_name: '靖远',
    f_id: '620400',
  },
  {
    id: '620422',
    name: '会宁县',
    short_name: '会宁',
    f_id: '620400',
  },
  {
    id: '620423',
    name: '景泰县',
    short_name: '景泰',
    f_id: '620400',
  },
  {
    id: '620502',
    name: '秦州区',
    short_name: '秦州',
    f_id: '620500',
  },
  {
    id: '620503',
    name: '麦积区',
    short_name: '麦积',
    f_id: '620500',
  },
  {
    id: '620521',
    name: '清水县',
    short_name: '清水',
    f_id: '620500',
  },
  {
    id: '620522',
    name: '秦安县',
    short_name: '秦安',
    f_id: '620500',
  },
  {
    id: '620523',
    name: '甘谷县',
    short_name: '甘谷',
    f_id: '620500',
  },
  {
    id: '620524',
    name: '武山县',
    short_name: '武山',
    f_id: '620500',
  },
  {
    id: '620525',
    name: '张家川回族自治县',
    short_name: '张家川',
    f_id: '620500',
  },
  {
    id: '620602',
    name: '凉州区',
    short_name: '凉州',
    f_id: '620600',
  },
  {
    id: '620621',
    name: '民勤县',
    short_name: '民勤',
    f_id: '620600',
  },
  {
    id: '620622',
    name: '古浪县',
    short_name: '古浪',
    f_id: '620600',
  },
  {
    id: '620623',
    name: '天祝藏族自治县',
    short_name: '天祝',
    f_id: '620600',
  },
  {
    id: '620702',
    name: '甘州区',
    short_name: '甘州',
    f_id: '620700',
  },
  {
    id: '620721',
    name: '肃南裕固族自治县',
    short_name: '肃南',
    f_id: '620700',
  },
  {
    id: '620722',
    name: '民乐县',
    short_name: '民乐',
    f_id: '620700',
  },
  {
    id: '620723',
    name: '临泽县',
    short_name: '临泽',
    f_id: '620700',
  },
  {
    id: '620724',
    name: '高台县',
    short_name: '高台',
    f_id: '620700',
  },
  {
    id: '620725',
    name: '山丹县',
    short_name: '山丹',
    f_id: '620700',
  },
  {
    id: '620802',
    name: '崆峒区',
    short_name: '崆峒',
    f_id: '620800',
  },
  {
    id: '620821',
    name: '泾川县',
    short_name: '泾川',
    f_id: '620800',
  },
  {
    id: '620822',
    name: '灵台县',
    short_name: '灵台',
    f_id: '620800',
  },
  {
    id: '620823',
    name: '崇信县',
    short_name: '崇信',
    f_id: '620800',
  },
  {
    id: '620824',
    name: '华亭县',
    short_name: '华亭',
    f_id: '620800',
  },
  {
    id: '620825',
    name: '庄浪县',
    short_name: '庄浪',
    f_id: '620800',
  },
  {
    id: '620826',
    name: '静宁县',
    short_name: '静宁',
    f_id: '620800',
  },
  {
    id: '620902',
    name: '肃州区',
    short_name: '肃州',
    f_id: '620900',
  },
  {
    id: '620921',
    name: '金塔县',
    short_name: '金塔',
    f_id: '620900',
  },
  {
    id: '620922',
    name: '瓜州县',
    short_name: '瓜州',
    f_id: '620900',
  },
  {
    id: '620923',
    name: '肃北蒙古族自治县',
    short_name: '肃北',
    f_id: '620900',
  },
  {
    id: '620924',
    name: '阿克塞哈萨克族自治县',
    short_name: '阿克塞',
    f_id: '620900',
  },
  {
    id: '620981',
    name: '玉门市',
    short_name: '玉门',
    f_id: '620900',
  },
  {
    id: '620982',
    name: '敦煌市',
    short_name: '敦煌',
    f_id: '620900',
  },
  {
    id: '621002',
    name: '西峰区',
    short_name: '西峰',
    f_id: '621000',
  },
  {
    id: '621021',
    name: '庆城县',
    short_name: '庆城',
    f_id: '621000',
  },
  {
    id: '621022',
    name: '环县',
    short_name: '环县',
    f_id: '621000',
  },
  {
    id: '621023',
    name: '华池县',
    short_name: '华池',
    f_id: '621000',
  },
  {
    id: '621024',
    name: '合水县',
    short_name: '合水',
    f_id: '621000',
  },
  {
    id: '621025',
    name: '正宁县',
    short_name: '正宁',
    f_id: '621000',
  },
  {
    id: '621026',
    name: '宁县',
    short_name: '宁县',
    f_id: '621000',
  },
  {
    id: '621027',
    name: '镇原县',
    short_name: '镇原',
    f_id: '621000',
  },
  {
    id: '621102',
    name: '安定区',
    short_name: '安定',
    f_id: '621100',
  },
  {
    id: '621121',
    name: '通渭县',
    short_name: '通渭',
    f_id: '621100',
  },
  {
    id: '621122',
    name: '陇西县',
    short_name: '陇西',
    f_id: '621100',
  },
  {
    id: '621123',
    name: '渭源县',
    short_name: '渭源',
    f_id: '621100',
  },
  {
    id: '621124',
    name: '临洮县',
    short_name: '临洮',
    f_id: '621100',
  },
  {
    id: '621125',
    name: '漳县',
    short_name: '漳县',
    f_id: '621100',
  },
  {
    id: '621126',
    name: '岷县',
    short_name: '岷县',
    f_id: '621100',
  },
  {
    id: '621202',
    name: '武都区',
    short_name: '武都',
    f_id: '621200',
  },
  {
    id: '621221',
    name: '成县',
    short_name: '成县',
    f_id: '621200',
  },
  {
    id: '621222',
    name: '文县',
    short_name: '文县',
    f_id: '621200',
  },
  {
    id: '621223',
    name: '宕昌县',
    short_name: '宕昌',
    f_id: '621200',
  },
  {
    id: '621224',
    name: '康县',
    short_name: '康县',
    f_id: '621200',
  },
  {
    id: '621225',
    name: '西和县',
    short_name: '西和',
    f_id: '621200',
  },
  {
    id: '621226',
    name: '礼县',
    short_name: '礼县',
    f_id: '621200',
  },
  {
    id: '621227',
    name: '徽县',
    short_name: '徽县',
    f_id: '621200',
  },
  {
    id: '621228',
    name: '两当县',
    short_name: '两当',
    f_id: '621200',
  },
  {
    id: '622901',
    name: '临夏市',
    short_name: '临夏市',
    f_id: '622900',
  },
  {
    id: '622921',
    name: '临夏县',
    short_name: '临夏县',
    f_id: '622900',
  },
  {
    id: '622922',
    name: '康乐县',
    short_name: '康乐',
    f_id: '622900',
  },
  {
    id: '622923',
    name: '永靖县',
    short_name: '永靖',
    f_id: '622900',
  },
  {
    id: '622924',
    name: '广河县',
    short_name: '广河',
    f_id: '622900',
  },
  {
    id: '622925',
    name: '和政县',
    short_name: '和政',
    f_id: '622900',
  },
  {
    id: '622926',
    name: '东乡族自治县',
    short_name: '东乡',
    f_id: '622900',
  },
  {
    id: '622927',
    name: '积石山保安族东乡族撒拉族自治县',
    short_name: '积石山',
    f_id: '622900',
  },
  {
    id: '623001',
    name: '合作市',
    short_name: '合作',
    f_id: '623000',
  },
  {
    id: '623021',
    name: '临潭县',
    short_name: '临潭',
    f_id: '623000',
  },
  {
    id: '623022',
    name: '卓尼县',
    short_name: '卓尼',
    f_id: '623000',
  },
  {
    id: '623023',
    name: '舟曲县',
    short_name: '舟曲',
    f_id: '623000',
  },
  {
    id: '623024',
    name: '迭部县',
    short_name: '迭部',
    f_id: '623000',
  },
  {
    id: '623025',
    name: '玛曲县',
    short_name: '玛曲',
    f_id: '623000',
  },
  {
    id: '623026',
    name: '碌曲县',
    short_name: '碌曲',
    f_id: '623000',
  },
  {
    id: '623027',
    name: '夏河县',
    short_name: '夏河',
    f_id: '623000',
  },
  {
    id: '630102',
    name: '城东区',
    short_name: '城东',
    f_id: '630100',
  },
  {
    id: '630103',
    name: '城中区',
    short_name: '城中',
    f_id: '630100',
  },
  {
    id: '630104',
    name: '城西区',
    short_name: '城西',
    f_id: '630100',
  },
  {
    id: '630105',
    name: '城北区',
    short_name: '城北',
    f_id: '630100',
  },
  {
    id: '630121',
    name: '大通回族土族自治县',
    short_name: '大通',
    f_id: '630100',
  },
  {
    id: '630122',
    name: '湟中县',
    short_name: '湟中',
    f_id: '630100',
  },
  {
    id: '630123',
    name: '湟源县',
    short_name: '湟源',
    f_id: '630100',
  },
  {
    id: '632121',
    name: '平安县',
    short_name: '平安',
    f_id: '632100',
  },
  {
    id: '632122',
    name: '民和回族土族自治县',
    short_name: '民和',
    f_id: '632100',
  },
  {
    id: '632123',
    name: '乐都区',
    short_name: '乐都',
    f_id: '632100',
  },
  {
    id: '632126',
    name: '互助土族自治县',
    short_name: '互助',
    f_id: '632100',
  },
  {
    id: '632127',
    name: '化隆回族自治县',
    short_name: '化隆',
    f_id: '632100',
  },
  {
    id: '632128',
    name: '循化撒拉族自治县',
    short_name: '循化',
    f_id: '632100',
  },
  {
    id: '632221',
    name: '门源回族自治县',
    short_name: '门源',
    f_id: '632200',
  },
  {
    id: '632222',
    name: '祁连县',
    short_name: '祁连',
    f_id: '632200',
  },
  {
    id: '632223',
    name: '海晏县',
    short_name: '海晏',
    f_id: '632200',
  },
  {
    id: '632224',
    name: '刚察县',
    short_name: '刚察',
    f_id: '632200',
  },
  {
    id: '632321',
    name: '同仁县',
    short_name: '同仁',
    f_id: '632300',
  },
  {
    id: '632322',
    name: '尖扎县',
    short_name: '尖扎',
    f_id: '632300',
  },
  {
    id: '632323',
    name: '泽库县',
    short_name: '泽库',
    f_id: '632300',
  },
  {
    id: '632324',
    name: '河南蒙古族自治县',
    short_name: '河南',
    f_id: '632300',
  },
  {
    id: '632521',
    name: '共和县',
    short_name: '共和',
    f_id: '632500',
  },
  {
    id: '632522',
    name: '同德县',
    short_name: '同德',
    f_id: '632500',
  },
  {
    id: '632523',
    name: '贵德县',
    short_name: '贵德',
    f_id: '632500',
  },
  {
    id: '632524',
    name: '兴海县',
    short_name: '兴海',
    f_id: '632500',
  },
  {
    id: '632525',
    name: '贵南县',
    short_name: '贵南',
    f_id: '632500',
  },
  {
    id: '632621',
    name: '玛沁县',
    short_name: '玛沁',
    f_id: '632600',
  },
  {
    id: '632622',
    name: '班玛县',
    short_name: '班玛',
    f_id: '632600',
  },
  {
    id: '632623',
    name: '甘德县',
    short_name: '甘德',
    f_id: '632600',
  },
  {
    id: '632624',
    name: '达日县',
    short_name: '达日',
    f_id: '632600',
  },
  {
    id: '632625',
    name: '久治县',
    short_name: '久治',
    f_id: '632600',
  },
  {
    id: '632626',
    name: '玛多县',
    short_name: '玛多',
    f_id: '632600',
  },
  {
    id: '632721',
    name: '玉树市',
    short_name: '玉树',
    f_id: '632700',
  },
  {
    id: '632722',
    name: '杂多县',
    short_name: '杂多',
    f_id: '632700',
  },
  {
    id: '632723',
    name: '称多县',
    short_name: '称多',
    f_id: '632700',
  },
  {
    id: '632724',
    name: '治多县',
    short_name: '治多',
    f_id: '632700',
  },
  {
    id: '632725',
    name: '囊谦县',
    short_name: '囊谦',
    f_id: '632700',
  },
  {
    id: '632726',
    name: '曲麻莱县',
    short_name: '曲麻莱',
    f_id: '632700',
  },
  {
    id: '632801',
    name: '格尔木市',
    short_name: '格尔木',
    f_id: '632800',
  },
  {
    id: '632802',
    name: '德令哈市',
    short_name: '德令哈',
    f_id: '632800',
  },
  {
    id: '632821',
    name: '乌兰县',
    short_name: '乌兰',
    f_id: '632800',
  },
  {
    id: '632822',
    name: '都兰县',
    short_name: '都兰',
    f_id: '632800',
  },
  {
    id: '632823',
    name: '天峻县',
    short_name: '天峻',
    f_id: '632800',
  },
  {
    id: '640104',
    name: '兴庆区',
    short_name: '兴庆',
    f_id: '640100',
  },
  {
    id: '640105',
    name: '西夏区',
    short_name: '西夏',
    f_id: '640100',
  },
  {
    id: '640106',
    name: '金凤区',
    short_name: '金凤',
    f_id: '640100',
  },
  {
    id: '640121',
    name: '永宁县',
    short_name: '永宁',
    f_id: '640100',
  },
  {
    id: '640122',
    name: '贺兰县',
    short_name: '贺兰',
    f_id: '640100',
  },
  {
    id: '640181',
    name: '灵武市',
    short_name: '灵武',
    f_id: '640100',
  },
  {
    id: '640202',
    name: '大武口区',
    short_name: '大武口',
    f_id: '640200',
  },
  {
    id: '640205',
    name: '惠农区',
    short_name: '惠农',
    f_id: '640200',
  },
  {
    id: '640221',
    name: '平罗县',
    short_name: '平罗',
    f_id: '640200',
  },
  {
    id: '640302',
    name: '利通区',
    short_name: '利通',
    f_id: '640300',
  },
  {
    id: '640303',
    name: '红寺堡区',
    short_name: '红寺堡',
    f_id: '640300',
  },
  {
    id: '640323',
    name: '盐池县',
    short_name: '盐池',
    f_id: '640300',
  },
  {
    id: '640324',
    name: '同心县',
    short_name: '同心',
    f_id: '640300',
  },
  {
    id: '640381',
    name: '青铜峡市',
    short_name: '青铜峡',
    f_id: '640300',
  },
  {
    id: '640402',
    name: '原州区',
    short_name: '原州',
    f_id: '640400',
  },
  {
    id: '640422',
    name: '西吉县',
    short_name: '西吉',
    f_id: '640400',
  },
  {
    id: '640423',
    name: '隆德县',
    short_name: '隆德',
    f_id: '640400',
  },
  {
    id: '640424',
    name: '泾源县',
    short_name: '泾源',
    f_id: '640400',
  },
  {
    id: '640425',
    name: '彭阳县',
    short_name: '彭阳',
    f_id: '640400',
  },
  {
    id: '640502',
    name: '沙坡头区',
    short_name: '沙坡头',
    f_id: '640500',
  },
  {
    id: '640521',
    name: '中宁县',
    short_name: '中宁',
    f_id: '640500',
  },
  {
    id: '640522',
    name: '海原县',
    short_name: '海原',
    f_id: '640500',
  },
  {
    id: '650102',
    name: '天山区',
    short_name: '天山',
    f_id: '650100',
  },
  {
    id: '650103',
    name: '沙依巴克区',
    short_name: '沙依巴克',
    f_id: '650100',
  },
  {
    id: '650104',
    name: '新市区',
    short_name: '新市',
    f_id: '650100',
  },
  {
    id: '650105',
    name: '水磨沟区',
    short_name: '水磨沟',
    f_id: '650100',
  },
  {
    id: '650106',
    name: '头屯河区',
    short_name: '头屯河',
    f_id: '650100',
  },
  {
    id: '650107',
    name: '达坂城区',
    short_name: '达坂城',
    f_id: '650100',
  },
  {
    id: '650109',
    name: '米东区',
    short_name: '米东',
    f_id: '650100',
  },
  {
    id: '650121',
    name: '乌鲁木齐县',
    short_name: '乌鲁木齐',
    f_id: '650100',
  },
  {
    id: '650202',
    name: '独山子区',
    short_name: '独山子',
    f_id: '650200',
  },
  {
    id: '650203',
    name: '克拉玛依区',
    short_name: '克拉玛依',
    f_id: '650200',
  },
  {
    id: '650204',
    name: '白碱滩区',
    short_name: '白碱滩',
    f_id: '650200',
  },
  {
    id: '650205',
    name: '乌尔禾区',
    short_name: '乌尔禾',
    f_id: '650200',
  },
  {
    id: '652101',
    name: '吐鲁番市',
    short_name: '吐鲁番',
    f_id: '652100',
  },
  {
    id: '652122',
    name: '鄯善县',
    short_name: '鄯善',
    f_id: '652100',
  },
  {
    id: '652123',
    name: '托克逊县',
    short_name: '托克逊',
    f_id: '652100',
  },
  {
    id: '652201',
    name: '哈密市',
    short_name: '哈密',
    f_id: '652200',
  },
  {
    id: '652222',
    name: '巴里坤哈萨克自治县',
    short_name: '巴里坤',
    f_id: '652200',
  },
  {
    id: '652223',
    name: '伊吾县',
    short_name: '伊吾',
    f_id: '652200',
  },
  {
    id: '652301',
    name: '昌吉市',
    short_name: '昌吉',
    f_id: '652300',
  },
  {
    id: '652302',
    name: '阜康市',
    short_name: '阜康',
    f_id: '652300',
  },
  {
    id: '652323',
    name: '呼图壁县',
    short_name: '呼图壁',
    f_id: '652300',
  },
  {
    id: '652324',
    name: '玛纳斯县',
    short_name: '玛纳斯',
    f_id: '652300',
  },
  {
    id: '652325',
    name: '奇台县',
    short_name: '奇台',
    f_id: '652300',
  },
  {
    id: '652327',
    name: '吉木萨尔县',
    short_name: '吉木萨尔',
    f_id: '652300',
  },
  {
    id: '652328',
    name: '木垒哈萨克自治县',
    short_name: '木垒',
    f_id: '652300',
  },
  {
    id: '652701',
    name: '博乐市',
    short_name: '博乐',
    f_id: '652700',
  },
  {
    id: '652702',
    name: '阿拉山口市',
    short_name: '阿拉山口',
    f_id: '652700',
  },
  {
    id: '652722',
    name: '精河县',
    short_name: '精河',
    f_id: '652700',
  },
  {
    id: '652723',
    name: '温泉县',
    short_name: '温泉',
    f_id: '652700',
  },
  {
    id: '652801',
    name: '库尔勒市',
    short_name: '库尔勒',
    f_id: '652800',
  },
  {
    id: '652822',
    name: '轮台县',
    short_name: '轮台',
    f_id: '652800',
  },
  {
    id: '652823',
    name: '尉犁县',
    short_name: '尉犁',
    f_id: '652800',
  },
  {
    id: '652824',
    name: '若羌县',
    short_name: '若羌',
    f_id: '652800',
  },
  {
    id: '652825',
    name: '且末县',
    short_name: '且末',
    f_id: '652800',
  },
  {
    id: '652826',
    name: '焉耆回族自治县',
    short_name: '焉耆',
    f_id: '652800',
  },
  {
    id: '652827',
    name: '和静县',
    short_name: '和静',
    f_id: '652800',
  },
  {
    id: '652828',
    name: '和硕县',
    short_name: '和硕',
    f_id: '652800',
  },
  {
    id: '652829',
    name: '博湖县',
    short_name: '博湖',
    f_id: '652800',
  },
  {
    id: '652901',
    name: '阿克苏市',
    short_name: '阿克苏',
    f_id: '652900',
  },
  {
    id: '652922',
    name: '温宿县',
    short_name: '温宿',
    f_id: '652900',
  },
  {
    id: '652923',
    name: '库车县',
    short_name: '库车',
    f_id: '652900',
  },
  {
    id: '652924',
    name: '沙雅县',
    short_name: '沙雅',
    f_id: '652900',
  },
  {
    id: '652925',
    name: '新和县',
    short_name: '新和',
    f_id: '652900',
  },
  {
    id: '652926',
    name: '拜城县',
    short_name: '拜城',
    f_id: '652900',
  },
  {
    id: '652927',
    name: '乌什县',
    short_name: '乌什',
    f_id: '652900',
  },
  {
    id: '652928',
    name: '阿瓦提县',
    short_name: '阿瓦提',
    f_id: '652900',
  },
  {
    id: '652929',
    name: '柯坪县',
    short_name: '柯坪',
    f_id: '652900',
  },
  {
    id: '653001',
    name: '阿图什市',
    short_name: '阿图什',
    f_id: '653000',
  },
  {
    id: '653022',
    name: '阿克陶县',
    short_name: '阿克陶',
    f_id: '653000',
  },
  {
    id: '653023',
    name: '阿合奇县',
    short_name: '阿合奇',
    f_id: '653000',
  },
  {
    id: '653024',
    name: '乌恰县',
    short_name: '乌恰',
    f_id: '653000',
  },
  {
    id: '653101',
    name: '喀什市',
    short_name: '喀什',
    f_id: '653100',
  },
  {
    id: '653121',
    name: '疏附县',
    short_name: '疏附',
    f_id: '653100',
  },
  {
    id: '653122',
    name: '疏勒县',
    short_name: '疏勒',
    f_id: '653100',
  },
  {
    id: '653123',
    name: '英吉沙县',
    short_name: '英吉沙',
    f_id: '653100',
  },
  {
    id: '653124',
    name: '泽普县',
    short_name: '泽普',
    f_id: '653100',
  },
  {
    id: '653125',
    name: '莎车县',
    short_name: '莎车',
    f_id: '653100',
  },
  {
    id: '653126',
    name: '叶城县',
    short_name: '叶城',
    f_id: '653100',
  },
  {
    id: '653127',
    name: '麦盖提县',
    short_name: '麦盖提',
    f_id: '653100',
  },
  {
    id: '653128',
    name: '岳普湖县',
    short_name: '岳普湖',
    f_id: '653100',
  },
  {
    id: '653129',
    name: '伽师县',
    short_name: '伽师',
    f_id: '653100',
  },
  {
    id: '653130',
    name: '巴楚县',
    short_name: '巴楚',
    f_id: '653100',
  },
  {
    id: '653131',
    name: '塔什库尔干塔吉克自治县',
    short_name: '塔什库尔干',
    f_id: '653100',
  },
  {
    id: '653201',
    name: '和田市',
    short_name: '和田市',
    f_id: '653200',
  },
  {
    id: '653221',
    name: '和田县',
    short_name: '和田县',
    f_id: '653200',
  },
  {
    id: '653222',
    name: '墨玉县',
    short_name: '墨玉',
    f_id: '653200',
  },
  {
    id: '653223',
    name: '皮山县',
    short_name: '皮山',
    f_id: '653200',
  },
  {
    id: '653224',
    name: '洛浦县',
    short_name: '洛浦',
    f_id: '653200',
  },
  {
    id: '653225',
    name: '策勒县',
    short_name: '策勒',
    f_id: '653200',
  },
  {
    id: '653226',
    name: '于田县',
    short_name: '于田',
    f_id: '653200',
  },
  {
    id: '653227',
    name: '民丰县',
    short_name: '民丰',
    f_id: '653200',
  },
  {
    id: '654002',
    name: '伊宁市',
    short_name: '伊宁市',
    f_id: '654000',
  },
  {
    id: '654003',
    name: '奎屯市',
    short_name: '奎屯',
    f_id: '654000',
  },
  {
    id: '654021',
    name: '伊宁县',
    short_name: '伊宁县',
    f_id: '654000',
  },
  {
    id: '654022',
    name: '察布查尔锡伯自治县',
    short_name: '察布查尔',
    f_id: '654000',
  },
  {
    id: '654023',
    name: '霍城县',
    short_name: '霍城',
    f_id: '654000',
  },
  {
    id: '654024',
    name: '巩留县',
    short_name: '巩留',
    f_id: '654000',
  },
  {
    id: '654025',
    name: '新源县',
    short_name: '新源',
    f_id: '654000',
  },
  {
    id: '654026',
    name: '昭苏县',
    short_name: '昭苏',
    f_id: '654000',
  },
  {
    id: '654027',
    name: '特克斯县',
    short_name: '特克斯',
    f_id: '654000',
  },
  {
    id: '654028',
    name: '尼勒克县',
    short_name: '尼勒克',
    f_id: '654000',
  },
  {
    id: '654201',
    name: '塔城市',
    short_name: '塔城',
    f_id: '654200',
  },
  {
    id: '654202',
    name: '乌苏市',
    short_name: '乌苏',
    f_id: '654200',
  },
  {
    id: '654221',
    name: '额敏县',
    short_name: '额敏',
    f_id: '654200',
  },
  {
    id: '654223',
    name: '沙湾县',
    short_name: '沙湾',
    f_id: '654200',
  },
  {
    id: '654224',
    name: '托里县',
    short_name: '托里',
    f_id: '654200',
  },
  {
    id: '654225',
    name: '裕民县',
    short_name: '裕民',
    f_id: '654200',
  },
  {
    id: '654226',
    name: '和布克赛尔蒙古自治县',
    short_name: '和布克赛尔',
    f_id: '654200',
  },
  {
    id: '654301',
    name: '阿勒泰市',
    short_name: '阿勒泰',
    f_id: '654300',
  },
  {
    id: '654321',
    name: '布尔津县',
    short_name: '布尔津',
    f_id: '654300',
  },
  {
    id: '654322',
    name: '富蕴县',
    short_name: '富蕴',
    f_id: '654300',
  },
  {
    id: '654323',
    name: '福海县',
    short_name: '福海',
    f_id: '654300',
  },
  {
    id: '654324',
    name: '哈巴河县',
    short_name: '哈巴河',
    f_id: '654300',
  },
  {
    id: '654325',
    name: '青河县',
    short_name: '青河',
    f_id: '654300',
  },
  {
    id: '654326',
    name: '吉木乃县',
    short_name: '吉木乃',
    f_id: '654300',
  },
  {
    id: '710101',
    name: '中正区',
    short_name: '中正',
    f_id: '710100',
  },
  {
    id: '710102',
    name: '大同区',
    short_name: '大同',
    f_id: '710100',
  },
  {
    id: '710103',
    name: '中山区',
    short_name: '中山',
    f_id: '710100',
  },
  {
    id: '710104',
    name: '松山区',
    short_name: '松山',
    f_id: '710100',
  },
  {
    id: '710105',
    name: '大安区',
    short_name: '大安',
    f_id: '710100',
  },
  {
    id: '710106',
    name: '万华区',
    short_name: '万华',
    f_id: '710100',
  },
  {
    id: '710107',
    name: '信义区',
    short_name: '信义',
    f_id: '710100',
  },
  {
    id: '710108',
    name: '士林区',
    short_name: '士林',
    f_id: '710100',
  },
  {
    id: '710109',
    name: '北投区',
    short_name: '北投',
    f_id: '710100',
  },
  {
    id: '710110',
    name: '内湖区',
    short_name: '内湖',
    f_id: '710100',
  },
  {
    id: '710111',
    name: '南港区',
    short_name: '南港',
    f_id: '710100',
  },
  {
    id: '710112',
    name: '文山区',
    short_name: '文山',
    f_id: '710100',
  },
  {
    id: '710201',
    name: '新兴区',
    short_name: '新兴',
    f_id: '710200',
  },
  {
    id: '710202',
    name: '前金区',
    short_name: '前金',
    f_id: '710200',
  },
  {
    id: '710203',
    name: '芩雅区',
    short_name: '芩雅',
    f_id: '710200',
  },
  {
    id: '710204',
    name: '盐埕区',
    short_name: '盐埕',
    f_id: '710200',
  },
  {
    id: '710205',
    name: '鼓山区',
    short_name: '鼓山',
    f_id: '710200',
  },
  {
    id: '710206',
    name: '旗津区',
    short_name: '旗津',
    f_id: '710200',
  },
  {
    id: '710207',
    name: '前镇区',
    short_name: '前镇',
    f_id: '710200',
  },
  {
    id: '710208',
    name: '三民区',
    short_name: '三民',
    f_id: '710200',
  },
  {
    id: '710209',
    name: '左营区',
    short_name: '左营',
    f_id: '710200',
  },
  {
    id: '710210',
    name: '楠梓区',
    short_name: '楠梓',
    f_id: '710200',
  },
  {
    id: '710211',
    name: '小港区',
    short_name: '小港',
    f_id: '710200',
  },
  {
    id: '710241',
    name: '苓雅区',
    short_name: '苓雅',
    f_id: '710200',
  },
  {
    id: '710242',
    name: '仁武区',
    short_name: '仁武',
    f_id: '710200',
  },
  {
    id: '710243',
    name: '大社区',
    short_name: '大社',
    f_id: '710200',
  },
  {
    id: '710244',
    name: '冈山区',
    short_name: '冈山',
    f_id: '710200',
  },
  {
    id: '710245',
    name: '路竹区',
    short_name: '路竹',
    f_id: '710200',
  },
  {
    id: '710246',
    name: '阿莲区',
    short_name: '阿莲',
    f_id: '710200',
  },
  {
    id: '710247',
    name: '田寮区',
    short_name: '田寮',
    f_id: '710200',
  },
  {
    id: '710248',
    name: '燕巢区',
    short_name: '燕巢',
    f_id: '710200',
  },
  {
    id: '710249',
    name: '桥头区',
    short_name: '桥头',
    f_id: '710200',
  },
  {
    id: '710250',
    name: '梓官区',
    short_name: '梓官',
    f_id: '710200',
  },
  {
    id: '710251',
    name: '弥陀区',
    short_name: '弥陀',
    f_id: '710200',
  },
  {
    id: '710252',
    name: '永安区',
    short_name: '永安',
    f_id: '710200',
  },
  {
    id: '710253',
    name: '湖内区',
    short_name: '湖内',
    f_id: '710200',
  },
  {
    id: '710254',
    name: '凤山区',
    short_name: '凤山',
    f_id: '710200',
  },
  {
    id: '710255',
    name: '大寮区',
    short_name: '大寮',
    f_id: '710200',
  },
  {
    id: '710256',
    name: '林园区',
    short_name: '林园',
    f_id: '710200',
  },
  {
    id: '710257',
    name: '鸟松区',
    short_name: '鸟松',
    f_id: '710200',
  },
  {
    id: '710258',
    name: '大树区',
    short_name: '大树',
    f_id: '710200',
  },
  {
    id: '710259',
    name: '旗山区',
    short_name: '旗山',
    f_id: '710200',
  },
  {
    id: '710260',
    name: '美浓区',
    short_name: '美浓',
    f_id: '710200',
  },
  {
    id: '710261',
    name: '六龟区',
    short_name: '六龟',
    f_id: '710200',
  },
  {
    id: '710262',
    name: '内门区',
    short_name: '内门',
    f_id: '710200',
  },
  {
    id: '710263',
    name: '杉林区',
    short_name: '杉林',
    f_id: '710200',
  },
  {
    id: '710264',
    name: '甲仙区',
    short_name: '甲仙',
    f_id: '710200',
  },
  {
    id: '710265',
    name: '桃源区',
    short_name: '桃源',
    f_id: '710200',
  },
  {
    id: '710266',
    name: '那玛夏区',
    short_name: '那玛夏',
    f_id: '710200',
  },
  {
    id: '710267',
    name: '茂林区',
    short_name: '茂林',
    f_id: '710200',
  },
  {
    id: '710268',
    name: '茄萣区',
    short_name: '茄萣',
    f_id: '710200',
  },
  {
    id: '710301',
    name: '中西区',
    short_name: '中西',
    f_id: '710300',
  },
  {
    id: '710302',
    name: '东区',
    short_name: '东区',
    f_id: '710300',
  },
  {
    id: '710303',
    name: '南区',
    short_name: '南区',
    f_id: '710300',
  },
  {
    id: '710304',
    name: '北区',
    short_name: '北区',
    f_id: '710300',
  },
  {
    id: '710305',
    name: '安平区',
    short_name: '安平',
    f_id: '710300',
  },
  {
    id: '710306',
    name: '安南区',
    short_name: '安南',
    f_id: '710300',
  },
  {
    id: '710339',
    name: '永康区',
    short_name: '永康',
    f_id: '710300',
  },
  {
    id: '710340',
    name: '归仁区',
    short_name: '归仁',
    f_id: '710300',
  },
  {
    id: '710341',
    name: '新化区',
    short_name: '新化',
    f_id: '710300',
  },
  {
    id: '710342',
    name: '左镇区',
    short_name: '左镇',
    f_id: '710300',
  },
  {
    id: '710343',
    name: '玉井区',
    short_name: '玉井',
    f_id: '710300',
  },
  {
    id: '710344',
    name: '楠西区',
    short_name: '楠西',
    f_id: '710300',
  },
  {
    id: '710345',
    name: '南化区',
    short_name: '南化',
    f_id: '710300',
  },
  {
    id: '710346',
    name: '仁德区',
    short_name: '仁德',
    f_id: '710300',
  },
  {
    id: '710347',
    name: '关庙区',
    short_name: '关庙',
    f_id: '710300',
  },
  {
    id: '710348',
    name: '龙崎区',
    short_name: '龙崎',
    f_id: '710300',
  },
  {
    id: '710349',
    name: '官田区',
    short_name: '官田',
    f_id: '710300',
  },
  {
    id: '710350',
    name: '麻豆区',
    short_name: '麻豆',
    f_id: '710300',
  },
  {
    id: '710351',
    name: '佳里区',
    short_name: '佳里',
    f_id: '710300',
  },
  {
    id: '710352',
    name: '西港区',
    short_name: '西港',
    f_id: '710300',
  },
  {
    id: '710353',
    name: '七股区',
    short_name: '七股',
    f_id: '710300',
  },
  {
    id: '710354',
    name: '将军区',
    short_name: '将军',
    f_id: '710300',
  },
  {
    id: '710355',
    name: '学甲区',
    short_name: '学甲',
    f_id: '710300',
  },
  {
    id: '710356',
    name: '北门区',
    short_name: '北门',
    f_id: '710300',
  },
  {
    id: '710357',
    name: '新营区',
    short_name: '新营',
    f_id: '710300',
  },
  {
    id: '710358',
    name: '后壁区',
    short_name: '后壁',
    f_id: '710300',
  },
  {
    id: '710359',
    name: '白河区',
    short_name: '白河',
    f_id: '710300',
  },
  {
    id: '710360',
    name: '东山区',
    short_name: '东山',
    f_id: '710300',
  },
  {
    id: '710361',
    name: '六甲区',
    short_name: '六甲',
    f_id: '710300',
  },
  {
    id: '710362',
    name: '下营区',
    short_name: '下营',
    f_id: '710300',
  },
  {
    id: '710363',
    name: '柳营区',
    short_name: '柳营',
    f_id: '710300',
  },
  {
    id: '710364',
    name: '盐水区',
    short_name: '盐水',
    f_id: '710300',
  },
  {
    id: '710365',
    name: '善化区',
    short_name: '善化',
    f_id: '710300',
  },
  {
    id: '710366',
    name: '大内区',
    short_name: '大内',
    f_id: '710300',
  },
  {
    id: '710367',
    name: '山上区',
    short_name: '山上',
    f_id: '710300',
  },
  {
    id: '710368',
    name: '新市区',
    short_name: '新市',
    f_id: '710300',
  },
  {
    id: '710369',
    name: '安定区',
    short_name: '安定',
    f_id: '710300',
  },
  {
    id: '710401',
    name: '中区',
    short_name: '中区',
    f_id: '710400',
  },
  {
    id: '710402',
    name: '东区',
    short_name: '东区',
    f_id: '710400',
  },
  {
    id: '710403',
    name: '南区',
    short_name: '南区',
    f_id: '710400',
  },
  {
    id: '710404',
    name: '西区',
    short_name: '西区',
    f_id: '710400',
  },
  {
    id: '710405',
    name: '北区',
    short_name: '北区',
    f_id: '710400',
  },
  {
    id: '710406',
    name: '北屯区',
    short_name: '北屯',
    f_id: '710400',
  },
  {
    id: '710407',
    name: '西屯区',
    short_name: '西屯',
    f_id: '710400',
  },
  {
    id: '710408',
    name: '南屯区',
    short_name: '南屯',
    f_id: '710400',
  },
  {
    id: '710431',
    name: '太平区',
    short_name: '太平',
    f_id: '710400',
  },
  {
    id: '710432',
    name: '大里区',
    short_name: '大里',
    f_id: '710400',
  },
  {
    id: '710433',
    name: '雾峰区',
    short_name: '雾峰',
    f_id: '710400',
  },
  {
    id: '710434',
    name: '乌日区',
    short_name: '乌日',
    f_id: '710400',
  },
  {
    id: '710435',
    name: '丰原区',
    short_name: '丰原',
    f_id: '710400',
  },
  {
    id: '710436',
    name: '后里区',
    short_name: '后里',
    f_id: '710400',
  },
  {
    id: '710437',
    name: '石冈区',
    short_name: '石冈',
    f_id: '710400',
  },
  {
    id: '710438',
    name: '东势区',
    short_name: '东势',
    f_id: '710400',
  },
  {
    id: '710439',
    name: '和平区',
    short_name: '和平',
    f_id: '710400',
  },
  {
    id: '710440',
    name: '新社区',
    short_name: '新社',
    f_id: '710400',
  },
  {
    id: '710441',
    name: '潭子区',
    short_name: '潭子',
    f_id: '710400',
  },
  {
    id: '710442',
    name: '大雅区',
    short_name: '大雅',
    f_id: '710400',
  },
  {
    id: '710443',
    name: '神冈区',
    short_name: '神冈',
    f_id: '710400',
  },
  {
    id: '710444',
    name: '大肚区',
    short_name: '大肚',
    f_id: '710400',
  },
  {
    id: '710445',
    name: '沙鹿区',
    short_name: '沙鹿',
    f_id: '710400',
  },
  {
    id: '710446',
    name: '龙井区',
    short_name: '龙井',
    f_id: '710400',
  },
  {
    id: '710447',
    name: '梧栖区',
    short_name: '梧栖',
    f_id: '710400',
  },
  {
    id: '710448',
    name: '清水区',
    short_name: '清水',
    f_id: '710400',
  },
  {
    id: '710449',
    name: '大甲区',
    short_name: '大甲',
    f_id: '710400',
  },
  {
    id: '710450',
    name: '外埔区',
    short_name: '外埔',
    f_id: '710400',
  },
  {
    id: '710451',
    name: '大安区',
    short_name: '大安',
    f_id: '710400',
  },
  {
    id: '710507',
    name: '金沙镇',
    short_name: '金沙',
    f_id: '710500',
  },
  {
    id: '710508',
    name: '金湖镇',
    short_name: '金湖',
    f_id: '710500',
  },
  {
    id: '710509',
    name: '金宁乡',
    short_name: '金宁',
    f_id: '710500',
  },
  {
    id: '710510',
    name: '金城镇',
    short_name: '金城',
    f_id: '710500',
  },
  {
    id: '710511',
    name: '烈屿乡',
    short_name: '烈屿',
    f_id: '710500',
  },
  {
    id: '710512',
    name: '乌坵乡',
    short_name: '乌坵',
    f_id: '710500',
  },
  {
    id: '710614',
    name: '南投市',
    short_name: '南投',
    f_id: '710600',
  },
  {
    id: '710615',
    name: '中寮乡',
    short_name: '中寮',
    f_id: '710600',
  },
  {
    id: '710616',
    name: '草屯镇',
    short_name: '草屯',
    f_id: '710600',
  },
  {
    id: '710617',
    name: '国姓乡',
    short_name: '国姓',
    f_id: '710600',
  },
  {
    id: '710618',
    name: '埔里镇',
    short_name: '埔里',
    f_id: '710600',
  },
  {
    id: '710619',
    name: '仁爱乡',
    short_name: '仁爱',
    f_id: '710600',
  },
  {
    id: '710620',
    name: '名间乡',
    short_name: '名间',
    f_id: '710600',
  },
  {
    id: '710621',
    name: '集集镇',
    short_name: '集集',
    f_id: '710600',
  },
  {
    id: '710622',
    name: '水里乡',
    short_name: '水里',
    f_id: '710600',
  },
  {
    id: '710623',
    name: '鱼池乡',
    short_name: '鱼池',
    f_id: '710600',
  },
  {
    id: '710624',
    name: '信义乡',
    short_name: '信义',
    f_id: '710600',
  },
  {
    id: '710625',
    name: '竹山镇',
    short_name: '竹山',
    f_id: '710600',
  },
  {
    id: '710626',
    name: '鹿谷乡',
    short_name: '鹿谷',
    f_id: '710600',
  },
  {
    id: '710701',
    name: '仁爱区',
    short_name: '仁爱',
    f_id: '710700',
  },
  {
    id: '710702',
    name: '信义区',
    short_name: '信义',
    f_id: '710700',
  },
  {
    id: '710703',
    name: '中正区',
    short_name: '中正',
    f_id: '710700',
  },
  {
    id: '710704',
    name: '中山区',
    short_name: '中山',
    f_id: '710700',
  },
  {
    id: '710705',
    name: '安乐区',
    short_name: '安乐',
    f_id: '710700',
  },
  {
    id: '710706',
    name: '暖暖区',
    short_name: '暖暖',
    f_id: '710700',
  },
  {
    id: '710707',
    name: '七堵区',
    short_name: '七堵',
    f_id: '710700',
  },
  {
    id: '710801',
    name: '东区',
    short_name: '东区',
    f_id: '710800',
  },
  {
    id: '710802',
    name: '北区',
    short_name: '北区',
    f_id: '710800',
  },
  {
    id: '710803',
    name: '香山区',
    short_name: '香山',
    f_id: '710800',
  },
  {
    id: '710901',
    name: '东区',
    short_name: '东区',
    f_id: '710900',
  },
  {
    id: '710902',
    name: '西区',
    short_name: '西区',
    f_id: '710900',
  },
  {
    id: '711130',
    name: '万里区',
    short_name: '万里',
    f_id: '711100',
  },
  {
    id: '711131',
    name: '金山区',
    short_name: '金山',
    f_id: '711100',
  },
  {
    id: '711132',
    name: '板桥区',
    short_name: '板桥',
    f_id: '711100',
  },
  {
    id: '711133',
    name: '汐止区',
    short_name: '汐止',
    f_id: '711100',
  },
  {
    id: '711134',
    name: '深坑区',
    short_name: '深坑',
    f_id: '711100',
  },
  {
    id: '711135',
    name: '石碇区',
    short_name: '石碇',
    f_id: '711100',
  },
  {
    id: '711136',
    name: '瑞芳区',
    short_name: '瑞芳',
    f_id: '711100',
  },
  {
    id: '711137',
    name: '平溪区',
    short_name: '平溪',
    f_id: '711100',
  },
  {
    id: '711138',
    name: '双溪区',
    short_name: '双溪',
    f_id: '711100',
  },
  {
    id: '711139',
    name: '贡寮区',
    short_name: '贡寮',
    f_id: '711100',
  },
  {
    id: '711140',
    name: '新店区',
    short_name: '新店',
    f_id: '711100',
  },
  {
    id: '711141',
    name: '坪林区',
    short_name: '坪林',
    f_id: '711100',
  },
  {
    id: '711142',
    name: '乌来区',
    short_name: '乌来',
    f_id: '711100',
  },
  {
    id: '711143',
    name: '永和区',
    short_name: '永和',
    f_id: '711100',
  },
  {
    id: '711144',
    name: '中和区',
    short_name: '中和',
    f_id: '711100',
  },
  {
    id: '711145',
    name: '土城区',
    short_name: '土城',
    f_id: '711100',
  },
  {
    id: '711146',
    name: '三峡区',
    short_name: '三峡',
    f_id: '711100',
  },
  {
    id: '711147',
    name: '树林区',
    short_name: '树林',
    f_id: '711100',
  },
  {
    id: '711148',
    name: '莺歌区',
    short_name: '莺歌',
    f_id: '711100',
  },
  {
    id: '711149',
    name: '三重区',
    short_name: '三重',
    f_id: '711100',
  },
  {
    id: '711150',
    name: '新庄区',
    short_name: '新庄',
    f_id: '711100',
  },
  {
    id: '711151',
    name: '泰山区',
    short_name: '泰山',
    f_id: '711100',
  },
  {
    id: '711152',
    name: '林口区',
    short_name: '林口',
    f_id: '711100',
  },
  {
    id: '711153',
    name: '芦洲区',
    short_name: '芦洲',
    f_id: '711100',
  },
  {
    id: '711154',
    name: '五股区',
    short_name: '五股',
    f_id: '711100',
  },
  {
    id: '711155',
    name: '八里区',
    short_name: '八里',
    f_id: '711100',
  },
  {
    id: '711156',
    name: '淡水区',
    short_name: '淡水',
    f_id: '711100',
  },
  {
    id: '711157',
    name: '三芝区',
    short_name: '三芝',
    f_id: '711100',
  },
  {
    id: '711158',
    name: '石门区',
    short_name: '石门',
    f_id: '711100',
  },
  {
    id: '711214',
    name: '宜兰市',
    short_name: '宜兰',
    f_id: '711200',
  },
  {
    id: '711215',
    name: '头城镇',
    short_name: '头城',
    f_id: '711200',
  },
  {
    id: '711216',
    name: '礁溪乡',
    short_name: '礁溪',
    f_id: '711200',
  },
  {
    id: '711217',
    name: '壮围乡',
    short_name: '壮围',
    f_id: '711200',
  },
  {
    id: '711218',
    name: '员山乡',
    short_name: '员山',
    f_id: '711200',
  },
  {
    id: '711219',
    name: '罗东镇',
    short_name: '罗东',
    f_id: '711200',
  },
  {
    id: '711220',
    name: '三星乡',
    short_name: '三星',
    f_id: '711200',
  },
  {
    id: '711221',
    name: '大同乡',
    short_name: '大同',
    f_id: '711200',
  },
  {
    id: '711222',
    name: '五结乡',
    short_name: '五结',
    f_id: '711200',
  },
  {
    id: '711223',
    name: '冬山乡',
    short_name: '冬山',
    f_id: '711200',
  },
  {
    id: '711224',
    name: '苏澳镇',
    short_name: '苏澳',
    f_id: '711200',
  },
  {
    id: '711225',
    name: '南澳乡',
    short_name: '南澳',
    f_id: '711200',
  },
  {
    id: '711226',
    name: '钓鱼台',
    short_name: '钓鱼',
    f_id: '711200',
  },
  {
    id: '711314',
    name: '竹北市',
    short_name: '竹北',
    f_id: '711300',
  },
  {
    id: '711315',
    name: '湖口乡',
    short_name: '湖口',
    f_id: '711300',
  },
  {
    id: '711316',
    name: '新丰乡',
    short_name: '新丰',
    f_id: '711300',
  },
  {
    id: '711317',
    name: '新埔镇',
    short_name: '新埔',
    f_id: '711300',
  },
  {
    id: '711318',
    name: '关西镇',
    short_name: '关西',
    f_id: '711300',
  },
  {
    id: '711319',
    name: '芎林乡',
    short_name: '芎林',
    f_id: '711300',
  },
  {
    id: '711320',
    name: '宝山乡',
    short_name: '宝山',
    f_id: '711300',
  },
  {
    id: '711321',
    name: '竹东镇',
    short_name: '竹东',
    f_id: '711300',
  },
  {
    id: '711322',
    name: '五峰乡',
    short_name: '五峰',
    f_id: '711300',
  },
  {
    id: '711323',
    name: '横山乡',
    short_name: '横山',
    f_id: '711300',
  },
  {
    id: '711324',
    name: '尖石乡',
    short_name: '尖石',
    f_id: '711300',
  },
  {
    id: '711325',
    name: '北埔乡',
    short_name: '北埔',
    f_id: '711300',
  },
  {
    id: '711326',
    name: '峨眉乡',
    short_name: '峨眉',
    f_id: '711300',
  },
  {
    id: '711414',
    name: '中坜市',
    short_name: '中坜',
    f_id: '711400',
  },
  {
    id: '711415',
    name: '平镇市',
    short_name: '平镇',
    f_id: '711400',
  },
  {
    id: '711416',
    name: '龙潭乡',
    short_name: '龙潭',
    f_id: '711400',
  },
  {
    id: '711417',
    name: '杨梅市',
    short_name: '杨梅',
    f_id: '711400',
  },
  {
    id: '711418',
    name: '新屋乡',
    short_name: '新屋',
    f_id: '711400',
  },
  {
    id: '711419',
    name: '观音乡',
    short_name: '观音',
    f_id: '711400',
  },
  {
    id: '711420',
    name: '桃园市',
    short_name: '桃园',
    f_id: '711400',
  },
  {
    id: '711421',
    name: '龟山乡',
    short_name: '龟山',
    f_id: '711400',
  },
  {
    id: '711422',
    name: '八德市',
    short_name: '八德',
    f_id: '711400',
  },
  {
    id: '711423',
    name: '大溪镇',
    short_name: '大溪',
    f_id: '711400',
  },
  {
    id: '711424',
    name: '复兴乡',
    short_name: '复兴',
    f_id: '711400',
  },
  {
    id: '711425',
    name: '大园乡',
    short_name: '大园',
    f_id: '711400',
  },
  {
    id: '711426',
    name: '芦竹乡',
    short_name: '芦竹',
    f_id: '711400',
  },
  {
    id: '711519',
    name: '竹南镇',
    short_name: '竹南',
    f_id: '711500',
  },
  {
    id: '711520',
    name: '头份镇',
    short_name: '头份',
    f_id: '711500',
  },
  {
    id: '711521',
    name: '三湾乡',
    short_name: '三湾',
    f_id: '711500',
  },
  {
    id: '711522',
    name: '南庄乡',
    short_name: '南庄',
    f_id: '711500',
  },
  {
    id: '711523',
    name: '狮潭乡',
    short_name: '狮潭',
    f_id: '711500',
  },
  {
    id: '711524',
    name: '后龙镇',
    short_name: '后龙',
    f_id: '711500',
  },
  {
    id: '711525',
    name: '通霄镇',
    short_name: '通霄',
    f_id: '711500',
  },
  {
    id: '711526',
    name: '苑里镇',
    short_name: '苑里',
    f_id: '711500',
  },
  {
    id: '711527',
    name: '苗栗市',
    short_name: '苗栗',
    f_id: '711500',
  },
  {
    id: '711528',
    name: '造桥乡',
    short_name: '造桥',
    f_id: '711500',
  },
  {
    id: '711529',
    name: '头屋乡',
    short_name: '头屋',
    f_id: '711500',
  },
  {
    id: '711530',
    name: '公馆乡',
    short_name: '公馆',
    f_id: '711500',
  },
  {
    id: '711531',
    name: '大湖乡',
    short_name: '大湖',
    f_id: '711500',
  },
  {
    id: '711532',
    name: '泰安乡',
    short_name: '泰安',
    f_id: '711500',
  },
  {
    id: '711533',
    name: '铜锣乡',
    short_name: '铜锣',
    f_id: '711500',
  },
  {
    id: '711534',
    name: '三义乡',
    short_name: '三义',
    f_id: '711500',
  },
  {
    id: '711535',
    name: '西湖乡',
    short_name: '西湖',
    f_id: '711500',
  },
  {
    id: '711536',
    name: '卓兰镇',
    short_name: '卓兰',
    f_id: '711500',
  },
  {
    id: '711727',
    name: '彰化市',
    short_name: '彰化',
    f_id: '711700',
  },
  {
    id: '711728',
    name: '芬园乡',
    short_name: '芬园',
    f_id: '711700',
  },
  {
    id: '711729',
    name: '花坛乡',
    short_name: '花坛',
    f_id: '711700',
  },
  {
    id: '711730',
    name: '秀水乡',
    short_name: '秀水',
    f_id: '711700',
  },
  {
    id: '711731',
    name: '鹿港镇',
    short_name: '鹿港',
    f_id: '711700',
  },
  {
    id: '711732',
    name: '福兴乡',
    short_name: '福兴',
    f_id: '711700',
  },
  {
    id: '711733',
    name: '线西乡',
    short_name: '线西',
    f_id: '711700',
  },
  {
    id: '711734',
    name: '和美镇',
    short_name: '和美',
    f_id: '711700',
  },
  {
    id: '711735',
    name: '伸港乡',
    short_name: '伸港',
    f_id: '711700',
  },
  {
    id: '711736',
    name: '员林镇',
    short_name: '员林',
    f_id: '711700',
  },
  {
    id: '711737',
    name: '社头乡',
    short_name: '社头',
    f_id: '711700',
  },
  {
    id: '711738',
    name: '永靖乡',
    short_name: '永靖',
    f_id: '711700',
  },
  {
    id: '711739',
    name: '埔心乡',
    short_name: '埔心',
    f_id: '711700',
  },
  {
    id: '711740',
    name: '溪湖镇',
    short_name: '溪湖',
    f_id: '711700',
  },
  {
    id: '711741',
    name: '大村乡',
    short_name: '大村',
    f_id: '711700',
  },
  {
    id: '711742',
    name: '埔盐乡',
    short_name: '埔盐',
    f_id: '711700',
  },
  {
    id: '711743',
    name: '田中镇',
    short_name: '田中',
    f_id: '711700',
  },
  {
    id: '711744',
    name: '北斗镇',
    short_name: '北斗',
    f_id: '711700',
  },
  {
    id: '711745',
    name: '田尾乡',
    short_name: '田尾',
    f_id: '711700',
  },
  {
    id: '711746',
    name: '埤头乡',
    short_name: '埤头',
    f_id: '711700',
  },
  {
    id: '711747',
    name: '溪州乡',
    short_name: '溪州',
    f_id: '711700',
  },
  {
    id: '711748',
    name: '竹塘乡',
    short_name: '竹塘',
    f_id: '711700',
  },
  {
    id: '711749',
    name: '二林镇',
    short_name: '二林',
    f_id: '711700',
  },
  {
    id: '711750',
    name: '大城乡',
    short_name: '大城',
    f_id: '711700',
  },
  {
    id: '711751',
    name: '芳苑乡',
    short_name: '芳苑',
    f_id: '711700',
  },
  {
    id: '711752',
    name: '二水乡',
    short_name: '二水',
    f_id: '711700',
  },
  {
    id: '711919',
    name: '番路乡',
    short_name: '番路',
    f_id: '711900',
  },
  {
    id: '711920',
    name: '梅山乡',
    short_name: '梅山',
    f_id: '711900',
  },
  {
    id: '711921',
    name: '竹崎乡',
    short_name: '竹崎',
    f_id: '711900',
  },
  {
    id: '711922',
    name: '阿里山乡',
    short_name: '阿里山',
    f_id: '711900',
  },
  {
    id: '711923',
    name: '中埔乡',
    short_name: '中埔',
    f_id: '711900',
  },
  {
    id: '711924',
    name: '大埔乡',
    short_name: '大埔',
    f_id: '711900',
  },
  {
    id: '711925',
    name: '水上乡',
    short_name: '水上',
    f_id: '711900',
  },
  {
    id: '711926',
    name: '鹿草乡',
    short_name: '鹿草',
    f_id: '711900',
  },
  {
    id: '711927',
    name: '太保市',
    short_name: '太保',
    f_id: '711900',
  },
  {
    id: '711928',
    name: '朴子市',
    short_name: '朴子',
    f_id: '711900',
  },
  {
    id: '711929',
    name: '东石乡',
    short_name: '东石',
    f_id: '711900',
  },
  {
    id: '711930',
    name: '六脚乡',
    short_name: '六脚',
    f_id: '711900',
  },
  {
    id: '711931',
    name: '新港乡',
    short_name: '新港',
    f_id: '711900',
  },
  {
    id: '711932',
    name: '民雄乡',
    short_name: '民雄',
    f_id: '711900',
  },
  {
    id: '711933',
    name: '大林镇',
    short_name: '大林',
    f_id: '711900',
  },
  {
    id: '711934',
    name: '溪口乡',
    short_name: '溪口',
    f_id: '711900',
  },
  {
    id: '711935',
    name: '义竹乡',
    short_name: '义竹',
    f_id: '711900',
  },
  {
    id: '711936',
    name: '布袋镇',
    short_name: '布袋',
    f_id: '711900',
  },
  {
    id: '712121',
    name: '斗南镇',
    short_name: '斗南',
    f_id: '712100',
  },
  {
    id: '712122',
    name: '大埤乡',
    short_name: '大埤',
    f_id: '712100',
  },
  {
    id: '712123',
    name: '虎尾镇',
    short_name: '虎尾',
    f_id: '712100',
  },
  {
    id: '712124',
    name: '土库镇',
    short_name: '土库',
    f_id: '712100',
  },
  {
    id: '712125',
    name: '褒忠乡',
    short_name: '褒忠',
    f_id: '712100',
  },
  {
    id: '712126',
    name: '东势乡',
    short_name: '东势',
    f_id: '712100',
  },
  {
    id: '712127',
    name: '台西乡',
    short_name: '台西',
    f_id: '712100',
  },
  {
    id: '712128',
    name: '仑背乡',
    short_name: '仑背',
    f_id: '712100',
  },
  {
    id: '712129',
    name: '麦寮乡',
    short_name: '麦寮',
    f_id: '712100',
  },
  {
    id: '712130',
    name: '斗六市',
    short_name: '斗六',
    f_id: '712100',
  },
  {
    id: '712131',
    name: '林内乡',
    short_name: '林内',
    f_id: '712100',
  },
  {
    id: '712132',
    name: '古坑乡',
    short_name: '古坑',
    f_id: '712100',
  },
  {
    id: '712133',
    name: '莿桐乡',
    short_name: '莿桐',
    f_id: '712100',
  },
  {
    id: '712134',
    name: '西螺镇',
    short_name: '西螺',
    f_id: '712100',
  },
  {
    id: '712135',
    name: '二仑乡',
    short_name: '二仑',
    f_id: '712100',
  },
  {
    id: '712136',
    name: '北港镇',
    short_name: '北港',
    f_id: '712100',
  },
  {
    id: '712137',
    name: '水林乡',
    short_name: '水林',
    f_id: '712100',
  },
  {
    id: '712138',
    name: '口湖乡',
    short_name: '口湖',
    f_id: '712100',
  },
  {
    id: '712139',
    name: '四湖乡',
    short_name: '四湖',
    f_id: '712100',
  },
  {
    id: '712140',
    name: '元长乡',
    short_name: '元长',
    f_id: '712100',
  },
  {
    id: '712434',
    name: '屏东市',
    short_name: '屏东',
    f_id: '712400',
  },
  {
    id: '712435',
    name: '三地门乡',
    short_name: '三地门',
    f_id: '712400',
  },
  {
    id: '712436',
    name: '雾台乡',
    short_name: '雾台',
    f_id: '712400',
  },
  {
    id: '712437',
    name: '玛家乡',
    short_name: '玛家',
    f_id: '712400',
  },
  {
    id: '712438',
    name: '九如乡',
    short_name: '九如',
    f_id: '712400',
  },
  {
    id: '712439',
    name: '里港乡',
    short_name: '里港',
    f_id: '712400',
  },
  {
    id: '712440',
    name: '高树乡',
    short_name: '高树',
    f_id: '712400',
  },
  {
    id: '712441',
    name: '盐埔乡',
    short_name: '盐埔',
    f_id: '712400',
  },
  {
    id: '712442',
    name: '长治乡',
    short_name: '长治',
    f_id: '712400',
  },
  {
    id: '712443',
    name: '麟洛乡',
    short_name: '麟洛',
    f_id: '712400',
  },
  {
    id: '712444',
    name: '竹田乡',
    short_name: '竹田',
    f_id: '712400',
  },
  {
    id: '712445',
    name: '内埔乡',
    short_name: '内埔',
    f_id: '712400',
  },
  {
    id: '712446',
    name: '万丹乡',
    short_name: '万丹',
    f_id: '712400',
  },
  {
    id: '712447',
    name: '潮州镇',
    short_name: '潮州',
    f_id: '712400',
  },
  {
    id: '712448',
    name: '泰武乡',
    short_name: '泰武',
    f_id: '712400',
  },
  {
    id: '712449',
    name: '来义乡',
    short_name: '来义',
    f_id: '712400',
  },
  {
    id: '712450',
    name: '万峦乡',
    short_name: '万峦',
    f_id: '712400',
  },
  {
    id: '712451',
    name: '崁顶乡',
    short_name: '崁顶',
    f_id: '712400',
  },
  {
    id: '712452',
    name: '新埤乡',
    short_name: '新埤',
    f_id: '712400',
  },
  {
    id: '712453',
    name: '南州乡',
    short_name: '南州',
    f_id: '712400',
  },
  {
    id: '712454',
    name: '林边乡',
    short_name: '林边',
    f_id: '712400',
  },
  {
    id: '712455',
    name: '东港镇',
    short_name: '东港',
    f_id: '712400',
  },
  {
    id: '712456',
    name: '琉球乡',
    short_name: '琉球',
    f_id: '712400',
  },
  {
    id: '712457',
    name: '佳冬乡',
    short_name: '佳冬',
    f_id: '712400',
  },
  {
    id: '712458',
    name: '新园乡',
    short_name: '新园',
    f_id: '712400',
  },
  {
    id: '712459',
    name: '枋寮乡',
    short_name: '枋寮',
    f_id: '712400',
  },
  {
    id: '712460',
    name: '枋山乡',
    short_name: '枋山',
    f_id: '712400',
  },
  {
    id: '712461',
    name: '春日乡',
    short_name: '春日',
    f_id: '712400',
  },
  {
    id: '712462',
    name: '狮子乡',
    short_name: '狮子',
    f_id: '712400',
  },
  {
    id: '712463',
    name: '车城乡',
    short_name: '车城',
    f_id: '712400',
  },
  {
    id: '712464',
    name: '牡丹乡',
    short_name: '牡丹',
    f_id: '712400',
  },
  {
    id: '712465',
    name: '恒春镇',
    short_name: '恒春',
    f_id: '712400',
  },
  {
    id: '712466',
    name: '满州乡',
    short_name: '满州',
    f_id: '712400',
  },
  {
    id: '712517',
    name: '台东市',
    short_name: '台东',
    f_id: '712500',
  },
  {
    id: '712518',
    name: '绿岛乡',
    short_name: '绿岛',
    f_id: '712500',
  },
  {
    id: '712519',
    name: '兰屿乡',
    short_name: '兰屿',
    f_id: '712500',
  },
  {
    id: '712520',
    name: '延平乡',
    short_name: '延平',
    f_id: '712500',
  },
  {
    id: '712521',
    name: '卑南乡',
    short_name: '卑南',
    f_id: '712500',
  },
  {
    id: '712522',
    name: '鹿野乡',
    short_name: '鹿野',
    f_id: '712500',
  },
  {
    id: '712523',
    name: '关山镇',
    short_name: '关山',
    f_id: '712500',
  },
  {
    id: '712524',
    name: '海端乡',
    short_name: '海端',
    f_id: '712500',
  },
  {
    id: '712525',
    name: '池上乡',
    short_name: '池上',
    f_id: '712500',
  },
  {
    id: '712526',
    name: '东河乡',
    short_name: '东河',
    f_id: '712500',
  },
  {
    id: '712527',
    name: '成功镇',
    short_name: '成功',
    f_id: '712500',
  },
  {
    id: '712528',
    name: '长滨乡',
    short_name: '长滨',
    f_id: '712500',
  },
  {
    id: '712529',
    name: '金峰乡',
    short_name: '金峰',
    f_id: '712500',
  },
  {
    id: '712530',
    name: '大武乡',
    short_name: '大武',
    f_id: '712500',
  },
  {
    id: '712531',
    name: '达仁乡',
    short_name: '达仁',
    f_id: '712500',
  },
  {
    id: '712532',
    name: '太麻里乡',
    short_name: '太麻里',
    f_id: '712500',
  },
  {
    id: '712615',
    name: '花莲市',
    short_name: '花莲',
    f_id: '712600',
  },
  {
    id: '712616',
    name: '新城乡',
    short_name: '新城',
    f_id: '712600',
  },
  {
    id: '712617',
    name: '太鲁阁',
    short_name: '太鲁',
    f_id: '712600',
  },
  {
    id: '712618',
    name: '秀林乡',
    short_name: '秀林',
    f_id: '712600',
  },
  {
    id: '712619',
    name: '吉安乡',
    short_name: '吉安',
    f_id: '712600',
  },
  {
    id: '712620',
    name: '寿丰乡',
    short_name: '寿丰',
    f_id: '712600',
  },
  {
    id: '712621',
    name: '凤林镇',
    short_name: '凤林',
    f_id: '712600',
  },
  {
    id: '712622',
    name: '光复乡',
    short_name: '光复',
    f_id: '712600',
  },
  {
    id: '712623',
    name: '丰滨乡',
    short_name: '丰滨',
    f_id: '712600',
  },
  {
    id: '712624',
    name: '瑞穗乡',
    short_name: '瑞穗',
    f_id: '712600',
  },
  {
    id: '712625',
    name: '万荣乡',
    short_name: '万荣',
    f_id: '712600',
  },
  {
    id: '712626',
    name: '玉里镇',
    short_name: '玉里',
    f_id: '712600',
  },
  {
    id: '712627',
    name: '卓溪乡',
    short_name: '卓溪',
    f_id: '712600',
  },
  {
    id: '712628',
    name: '富里乡',
    short_name: '富里',
    f_id: '712600',
  },
  {
    id: '712707',
    name: '马公市',
    short_name: '马公',
    f_id: '712700',
  },
  {
    id: '712708',
    name: '西屿乡',
    short_name: '西屿',
    f_id: '712700',
  },
  {
    id: '712709',
    name: '望安乡',
    short_name: '望安',
    f_id: '712700',
  },
  {
    id: '712710',
    name: '七美乡',
    short_name: '七美',
    f_id: '712700',
  },
  {
    id: '712711',
    name: '白沙乡',
    short_name: '白沙',
    f_id: '712700',
  },
  {
    id: '712712',
    name: '湖西乡',
    short_name: '湖西',
    f_id: '712700',
  },
  {
    id: '712805',
    name: '南竿乡',
    short_name: '南竿',
    f_id: '712800',
  },
  {
    id: '712806',
    name: '北竿乡',
    short_name: '北竿',
    f_id: '712800',
  },
  {
    id: '712807',
    name: '莒光乡',
    short_name: '莒光',
    f_id: '712800',
  },
  {
    id: '712808',
    name: '东引乡',
    short_name: '东引',
    f_id: '712800',
  },
  {
    id: '810101',
    name: '中西区',
    short_name: '中西',
    f_id: '810100',
  },
  {
    id: '810102',
    name: '湾仔',
    short_name: '湾仔',
    f_id: '810100',
  },
  {
    id: '810103',
    name: '东区',
    short_name: '东区',
    f_id: '810100',
  },
  {
    id: '810104',
    name: '南区',
    short_name: '南区',
    f_id: '810100',
  },
  {
    id: '810201',
    name: '九龙城区',
    short_name: '九龙城',
    f_id: '810200',
  },
  {
    id: '810202',
    name: '油尖旺区',
    short_name: '油尖旺',
    f_id: '810200',
  },
  {
    id: '810203',
    name: '深水埗区',
    short_name: '深水埗',
    f_id: '810200',
  },
  {
    id: '810204',
    name: '黄大仙区',
    short_name: '黄大仙',
    f_id: '810200',
  },
  {
    id: '810205',
    name: '观塘区',
    short_name: '观塘',
    f_id: '810200',
  },
  {
    id: '810301',
    name: '北区',
    short_name: '北区',
    f_id: '810300',
  },
  {
    id: '810302',
    name: '大埔区',
    short_name: '大埔',
    f_id: '810300',
  },
  {
    id: '810303',
    name: '沙田区',
    short_name: '沙田',
    f_id: '810300',
  },
  {
    id: '810304',
    name: '西贡区',
    short_name: '西贡',
    f_id: '810300',
  },
  {
    id: '810305',
    name: '元朗区',
    short_name: '元朗',
    f_id: '810300',
  },
  {
    id: '810306',
    name: '屯门区',
    short_name: '屯门',
    f_id: '810300',
  },
  {
    id: '810307',
    name: '荃湾区',
    short_name: '荃湾',
    f_id: '810300',
  },
  {
    id: '810308',
    name: '葵青区',
    short_name: '葵青',
    f_id: '810300',
  },
  {
    id: '810309',
    name: '离岛区',
    short_name: '离岛',
    f_id: '810300',
  },
  {
    id: '419001001',
    name: '沁园街道',
    short_name: '沁园街道',
    f_id: '410881',
  },
  {
    id: '419001002',
    name: '济水街道',
    short_name: '济水街道',
    f_id: '410881',
  },
  {
    id: '419001003',
    name: '北海街道',
    short_name: '北海街道',
    f_id: '410881',
  },
  {
    id: '419001004',
    name: '天坛街道',
    short_name: '天坛街道',
    f_id: '410881',
  },
  {
    id: '419001005',
    name: '玉泉街道',
    short_name: '玉泉街道',
    f_id: '410881',
  },
  {
    id: '419001100',
    name: '克井镇',
    short_name: '克井镇',
    f_id: '410881',
  },
  {
    id: '419001101',
    name: '五龙口镇',
    short_name: '五龙口镇',
    f_id: '410881',
  },
  {
    id: '419001102',
    name: '轵城镇',
    short_name: '轵城镇',
    f_id: '410881',
  },
  {
    id: '419001103',
    name: '承留镇',
    short_name: '承留镇',
    f_id: '410881',
  },
  {
    id: '419001104',
    name: '邵原镇',
    short_name: '邵原镇',
    f_id: '410881',
  },
  {
    id: '419001105',
    name: '坡头镇',
    short_name: '坡头镇',
    f_id: '410881',
  },
  {
    id: '419001106',
    name: '梨林镇',
    short_name: '梨林镇',
    f_id: '410881',
  },
  {
    id: '419001107',
    name: '大峪镇',
    short_name: '大峪镇',
    f_id: '410881',
  },
  {
    id: '419001108',
    name: '思礼镇',
    short_name: '思礼镇',
    f_id: '410881',
  },
  {
    id: '419001109',
    name: '王屋镇',
    short_name: '王屋镇',
    f_id: '410881',
  },
  {
    id: '419001110',
    name: '下冶镇',
    short_name: '下冶镇',
    f_id: '410881',
  },
  {
    id: '429004001',
    name: '沙嘴街道',
    short_name: '沙嘴街道',
    f_id: '429004',
  },
  {
    id: '429004002',
    name: '干河街道',
    short_name: '干河街道',
    f_id: '429004',
  },
  {
    id: '429004003',
    name: '龙华山办事处',
    short_name: '龙华山办事处',
    f_id: '429004',
  },
  {
    id: '429004100',
    name: '郑场镇',
    short_name: '郑场镇',
    f_id: '429004',
  },
  {
    id: '429004101',
    name: '毛嘴镇',
    short_name: '毛嘴镇',
    f_id: '429004',
  },
  {
    id: '429004102',
    name: '豆河镇',
    short_name: '豆河镇',
    f_id: '429004',
  },
  {
    id: '429004103',
    name: '三伏潭镇',
    short_name: '三伏潭镇',
    f_id: '429004',
  },
  {
    id: '429004104',
    name: '胡场镇',
    short_name: '胡场镇',
    f_id: '429004',
  },
  {
    id: '429004105',
    name: '长倘口镇',
    short_name: '长倘口镇',
    f_id: '429004',
  },
  {
    id: '429004106',
    name: '西流河镇',
    short_name: '西流河镇',
    f_id: '429004',
  },
  {
    id: '429004107',
    name: '沙湖镇',
    short_name: '沙湖镇',
    f_id: '429004',
  },
  {
    id: '429004108',
    name: '杨林尾镇',
    short_name: '杨林尾镇',
    f_id: '429004',
  },
  {
    id: '429004109',
    name: '彭场镇',
    short_name: '彭场镇',
    f_id: '429004',
  },
  {
    id: '429004110',
    name: '张沟镇',
    short_name: '张沟镇',
    f_id: '429004',
  },
  {
    id: '429004111',
    name: '郭河镇',
    short_name: '郭河镇',
    f_id: '429004',
  },
  {
    id: '429004112',
    name: '沔城回族镇',
    short_name: '沔城回族镇',
    f_id: '429004',
  },
  {
    id: '429004113',
    name: '通海口镇',
    short_name: '通海口镇',
    f_id: '429004',
  },
  {
    id: '429004114',
    name: '陈场镇',
    short_name: '陈场镇',
    f_id: '429004',
  },
  {
    id: '429004400',
    name: '工业园区',
    short_name: '工业园区',
    f_id: '429004',
  },
  {
    id: '429004401',
    name: '九合垸原种场',
    short_name: '九合垸原种场',
    f_id: '429004',
  },
  {
    id: '429004402',
    name: '沙湖原种场',
    short_name: '沙湖原种场',
    f_id: '429004',
  },
  {
    id: '429004404',
    name: '五湖渔场',
    short_name: '五湖渔场',
    f_id: '429004',
  },
  {
    id: '429004405',
    name: '赵西垸林场',
    short_name: '赵西垸林场',
    f_id: '429004',
  },
  {
    id: '429004407',
    name: '畜禽良种场',
    short_name: '畜禽良种场',
    f_id: '429004',
  },
  {
    id: '429004408',
    name: '排湖风景区',
    short_name: '排湖风景区',
    f_id: '429004',
  },
  {
    id: '429005001',
    name: '园林办事处',
    short_name: '园林办事处',
    f_id: '429005',
  },
  {
    id: '429005002',
    name: '杨市办事处',
    short_name: '杨市办事处',
    f_id: '429005',
  },
  {
    id: '429005003',
    name: '周矶办事处',
    short_name: '周矶办事处',
    f_id: '429005',
  },
  {
    id: '429005004',
    name: '广华办事处',
    short_name: '广华办事处',
    f_id: '429005',
  },
  {
    id: '429005005',
    name: '泰丰办事处',
    short_name: '泰丰办事处',
    f_id: '429005',
  },
  {
    id: '429005006',
    name: '高场办事处',
    short_name: '高场办事处',
    f_id: '429005',
  },
  {
    id: '429005100',
    name: '竹根滩镇',
    short_name: '竹根滩镇',
    f_id: '429005',
  },
  {
    id: '429005101',
    name: '渔洋镇',
    short_name: '渔洋镇',
    f_id: '429005',
  },
  {
    id: '429005102',
    name: '王场镇',
    short_name: '王场镇',
    f_id: '429005',
  },
  {
    id: '429005103',
    name: '高石碑镇',
    short_name: '高石碑镇',
    f_id: '429005',
  },
  {
    id: '429005104',
    name: '熊口镇',
    short_name: '熊口镇',
    f_id: '429005',
  },
  {
    id: '429005105',
    name: '老新镇',
    short_name: '老新镇',
    f_id: '429005',
  },
  {
    id: '429005106',
    name: '浩口镇',
    short_name: '浩口镇',
    f_id: '429005',
  },
  {
    id: '429005107',
    name: '积玉口镇',
    short_name: '积玉口镇',
    f_id: '429005',
  },
  {
    id: '429005108',
    name: '张金镇',
    short_name: '张金镇',
    f_id: '429005',
  },
  {
    id: '429005109',
    name: '龙湾镇',
    short_name: '龙湾镇',
    f_id: '429005',
  },
  {
    id: '429005400',
    name: '江汉石油管理局',
    short_name: '江汉石油管理局',
    f_id: '429005',
  },
  {
    id: '429005401',
    name: '潜江经济开发区',
    short_name: '潜江经济开发区',
    f_id: '429005',
  },
  {
    id: '429005450',
    name: '周矶管理区',
    short_name: '周矶管理区',
    f_id: '429005',
  },
  {
    id: '429005451',
    name: '后湖管理区',
    short_name: '后湖管理区',
    f_id: '429005',
  },
  {
    id: '429005452',
    name: '熊口管理区',
    short_name: '熊口管理区',
    f_id: '429005',
  },
  {
    id: '429005453',
    name: '总口管理区',
    short_name: '总口管理区',
    f_id: '429005',
  },
  {
    id: '429005454',
    name: '白鹭湖管理区',
    short_name: '白鹭湖管理区',
    f_id: '429005',
  },
  {
    id: '429005455',
    name: '运粮湖管理区',
    short_name: '运粮湖管理区',
    f_id: '429005',
  },
  {
    id: '429005457',
    name: '浩口原种场',
    short_name: '浩口原种场',
    f_id: '429005',
  },
  {
    id: '429006001',
    name: '竟陵街道',
    short_name: '竟陵街道',
    f_id: '429006',
  },
  {
    id: '429006002',
    name: '侨乡街道开发区',
    short_name: '侨乡街道开发区',
    f_id: '429006',
  },
  {
    id: '429006003',
    name: '杨林街道',
    short_name: '杨林街道',
    f_id: '429006',
  },
  {
    id: '429006100',
    name: '多宝镇',
    short_name: '多宝镇',
    f_id: '429006',
  },
  {
    id: '429006101',
    name: '拖市镇',
    short_name: '拖市镇',
    f_id: '429006',
  },
  {
    id: '429006102',
    name: '张港镇',
    short_name: '张港镇',
    f_id: '429006',
  },
  {
    id: '429006103',
    name: '蒋场镇',
    short_name: '蒋场镇',
    f_id: '429006',
  },
  {
    id: '429006104',
    name: '汪场镇',
    short_name: '汪场镇',
    f_id: '429006',
  },
  {
    id: '429006105',
    name: '渔薪镇',
    short_name: '渔薪镇',
    f_id: '429006',
  },
  {
    id: '429006106',
    name: '黄潭镇',
    short_name: '黄潭镇',
    f_id: '429006',
  },
  {
    id: '429006107',
    name: '岳口镇',
    short_name: '岳口镇',
    f_id: '429006',
  },
  {
    id: '429006108',
    name: '横林镇',
    short_name: '横林镇',
    f_id: '429006',
  },
  {
    id: '429006109',
    name: '彭市镇',
    short_name: '彭市镇',
    f_id: '429006',
  },
  {
    id: '429006110',
    name: '麻洋镇',
    short_name: '麻洋镇',
    f_id: '429006',
  },
  {
    id: '429006111',
    name: '多祥镇',
    short_name: '多祥镇',
    f_id: '429006',
  },
  {
    id: '429006112',
    name: '干驿镇',
    short_name: '干驿镇',
    f_id: '429006',
  },
  {
    id: '429006113',
    name: '马湾镇',
    short_name: '马湾镇',
    f_id: '429006',
  },
  {
    id: '429006114',
    name: '卢市镇',
    short_name: '卢市镇',
    f_id: '429006',
  },
  {
    id: '429006115',
    name: '小板镇',
    short_name: '小板镇',
    f_id: '429006',
  },
  {
    id: '429006116',
    name: '九真镇',
    short_name: '九真镇',
    f_id: '429006',
  },
  {
    id: '429006118',
    name: '皂市镇',
    short_name: '皂市镇',
    f_id: '429006',
  },
  {
    id: '429006119',
    name: '胡市镇',
    short_name: '胡市镇',
    f_id: '429006',
  },
  {
    id: '429006120',
    name: '石河镇',
    short_name: '石河镇',
    f_id: '429006',
  },
  {
    id: '429006121',
    name: '佛子山镇',
    short_name: '佛子山镇',
    f_id: '429006',
  },
  {
    id: '429006201',
    name: '净潭乡',
    short_name: '净潭乡',
    f_id: '429006',
  },
  {
    id: '429006450',
    name: '蒋湖农场',
    short_name: '蒋湖农场',
    f_id: '429006',
  },
  {
    id: '429006451',
    name: '白茅湖农场',
    short_name: '白茅湖农场',
    f_id: '429006',
  },
  {
    id: '429006452',
    name: '沉湖管委会',
    short_name: '沉湖管委会',
    f_id: '429006',
  },
  {
    id: '429021100',
    name: '松柏镇',
    short_name: '松柏镇',
    f_id: '429021',
  },
  {
    id: '429021101',
    name: '阳日镇',
    short_name: '阳日镇',
    f_id: '429021',
  },
  {
    id: '429021102',
    name: '木鱼镇',
    short_name: '木鱼镇',
    f_id: '429021',
  },
  {
    id: '429021103',
    name: '红坪镇',
    short_name: '红坪镇',
    f_id: '429021',
  },
  {
    id: '429021104',
    name: '新华镇',
    short_name: '新华镇',
    f_id: '429021',
  },
  {
    id: '429021105',
    name: '九湖镇',
    short_name: '九湖镇',
    f_id: '429021',
  },
  {
    id: '429021200',
    name: '宋洛乡',
    short_name: '宋洛乡',
    f_id: '429021',
  },
  {
    id: '429021202',
    name: '下谷坪土家族乡',
    short_name: '下谷坪土家族乡',
    f_id: '429021',
  },
  {
    id: '441901003',
    name: '东城街道',
    short_name: '东城街道',
    f_id: '441900',
  },
  {
    id: '441901004',
    name: '南城街道',
    short_name: '南城街道',
    f_id: '441900',
  },
  {
    id: '441901005',
    name: '万江街道',
    short_name: '万江街道',
    f_id: '441900',
  },
  {
    id: '441901006',
    name: '莞城街道',
    short_name: '莞城街道',
    f_id: '441900',
  },
  {
    id: '441901101',
    name: '石碣镇',
    short_name: '石碣镇',
    f_id: '441900',
  },
  {
    id: '441901102',
    name: '石龙镇',
    short_name: '石龙镇',
    f_id: '441900',
  },
  {
    id: '441901103',
    name: '茶山镇',
    short_name: '茶山镇',
    f_id: '441900',
  },
  {
    id: '441901104',
    name: '石排镇',
    short_name: '石排镇',
    f_id: '441900',
  },
  {
    id: '441901105',
    name: '企石镇',
    short_name: '企石镇',
    f_id: '441900',
  },
  {
    id: '441901106',
    name: '横沥镇',
    short_name: '横沥镇',
    f_id: '441900',
  },
  {
    id: '441901107',
    name: '桥头镇',
    short_name: '桥头镇',
    f_id: '441900',
  },
  {
    id: '441901108',
    name: '谢岗镇',
    short_name: '谢岗镇',
    f_id: '441900',
  },
  {
    id: '441901109',
    name: '东坑镇',
    short_name: '东坑镇',
    f_id: '441900',
  },
  {
    id: '441901110',
    name: '常平镇',
    short_name: '常平镇',
    f_id: '441900',
  },
  {
    id: '441901111',
    name: '寮步镇',
    short_name: '寮步镇',
    f_id: '441900',
  },
  {
    id: '441901112',
    name: '樟木头镇',
    short_name: '樟木头镇',
    f_id: '441900',
  },
  {
    id: '441901113',
    name: '大朗镇',
    short_name: '大朗镇',
    f_id: '441900',
  },
  {
    id: '441901114',
    name: '黄江镇',
    short_name: '黄江镇',
    f_id: '441900',
  },
  {
    id: '441901115',
    name: '清溪镇',
    short_name: '清溪镇',
    f_id: '441900',
  },
  {
    id: '441901116',
    name: '塘厦镇',
    short_name: '塘厦镇',
    f_id: '441900',
  },
  {
    id: '441901117',
    name: '凤岗镇',
    short_name: '凤岗镇',
    f_id: '441900',
  },
  {
    id: '441901118',
    name: '大岭山镇',
    short_name: '大岭山镇',
    f_id: '441900',
  },
  {
    id: '441901119',
    name: '长安镇',
    short_name: '长安镇',
    f_id: '441900',
  },
  {
    id: '441901121',
    name: '虎门镇',
    short_name: '虎门镇',
    f_id: '441900',
  },
  {
    id: '441901122',
    name: '厚街镇',
    short_name: '厚街镇',
    f_id: '441900',
  },
  {
    id: '441901123',
    name: '沙田镇',
    short_name: '沙田镇',
    f_id: '441900',
  },
  {
    id: '441901124',
    name: '道滘镇',
    short_name: '道滘镇',
    f_id: '441900',
  },
  {
    id: '441901125',
    name: '洪梅镇',
    short_name: '洪梅镇',
    f_id: '441900',
  },
  {
    id: '441901126',
    name: '麻涌镇',
    short_name: '麻涌镇',
    f_id: '441900',
  },
  {
    id: '441901127',
    name: '望牛墩镇',
    short_name: '望牛墩镇',
    f_id: '441900',
  },
  {
    id: '441901128',
    name: '中堂镇',
    short_name: '中堂镇',
    f_id: '441900',
  },
  {
    id: '441901129',
    name: '高埗镇',
    short_name: '高埗镇',
    f_id: '441900',
  },
  {
    id: '441901401',
    name: '松山湖管委会',
    short_name: '松山湖管委会',
    f_id: '441900',
  },
  {
    id: '441901402',
    name: '虎门港管委会',
    short_name: '虎门港管委会',
    f_id: '441900',
  },
  {
    id: '441901403',
    name: '东莞生态园',
    short_name: '东莞生态园',
    f_id: '441900',
  },
  {
    id: '442001001',
    name: '石岐区街道',
    short_name: '石岐区街道',
    f_id: '442000',
  },
  {
    id: '442001002',
    name: '东区街道',
    short_name: '东区街道',
    f_id: '442000',
  },
  {
    id: '442001003',
    name: '火炬开发区街道',
    short_name: '火炬开发区街道',
    f_id: '442000',
  },
  {
    id: '442001004',
    name: '西区街道',
    short_name: '西区街道',
    f_id: '442000',
  },
  {
    id: '442001005',
    name: '南区街道',
    short_name: '南区街道',
    f_id: '442000',
  },
  {
    id: '442001006',
    name: '五桂山街道',
    short_name: '五桂山街道',
    f_id: '442000',
  },
  {
    id: '442001100',
    name: '小榄镇',
    short_name: '小榄镇',
    f_id: '442000',
  },
  {
    id: '442001101',
    name: '黄圃镇',
    short_name: '黄圃镇',
    f_id: '442000',
  },
  {
    id: '442001102',
    name: '民众镇',
    short_name: '民众镇',
    f_id: '442000',
  },
  {
    id: '442001103',
    name: '东凤镇',
    short_name: '东凤镇',
    f_id: '442000',
  },
  {
    id: '442001104',
    name: '东升镇',
    short_name: '东升镇',
    f_id: '442000',
  },
  {
    id: '442001105',
    name: '古镇镇',
    short_name: '古镇镇',
    f_id: '442000',
  },
  {
    id: '442001106',
    name: '沙溪镇',
    short_name: '沙溪镇',
    f_id: '442000',
  },
  {
    id: '442001107',
    name: '坦洲镇',
    short_name: '坦洲镇',
    f_id: '442000',
  },
  {
    id: '442001108',
    name: '港口镇',
    short_name: '港口镇',
    f_id: '442000',
  },
  {
    id: '442001109',
    name: '三角镇',
    short_name: '三角镇',
    f_id: '442000',
  },
  {
    id: '442001110',
    name: '横栏镇',
    short_name: '横栏镇',
    f_id: '442000',
  },
  {
    id: '442001111',
    name: '南头镇',
    short_name: '南头镇',
    f_id: '442000',
  },
  {
    id: '442001112',
    name: '阜沙镇',
    short_name: '阜沙镇',
    f_id: '442000',
  },
  {
    id: '442001113',
    name: '南朗镇',
    short_name: '南朗镇',
    f_id: '442000',
  },
  {
    id: '442001114',
    name: '三乡镇',
    short_name: '三乡镇',
    f_id: '442000',
  },
  {
    id: '442001115',
    name: '板芙镇',
    short_name: '板芙镇',
    f_id: '442000',
  },
  {
    id: '442001116',
    name: '大涌镇',
    short_name: '大涌镇',
    f_id: '442000',
  },
  {
    id: '442001117',
    name: '神湾镇',
    short_name: '神湾镇',
    f_id: '442000',
  },
  {
    id: '460201100',
    name: '海棠湾镇',
    short_name: '海棠湾镇',
    f_id: '460200',
  },
  {
    id: '460201101',
    name: '吉阳镇',
    short_name: '吉阳镇',
    f_id: '460200',
  },
  {
    id: '460201102',
    name: '凤凰镇',
    short_name: '凤凰镇',
    f_id: '460200',
  },
  {
    id: '460201103',
    name: '崖城镇',
    short_name: '崖城镇',
    f_id: '460200',
  },
  {
    id: '460201104',
    name: '天涯镇',
    short_name: '天涯镇',
    f_id: '460200',
  },
  {
    id: '460201105',
    name: '育才镇',
    short_name: '育才镇',
    f_id: '460200',
  },
  {
    id: '460201400',
    name: '国营南田农场',
    short_name: '国营南田农场',
    f_id: '460200',
  },
  {
    id: '460201401',
    name: '国营南新农场',
    short_name: '国营南新农场',
    f_id: '460200',
  },
  {
    id: '460201403',
    name: '国营立才农场',
    short_name: '国营立才农场',
    f_id: '460200',
  },
  {
    id: '460201404',
    name: '国营南滨农场',
    short_name: '国营南滨农场',
    f_id: '460200',
  },
  {
    id: '460201451',
    name: '河西区街道',
    short_name: '河西区街道',
    f_id: '460200',
  },
  {
    id: '460201452',
    name: '河东区街道',
    short_name: '河东区街道',
    f_id: '460200',
  },
  {
    id: '469001100',
    name: '通什镇',
    short_name: '通什镇',
    f_id: '469001',
  },
  {
    id: '469001101',
    name: '南圣镇',
    short_name: '南圣镇',
    f_id: '469001',
  },
  {
    id: '469001102',
    name: '毛阳镇',
    short_name: '毛阳镇',
    f_id: '469001',
  },
  {
    id: '469001103',
    name: '番阳镇',
    short_name: '番阳镇',
    f_id: '469001',
  },
  {
    id: '469001200',
    name: '畅好乡',
    short_name: '畅好乡',
    f_id: '469001',
  },
  {
    id: '469001201',
    name: '毛道乡',
    short_name: '毛道乡',
    f_id: '469001',
  },
  {
    id: '469001202',
    name: '水满乡',
    short_name: '水满乡',
    f_id: '469001',
  },
  {
    id: '469001400',
    name: '国营畅好农场',
    short_name: '国营畅好农场',
    f_id: '469001',
  },
  {
    id: '469002100',
    name: '嘉积镇',
    short_name: '嘉积镇',
    f_id: '469002',
  },
  {
    id: '469002101',
    name: '万泉镇',
    short_name: '万泉镇',
    f_id: '469002',
  },
  {
    id: '469002102',
    name: '石壁镇',
    short_name: '石壁镇',
    f_id: '469002',
  },
  {
    id: '469002103',
    name: '中原镇',
    short_name: '中原镇',
    f_id: '469002',
  },
  {
    id: '469002104',
    name: '博鳌镇',
    short_name: '博鳌镇',
    f_id: '469002',
  },
  {
    id: '469002105',
    name: '阳江镇',
    short_name: '阳江镇',
    f_id: '469002',
  },
  {
    id: '469002106',
    name: '龙江镇',
    short_name: '龙江镇',
    f_id: '469002',
  },
  {
    id: '469002107',
    name: '潭门镇',
    short_name: '潭门镇',
    f_id: '469002',
  },
  {
    id: '469002108',
    name: '塔洋镇',
    short_name: '塔洋镇',
    f_id: '469002',
  },
  {
    id: '469002109',
    name: '长坡镇',
    short_name: '长坡镇',
    f_id: '469002',
  },
  {
    id: '469002110',
    name: '大路镇',
    short_name: '大路镇',
    f_id: '469002',
  },
  {
    id: '469002111',
    name: '会山镇',
    short_name: '会山镇',
    f_id: '469002',
  },
  {
    id: '469002400',
    name: '国营东太农场',
    short_name: '国营东太农场',
    f_id: '469002',
  },
  {
    id: '469002402',
    name: '国营东红农场',
    short_name: '国营东红农场',
    f_id: '469002',
  },
  {
    id: '469002403',
    name: '国营东升农场',
    short_name: '国营东升农场',
    f_id: '469002',
  },
  {
    id: '469002500',
    name: '彬村山华侨农场',
    short_name: '彬村山华侨农场',
    f_id: '469002',
  },
  {
    id: '469003100',
    name: '那大镇',
    short_name: '那大镇',
    f_id: '469003',
  },
  {
    id: '469003101',
    name: '和庆镇',
    short_name: '和庆镇',
    f_id: '469003',
  },
  {
    id: '469003102',
    name: '南丰镇',
    short_name: '南丰镇',
    f_id: '469003',
  },
  {
    id: '469003103',
    name: '大成镇',
    short_name: '大成镇',
    f_id: '469003',
  },
  {
    id: '469003104',
    name: '雅星镇',
    short_name: '雅星镇',
    f_id: '469003',
  },
  {
    id: '469003105',
    name: '兰洋镇',
    short_name: '兰洋镇',
    f_id: '469003',
  },
  {
    id: '469003106',
    name: '光村镇',
    short_name: '光村镇',
    f_id: '469003',
  },
  {
    id: '469003107',
    name: '木棠镇',
    short_name: '木棠镇',
    f_id: '469003',
  },
  {
    id: '469003108',
    name: '海头镇',
    short_name: '海头镇',
    f_id: '469003',
  },
  {
    id: '469003109',
    name: '峨蔓镇',
    short_name: '峨蔓镇',
    f_id: '469003',
  },
  {
    id: '469003110',
    name: '三都镇',
    short_name: '三都镇',
    f_id: '469003',
  },
  {
    id: '469003111',
    name: '王五镇',
    short_name: '王五镇',
    f_id: '469003',
  },
  {
    id: '469003112',
    name: '白马井镇',
    short_name: '白马井镇',
    f_id: '469003',
  },
  {
    id: '469003113',
    name: '中和镇',
    short_name: '中和镇',
    f_id: '469003',
  },
  {
    id: '469003114',
    name: '排浦镇',
    short_name: '排浦镇',
    f_id: '469003',
  },
  {
    id: '469003115',
    name: '东成镇',
    short_name: '东成镇',
    f_id: '469003',
  },
  {
    id: '469003116',
    name: '新州镇',
    short_name: '新州镇',
    f_id: '469003',
  },
  {
    id: '469003400',
    name: '国营西培农场',
    short_name: '国营西培农场',
    f_id: '469003',
  },
  {
    id: '469003404',
    name: '国营西联农场',
    short_name: '国营西联农场',
    f_id: '469003',
  },
  {
    id: '469003405',
    name: '国营蓝洋农场',
    short_name: '国营蓝洋农场',
    f_id: '469003',
  },
  {
    id: '469003407',
    name: '国营八一农场',
    short_name: '国营八一农场',
    f_id: '469003',
  },
  {
    id: '469003499',
    name: '洋浦经济开发区',
    short_name: '洋浦经济开发区',
    f_id: '469003',
  },
  {
    id: '469003500',
    name: '华南热作学院',
    short_name: '华南热作学院',
    f_id: '469003',
  },
  {
    id: '469005100',
    name: '文城镇',
    short_name: '文城镇',
    f_id: '469005',
  },
  {
    id: '469005101',
    name: '重兴镇',
    short_name: '重兴镇',
    f_id: '469005',
  },
  {
    id: '469005102',
    name: '蓬莱镇',
    short_name: '蓬莱镇',
    f_id: '469005',
  },
  {
    id: '469005103',
    name: '会文镇',
    short_name: '会文镇',
    f_id: '469005',
  },
  {
    id: '469005104',
    name: '东路镇',
    short_name: '东路镇',
    f_id: '469005',
  },
  {
    id: '469005105',
    name: '潭牛镇',
    short_name: '潭牛镇',
    f_id: '469005',
  },
  {
    id: '469005106',
    name: '东阁镇',
    short_name: '东阁镇',
    f_id: '469005',
  },
  {
    id: '469005107',
    name: '文教镇',
    short_name: '文教镇',
    f_id: '469005',
  },
  {
    id: '469005108',
    name: '东郊镇',
    short_name: '东郊镇',
    f_id: '469005',
  },
  {
    id: '469005109',
    name: '龙楼镇',
    short_name: '龙楼镇',
    f_id: '469005',
  },
  {
    id: '469005110',
    name: '昌洒镇',
    short_name: '昌洒镇',
    f_id: '469005',
  },
  {
    id: '469005111',
    name: '翁田镇',
    short_name: '翁田镇',
    f_id: '469005',
  },
  {
    id: '469005112',
    name: '抱罗镇',
    short_name: '抱罗镇',
    f_id: '469005',
  },
  {
    id: '469005113',
    name: '冯坡镇',
    short_name: '冯坡镇',
    f_id: '469005',
  },
  {
    id: '469005114',
    name: '锦山镇',
    short_name: '锦山镇',
    f_id: '469005',
  },
  {
    id: '469005115',
    name: '铺前镇',
    short_name: '铺前镇',
    f_id: '469005',
  },
  {
    id: '469005116',
    name: '公坡镇',
    short_name: '公坡镇',
    f_id: '469005',
  },
  {
    id: '469005400',
    name: '国营东路农场',
    short_name: '国营东路农场',
    f_id: '469005',
  },
  {
    id: '469005401',
    name: '国营南阳农场',
    short_name: '国营南阳农场',
    f_id: '469005',
  },
  {
    id: '469005402',
    name: '国营罗豆农场',
    short_name: '国营罗豆农场',
    f_id: '469005',
  },
  {
    id: '469006100',
    name: '万城镇',
    short_name: '万城镇',
    f_id: '469006',
  },
  {
    id: '469006101',
    name: '龙滚镇',
    short_name: '龙滚镇',
    f_id: '469006',
  },
  {
    id: '469006102',
    name: '和乐镇',
    short_name: '和乐镇',
    f_id: '469006',
  },
  {
    id: '469006103',
    name: '后安镇',
    short_name: '后安镇',
    f_id: '469006',
  },
  {
    id: '469006104',
    name: '大茂镇',
    short_name: '大茂镇',
    f_id: '469006',
  },
  {
    id: '469006105',
    name: '东澳镇',
    short_name: '东澳镇',
    f_id: '469006',
  },
  {
    id: '469006106',
    name: '礼纪镇',
    short_name: '礼纪镇',
    f_id: '469006',
  },
  {
    id: '469006107',
    name: '长丰镇',
    short_name: '长丰镇',
    f_id: '469006',
  },
  {
    id: '469006108',
    name: '山根镇',
    short_name: '山根镇',
    f_id: '469006',
  },
  {
    id: '469006109',
    name: '北大镇',
    short_name: '北大镇',
    f_id: '469006',
  },
  {
    id: '469006110',
    name: '南桥镇',
    short_name: '南桥镇',
    f_id: '469006',
  },
  {
    id: '469006111',
    name: '三更罗镇',
    short_name: '三更罗镇',
    f_id: '469006',
  },
  {
    id: '469006400',
    name: '国营东兴农场',
    short_name: '国营东兴农场',
    f_id: '469006',
  },
  {
    id: '469006401',
    name: '国营东和农场',
    short_name: '国营东和农场',
    f_id: '469006',
  },
  {
    id: '469006404',
    name: '国营新中农场',
    short_name: '国营新中农场',
    f_id: '469006',
  },
  {
    id: '469006500',
    name: '兴隆华侨农场',
    short_name: '兴隆华侨农场',
    f_id: '469006',
  },
  {
    id: '469006501',
    name: '地方国营六连林场',
    short_name: '地方国营六连林场',
    f_id: '469006',
  },
  {
    id: '469007100',
    name: '八所镇',
    short_name: '八所镇',
    f_id: '469007',
  },
  {
    id: '469007101',
    name: '东河镇',
    short_name: '东河镇',
    f_id: '469007',
  },
  {
    id: '469007102',
    name: '大田镇',
    short_name: '大田镇',
    f_id: '469007',
  },
  {
    id: '469007103',
    name: '感城镇',
    short_name: '感城镇',
    f_id: '469007',
  },
  {
    id: '469007104',
    name: '板桥镇',
    short_name: '板桥镇',
    f_id: '469007',
  },
  {
    id: '469007105',
    name: '三家镇',
    short_name: '三家镇',
    f_id: '469007',
  },
  {
    id: '469007106',
    name: '四更镇',
    short_name: '四更镇',
    f_id: '469007',
  },
  {
    id: '469007107',
    name: '新龙镇',
    short_name: '新龙镇',
    f_id: '469007',
  },
  {
    id: '469007200',
    name: '天安乡',
    short_name: '天安乡',
    f_id: '469007',
  },
  {
    id: '469007201',
    name: '江边乡',
    short_name: '江边乡',
    f_id: '469007',
  },
  {
    id: '469007400',
    name: '国营广坝农场',
    short_name: '国营广坝农场',
    f_id: '469007',
  },
  {
    id: '469007500',
    name: '东方华侨农场',
    short_name: '东方华侨农场',
    f_id: '469007',
  },
  {
    id: '469021100',
    name: '定城镇',
    short_name: '定城镇',
    f_id: '469025',
  },
  {
    id: '469021101',
    name: '新竹镇',
    short_name: '新竹镇',
    f_id: '469025',
  },
  {
    id: '469021102',
    name: '龙湖镇',
    short_name: '龙湖镇',
    f_id: '469025',
  },
  {
    id: '469021103',
    name: '黄竹镇',
    short_name: '黄竹镇',
    f_id: '469025',
  },
  {
    id: '469021104',
    name: '雷鸣镇',
    short_name: '雷鸣镇',
    f_id: '469025',
  },
  {
    id: '469021105',
    name: '龙门镇',
    short_name: '龙门镇',
    f_id: '469025',
  },
  {
    id: '469021106',
    name: '龙河镇',
    short_name: '龙河镇',
    f_id: '469025',
  },
  {
    id: '469021107',
    name: '岭口镇',
    short_name: '岭口镇',
    f_id: '469025',
  },
  {
    id: '469021108',
    name: '翰林镇',
    short_name: '翰林镇',
    f_id: '469025',
  },
  {
    id: '469021109',
    name: '富文镇',
    short_name: '富文镇',
    f_id: '469025',
  },
  {
    id: '469021400',
    name: '国营中瑞农场',
    short_name: '国营中瑞农场',
    f_id: '469025',
  },
  {
    id: '469021401',
    name: '国营南海农场',
    short_name: '国营南海农场',
    f_id: '469025',
  },
  {
    id: '469021402',
    name: '国营金鸡岭农场',
    short_name: '国营金鸡岭农场',
    f_id: '469025',
  },
  {
    id: '469022100',
    name: '屯城镇',
    short_name: '屯城镇',
    f_id: '469026',
  },
  {
    id: '469022101',
    name: '新兴镇',
    short_name: '新兴镇',
    f_id: '469026',
  },
  {
    id: '469022102',
    name: '枫木镇',
    short_name: '枫木镇',
    f_id: '469026',
  },
  {
    id: '469022103',
    name: '乌坡镇',
    short_name: '乌坡镇',
    f_id: '469026',
  },
  {
    id: '469022104',
    name: '南吕镇',
    short_name: '南吕镇',
    f_id: '469026',
  },
  {
    id: '469022105',
    name: '南坤镇',
    short_name: '南坤镇',
    f_id: '469026',
  },
  {
    id: '469022106',
    name: '坡心镇',
    short_name: '坡心镇',
    f_id: '469026',
  },
  {
    id: '469022107',
    name: '西昌镇',
    short_name: '西昌镇',
    f_id: '469026',
  },
  {
    id: '469022400',
    name: '国营中建农场',
    short_name: '国营中建农场',
    f_id: '469026',
  },
  {
    id: '469022401',
    name: '国营中坤农场',
    short_name: '国营中坤农场',
    f_id: '469026',
  },
  {
    id: '469023100',
    name: '金江镇',
    short_name: '金江镇',
    f_id: '469027',
  },
  {
    id: '469023101',
    name: '老城镇',
    short_name: '老城镇',
    f_id: '469027',
  },
  {
    id: '469023102',
    name: '瑞溪镇',
    short_name: '瑞溪镇',
    f_id: '469027',
  },
  {
    id: '469023103',
    name: '永发镇',
    short_name: '永发镇',
    f_id: '469027',
  },
  {
    id: '469023104',
    name: '加乐镇',
    short_name: '加乐镇',
    f_id: '469027',
  },
  {
    id: '469023105',
    name: '文儒镇',
    short_name: '文儒镇',
    f_id: '469027',
  },
  {
    id: '469023106',
    name: '中兴镇',
    short_name: '中兴镇',
    f_id: '469027',
  },
  {
    id: '469023107',
    name: '仁兴镇',
    short_name: '仁兴镇',
    f_id: '469027',
  },
  {
    id: '469023108',
    name: '福山镇',
    short_name: '福山镇',
    f_id: '469027',
  },
  {
    id: '469023109',
    name: '桥头镇',
    short_name: '桥头镇',
    f_id: '469027',
  },
  {
    id: '469023110',
    name: '大丰镇',
    short_name: '大丰镇',
    f_id: '469027',
  },
  {
    id: '469023400',
    name: '国营红光农场',
    short_name: '国营红光农场',
    f_id: '469027',
  },
  {
    id: '469023402',
    name: '国营西达农场',
    short_name: '国营西达农场',
    f_id: '469027',
  },
  {
    id: '469023405',
    name: '国营金安农场',
    short_name: '国营金安农场',
    f_id: '469027',
  },
  {
    id: '469024100',
    name: '临城镇',
    short_name: '临城镇',
    f_id: '469028',
  },
  {
    id: '469024101',
    name: '波莲镇',
    short_name: '波莲镇',
    f_id: '469028',
  },
  {
    id: '469024102',
    name: '东英镇',
    short_name: '东英镇',
    f_id: '469028',
  },
  {
    id: '469024103',
    name: '博厚镇',
    short_name: '博厚镇',
    f_id: '469028',
  },
  {
    id: '469024104',
    name: '皇桐镇',
    short_name: '皇桐镇',
    f_id: '469028',
  },
  {
    id: '469024105',
    name: '多文镇',
    short_name: '多文镇',
    f_id: '469028',
  },
  {
    id: '469024106',
    name: '和舍镇',
    short_name: '和舍镇',
    f_id: '469028',
  },
  {
    id: '469024107',
    name: '南宝镇',
    short_name: '南宝镇',
    f_id: '469028',
  },
  {
    id: '469024108',
    name: '新盈镇',
    short_name: '新盈镇',
    f_id: '469028',
  },
  {
    id: '469024109',
    name: '调楼镇',
    short_name: '调楼镇',
    f_id: '469028',
  },
  {
    id: '469024400',
    name: '国营红华农场',
    short_name: '国营红华农场',
    f_id: '469028',
  },
  {
    id: '469024401',
    name: '国营加来农场',
    short_name: '国营加来农场',
    f_id: '469028',
  },
  {
    id: '469025100',
    name: '牙叉镇',
    short_name: '牙叉镇',
    f_id: '469030',
  },
  {
    id: '469025101',
    name: '七坊镇',
    short_name: '七坊镇',
    f_id: '469030',
  },
  {
    id: '469025102',
    name: '邦溪镇',
    short_name: '邦溪镇',
    f_id: '469030',
  },
  {
    id: '469025103',
    name: '打安镇',
    short_name: '打安镇',
    f_id: '469030',
  },
  {
    id: '469025200',
    name: '细水乡',
    short_name: '细水乡',
    f_id: '469030',
  },
  {
    id: '469025201',
    name: '元门乡',
    short_name: '元门乡',
    f_id: '469030',
  },
  {
    id: '469025202',
    name: '南开乡',
    short_name: '南开乡',
    f_id: '469030',
  },
  {
    id: '469025203',
    name: '阜龙乡',
    short_name: '阜龙乡',
    f_id: '469030',
  },
  {
    id: '469025204',
    name: '青松乡',
    short_name: '青松乡',
    f_id: '469030',
  },
  {
    id: '469025205',
    name: '金波乡',
    short_name: '金波乡',
    f_id: '469030',
  },
  {
    id: '469025206',
    name: '荣邦乡',
    short_name: '荣邦乡',
    f_id: '469030',
  },
  {
    id: '469025401',
    name: '国营白沙农场',
    short_name: '国营白沙农场',
    f_id: '469030',
  },
  {
    id: '469025404',
    name: '国营龙江农场',
    short_name: '国营龙江农场',
    f_id: '469030',
  },
  {
    id: '469025408',
    name: '国营邦溪农场',
    short_name: '国营邦溪农场',
    f_id: '469030',
  },
  {
    id: '469026100',
    name: '石碌镇',
    short_name: '石碌镇',
    f_id: '469031',
  },
  {
    id: '469026101',
    name: '叉河镇',
    short_name: '叉河镇',
    f_id: '469031',
  },
  {
    id: '469026102',
    name: '十月田镇',
    short_name: '十月田镇',
    f_id: '469031',
  },
  {
    id: '469026103',
    name: '乌烈镇',
    short_name: '乌烈镇',
    f_id: '469031',
  },
  {
    id: '469026104',
    name: '昌化镇',
    short_name: '昌化镇',
    f_id: '469031',
  },
  {
    id: '469026105',
    name: '海尾镇',
    short_name: '海尾镇',
    f_id: '469031',
  },
  {
    id: '469026106',
    name: '七叉镇',
    short_name: '七叉镇',
    f_id: '469031',
  },
  {
    id: '469026200',
    name: '王下乡',
    short_name: '王下乡',
    f_id: '469031',
  },
  {
    id: '469026401',
    name: '国营红林农场',
    short_name: '国营红林农场',
    f_id: '469031',
  },
  {
    id: '469026500',
    name: '国营霸王岭林场',
    short_name: '国营霸王岭林场',
    f_id: '469031',
  },
  {
    id: '469026501',
    name: '海南矿业联合有限公司',
    short_name: '海南矿业联合有限公司',
    f_id: '469031',
  },
  {
    id: '469027100',
    name: '抱由镇',
    short_name: '抱由镇',
    f_id: '469033',
  },
  {
    id: '469027101',
    name: '万冲镇',
    short_name: '万冲镇',
    f_id: '469033',
  },
  {
    id: '469027102',
    name: '大安镇',
    short_name: '大安镇',
    f_id: '469033',
  },
  {
    id: '469027103',
    name: '志仲镇',
    short_name: '志仲镇',
    f_id: '469033',
  },
  {
    id: '469027104',
    name: '千家镇',
    short_name: '千家镇',
    f_id: '469033',
  },
  {
    id: '469027105',
    name: '九所镇',
    short_name: '九所镇',
    f_id: '469033',
  },
  {
    id: '469027106',
    name: '利国镇',
    short_name: '利国镇',
    f_id: '469033',
  },
  {
    id: '469027107',
    name: '黄流镇',
    short_name: '黄流镇',
    f_id: '469033',
  },
  {
    id: '469027108',
    name: '佛罗镇',
    short_name: '佛罗镇',
    f_id: '469033',
  },
  {
    id: '469027109',
    name: '尖峰镇',
    short_name: '尖峰镇',
    f_id: '469033',
  },
  {
    id: '469027110',
    name: '莺歌海镇',
    short_name: '莺歌海镇',
    f_id: '469033',
  },
  {
    id: '469027401',
    name: '国营山荣农场',
    short_name: '国营山荣农场',
    f_id: '469033',
  },
  {
    id: '469027402',
    name: '国营乐光农场',
    short_name: '国营乐光农场',
    f_id: '469033',
  },
  {
    id: '469027405',
    name: '国营保国农场',
    short_name: '国营保国农场',
    f_id: '469033',
  },
  {
    id: '469027500',
    name: '国营尖峰岭林业公司',
    short_name: '国营尖峰岭林业公司',
    f_id: '469033',
  },
  {
    id: '469027501',
    name: '国营莺歌海盐场',
    short_name: '国营莺歌海盐场',
    f_id: '469033',
  },
  {
    id: '469028100',
    name: '椰林镇',
    short_name: '椰林镇',
    f_id: '469034',
  },
  {
    id: '469028101',
    name: '光坡镇',
    short_name: '光坡镇',
    f_id: '469034',
  },
  {
    id: '469028102',
    name: '三才镇',
    short_name: '三才镇',
    f_id: '469034',
  },
  {
    id: '469028103',
    name: '英州镇',
    short_name: '英州镇',
    f_id: '469034',
  },
  {
    id: '469028104',
    name: '隆广镇',
    short_name: '隆广镇',
    f_id: '469034',
  },
  {
    id: '469028105',
    name: '文罗镇',
    short_name: '文罗镇',
    f_id: '469034',
  },
  {
    id: '469028106',
    name: '本号镇',
    short_name: '本号镇',
    f_id: '469034',
  },
  {
    id: '469028107',
    name: '新村镇',
    short_name: '新村镇',
    f_id: '469034',
  },
  {
    id: '469028108',
    name: '黎安镇',
    short_name: '黎安镇',
    f_id: '469034',
  },
  {
    id: '469028200',
    name: '提蒙乡',
    short_name: '提蒙乡',
    f_id: '469034',
  },
  {
    id: '469028201',
    name: '群英乡',
    short_name: '群英乡',
    f_id: '469034',
  },
  {
    id: '469028400',
    name: '国营岭门农场',
    short_name: '国营岭门农场',
    f_id: '469034',
  },
  {
    id: '469028401',
    name: '国营南平农场',
    short_name: '国营南平农场',
    f_id: '469034',
  },
  {
    id: '469028500',
    name: '国营吊罗山林业公司',
    short_name: '国营吊罗山林业公司',
    f_id: '469034',
  },
  {
    id: '469029100',
    name: '保城镇',
    short_name: '保城镇',
    f_id: '469035',
  },
  {
    id: '469029101',
    name: '什玲镇',
    short_name: '什玲镇',
    f_id: '469035',
  },
  {
    id: '469029102',
    name: '加茂镇',
    short_name: '加茂镇',
    f_id: '469035',
  },
  {
    id: '469029103',
    name: '响水镇',
    short_name: '响水镇',
    f_id: '469035',
  },
  {
    id: '469029104',
    name: '新政镇',
    short_name: '新政镇',
    f_id: '469035',
  },
  {
    id: '469029105',
    name: '三道镇',
    short_name: '三道镇',
    f_id: '469035',
  },
  {
    id: '469029200',
    name: '六弓乡',
    short_name: '六弓乡',
    f_id: '469035',
  },
  {
    id: '469029201',
    name: '南林乡',
    short_name: '南林乡',
    f_id: '469035',
  },
  {
    id: '469029202',
    name: '毛感乡',
    short_name: '毛感乡',
    f_id: '469035',
  },
  {
    id: '469029401',
    name: '国营新星农场',
    short_name: '国营新星农场',
    f_id: '469035',
  },
  {
    id: '469029402',
    name: '海南保亭热带作物研究所',
    short_name: '海南保亭热带作物研究所',
    f_id: '469035',
  },
  {
    id: '469029403',
    name: '国营金江农场',
    short_name: '国营金江农场',
    f_id: '469035',
  },
  {
    id: '469029405',
    name: '国营三道农场',
    short_name: '国营三道农场',
    f_id: '469035',
  },
  {
    id: '469030100',
    name: '营根镇',
    short_name: '营根镇',
    f_id: '469036',
  },
  {
    id: '469030101',
    name: '湾岭镇',
    short_name: '湾岭镇',
    f_id: '469036',
  },
  {
    id: '469030102',
    name: '黎母山镇',
    short_name: '黎母山镇',
    f_id: '469036',
  },
  {
    id: '469030103',
    name: '和平镇',
    short_name: '和平镇',
    f_id: '469036',
  },
  {
    id: '469030104',
    name: '长征镇',
    short_name: '长征镇',
    f_id: '469036',
  },
  {
    id: '469030105',
    name: '红毛镇',
    short_name: '红毛镇',
    f_id: '469036',
  },
  {
    id: '469030106',
    name: '中平镇',
    short_name: '中平镇',
    f_id: '469036',
  },
  {
    id: '469030200',
    name: '吊罗山乡',
    short_name: '吊罗山乡',
    f_id: '469036',
  },
  {
    id: '469030201',
    name: '上安乡',
    short_name: '上安乡',
    f_id: '469036',
  },
  {
    id: '469030202',
    name: '什运乡',
    short_name: '什运乡',
    f_id: '469036',
  },
  {
    id: '469030402',
    name: '国营阳江农场',
    short_name: '国营阳江农场',
    f_id: '469036',
  },
  {
    id: '469030403',
    name: '国营乌石农场',
    short_name: '国营乌石农场',
    f_id: '469036',
  },
  {
    id: '469030406',
    name: '国营加钗农场',
    short_name: '国营加钗农场',
    f_id: '469036',
  },
  {
    id: '469030407',
    name: '国营长征农场',
    short_name: '国营长征农场',
    f_id: '469036',
  },
  {
    id: '469030500',
    name: '国营黎母山林业公司',
    short_name: '国营黎母山林业公司',
    f_id: '469036',
  },
  {
    id: '620201100',
    name: '新城镇',
    short_name: '新城镇',
    f_id: '620200',
  },
  {
    id: '620201101',
    name: '峪泉镇',
    short_name: '峪泉镇',
    f_id: '620200',
  },
  {
    id: '620201102',
    name: '文殊镇',
    short_name: '文殊镇',
    f_id: '620200',
  },
  {
    id: '620201401',
    name: '雄关区',
    short_name: '雄关区',
    f_id: '620200',
  },
  {
    id: '620201402',
    name: '镜铁区',
    short_name: '镜铁区',
    f_id: '620200',
  },
  {
    id: '620201403',
    name: '长城区',
    short_name: '长城区',
    f_id: '620200',
  },
  {
    id: '659001001',
    name: '新城街道',
    short_name: '新城街道',
    f_id: '659001',
  },
  {
    id: '659001002',
    name: '向阳街道',
    short_name: '向阳街道',
    f_id: '659001',
  },
  {
    id: '659001003',
    name: '红山街道',
    short_name: '红山街道',
    f_id: '659001',
  },
  {
    id: '659001004',
    name: '老街街道',
    short_name: '老街街道',
    f_id: '659001',
  },
  {
    id: '659001005',
    name: '东城街道',
    short_name: '东城街道',
    f_id: '659001',
  },
  {
    id: '659001100',
    name: '北泉镇',
    short_name: '北泉镇',
    f_id: '659001',
  },
  {
    id: '659001200',
    name: '石河子乡',
    short_name: '石河子乡',
    f_id: '659001',
  },
  {
    id: '659001500',
    name: '兵团一五二团',
    short_name: '兵团一五二团',
    f_id: '659001',
  },
  {
    id: '659002001',
    name: '金银川路街道',
    short_name: '金银川路街道',
    f_id: '659002',
  },
  {
    id: '659002002',
    name: '幸福路街道',
    short_name: '幸福路街道',
    f_id: '659002',
  },
  {
    id: '659002003',
    name: '青松路街道',
    short_name: '青松路街道',
    f_id: '659002',
  },
  {
    id: '659002004',
    name: '南口街道',
    short_name: '南口街道',
    f_id: '659002',
  },
  {
    id: '659002200',
    name: '托喀依乡',
    short_name: '托喀依乡',
    f_id: '659002',
  },
  {
    id: '659002402',
    name: '工业园区',
    short_name: '工业园区',
    f_id: '659002',
  },
  {
    id: '659002500',
    name: '兵团七团',
    short_name: '兵团七团',
    f_id: '659002',
  },
  {
    id: '659002501',
    name: '兵团八团',
    short_name: '兵团八团',
    f_id: '659002',
  },
  {
    id: '659002503',
    name: '兵团十团',
    short_name: '兵团十团',
    f_id: '659002',
  },
  {
    id: '659002504',
    name: '兵团十一团',
    short_name: '兵团十一团',
    f_id: '659002',
  },
  {
    id: '659002505',
    name: '兵团十二团',
    short_name: '兵团十二团',
    f_id: '659002',
  },
  {
    id: '659002506',
    name: '兵团十三团',
    short_name: '兵团十三团',
    f_id: '659002',
  },
  {
    id: '659002507',
    name: '兵团十四团',
    short_name: '兵团十四团',
    f_id: '659002',
  },
  {
    id: '659002509',
    name: '兵团十六团',
    short_name: '兵团十六团',
    f_id: '659002',
  },
  {
    id: '659002511',
    name: '兵团第一师水利水电工程处',
    short_name: '兵团第一师水利水电工程处',
    f_id: '659002',
  },
  {
    id: '659002512',
    name: '兵团第一师塔里木灌区水利管理处',
    short_name: '兵团第一师塔里木灌区水利管理处',
    f_id: '659002',
  },
  {
    id: '659002513',
    name: '阿拉尔农场',
    short_name: '阿拉尔农场',
    f_id: '659002',
  },
  {
    id: '659002514',
    name: '兵团第一师幸福农场',
    short_name: '兵团第一师幸福农场',
    f_id: '659002',
  },
  {
    id: '659002515',
    name: '中心监狱',
    short_name: '中心监狱',
    f_id: '659002',
  },
  {
    id: '659003001',
    name: '齐干却勒街道',
    short_name: '齐干却勒街道',
    f_id: '659003',
  },
  {
    id: '659003002',
    name: '前海街道',
    short_name: '前海街道',
    f_id: '659003',
  },
  {
    id: '659003003',
    name: '永安坝街道',
    short_name: '永安坝街道',
    f_id: '659003',
  },
  {
    id: '659003504',
    name: '兵团四十四团',
    short_name: '兵团四十四团',
    f_id: '659003',
  },
  {
    id: '659003509',
    name: '兵团四十九团',
    short_name: '兵团四十九团',
    f_id: '659003',
  },
  {
    id: '659003510',
    name: '兵团五十团',
    short_name: '兵团五十团',
    f_id: '659003',
  },
  {
    id: '659003511',
    name: '兵团五十一团',
    short_name: '兵团五十一团',
    f_id: '659003',
  },
  {
    id: '659003513',
    name: '兵团五十三团',
    short_name: '兵团五十三团',
    f_id: '659003',
  },
  {
    id: '659003514',
    name: '兵团图木舒克市喀拉拜勒镇',
    short_name: '兵团图木舒克市喀拉拜勒镇',
    f_id: '659003',
  },
  {
    id: '659003515',
    name: '兵团图木舒克市永安坝',
    short_name: '兵团图木舒克市永安坝',
    f_id: '659003',
  },
  {
    id: '659004001',
    name: '军垦路街道',
    short_name: '军垦路街道',
    f_id: '659004',
  },
  {
    id: '659004002',
    name: '青湖路街道',
    short_name: '青湖路街道',
    f_id: '659004',
  },
  {
    id: '659004003',
    name: '人民路街道',
    short_name: '人民路街道',
    f_id: '659004',
  },
  {
    id: '659004500',
    name: '兵团一零一团',
    short_name: '兵团一零一团',
    f_id: '659004',
  },
  {
    id: '659004501',
    name: '兵团一零二团',
    short_name: '兵团一零二团',
    f_id: '659004',
  },
  {
    id: '659004502',
    name: '兵团一零三团',
    short_name: '兵团一零三团',
    f_id: '659004',
  },
]
export default areaData
