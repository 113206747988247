<script setup lang="ts">
import { isClient, isString } from '@qctsw/utils'

const props = withDefaults(defineProps<{
  error: { url: string, message: string, statusCode: number, stack: string } & any
}>(), {})

const isTimeOutError = computed(() => {
  return props.error?.message?.includes('请求响应超时')
})
function errorHandler(redirect?: string) {
  if (!isClient)
    return
  clearError({ redirect })
  const query = useRoute().query
  if (!query.clearError)
    query.clearError = 'true'
  if (redirect)
    query.timestamp = `${Date.now()}`
  window.location.href = `${useRoute().fullPath.split('?')[0]}?${qs.stringify(query)}`
}

// 自动清除错误
if (isClient) {
  const stop = watch(() => props.error, (error) => {
    if (isTimeOutError)
      return
    if (error && !useRoute().query.clearError)
      errorHandler()
    else if (error && useRoute().query.clearError && !useRoute().query.timestamp)
      errorHandler('/')
  }, { immediate: true })
  onBeforeUnmount(stop)
}
</script>

<template>
  <div class="grid place-items-center h-100vh text-center">
    <div>
      <p class="text-5 font-bold text-center text-gray-5">
        <i class="i-custom-undraw-firmware?bg text-8 mr-3 inline-block" />
        {{ isTimeOutError ? '页面加载超时' : '页面出现了些问题' }}
      </p>
      <div v-if="!isTimeOutError">
        <n-ellipsis v-if="isString(error)" class="text-error pt-3 pb-10 text-5 max-w-80vw" expand-trigger="click" line-clamp="5" :tooltip="false">
          {{ error }}
        </n-ellipsis>
        <div v-else-if="Object.keys(error).includes('stack')" class="text-left max-w-80vw overflow-auto scrollbar-default bg-slate-1 mt-3 rounded-md p-3">
          <div>页面：{{ error.url }}</div>
          <div>状态码：{{ error.statusCode }}</div>
          <div>错误消息：{{ error.message }}</div>
          <div v-html="error.stack" />
        </div>
      </div>
      <div class="text-4 mt-3">
        <button class="px-5 py-1 bg-blue-5 rounded-md text-white">
          <NuxtLink to="/">
            返回首页
          </NuxtLink>
        </button>
        <button v-if="!useRoute().query.clearError && !isTimeOutError" class="text-gray-8 ml-5 inline-flex underline items-center hover:text-blue-5" @click="errorHandler()">
          <span>尝试清除错误，以继续访问</span>
          <i class="i-ic:sharp-double-arrow" />
        </button>
      </div>
    </div>
  </div>
</template>
