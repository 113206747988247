import { BASE_HOST_APP } from '@qctsw/common'
import { isClient } from '@qctsw/utils'
import { ApiRequest } from './core'
import { ApiInterceptor } from './interceptors'
import type { FetchResponseType } from './types'

const interceptors = new ApiInterceptor()
const { onRequest, onRequestError, onResponse, onResponseError } = interceptors

export const useApiFetch = ApiRequest({
  baseURL: `${BASE_HOST_APP}/api`,
  retry: 0,
  headers: {
    'Accept': 'application/json',
    'Cache-Control': 'no-cache',
  },
  timeout: isClient ? 15000 : 5000,
  setting: {
    repetition: 'pre',
  },
  onRequest,
  onRequestError,
  // eslint-disable-next-line ts/ban-ts-comment
  // @ts-expect-error
  onResponse: onResponse<FetchResponseType>,
  onResponseError: onResponseError<FetchResponseType>,
})
