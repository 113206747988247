import qs from 'qs'

export enum APP_ROUTE_PATH {
  home = '/', // 首页
  news = '/news', // 资讯
  newsClass = '/news/classification', // 资讯分类
  newsMedial = '/news/media', // 资讯媒体
  newsDetail = '/news/detail/', // 资讯详情
  authorDetail = '/news/author/',
  complaint = '/complaint', // 投诉
  complaintSearch = '/complaint/search', // 投诉搜索
  complaintList = '/complaint/list', // 投诉列表
  complaintDetail = '/complaint/detail/', // 投诉详情
  collectiveDetail = '/complaint/collective/', // 集体投诉详情
  reputation = '/reputation', // 口碑
  reputationDetail = '/reputation/', // 口碑详情
  redAndBlackList = '/list/red-black', // 红黑榜
  publish = '/publish', // 发布
  helpDetail = '/help/', // 帮助详情
  forget = '/forget', // 忘记密码
  register = '/register', // 注册
  topic = '/topic',

  // user
  userInfo = '/user/userInfo', // 用户信息
  userModifyPhone = '/user/modifyPhone', // 修改手机号
  userModifyPassword = '/user/modifyPassword', // 修改密码
  userBindAccount = '/user/bindAccount', // 绑定账号
  userAddress = '/user/addressManage', // 用户地址
  userNotification = '/user/notification', // 用户通知
  userComment = '/user/comment', // 用户评论
  userCar = '/user/car', // 用户车辆
  userComplaint = '/user/complaint', // 用户投诉
  userComplaintDetail = '/user/complaint/', // 用户投诉详情
  userCollection = '/user/collection', // 用户收藏
  userIntegrationLog = '/user/integration-log', // 用户积分
}

function homePath(options?: {
  showLogin?: boolean | 'phone' | 'account'
  [key: string]: any
}) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.home + (query ? `?${query}` : '')
}

function complaintSearchPath(options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.complaintSearch + (query ? `?${query}` : '')
}

function complaintListPath(options?: {
  type: number | string
  sub?: number | string
} & Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.complaintList + (query ? `?${query}` : '')
}
function newsClassPath(options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.newsClass + (query ? `?${query}` : '')
}
function newsDetailPath(id: string | number, options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.newsDetail + id + (query ? `?${query}` : '')
}
function authorDetailPath(id: string | number, options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.authorDetail + id + (query ? `?${query}` : '')
}
function newsMediaDetail(id: string | number, options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return `${APP_ROUTE_PATH.newsMedial}/${id}${query ? `?${query}` : ''}`
}

function topicDetail(id: string | number, options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return `${APP_ROUTE_PATH.topic}/${id}${query ? `?${query}` : ''}`
}

function complaintDetailPath(id: string | number, options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.complaintDetail + id + (query ? `?${query}` : '')
}
function collectiveDetailPath(id: string | number, options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.collectiveDetail + id + (query ? `?${query}` : '')
}

function reputationDetailPath(seriesId: string | number, options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.reputationDetail + seriesId + (query ? `?${query}` : '')
}

function userAddressPath(options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.userAddress + (query ? `?${query}` : '')
}
function userNotificationPath(options?: { type: 'notification' | 'feedback', [key: string]: any }) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.userNotification + (query ? `?${query}` : '')
}
function userCommentPath(options?: { type: '1' | '2', [key: string]: any }) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.userComment + (query ? `?${query}` : '')
}
function userIntegrationLogPath(options?: Record<string, any>) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.userIntegrationLog + (query ? `?${query}` : '')
}
function userCollectionPath(options?: { type: 'news' | 'complaint', [key: string]: any }) {
  const query = qs.stringify(options, { arrayFormat: 'repeat' })
  return APP_ROUTE_PATH.userCollection + (query ? `?${query}` : '')
}

function useAddQuery<T extends Record<string, any>>(path: string) {
  return (options?: T) => {
    const query = qs.stringify(options, { arrayFormat: 'repeat' })
    return path + (query ? `?${query}` : '')
  }
}

export const APP_ROUTER = {
  home: homePath,
  news: () => APP_ROUTE_PATH.news,
  newsClass: newsClassPath,
  newsMedia: () => APP_ROUTE_PATH.newsMedial,
  newsMediaDetail,
  newsDetail: newsDetailPath,
  authorDetail: authorDetailPath,
  complaint: () => APP_ROUTE_PATH.complaint,
  complaintSearch: complaintSearchPath,
  complaintList: complaintListPath,
  complaintDetail: complaintDetailPath,
  collectiveDetail: collectiveDetailPath,
  reputation: useAddQuery(APP_ROUTE_PATH.reputation),
  reputationDetail: reputationDetailPath,
  redAndBlackList: useAddQuery<{ type: 'week' | 'day', page?: string | number }>(APP_ROUTE_PATH.redAndBlackList),
  publish: useAddQuery(APP_ROUTE_PATH.publish),
  helpDetail: (id: string | number) => `${APP_ROUTE_PATH.helpDetail}${id}`,
  forget: () => APP_ROUTE_PATH.forget,
  register: () => APP_ROUTE_PATH.register,
  topicDetail,

  // user
  userInfo: () => APP_ROUTE_PATH.userInfo,
  userModifyPhone: () => APP_ROUTE_PATH.userModifyPhone,
  userModifyPassword: () => APP_ROUTE_PATH.userModifyPassword,
  userBindAccount: () => APP_ROUTE_PATH.userBindAccount,
  userAddress: userAddressPath,
  userNotification: userNotificationPath,
  userComment: userCommentPath,
  userCar: useAddQuery(APP_ROUTE_PATH.userCar),
  userEditCar: (id?: string | number) => `${APP_ROUTE_PATH.userCar}/edit${!id ? '' : `?id=${id}`}`,
  userComplaint: useAddQuery(APP_ROUTE_PATH.userComplaint),
  userComplaintDetail: (id: string | number) => `${APP_ROUTE_PATH.userComplaintDetail}${id}`,
  userCollection: userCollectionPath,
  userIntegrationLog: userIntegrationLogPath,

}
