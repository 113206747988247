import { isClient } from '@qctsw/utils'
import { useAbort } from '@/server/request/abort'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$useAbort = useAbort()
  const { pendingRequest, abortPrePageKeys, clearPendingRequest } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
  addRouteMiddleware('global-abort', (_to, _from) => {
    // clear abort request
    const extendsRoute: string[] = []
    const isExtendsPage = extendsRoute.includes(_to.name as string) || extendsRoute.includes(_from.name as string)
    if (pendingRequest.size > 0 && !isExtendsPage) {
      // eslint-disable-next-line no-console
      console.log(
        `%c[request abort because route change] %c pending request count: ${pendingRequest.size}`,
        'background: #14b8a6; padding: 2px 4px; border-radius: 3px 0 0 3px',
        'background: #99f6e4; padding: 2px 4px; border-radius: 0 3px 3px 0;',
      )
      pendingRequest.forEach((value: any, key: any) => {
        (value.controller as AbortController).abort(new Error('页面切换终止请求'))
        abortPrePageKeys.push(key)
        clearPendingRequest(key, 'clear')
      })
      pendingRequest.clear()
    }
  }, { global: true })
})
