import { VERSION } from '@qctsw/common'
import { isClient } from '@qctsw/utils'
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_KEYWORDS,
  DEFAULT_TITLE,
  HOME_SITE_META,
} from '@/shared/site'
import type { SiteConfig } from '@/shared/site'

export function useSite(config: Partial<SiteConfig> = HOME_SITE_META) {
  const siteMeta = ref({
    title: config?.title || DEFAULT_TITLE,
    description: config?.description || DEFAULT_DESCRIPTION,
    keywords: config?.keywords || DEFAULT_KEYWORDS,
  })

  siteMeta.value.title = config?.title || DEFAULT_TITLE
  siteMeta.value.description = config?.description || DEFAULT_DESCRIPTION
  siteMeta.value.keywords = config?.keywords || DEFAULT_KEYWORDS

  function changeMeta(config: Partial<SiteConfig>) {
    for (const key in config) {
      if (config[key as keyof SiteConfig])
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        siteMeta.value[key] = config[key]
    }
  }
  // 如果只是静态不变
  try {
    useHead(computed(() => {
      return {
        title: siteMeta.value.title,
        meta: [
          {
            name: 'description',
            content: siteMeta.value.description?.slice(0, 200),
          },
          {
            name: 'keywords',
            content: siteMeta.value.keywords?.join(','),
          },
        ],
      }
    }))
  }
  catch (err) {
    console.error('useHead error', err)
  }

  // 初始化
  changeMeta(config)
  return {
    siteMeta,
    changeMeta,
  }
}

// TODO: Determine version when js loading error
export function setSiteVersion() {
  if (!isClient)
    return
  const config = useRuntimeConfig()
  config.public.version = VERSION.APP
  const version = useLocalStorage('VERSION_WEB', VERSION.APP)
  // TODO some handle
  if (version.value !== VERSION.APP) {
    // eslint-disable-next-line no-console
    console.log('<<WEB:site update>>')
    localStorage.clear()
    version.value = VERSION.APP
  }
}
