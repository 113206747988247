/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-02-21 14:46:09
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-07-12 16:19:43
 * @FilePath: \qctsw-vue\packages\app\src\stores\ad.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEA    , {

 */

import { FILE_PRE_HOST } from '@qctsw/common'
import { getAdListApi } from '~/server'
import type { AD, AD_KEY, AD_KEY_TYPE, IAdResponse } from '~/server/types'

export default defineStore('adList', () => {
  const adList = reactive<Map<AD_KEY_TYPE, IAdResponse[]>>(new Map())
  async function getAdData() {
    const { data } = await useAsyncData('广告', () => getAdListApi())
    // const cc = [{
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'A1',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'A1',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'A6',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'A9',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://qcts.cdn.bcebos.com/qcts/sys/video/qctsw_mv.mp4',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'A4',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/14/1724232623344488448.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'A2',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/05/29/1663020557908549632.jpg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'A3',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/14/1724232623344488448.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'B1',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/14/1724232623344488448.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'B3',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'C1',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/14/1724232623344488448.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'C2',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'C3',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'C4',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'D1',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'D2',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'D3',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'E1',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'E2',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }, {
    //   id: 36,

    //   adClientTypeCode: 1,
    //   adShowLocationCode: 'E3',
    //   imgWidth: '',
    //   imgHeight: '',
    //   imgUrl: 'https://qcts.gz.bcebos.com/qcts/other/img/2023/11/16/1725062573748293632.jpeg',
    //   linkUrl: 'https://www.qctsw.com/topic/45',
    //   adShowLocationId: 1,

    // }]
    formatAdList(data.value?.data || [])
  }

  function formatAdList(data: IAdResponse[]) {
    const obj = data.reduce((pre, next) => {
      if (pre[next.adShowLocationCode])
        pre[next.adShowLocationCode].push(next)

      else
        pre[next.adShowLocationCode] = [next]

      return pre
    }, {} as Record<AD_KEY_TYPE, IAdResponse[]>)

    for (const key in obj)
      adList.set(key as AD_KEY, obj[key as AD_KEY] as IAdResponse[])
  }

  function computedAd(key: keyof typeof AD_KEY) {
    return computed(() => {
      return adList.get(key)
    })
  }
  return {
    getAdData,
    computedAd,
    adList,
  }
})
