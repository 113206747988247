<script setup lang='ts'>
import type { UploadFileInfo } from 'naive-ui'
import { DEFAULT_CHECK_FILE_SIZE_STATUS, DEFAULT_CHECK_FILE_TYPE_STATUS, UPLOAD_IMAGE_TYPE, addHost, checkFileSize, checkFileType, removeHost } from '@qctsw/common'
import { v4 as uuidv4 } from 'uuid'
import qs from 'qs'
import { UPLOAD_URL } from '@/shared/constants'
import { FILE_UPLOAD_TYPE } from '@/shared/types'
import type { FetchResponseType } from '@/server/request/types'

const props = withDefaults(defineProps<{
  modelValue?: string[]
  fileType?: FILE_UPLOAD_TYPE // 上传问题类别
  accept?: string // 上传文件类型
  fileSize?: number // 上传文件大小 M
  multiple?: boolean
  listType?: 'text' | 'image' | 'image-card'
}>(), {
  modelValue: () => [],
  fileType: FILE_UPLOAD_TYPE.other,
  fileSize: 5,
  accept: UPLOAD_IMAGE_TYPE,
  multiple: true,
  listType: 'image-card',
})

const emits = defineEmits({
  'update:modelValue': (data: string[]) => typeof data === 'object',
  'uploaded': (data: string) => typeof data === 'string',
})

const { USER_TOKEN } = storeToRefs(useAuth())
const fileUrls = ref<string[]>([])
const fileList = ref<UploadFileInfo[]>([])
watch(() => props.modelValue, (nv) => {
  if (
    qs.stringify(fileUrls.value) !== qs.stringify(nv)
    || fileUrls.value.length !== fileList.value.length
  ) {
    // ERROR:这里全部替换,在正在上传时应该会出现问题
    const newFileList = []
    for (const url of nv) {
      const item = generateFileItem(url)
      const findIndex = fileList.value.findIndex(v => addHost(url) === v.url)
      if (findIndex === -1)
        newFileList.push(item)
    }

    fileList.value = [
      ...newFileList,
      ...fileList.value,
    ]
  }
}, { immediate: true })

function handleUploadChange(data: { fileList: UploadFileInfo[] }) {
  fileList.value = data.fileList

  fileUrls.value = data.fileList.map(v => v.url ? removeHost(v.url) : '').filter(v => v) // `ID_${v.id}`
  emits('update:modelValue', fileUrls.value)
}

function finishHandle(options: {
  file: UploadFileInfo
  event?: ProgressEvent
}): UploadFileInfo | undefined {
  const response: FetchResponseType<string> | null | undefined = (options.event as ProgressEvent<XMLHttpRequest>)?.target?.response
  const fileUrl = response ? response.data : ''

  emits('uploaded', fileUrl)
  return {
    ...options.file,
    url: addHost(fileUrl),
  }
}

async function beforeUploadHandle(options: {
  file: UploadFileInfo
  fileList: UploadFileInfo[]
}): Promise<boolean | void> {
  const fileType = options.file.file?.type
  const fileSize = options.file.file?.size

  let errorMessage = null
  const fileSizeCheck = fileSize ? checkFileSize(fileSize, props.fileSize) : DEFAULT_CHECK_FILE_SIZE_STATUS
  const fileTypeCheck = fileType ? checkFileType(fileType, props.accept) : DEFAULT_CHECK_FILE_TYPE_STATUS

  if (!fileSizeCheck.status)
    errorMessage = fileSizeCheck
  else if (!fileTypeCheck.status)
    errorMessage = fileTypeCheck
  else
    return true

  useDialog.warning({
    title: errorMessage.title,
    content: errorMessage.message,
    closable: true,
  })
  return false
}
function generateFileItem(url: string): UploadFileInfo {
  return {
    id: uuidv4(),
    name: url.slice(-20),
    url: addHost(url),
    status: 'finished',
  }
}
</script>

<template>
  <NUpload
    :multiple="props.multiple"
    response-type="json"
    :list-type="props.listType"
    :action="`/api${UPLOAD_URL}`"
    :file-list="fileList"
    :headers="{ Authorization: USER_TOKEN }"
    :data="{ code: props.fileType }"
    :accept="props.accept"
    @before-upload="beforeUploadHandle"
    @change="handleUploadChange"
    @finish="finishHandle"
  >
    <slot />
  </NUpload>
</template>
