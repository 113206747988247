import { darkTheme, lightTheme } from 'naive-ui'
import type { GlobalTheme, GlobalThemeOverrides } from 'naive-ui'
import { isClient } from '@qctsw/utils'

const PRIMARY_COLOR = '#3b82f6'
const PRIMARY_COLOR_HOVER = '#60A5FA'
const PRIMARY_COLOR_PRESSED = '#3B82F6'
export const lightThemeThemeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: PRIMARY_COLOR,
    primaryColorHover: PRIMARY_COLOR_HOVER,
    primaryColorPressed: PRIMARY_COLOR_PRESSED,
  },
  Breadcrumb: {
    fontSize: `${pxToRem(14)}rem`,
  },
  Button: {
    paddingTiny: `0 ${pxToRem(6)}rem`,
    paddingSmall: `0 ${pxToRem(10)}rem`,
    paddingMedium: `0 ${pxToRem(14)}rem`,
    paddingLarge: `0 ${pxToRem(18)}rem`,

    paddingRoundTiny: `0 ${pxToRem(10)}rem`,
    paddingRoundSmall: `0 ${pxToRem(14)}rem`,
    paddingRoundMedium: `0 ${pxToRem(18)}rem`,
    paddingRoundLarge: `0 ${pxToRem(22)}rem`,

    borderRadiusTiny: `${pxToRem(3)}rem`,
    borderRadiusSmall: `${pxToRem(3)}rem`,
    borderRadiusMedium: `${pxToRem(3)}rem`,
    borderRadiusLarge: `${pxToRem(3)}rem`,

    heightTiny: `${pxToRem(22)}rem`,
    heightSmall: `${pxToRem(28)}rem`,
    heightMedium: `${pxToRem(34)}rem`,
    heightLarge: `${pxToRem(38)}rem`,

    fontSizeTiny: `${pxToRem(12)}rem`,
    fontSizeSmall: `${pxToRem(14)}rem`,
    fontSizeMedium: `${pxToRem(16)}rem`,
    fontSizeLarge: `${pxToRem(18)}rem`,
  },
  Avatar: {
    fontSize: `${pxToRem(14)}rem`,
    heightSmall: `${pxToRem(28)}rem`,
    heightMedium: `${pxToRem(34)}rem`,
    heightLarge: `${pxToRem(40)}rem`,
    heightHuge: `${pxToRem(46)}rem`,
  },
  Input: {
    paddingMedium: `${pxToRem(12)}rem`,
    paddingLarge: `${pxToRem(14)}rem`,
    heightMedium: `${pxToRem(36)}rem`,
    heightLarge: `${pxToRem(40)}rem`,
    fontSizeTiny: `${pxToRem(12)}rem`,
    fontSizeSmall: `${pxToRem(14)}rem`,
    fontSizeMedium: `${pxToRem(14)}rem`,
    fontSizeLarge: `${pxToRem(16)}rem`,
  },
  Tabs: {
    tabTextColorCard: 'var(--color-text-500)',
    tabFontWeightActive: 'bold', // --n-tab-font-weight-active
    tabFontSizeMedium: '1rem',
    tabPaddingMediumLine: `${pxToRem(6)}rem 0`,
    tabGapMediumLine: `${pxToRem(34)}rem`,
  },
  Card: {
    titleFontSizeSmall: '1.125rem',
    fontSizeSmall: '0.875rem',
    paddingSmall: `${pxToRem(12)}rem`,
  },
}

export const darkThemeThemeOverrides: GlobalThemeOverrides = {
  common: {
  },
  Button: {
  },
}

export function pxToRem(data: number): number {
  return Math.floor((data / 16) * 1000) / 1000
}

export function remToPx(rem: number) {
  if (!isClient)
    return 0
  return rem * Number.parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export function useTheme() {
  // const theme =  useColorMode({ initialValue: 'light' })
  const theme = ref('light')

  const naiveTheme = computed<GlobalTheme | null>(() => theme.value === 'dark' ? darkTheme : lightTheme)
  const naiveThemeOverrides = computed<GlobalThemeOverrides | null>(() => theme.value === 'dark'
    ? darkThemeThemeOverrides
    : lightThemeThemeOverrides,
  )

  return {
    naiveTheme,
    naiveThemeOverrides,
  }
}
