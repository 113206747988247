export const LOGIN_POPUP = Symbol('LOGIN_POPUP')

/**
 * 评论列表添加
 */
export const COMMENT_ADD_KEY = Symbol('COMMENT_ADD_KEY')

/**
 * 子评论列表添加
 */
export const COMMENT_CHILD_ADD_KEY = Symbol('COMMENT_CHILD_ADD_KEY')
/**
 * 评论点赞
 */
export const COMMENT_FAVORITE_KEY = Symbol('COMMENT_FAVORITE_KEY')

/**
 * 内容点赞/收藏
 */
export const COMMON_CHANGE_FAVORITE_OR_COLLECT_KEY = Symbol('COMMON_CHANGE_FAVORITE_OR_COLLECT_KEY')

/**
 * 用户地址添加弹窗
 */
export const ADD_ADDRESS_POPUP = Symbol('ADD_ADDRESS_POPUP')

/**
 * 用户地址列表数据变更
 */
export const CHANGE_USER_ADDRESS = Symbol('CHANGE_USER_ADDRESS')

/**
 * 用户车辆
 */
export const CHANGE_USER_CAR = Symbol('CHANGE_USER_CAR')
