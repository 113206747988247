export function sleep(duration: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}
/**
 *  通过对象的值来获取对象的key
 * @param object Record<string, T>
 * @param value  T
 * @returns string
 */
export function getObjectKey<T = string>(object: Record<string, T>, value: T) {
  return Object.keys(object).find(key => object[key] === value)
}

// 判断当前元素和其父元素是否包含data-[key]属性
export function checkElDataset(el: HTMLElement, key: string, parent = true): boolean {
  if (el?.dataset?.[key] && ['false', '0', 0].includes(el.dataset[key] || ''))
    return false

  return (parent && el.parentElement) ? checkElDataset(el.parentElement, key, parent) : true
}

/**
 * 包含特殊字符转义成正则表达式
 * @param str 需要转换的字符串
 * @param flags RegExp flags
 */
export function createRegExp(str: string, flags?: string) {
  str = str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
  return new RegExp(str, flags)
}

export * from './string'
export * from './time'
export * from './number'
export * from './check'
