<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-01-24 10:05:36
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-07-12 09:30:30
-->
<script setup lang="ts">
import { dateZhCN, zhCN } from 'naive-ui'
import { HOME_SITE_META } from '@/shared/site'
import { useTheme } from '@/shared/theme'
import adStore from '@/stores/ad'
import { userAddAccessApi } from '~/server'

const store = adStore()
 store.getAdData()
const { naiveTheme, naiveThemeOverrides } = useTheme()
useSite(HOME_SITE_META)
process.client && userAddAccessApi()

</script>

<template>
  <div>
    <n-config-provider
      :locale="zhCN" :date-locale="dateZhCN"
      :theme="naiveTheme" :theme-overrides="naiveThemeOverrides"
      inline-theme-disabled
    >
      <base-feedback />
      <base-mobile />
      <NuxtLayout>
        <NuxtPage :keepalive="{ max: 5, exclude: [/\/user\/.*/, 'publish'] }" />
      </NuxtLayout>
    </n-config-provider>
  </div>
</template>

<style lang="scss">
html {
  font-size: 16px;
}

.image-error {
  position: relative;
}

.n-image {
  img {
    --uno: w-full;
  }
}
.n-image-preview-toolbar .n-base-icon:nth-child(6){
  display: none;
}

img {
  --uno: bg-slate-1;
}

img.image-error::before {
  --uno: content-empty absolute top-0 left-0 right-0 bottom-0;
  --uno: bg-slate-1;
}

img.image-error::after {
  --uno: content-empty absolute top-1/2 left-1/2 -translate-1/2 right-0 bottom-0;
  --uno: bg-slate-3 i-ic:baseline-broken-image;
}

#__nuxt,
html,
body {
  --uno: m-0 p-0 break-all;
}

body {
  --uno: text-4;
}

#nprogress .bar {
  --uno: bg-blue-5 text-3.5 w-full h-1;
  --uno: shadow-[0_2px_4px_0_#3b82f6];
  // ??打包后没有 top-[calc(3.75rem+2em)]
  --uno: fixed top-0 top-[calc(3.75rem+2em)] left-0 right-0 z-999;
}

a:focus:focus-visible,
button:focus:focus-visible {
  --uno: outline outline-2 outline-blue-5;
}
</style>
