import {
  createDiscreteApi,
} from 'naive-ui'

export const {
  message: useMessage,
  notification: useNotification,
  dialog: useDialog,
} = createDiscreteApi(
  ['message', 'dialog', 'notification'],
  {},
)
