export * from './validator'

const toString = Object.prototype.toString

export const isDef = <T = any>(val?: T): val is T => typeof val !== 'undefined'

export const isNumber = (val: any): val is number => typeof val === 'number'

export const isString = (val: unknown): val is string => typeof val === 'string'

export const isBoolean = (val: any): val is boolean => typeof val === 'boolean'

export const isFunction = <T extends Function> (val: any): val is T => typeof val === 'function'

export const isArray = Array.isArray

export function isObject(val: any): val is object {
  return toString.call(val) === '[object Object]'
}

export const isEmptyArray = (val: unknown): boolean => isArray(val) && !val.length

export function isEmptyObject(val: unknown): boolean {
  return isObject(val) && !Object.keys(val).length
}

export function isValKey<T>(val: unknown, key: string): val is T {
  if (val && key && Object.prototype.hasOwnProperty.call(val, key))
    return true
  return false
}
export function isValidKey(key: string | number | symbol, data: object): key is keyof typeof data {
  if (!isDef(key) || !data || !isObject(data))
    return false

  // return key in data && Object.prototype.hasOwnProperty.call(data, key)
  // eslint-disable-next-line no-prototype-builtins
  return key in data && data.hasOwnProperty(key)
}

export const isClient = typeof window !== 'undefined'

export function isWindow(val: any): val is Window {
  return typeof window !== 'undefined' && toString.call(val) === '[object Window]'
}

export const isIOS = /* #__PURE__ */ isClient && window?.navigator?.userAgent && /iP(ad|hone|od)/.test(window.navigator.userAgent)

export function isWechat() {
  if (!globalThis.navigator?.userAgent)
    throw new Error('isWechat: navigator.userAgent is undefined')

  return (
    String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i))
    === 'micromessenger'
  )
}
export function isMobile() {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
}

/**
 * 校验字符串是否是有效字符
 * @param data
 * @param emptyString
 * @returns boolean
 */
export function isEmptyString(data: unknown, emptyString: string[] = ['null', 'undefined', '']) {
  return isString(data) && emptyString.includes(data)
}
export function isBase64(data: unknown) {
  return isString(data) && !!data.match(/^data:.*;base64.*/)
}

export function checkIsStringArray(data: unknown): data is string[] {
  if (!isArray(data))
    return false
  for (const v of data) {
    if (!isString(v))
      return false
  }
  return true
}
