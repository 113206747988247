import { isClient, isFunction } from '@qctsw/utils'
import type { _AsyncData } from 'nuxt/dist/app/composables/asyncData'
import { LOAD_STATE } from '@/shared/types'
import type { FetchResponseType } from '@/server/request/types'

export const RELOAD_CBS = () => useState('RELOAD_CBS', () => new Set<() => Promise<void> | void>())
/**
 * 加载状态控制
 * @param options
 * @param options.key 如果传入key并且onlyClient:false, 将会在服务端渲染时将使用useAsyncData进行缓存
 * @param options.state 加载状态 Ref<LOAD_STATE>
 * @param options.fn 请求函数
 * @param options.onlyClient 是否只在客户端请求
 * @param options.throwError 是否抛出错误
 * @description ⚠️1. 需要避免有key,onlyClient:false,但又在ClientOnly下加载的的情况，该情况下无法在同步的情况下从useAsyncData中获取到数据，导致data=null
 */
export async function useLoadState<U = any>(options: {
  key?: string
  state: Ref<LOAD_STATE> | ((state: LOAD_STATE) => void)
  fn: () => Promise<FetchResponseType<U>>
  onlyClient?: boolean
  throwError?: boolean | ((error: unknown) => void)
}) {
  const {
    key,
    fn,
    onlyClient = false,
    throwError = false,
  } = options

  let nuxt
  try {
    nuxt = useNuxtApp()
  }
  catch (e) {
    console.error('useNuxtApp', e)
    nuxt = {}
  }
  const setState = (state: LOAD_STATE) => {
    if (isFunction(options.state))
      options.state(state)
    else
      options.state.value = state
  }

  const clientHandler = async () => {
    return await fn()
  }

  const serverHandler = async () => {
    if (!key)
      return await clientHandler()

    const asyncData = await useAsyncData(key, fn)

    if (!asyncData)
      return
    const { result, error } = useNuxtAsyncSimpleHandle(asyncData)
    // 暴露错误，以通过catch统一处理
    if (error.value)
      throw error.value

    return result
  }

  const errorHandler = (e: unknown) => {
    setState(LOAD_STATE.ERROR)
    if (typeof throwError === 'function')
      throwError(e)
    else if (throwError)
      throw e
    else
      recordError(e)
  }

  setState(LOAD_STATE.LOADING)

  const isServerSide = nuxt.isHydrating || !isClient

  let data: FetchResponseType<U> | undefined
  try {
    if (!onlyClient && key && isServerSide)
      data = await serverHandler()
    else
      data = await clientHandler()

    // 这个会导致满足⚠️1的请求,会发送两次请求,一次由useAsyncData发出,一次由下方的clientHandler发出,可通过缓存对应的请求来避免, see: server/request/cache.ts
    // if(isClient && data === null) {
    //   data = await clientHandler()
    // }

    if (data === null || data?.data === null)
      setState(LOAD_STATE.NULL)
    else
      setState(LOAD_STATE.END)
    return data
  }
  catch (e) {
    errorHandler(e)
  }
}
