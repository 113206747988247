export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.server) {
    nuxtApp.ssrContext?.head.hooks.hook('tags:resolve', (ctx) => {
      const scriptTag = {
        tag: 'script',
        props: { type: 'text/javascript' },
        innerHTML: `
window.addEventListener('error', (e) => {
  const target = e.target
  // console.log('error', [target], target.dataset)
  if (
    target
    && target instanceof HTMLImageElement
    && (target.src || !('errorSrc' in target.dataset))
  ) {
    const size = Math.min(target.clientWidth, target.clientHeight)
    target.style.fontSize = size ? Math.floor(size / 2.5) + 'px' : 'inherit'
    target.setAttribute('data-error-src', target.src)
    target.removeAttribute('src')
    !target.classList.contains('image-error') && target.classList.add('image-error')
  }
}, { capture: true })
        `,
      } as any
      ctx.tags.push(scriptTag)
    })
  }
})
