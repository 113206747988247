import { BASE_HOST_APP, BASE_HOST_COMPANY } from '../constants'

export * from './app'
export * from './company'

export type RouterPlatform = 'app' | 'company' | false
export const ROUTE_PATH = {
  app: BASE_HOST_APP,
  company: BASE_HOST_COMPANY, // 管理后台
} as const

export function getHostPagePath(platform: RouterPlatform, url: string) {
  return (!platform || url.match(/^http/))
    ? url
    : `${platform === 'app' ? ROUTE_PATH.app : ROUTE_PATH.company}${url}`
}

export function checkLink(link_1: string | null, link_2: string) {
  return link_1 || link_2
}

export function goAnyPage(url: string, platform: RouterPlatform = false, isNewWindow = false) {
  const newUrl = getHostPagePath(platform, url)
  // console.log('goAnyPage', router, newUrl)
  if (isNewWindow)
    window.open(newUrl, '_blank')
  else
    window.open(newUrl, '_self')
  // else
  //   window.location.href = newUrl
}

export function goCompanyPage(url: string, isNewWindow = false) {
  goAnyPage(url, 'company', isNewWindow)
}
