import type { _AsyncData } from 'nuxt/dist/app/composables/asyncData'
import type { FetchResponseType } from '@/server/request/types'

export function useNuxtAsyncHandle<T = any>(
  asyncData: _AsyncData<FetchResponseType<T> | null, Error | null>,
  _throwError = false,
) {
  const { error, refresh } = asyncData

  // 通过useLoadState处理错误
  if (error.value) {
    recordError(error)

    if (_throwError)
      throw error.value
  }

  const result = asyncData.data
  const { data, code, msg } = result.value || {}
  return {
    result,
    error,
    refresh,
    data,
    code,
    msg,
  }
}

export function useNuxtAsyncSimpleHandle<T = any>(
  asyncData: _AsyncData<FetchResponseType<T> | null, Error | null>,
  _throwError = false,
) {
  const { error } = asyncData

  const result = asyncData.data
  return {
    result: result.value as FetchResponseType<T>,
    error,
  }
}
