/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-02-21 14:50:49
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-02-21 15:10:04
 * @FilePath: \qctsw-vue\packages\app\src\server\modules\ad\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import type { IAdResponse } from './types'
import { useApiFetch } from '~/server/request'
import type { FetchResponseType } from '~/server/request/types'

export async function getAdListApi() {
  return useApiFetch<FetchResponseType<IAdResponse[]>>('/webportal/base/ad/list', {
    method: 'GET',
    params:{ adClientTypeCodeEnum: 'PC' }
  })
}
