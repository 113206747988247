import { isClient, isValKey } from '@qctsw/utils'
import type { FetchResponseType } from '@/server/request/types'

export function recordError(error: unknown) {
  if (isClient)
    console.error(error)
}

export function getErrorMsg(error: any, defaultMsg = '噢！！出现了未知错误'): string {
  if (isValKey<FetchResponseType>(error, 'msg')) {
    return error.msg
  }
  else if (error?.message) {
    try {
      return getErrorMsg(JSON.parse(error.message))
    }
    catch (err) {
      return error.message
      // console.error('getErrorMsg error JSON.parse', err)
    }
  }
  else if (isValKey<PromiseRejectionEvent>(error, 'reason')) {
    return error.reason?.stack || JSON.stringify(error.reason)
  }

  return error?.message
    ? error?.message.toString()
    : JSON.stringify(error) !== '{}' ? JSON.stringify(error) : defaultMsg
}
