import { defineStore } from 'pinia'
import { APP_ROUTER, COOKIE_BASE_DOMAIN, goAnyPage } from '@qctsw/common'
import { isClient } from '@qctsw/utils'
import type { UserInfoResponse } from '@/server/types'
import { getUserInfoApi } from '@/server'
import { usePublishStore } from '@/stores/publish'

export type UserInfo = LoginUserInfo | GhostUserInfo
export interface LoginUserInfo extends UserInfoResponse {
  type: 'LoginUserInfo'
  expires: number
}
export interface GhostUserInfo {
  type: 'GhostUserInfo'
  expires: number
}

const USER_INFO_EXPIRES = 1 * 24 * 60 * 60 * 1000 // 1天
const EXPIRES = 20 * 24 * 60 * 60
function generatePersist() {
  const persist = [
    {
      storage: persistedState.localStorage,
      paths: ['USER_INFO'],
    },
    {
      storage: persistedState.sessionStorage,
      paths: ['backPagePath'],
    },
    {
      storage: persistedState.cookiesWithOptions(
        {
          expires: new Date(Date.now() + EXPIRES),
          maxAge: EXPIRES,
          sameSite: 'lax',
          domain: COOKIE_BASE_DOMAIN,
          secure: false,
          path: '/',
        },
      ),
      paths: ['USER_TOKEN', 'NEED_UPDATE'],
    },
  ]

  return persist
}

const AUTH_PATH = [
  '/publish',
]
export const useAuth = defineStore('WEB_AUTH', () => {
  // 用户token
  const USER_TOKEN = ref('')
  const USER_INFO = ref<UserInfo>({
    type: 'GhostUserInfo',
    expires: 0,
  })
  const IS_LOGIN = computed(() => {
    if (!USER_TOKEN.value)
      clearLogin()
    if (!!USER_TOKEN.value && isClient && (USER_INFO.value.type === 'GhostUserInfo' || USER_INFO.value.expires < Date.now()))
      getUserInfo()

    return !!USER_TOKEN.value
  })
  const USER_HEAD = computed(() => {
    if (USER_INFO.value.type !== 'LoginUserInfo')
      return ''
    return USER_INFO.value.headImg || ''
  })
  const USER_NAME = computed(() => {
    if (!IS_LOGIN.value)
      return '还未登录，请先登录'
    if (USER_INFO.value.type === 'GhostUserInfo')
      return '获取用户信息失败'

    return USER_INFO.value.nickName || USER_INFO.value.userName
  })
  // 登录后回退地址
  const backPagePath = ref<string>(APP_ROUTER.home())
  function getUserInfoItem(key: keyof UserInfoResponse) {
    if (USER_INFO.value.type !== 'LoginUserInfo')
      return ''
    return USER_INFO.value[key] || ''
  }

  /**
   * 更新用户信息
   * @param data
   */
  function setUserInfo(data?: UserInfoResponse) {
    USER_INFO.value = data ? { type: 'LoginUserInfo', ...data, expires: Date.now() + USER_INFO_EXPIRES } : { type: 'GhostUserInfo', expires: 0 }
  }
  function setUserInfoItem<T extends keyof UserInfoResponse>(key: T, value: UserInfoResponse[T]) {
    if (USER_INFO.value.type !== 'LoginUserInfo')
      return
    USER_INFO.value[key] = value as any
  }
  /**
   * 更新token
   * @param data
   */
  function setToken(data: string, _isGetUserInfo = false) {
    USER_TOKEN.value = data
  }

  // 清除登录信息
  function clearLogin() {
    usePublishStore().reset()
    setToken('')
    setUserInfo()
  }

  // 保存登录回跳地址
  function saveLoginPrePage(backUrl?: string) {
    backPagePath.value = backUrl || '/'
  }
  // 返回登录前的页面
  function backPage() {
    const url = backPagePath.value || APP_ROUTER.home()
    // 清空
    backPagePath.value = ''

    goAnyPage(decodeURIComponent(url))
  }
  // 需要登录时处理函数
  function needLoginHandle(backUrl?: string) {
    saveLoginPrePage(backUrl ? decodeURIComponent(backUrl) : backUrl)
    setTimeout(() => {
      useMessage.warning('还未登录，请先登录')
      useLoginPopupEvent().showLoginPopup('phone')
    }, 500)
  }
  //
  function checkIsLogin() {
    if (IS_LOGIN.value)
      return true
    else
      needLoginHandle(isClient ? window.location.href : '')
  }

  // 判断当前页面是否需要登录
  function checkCurrentPageAuth() {
    const currentUrl = useRoute().path
    let needLogin = false

    for (const path of AUTH_PATH) {
      if (currentUrl === path) {
        needLogin = true
        break
      }
    }

    return needLogin
  }

  async function getUserInfo() {
    try {
      const res = await getUserInfoApi()
      if (res && res.data)
        setUserInfo(res.data)
    }
    catch (e) {
      recordError(e)
      useDialog.error({
        title: '获取用户信息失败，请刷新重试',
        content: getErrorMsg(e),
        closable: true,
        positiveText: '确定',
      })
    }
  }

  return {
    USER_TOKEN,
    USER_INFO,
    IS_LOGIN,
    USER_HEAD,
    USER_NAME,
    backPagePath,

    needLoginHandle, /* 需要登录时处理函数 */
    checkIsLogin,
    checkCurrentPageAuth, /* 判断当前页面是否需要登录 */
    saveLoginPrePage, /* 保存登录回跳地址 */
    backPage, /* 返回登录前的页面 */

    clearLogin,
    getUserInfoItem,
    setUserInfo,
    setUserInfoItem,
    setToken,
  }
}, {
  persist: generatePersist(),
})
