import provinceData from './province'
import cityData from './city'
import areaData from './area'
import type { NestProvince } from './index'

const nestProvince: NestProvince[] = []

const provinceChildMap = new Map()
const cityChildrenMap = new Map()
for (const area of areaData) {
  const cityId = area.f_id
  if (!cityChildrenMap.has(cityId))
    cityChildrenMap.set(cityId, [])

  cityChildrenMap.get(cityId).push(area)
}

for (const city of cityData) {
  const provinceId = city.f_id
  if (!provinceChildMap.has(provinceId))
    provinceChildMap.set(provinceId, [])

  provinceChildMap.get(provinceId).push({
    ...city,
    children: cityChildrenMap.get(city.id) || [],
  })
}

for (const province of provinceData) {
  nestProvince.push({
    ...province,
    children: provinceChildMap.get(province.id) || [],
  })
}

provinceChildMap.clear()
cityChildrenMap.clear()

export default nestProvince
