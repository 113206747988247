import { isClient } from '@qctsw/utils'
import NProgress from 'nprogress'

// https://nuxt.com/docs/api/advanced/hooks
export default defineNuxtPlugin((nuxtApp) => {
  /**
   * Environment Server & Client
   */
  // app:created
  const startTimestamp = performance.now()
  nuxtApp.hook('app:created', () => {
    // console.log('<<app:created>>')
  })
  // app:error
  nuxtApp.hook('app:error', () => {
    // console.log('<<app:error>>', e)
  })
  // app:error:cleared
  nuxtApp.hook('app:error:cleared', () => {
    // console.log('<<app:error>>:cleared')
  })
  // app:data:refresh
  nuxtApp.hook('app:data:refresh', () => {
    // console.log('<<app:data>>:refresh')
  })
  // vue:setup
  nuxtApp.hook('vue:setup', () => {
    // console.log('<<vue:setup>>')
  })
  // vue:error
  nuxtApp.hook('vue:error', () => {
    // console.log('<<vue:error>>')
  })

  /**
   * Environment Server
   */
  // app:rendered
  nuxtApp.hook('app:rendered', () => {
    // 返回的是一个未拼接的html对象
    // eslint-disable-next-line no-console
    console.log('<<app:rendered>>', performance.now() - startTimestamp)
  })
  // app:redirected
  nuxtApp.hook('app:redirected', () => {
    // console.log('<<app:redirected>>')
  })

  // app:beforeMount
  nuxtApp.hook('app:beforeMount', () => {
    // console.log('<<app:beforeMount>>')
  })
  // app:mounted
  nuxtApp.hook('app:mounted', () => {
    // console.log('<<app:mounted>>')
  })
  // page:start | only client side when page router change
  nuxtApp.hook('page:start', () => {
    // console.log('<<page:start>>', platform.value)
    if (isClient)
      NProgress.start()
  })
  // page:finish
  nuxtApp.hook('page:finish', () => {
    // console.log('<<page:finish>>')
    if (isClient)
      NProgress.done()
  })
  // page:transition:finish
  nuxtApp.hook('page:transition:finish', () => {
    // console.log('<<page:transition:finish>>')

  })
  // app:suspense:resolve
  nuxtApp.hook('app:suspense:resolve', () => {
    // console.log('<<app:suspense:resolve>>', nuxtApp.isHydrating, RELOAD_CBS().value)

    const reloadCbs = RELOAD_CBS().value
    if (reloadCbs.size > 0) {
      reloadCbs.forEach(cb => cb())
      RELOAD_CBS().value.clear()
    }
    // console.log('<<app:suspense:resolve>>')
  })
})
