export type TimeString = string

export enum LOAD_STATE {
  INIT = 'init',
  LOADING = 'loading',
  LOAD_MORE = 'loadMore',
  ERROR = 'error',
  END = 'end',
  NULL = 'null',
}

/**
 * 文件上传类型
 */
export enum FILE_UPLOAD_TYPE {
  userinfo = 'USERINFO',
  complain = 'COMPLAIN',
  article = 'ARTICLE',
  richtext = 'RICHTEXT',
  other = 'OTHER',
}

/**
 * 车系级别
 */
export enum SERIES_GRADE_ENUM {
  MPV = 'MPV',
  SUV = 'SUV',
  /**
   * 小型车
   */
  MINI = 'MINI',
  /**
   * 中型车
   */
  MEDIUM = 'MEDIUM', // 中型车
  /**
   * 中大型车
   */
  MEDIUM_LARGE = 'MEDIUM_LARGE',
  /**
   * 微卡
   */
  VACCAE = 'VACCAE',
  /**
   * 微型车
   */
  MINIATURE = 'MINIATURE',
  /**
   * 微面
   */
  MINIVAN = 'MINIVAN',
  /**
   * 皮卡
   */
  PICKUP = 'PICKUP',
  /**
   * 紧凑型车
   */
  COMPACT = 'COMPACT',
  /**
   * 跑车
   */
  ROADSTER = 'ROADSTER',
  /**
   * 轻客
   */
  TSINOVA = 'TSINOVA',
  /**
   * 低端皮卡
   */
  LOW_END_PICKUP = 'LOW_END_PICKUP',
  /**
   * 大型车
   */
  LARGE = 'LARGE',
  /**
   * 其他车型
   */
  OTHER = 'OTHER',
}

/**
 * 车保
 */
export enum CAR_INSURANCE_CODE_ENUM {
  firstInsurance = 9,
  beInsurance = 8,
  overInsurance = 7,
}

export interface RadioItem<T = any> {
  label: string
  value: T
  enum?: string
}

// 红黑榜

export interface redBlackItem {
  carBrandId?: number
  carBrandLogo?: string
  carVendorName?: string
  finishRate?: number
  qt?: number
  reason?: string
  total?: number
  [key: string]: any
}
export interface RedBlackDataSeg {
  id: number
  red: redBlackItem | null
  black: redBlackItem | null
  [key: string]: any
}

export interface TableItem {
  label: string
  content: string | Component
  isComponent?: boolean
  tips?: TableTips
  style?: any
  isShow?: boolean
}
export interface TableTips {
  className?: string
  content?: string
}
