export interface SiteConfig {
  title: string
  description: string
  keywords: string[]
}

export const SITE_NAME = '汽车投诉网'

export const DEFAULT_TITLE = '汽车投诉网_专业的汽车质量投诉平台'
export const DEFAULT_DESCRIPTION = '车主首选12315汽车网上投诉平台、汽车召回中心、汽车产品质量监督维权平台、工信部授权汽车质量、汽车缺陷分析平台，为汽车消费者提供汽车投诉排行榜以及协同汽车经销商、汽车客服建立三方沟通机制，推动汽车产业向前向好发展。'
export const DEFAULT_KEYWORDS = ['汽车投诉网', '汽车', '汽车网站', '汽车网', '新闻', '评测', '视频', '汽车之家', '车友', '车友圈', '社区', '汽车投诉', '车质网', '汽车质量维权', '汽车救援', '车主投诉', '汽车投诉排行', '汽车质量排行', '汽车故障排行榜', '315投诉网', '汽车网上投诉', '汽车召回']
// 首页
export const HOME_SITE_META: SiteConfig = {
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION,
  keywords: [...DEFAULT_KEYWORDS],
}

// 资讯首页
export const NEWS_SITE_META: SiteConfig = {
  title: '资讯_汽车投诉网',
  description: '资讯-汽车投诉网,资讯频道为您提供最新最及时的汽车资讯、汽车召回、媒体关注尽在汽车投诉网汽车资讯！',
  keywords: ['资讯', '汽车资讯', '新闻', '热门资讯', '合作媒体', '媒体关注', '召回', '监管', '新车', '用车', '统计调查'],
}
// 资讯列表页
export const NEWS_CLASS_SITE_META: SiteConfig = {
  title: '资讯分类_汽车投诉网',
  description: '资讯分类-汽车投诉网,资讯频道为您提供最新最及时的汽车资讯、汽车召回、媒体关注尽在汽车投诉网汽车资讯！',
  keywords: ['全部资讯', '汽车', '新闻', '汽车投诉', '热门资讯', '行业资讯', '媒体关注', '召回', '监管', '新车', '用车', '统计调查', '专题', '政策法规'],
}

// 合作媒体
export const NEWS_MEDIA_SITE_META: SiteConfig = {
  title: '合作媒体_汽车投诉网',
  description: '合作媒体-汽车投诉网,汽车投诉网合作媒体相关信息和相关资讯',
  keywords: ['电台', '新闻', '汽车', '汽车投诉', '电视台', '网络媒体', '报刊杂志', '合作媒体'],
}

export const COMPLAINT_SITE_META: SiteConfig = {
  title: '投诉_汽车投诉网',
  description: `${DEFAULT_DESCRIPTION}广大汽车车主发布的汽车销售维权、汽车质量维权、汽车售后维权等相关投诉信息`,
  keywords: [...DEFAULT_KEYWORDS, '汽车', '汽车投诉', '已解决投诉', '热门投诉', '最新投诉', '一追到底', '集体投诉', '关联投诉', '相关投诉', '多次投诉', '企业回复'],
}

export const COMPLAINT_CLASS_SITE_META: SiteConfig = {
  title: '投诉分类_汽车投诉网',
  description: `${DEFAULT_DESCRIPTION}广大汽车车主发布的汽车销售维权、汽车质量维权、汽车售后维权等相关投诉信息;${DEFAULT_DESCRIPTION}`,
  keywords: [...DEFAULT_KEYWORDS, '汽车', '汽车投诉', '所有投诉', '集体投诉', '多次投诉', '一追到底', '今日投诉', '典型投诉', '最多评论', '图片投诉', '视频投诉', '最新投诉', '最多综述', '升幅最大', '热门投诉', '最多次数', '已结案',
  ],
}
export const COMPLAINT_SEARCH_SITE_META: SiteConfig = {
  title: '投诉搜索_汽车投诉网',
  description: DEFAULT_DESCRIPTION,
  keywords: [...DEFAULT_KEYWORDS, '汽车', '汽车投诉', '投诉', '汽车质量投诉', '汽车质量', '汽车维权', '汽车缺陷', '汽车服务'],
}

export const COLLECTIVE_SITE_META: SiteConfig = {
  title: '集体投诉_汽车投诉网',
  description: DEFAULT_DESCRIPTION,
  keywords: [...DEFAULT_KEYWORDS, DEFAULT_TITLE, '汽车', '汽车投诉', '集体投诉', '集体投诉_汽车投诉网'],
}

export const MY_SITE_META: SiteConfig = {
  title: '个人中心_汽车投诉网',
  description: '汽车投诉网用户中心',
  keywords: ['我的投诉', '我的评论', '设置', '投诉客服', '投诉帮助', '车辆管理', '再次投诉'],
}

export const PUBLISH_SITE_META: SiteConfig = {
  title: '发布投诉_汽车投诉网',
  description: `${DEFAULT_DESCRIPTION}投诉汽车相关问题，进行汽车销售维权、汽车质量维权、汽车售后维权`,
  keywords: [...DEFAULT_KEYWORDS, '汽车', '发布投诉', '汽车投诉', '发表投诉', '315投诉网', '网上投诉', '12315网上投诉平台', '汽车质量投诉网'],
}

export const TOPIC_SITE_META: SiteConfig = {
  title: '专题_汽车投诉网',
  description: '汽车销售维权、汽车质量维权、汽车售后维权、汽车展览等相关信息',
  keywords: ['专题', '汽车', '汽车投诉', '投诉专题', '车展专题'],
}

export const REPUTATION_SITE_META: SiteConfig = {
  title: '口碑_汽车投诉网',
  description: `${DEFAULT_DESCRIPTION}汽车口碑统计信息,口碑_汽车投诉网`,
  keywords: ['口碑', '汽车', '汽车投诉', '汽车口碑', '口碑_汽车投诉网'],
}

export const RED_BLACK_SITE_META: SiteConfig = {
  title: '红黑榜_汽车投诉网',
  description: '红黑榜_汽车投诉网',
  keywords: ['红黑榜', '汽车', '汽车投诉', '汽车榜单', '周榜', '日榜', '红黑榜_汽车投诉网'],
}

export const STATISTIC_DEFAULT_SITE: SiteConfig = {
  title: '统计_汽车投诉网',
  description: `${DEFAULT_DESCRIPTION}统计_汽车投诉网`,
  keywords: ['直接统计', '汽车', '汽车投诉', '分组统计', '品牌对比', '投诉量统计', '统计_汽车投诉网'],
}

export const HELP_DEFAULT_SITE: SiteConfig = {
  title: '帮助_汽车投诉网',
  description: '帮助_汽车投诉网',
  keywords: ['会员注册协议', '汽车', '汽车投诉', '网站帮助', '关于我们', '商务合作', '隐私政策', '友情链接', '法律顾问团', '撤贴须知'],
}
