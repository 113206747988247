export const DEFAULT_CHECK_FILE_SIZE_STATUS = { status: false, title: '文件大小校验失败', message: '无法获取当前文件大小' }
export const DEFAULT_CHECK_FILE_TYPE_STATUS = { status: false, title: '文件类型校验失败', message: '无法获取当前文件类型' }

/**
 * 校验文件大小
 * @param fileSize
 * @param accept
 * @returns
 */
export function checkFileSize(fileSize: number, accept: number) {
  const acceptByte = accept * 1024 * 1024
  if (acceptByte < fileSize) {
    return {
      status: false,
      title: '文件上传失败',
      message: `文件大小超出限制，当前文件上传大小限制为${accept}M`,
    }
  }
  return {
    status: true,
    title: '',
    message: '',
  }
}

export function checkFileType(type: string, accept: string) {
  const baseType = type.split('/')[0]
  const acceptObject = getCheckAccepts(accept)
  let isTrue = false
  switch (baseType) {
    case 'image':{
      isTrue = checkImageType(type, acceptObject.image.join(','))
      break
    }
    case 'video': {
      isTrue = checkImageType(type, acceptObject.video.join(','))
      break
    }
    case 'audio': {
      isTrue = checkImageType(type, acceptObject.audio.join(','))
      break
    }
    default: {
      isTrue = false
    }
  }

  if (isTrue) {
    return {
      status: true,
      title: '',
      message: '',
    }
  }

  return {
    status: false,
    title: '文件上传失败',
    message: `文件类型错误，当前文件类型限制为${accept}，而当前文件类型为${type}`,
  }
}

export function checkImageType(type: string, accept: string) {
  if (accept.includes('*'))
    return true
  const accepts = accept.split(',')
  for (const item of accepts) {
    if (item.trim() === type)
      return true
  }

  return false
}

export function getCheckAccepts(accept: string) {
  const acceptObject: Record<string, string[]> = {
    image: [],
    video: [],
    audio: [],
  }
  const accepts = accept.split(',')
  for (const item of accepts) {
    switch (item.split('/')[0]) {
      case 'image': {
        acceptObject.image.push(item)
        break
      }
      case 'video': {
        acceptObject.video.push(item)
        break
      }
      case 'audio': {
        acceptObject.audio.push(item)
        break
      }
    }
  }

  return acceptObject
}
