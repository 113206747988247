import { v4 as uuidv4 } from 'uuid'
import { isClient } from '@qctsw/utils'
import type { NitroFetchContext, RequestControllerKey } from './types'
import { generateRequestKey } from './core'

const TIMEOUT_KEYS = new Map<string, NodeJS.Timeout>()
/**
 * 取消重复请求
 */
export function useAbort() {
  const abortPrePageKeys: RequestControllerKey[] = []
  // 用于存储pending的请求
  const pendingRequest = new Map<RequestControllerKey, { controller: AbortController, timeoutKey: string }>()
  const extendsApi: string[] = []

  function addPendingRequest(config: NitroFetchContext) {
    const key = generateRequestKey(config.request, config.options)

    const url = config.request as string
    // 判断pendingRequest中是否存在key
    if (pendingRequest.has(key) || extendsApi.some(api => url?.includes(api)))
      return

    // https://developer.mozilla.org/zh-CN/docs/Web/API/AbortController/AbortController
    const controller = new AbortController()
    config.options.signal = controller.signal

    let timeoutKey: NodeJS.Timeout | null = null

    const sideText = isClient ? '客户端' : '服务端'
    if (config.options.timeout) {
      timeoutKey = setTimeout(() => {
        if (controller.signal.aborted) {
          // eslint-disable-next-line no-console
          console.log(
            `%c[request abort because timeout] %c${key}`,
            'background: #ef4444; padding: 2px 4px; border-radius: 3px 0 0 3px',
            'background: #fecaca; padding: 2px 4px; border-radius: 0 3px 3px 0;',
          )
          controller.abort(new Error(`停止超时请求-${sideText}:(${config.options.timeout}ms)`))
        }
        if (timeoutKey) {
          timeoutKey && clearTimeout(timeoutKey)
          timeoutKey = null
        }
      }, config.options.timeout)
    }
    const uuid = timeoutKey ? uuidv4() : ''
    uuid && timeoutKey && TIMEOUT_KEYS.set(uuid, timeoutKey)
    pendingRequest.set(key, { controller, timeoutKey: uuid })
  }

  // removePendingRequest 取消重复请求
  function removePendingRequest(config: NitroFetchContext, type: 'abort' | 'clear' = 'clear') {
    const key = generateRequestKey(config.request, config.options)
    clearPendingRequest(key, type)
  }

  function clearPendingRequest(key: RequestControllerKey, type: 'abort' | 'clear' = 'clear') {
    if (!pendingRequest.has(key))
      return

    const { controller, timeoutKey } = pendingRequest.get(key) || {}

    if (timeoutKey) {
      const key = TIMEOUT_KEYS.get(timeoutKey)
      key && clearTimeout(key)
    }
    if (type === 'abort' && controller && !controller.signal.aborted) {
      // eslint-disable-next-line no-console
      console.log(
        `%c[request abort because repetition] %c${key}`,
        'background: #a855f7; padding: 2px 4px; border-radius: 3px 0 0 3px',
        'background: #e9d5ff; padding: 2px 4px; border-radius: 0 3px 3px 0;',
      )
      controller.abort(new Error('终止重复请求'))
    }
    pendingRequest.delete(key) // 请求对象中删除requestKey
  }

  return {
    abortPrePageKeys,
    pendingRequest,
    addPendingRequest,
    removePendingRequest,
    clearPendingRequest,
  }
}
